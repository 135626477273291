import React from "react";
import { Modal } from "antd";

import PermissionDetail from "../admin/PermissionDetail";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";

const ModalUserPermission = ({ visible, perm, setVisible, needUpdate, setNeedUpdate = () => { }, selection = false, accountForce = null }) => {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();

  const tag = accountState.filters.some(filtre => filtre.type === 'directory') && accountState.activeTab === "2" && !accountState.globalSearch ?
    {
      label: accountState.filters.filter(filtre => filtre.type === 'directory')[0].element['name'],
      value: accountState.filters.filter(filtre => filtre.type === 'directory')[0].element['id']
    }
    : false;
  const account = accountState.globalSearch ? accountState.group : accountState.account

  const onCancel = () => {
    setNeedUpdate(needUpdate + 1)
    setVisible(false);
  }

  // console.log(accountState.filters.some(filtre => filtre.type === 'directory') ? accountState.filters.filter(filtre => filtre.type === 'directory')[0].element : null)

  const emailResolver = (perm) => {
    let email = perm.user ? perm.user.email : perm.email;
    return email ?? 'Modifier la permission';
  }

  return (
    visible ?
      <Modal
        visible={visible}
        centered
        title={perm ? emailResolver(perm) : t("Ajouter une permission")}
        closable={true}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}
        width={600}
      >

            <PermissionDetail account={accountForce ? accountForce : account} user={perm ? perm.user : null}
              permission={perm} onClose={onCancel} forceTag={tag}
              selection={selection} />
      </Modal> :
      ''
  );
};


export default ModalUserPermission;
