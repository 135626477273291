import React, { useEffect, useRef, useState } from "react";
import { Empty, Spin } from 'antd';
import { useApiContext } from "../../../providers/ApiProvider";
import { useTranslation } from "react-i18next";
import MediaView from "../MediaView";

export default function CartCard({ cart, setSelectedCart, fetchThumbs }) {

  const { t } = useTranslation()
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const [loadingThumbs, setLoadingThumbs] = useState(false)
  const [assets, setAssets] = useState([])
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    setLoadingThumbs(true)
    fetchThumbs()

    return () => {
      isMounted.current = false;
    }
  }, [])

  async function fetchThumbs() {
    let params = {
      itemsPerPage: 4,
      filters: [{ name: "tags.name", value: cart.id }, { name: "status", value: 1 }, { name: "obsolete", value: 0 }, { name: "isPrivate", value: 0 }]
    }
    apiFetchCollection('assets', params, response => {
      if (!isMounted.current) return
      if (response['@type'] === 'hydra:Error') {
        console.log(response['description'])
      } else {
        setAssets(response['hydra:member'])
      }
      setLoadingThumbs(false)
    })
  }

  return (
    <div className="item-card pointer" onClick={() => setSelectedCart(cart)} key={cart.id} style={{ backgroundColor: '#fff' }}>
      <div className="mosaic flex around wrap align_center">
        {
          assets.length > 0 ? assets.map(asset => (
            <div className="img" key={asset.id}>
              <MediaView asset={asset} />
            </div>
          ))
            :
            <Spin size="small" spinning={loadingThumbs} >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Vide')} />
            </Spin>
        }
      </div>
      <div className="cart-title text-center">
        {cart.name}
      </div>
    </div>
  )

}
