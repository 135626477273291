import React, { useEffect, useState, useRef } from 'react';
import { Tag, Spin } from 'antd';
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";

const TagsList = () => {

  const { t, i18n } = useTranslation();
  const [apiDispatch] = useApiContext();
  const [loading, setLoading] = useState(true)
  const { apiFetchCollection } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { filter } = accountDispatch;
  const [tags, setTags] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    setLoading(true)
    getDynamicTag({
      filters: accountState.filters.concat({ name: 'pagination', value: 'false' })
    })
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [accountState.filters, accountState.rerender, accountState.rerenderTags0, accountState.account]);

  function getDynamicTag(params) {

    let tags = getTags()
    params.filters.push({ name: 'tags', value: tags })

    if (accountState.globalSearch) {
      if (accountState.accountActive) {
        params.filters.push({ name: 'account', value: accountState.accountActive['id'] });
      }
      apiFetchCollection('get_tag_filters', params, (response) => setDynamicTags(response))
    } else {
      params.filters.push({ name: "account", value: accountState.account.id })
      apiFetchCollection('get_tag_filters', params, (hydra_response) => {
        setDynamicTags(hydra_response)

      });
    }
  }

  function getTags() {
    return accountState.filters.filter(item => item.name === 'tags.name[]').reduce((prev, current) => {
      prev.push(current.id)
      return prev
    }, []).join(',');
  }

  function setDynamicTags(response) {
    setTags(response)
    setLoading(false)
  }


  function handleClick(item) {
    setLoading(true)
    filter({
      ids: item.ids ? item.ids : null,
      id: item.id,
      value: item.id,
      name: 'tags.name[]',
      tagName: item.name,
      type: 'tag'
    })
  }

  return (
    <Spin spinning={loading} >
      <div className='tag-list'>
        {
          tags.length > 0
            ?
            tags.sort((a, b) => {
              let textA = a.name.toUpperCase();
              let textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }).map((item) => (
              <Tag
                className={accountState.filters.filter(filtre => filtre.id === item.id).length == 1 ? 'isFiltering' : null}
                key={item.id}
                data={item.name}
                color={'blue'}
                onClick={(e) => { handleClick(item) }}
              >
                {item.name} ({item.assets_count})
              </Tag>
            ))
            :
            <div style={{ textAlign: 'center' }}>
              <em>{t('Aucun tag')}</em>
            </div>
        }
      </div>
    </Spin>
  );
};

export default TagsList;
