import React from 'react'
import DocumentIcon from '../../asset/DocumentIcon'
import VideoView from '../../asset/VideoView'
import Placeholder from '../../../assets/img_placeholder.svg'
import { useAccountContext } from '../../../providers/AccountProvider';

export default function MediaView({ asset, title }) {

  const [accountState] = useAccountContext();
  const { appearance } = accountState
  const media = asset.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset.medias[0];
  const transparentBg = media && (media.mimeType === 'image/png' || media.mimeType === 'image/svg' || media.mimeType === 'image/svg+xml');

  function getUrl() {
    if (media.mimeType === 'image/gif') return media.contentUrl
    return media.extraUrl.sm.url
  }

  if (!media) return <></>
  if (asset.type === 3)
    return <VideoView asset={asset} title={appearance.size === 'small' ? null : title} />

  if (asset.type === 1)
    return <img
      className={`pointer ${appearance?.card === 'list' && 'thumb-classical'} ${transparentBg ? 'bg-pattern-'+appearance?.background : ''}`}
      src={media.extraUrl ? getUrl() : Placeholder} />

  if (asset.type === 2) {
    return <DocumentIcon asset={asset} title={title} />
  }
}
