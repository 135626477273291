import React from 'react';
import {Icon, Tooltip} from "antd";

const TagPopup = ({tags}) => {

  const content = (
    !!tags.length &&
    <div>
      {tags.map(tag => (
        <div key={tag.id}>{tag.name}</div>
      ))}
    </div>
  )

  return (
    <Tooltip title={content}>
      <div>
        {tags.length}
        &nbsp;
        {tags.length ? <Icon type="tag" theme="filled"/> : <Icon type="tag"/>}
      </div>
    </Tooltip>
  );
};

export default TagPopup;
