import React, { useState } from 'react'
import { Button, Dropdown, Menu, message, Rate } from 'antd';
import { useTranslation } from "react-i18next";
import { useApiContext } from "../providers/ApiProvider";
import { useMediaQuery } from 'react-responsive'

export default function RateAction({ selection, rerender }) {

  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [note, setNote] = useState(0)

  const handleChange = async (value) => {
    setNote(value)
    let params = {
      note: value
    }
    await Promise.all(selection.map(async asset => {
      await updateAsset(asset, params)
    }))
    setNote(0)
    rerender()
  }

  const updateAsset = async (asset, params) => {
    await apiUpdateEntity('assets', asset.id, params, response => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t('Il y a eu une erreur'))
      }
    })
  }

  const moveMenu = (
    <Menu>
      <Menu.Item key="rate">
        <Rate onChange={handleChange} value={note} />
      </Menu.Item>
      <Menu.Item key="remove"
        onClick={() => handleChange(0)}>
        {t('Retirer les notes')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={moveMenu} trigger={["click"]}>
      <Button className='mr' size="small" shape="round" icon="star">
        {!isTabletOrMobile && t('Noter')}
      </Button>
    </Dropdown>
  )
}
