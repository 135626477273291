import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import { useAccountContext } from "../../providers/AccountProvider";
import { useParams } from "react-router-dom";
import { useApiContext } from "../../providers/ApiProvider";
import { useAssetContext } from "../../providers/AssetProvider";

import ActionBar from './ActionBar';
import ActiveFilters from "./ActiveFilters";
import BtnModalCreateCart from '../modal/BtnModalCreateCart.js';
import BtnSelectVeryAll from "./button/BtnSelectVeryAll.js";
import Media from '../../pages/Media';
import DisplayCards from "./DisplayCards";
import SubDirectory from "./SubDirectory";
import Selectable from "./Selectable";
import { useMediaQuery } from 'react-responsive';

const AssetsSort = (props) => {

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [accountState] = useAccountContext();
  const { filters } = accountState
  const [assetState, assetDispatch] = useAssetContext();
  const { assets, itemsPerPage, visible, loading, total, page } = assetState;
  const { setAssets, setLoading, setTotal, setVisible, setPage, updateAsset } = assetDispatch;

  const params = useParams();

  const [firstLoading, setFirstLoading] = useState(true);
  const [assetId, setAssetId] = useState(0);
  const [selected, setSelected] = useState(() => new Set());
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    setLoading(true)
    setFirstLoading(true);
    fetch(1);
    if (page > 1) setPage(1);
  }, [accountState.filters, accountState.rerender]);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      fetch(page);
    }
  }, [page]);

  function fetch(page) {
    let baseFilters = props.baseFilters || []
    let data = {
      id: params.account_id,
      itemsPerPage: itemsPerPage,
      page: page,
      filters: [...accountState.filters, ...baseFilters]
    }

    apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
      hydrateAssets(response, page);
    })
  }

  function hydrateAssets(response, page) {
    if (response['@type'] === 'hydra:Error') return;

    if ((response['hydra:totalItems'] / itemsPerPage) <= page - 1)
      setPage(1);

    if (page === 1)
      setAssets(response['hydra:member']);
    else
      setAssets([...assets, ...response['hydra:member']]);

    setTotal(response['hydra:totalItems']);
    setFirstLoading(false)
    setTimeout(() => setLoading(false), 1500)
  }

  function unselectAll() {
    setSelected(new Set());
  }

  function selectAll() {
    const next = new Set([...selected]);

    assets.map(item => {
      if (!next.has(item.id))
        next.add(item.id)
    })

    setSelected(next);
  }


  const messageSelectAll = <BtnSelectVeryAll
    itemsChecked={selected}
    setItemsChecked={setSelected}
  />

  const updateAssetId = useCallback((value) => {
    let index = assets.findIndex(item => item.id === value);
    let loadMore = false;

    if ((index >= assets.length - 5) && total > assets.length)
      loadMore = true;

    setAssetId(value)
    if (loadMore)
      setPage(prev => prev + 1);

  }, [assets, total])

  const hasMore = !(total / itemsPerPage < page);
  const filterDirectoryActive = filters.some(filter => filter.type === 'directory')
  const scrollerRef = useRef(null);

  return (
    <Spin spinning={firstLoading}>

      <ActiveFilters
        selection={selected}
        selectAll={selectAll}
        unselectAll={unselectAll}
        messageSelectAll={messageSelectAll}
      />

      {
        accountState.editor ?
          <ActionBar
            sortSelection={selected}
            setSortSelection={setSelected}
          />
          : null
      }

      {
        accountState.editor && !isTabletOrMobile ?
          <Selectable setSelected={setSelected} scrollerRef={scrollerRef} />
          : null
      }
      <div className="assets-list selectable-container" ref={scrollerRef}>
        <DisplayCards
          {...props}
          selected={selected}
          setSelected={setSelected}
          selectable={accountState.editor}
          setAssetId={updateAssetId}
          assets={assets}
          page={page}
          setPage={setPage}
          loading={loading}
          hasMore={hasMore}
        />

        {filterDirectoryActive && !hasMore && !loading &&
          <SubDirectory
            {...props}
            selected={selected}
            setSelected={setSelected}
            id={filters.find(filter => filter.type === 'directory').id}
          />}
      </div>

      <BtnModalCreateCart
        visible={visible}
        setVisible={setVisible}
        btnVisible={false}
      />

      {!!assetId && <Media assets={assets} assetId={assetId} setAssetId={updateAssetId} updateAsset={updateAsset} />}

    </Spin>
  );
}

export default AssetsSort;
