import React, { useState } from 'react'
import { Button, Icon, Modal } from 'antd'

import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";
import UploadFolder from "../Upload/UploadFolder";
import UploadMedias from '../Upload/UploadMedias';

export default function ModalUploadFiles({ showBtnText = true, size = 'default' }) {

  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const [isUploadingFiles, setIsUploadingFiles] = useState(true)
  const [isUploading, setIsUploading] = useState(false)

  const closeModal = () => {
    setIsModalVisible(false);
    rerender()
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Modal
        title={t('Importer vos photos et images')}
        centered
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        {
          isUploadingFiles ?
            <>
              <UploadMedias
                callBack={closeModal} multiple={true}
                mode={'create'} 
                isUploading={isUploading} setIsUploading={setIsUploading}
                visible={isModalVisible}
                setVisible={setIsModalVisible}
              />
              {isUploading ? null :
                <p className="change-upload"
                  onClick={() => setIsUploadingFiles(false)}>{t('Envie d\'importer un dossier ? cliquez ici (beta)')}</p>}
            </>
            : <>
              <UploadFolder accountState={accountState} visible={isModalVisible} closeModal={closeModal}
                setIsUploading={setIsUploading} />

              {
                isUploading ? null :
                  <p className="change-upload"
                    onClick={() => setIsUploadingFiles(true)}>{t('Cliquez ici pour importer des fichiers')}</p>
              }
            </>
        }
      </Modal>
      <Button
      className='import-btn'
        type="primary" size={size}
        shape={showBtnText ? 'round' : 'circle'}
        icon={isUploading ? "" : "cloud-upload"}
        onClick={openModal}
        key="upload"
      >
        {isUploading && <Icon type="loading" spin/>}
        {showBtnText ? t('Importer') : null}
      </Button>
    </>
  )
}
