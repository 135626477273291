import { Button, Checkbox, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function Resize({ save, image }) {

    const { t } = useTranslation();
    const [keepRatio, setKeepRatio] = useState(false)
    const [size, setSize] = useState({})

    useEffect(() => {
        setSize({ width: image.naturalWidth, height: image.naturalHeight })
    }, [image])

    function handleSizeChange(value, property) {
        let ratio = property === 'width' ? image.naturalHeight / image.naturalWidth : image.naturalWidth / image.naturalHeight;

        if (keepRatio) {
            let secondProperty = property === 'width' ? 'height' : 'width'
            setSize({ [property]: value, [secondProperty]: Math.round(value * ratio) })
        } else {
            setSize(prev => { return { ...prev, [property]: value } })
        }
    }

    function toggleKeepRatio() {
        if (!keepRatio) {
            let ratio = image.naturalHeight / image.naturalWidth;
            setSize(prev => {return { ...prev, height: Math.round(prev.width * ratio)} })
        }
        setKeepRatio(!keepRatio)
    }

    return (
        <div style={{ padding: 10 }}>
            <Checkbox onChange={toggleKeepRatio} checked={keepRatio}>{t('Garder le ratio')}</Checkbox>
            <div style={{ margin: '5px 0' }}>
                <span style={{ marginRight: '10px' }}>{t('Largeur')} :</span>
                <InputNumber value={size.width} style={{ width: 120 }} min={0} max={image.naturalWidth}
                    formatter={value => `${value}px`}
                    parser={value => value.replace('p', '').replace('x', '')}
                    onChange={(value) => handleSizeChange(value, 'width')} />
            </div>
            <div style={{ margin: '5px 0' }}>
                <span style={{ marginRight: '10px' }}>{t('Hauteur')} :</span>
                <InputNumber value={size.height} style={{ width: 120 }} min={0} max={image.naturalHeight}
                    formatter={value => `${value}px`}
                    parser={value => value.replace('p', '').replace('x', '')}
                    onChange={(value) => handleSizeChange(value, 'height')} />
            </div>
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('resize', size)} />
                </Button.Group>
            </div>
        </div>
    )
}
