import React from 'react'
import { useTranslation } from 'react-i18next';

import { Button } from 'antd'

export default function Mirror({ mirror, setMirror, save }) {

    const { t } = useTranslation();

    function reverse(axe) {
        if (axe === 'x') {
            let value = mirror[0] > 0 ? -1 : 1
            setMirror(prev => [value, prev[1]])
        } else {
            let value = mirror[1] > 0 ? -1 : 1
            setMirror(prev => [prev[0], value])
        }
    }

    return (
        <div style={{ padding: 10 }}>
            <div>
                <Button style={{ marginRight: 10 }} type="primary" title="rotate"
                    onClick={() => reverse('x')}>{t('Horizontal')}</Button>
                <Button style={{ marginRight: 10 }} type="primary" title="rotate"
                    onClick={() => reverse('y')}>{t('Vertical')}</Button>
            </div>
            <div style={{ margin: '5px 0' }}>
                <Button type="primary" icon="check" onClick={() => save('mirror')} />
            </div>
        </div>
    )
}
