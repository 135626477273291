import React, {useContext} from 'react';
import {useImmer} from 'use-immer';

const defaultState = {
  assets: [],
  page: 1,
  loading: true,
  total: 0,
  itemsPerPage: 50,
  duplicate: false,
  selected: new Set(),
  visible: false,
  activeCard: 'mosaic-card-masonry',
  typeCards: ["classic-card", "card-list", "mosaic-card-masonry", "mosaic-card-square"]
};

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const AssetProvider = ({children}) => {
  const [state, dispatch] = useImmer({...defaultState});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

function useStateContext() {
  const state = useContext(StateContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  return state;
}

function useDispatchContext() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (state === undefined)
    throw new Error("Ut oh, where is my state?");

  if (dispatch === undefined)
    throw new Error("Ut oh, where is my dispatch?");


  function setter(key, value) {
    dispatch(draft => {
      draft[key] = value
    })
  }

  function setPage(value) {
    dispatch(draft => {
      draft.page = value
    })
  }

  function setAssets(value) {
    dispatch(draft => {
      draft.assets = value
    })
  }

  function setLoading(value) {
    dispatch(draft => {
      draft.loading = value
    })
  }

  function setDuplicate(value) {
    dispatch(draft => {
      draft.duplicate = value
    })
  }

  function setTotal(value) {
    dispatch(draft => {
      draft.total = value
    })
  }

  function setSelected(value) {
    console.log('value', value)
    dispatch(draft => {
      draft.selected = value
    })
  }

  function updateAsset(data) {
    // console.log('eee', data)
    dispatch(draft => {
      draft.assets = draft.assets.map(item => item.id === data.id ? data : item)
    })
  }

  function setVisible(value) {
    dispatch(draft => {
      draft.visible = value
    })
  }

  function setActiveCard(value) {
    dispatch(draft => {
      draft.activeCard = value
    })
  }

  return {
    setter,
    setPage,
    setAssets,
    setLoading,
    setDuplicate,
    setTotal,
    setSelected,
    setVisible,
    setActiveCard,
    updateAsset
  };
}

const useAssetContext = () => {
  return [useStateContext(), useDispatchContext()]
};

export {useAssetContext, AssetProvider, StateContext, DispatchContext};
