import React, { useEffect, useRef, useState } from "react";
import { Button, Form, message, Modal, Spin, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";

const { TreeNode } = TreeSelect;

/**
 * -Front
 * --Modal pour trier/transferer les assets d'un dossier a un autre
 */
const ModalSortSelection = ({ form, visible, setVisible, duplicate, selected, setSelected, setLoading }) => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, patch } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const [tags, setTags] = useState([])
  const [value, setValue] = useState(undefined)
  const isMounted = useRef(null);
  const [modalLoading, setModalLoading] = useState(false)
  const [treeTags, setTreeTags] = useState([])
  const { getFieldDecorator, getFieldValue } = form;

  function onSelectChange(value) {
    setValue(value);
  }

  useEffect(() => {
    isMounted.current = true;
    fetchFolders()
    return () => {
      isMounted.current = false;
    }
  }, [accountState])

  const fetchFolders = () => {
    setModalLoading(true)
    let params = {
      filters: [{
        name: 'account',
        value: accountState.account.id
      }]
    };
    apiFetchCollection('get_folders', params, (response) => {
      if (isMounted.current) {
        setTags(getAllTags(response))
        setTreeTags([
          <TreeNode value="unsort" title={<strong>A trier</strong>} key='sortFolder_unsort' />,
          ...loop(response)
        ]);
        setModalLoading(false);
      }
    });
  }

  const getAllTags = (tagList) => {
    let tags = []
    tagList.map(tag => {
      let children = getAllTags(tag.childTags)
      tags = [...tags, tag, ...children]
    })
    return tags;
  }

  const onCancel = () => {
    setVisible(false);
  }

  const handleOk = () => {
    console.time("sort")
    setLoading(true)
    setModalLoading(true)
    const value = getFieldValue('dossier');
    const tag = value !== 'unsort' ? tags.filter(item => item['@id'] === value)[0] : null;
    const data = {
      assetIds: [...selected],
      dirId: tag ? tag.id : value,
      rename: accountState.account.renameOn,
      duplicate: duplicate
    }

    patch(`/api/account/${accountState.account.id}/sort-assets`, data, response => {
      message.info(t('Action terminé'))
      setModalLoading(false)
      setSelected(new Set())
      onCancel();
      rerender();
      console.timeEnd("sort");
    })
  }

  const loop = (defaultTree) => {
    return defaultTree.map(item => {
      if (item.childTags && item.childTags.length) {
        return (
          <TreeNode value={item['@id']} title={item.name} key={'sortFolder_' + item.id}>
            {loop(item.childTags)}
          </TreeNode>
        );
      } else {
        return <TreeNode value={item['@id']} title={item.name} key={'sortFolder_' + item.id} />;
      }
    });
  };

  return (
    <Modal
      visible={visible}
      title={t('Choisissez un dossier')}
      closable={true}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('Annuler')}
        </Button>,
        <Button key="ok" onClick={handleOk}>
          {t('Valider')}
        </Button>,
      ]}
    >
      <Spin spinning={modalLoading}>
        <Form layout="vertical">
          <Form.Item label={t('Dossier')}>
            {getFieldDecorator("dossier")(
              <TreeSelect
                name="dossierParent"
                showSearch
                style={{ width: '100%' }}
                setFieldsValue={value}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={t('Choisissez un dossier')}
                allowClear
                treeDefaultExpandAll
                onChange={onSelectChange}
                treeNodeFilterProp="title"
              >
                {treeTags}
              </TreeSelect>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};


export default Form.create()(ModalSortSelection);
