import React, { useEffect, useState } from 'react'
import { Button, message, Radio, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { downloadResource } from '../../helpers';
import { useAccountContext } from '../../providers/AccountProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import { useApiContext } from '../../providers/ApiProvider';

const api_endpoint = process.env.API_ENTRYPOINT + '/';
const qualityRights = {
    source: 4, original: 4,
    lg: 3, "2160p": 3, "1440p": 3, "1080p": 3,
    md: 2, "720p": 2, "540p": 2,
    sm: 1, "360p": 1, "240p": 1
}

export default function DownloadMenu({ asset, maxQuality }) {

    const [authState] = useAuthContext();
    const { auth } = authState;
    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiPostEntity } = apiDispatch;
    const { t } = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const [toDownload, setToDownload] = useState(maxQuality ? maxQuality : 'original')
    const [links, setLinks] = useState([])
    const [loading, setLoading] = useState(false)
    const [media, setMedia] = useState(null)

    useEffect(() => {
        setMedia(asset.currentMedia ? asset.medias.filter(media => media.id === asset.currentMedia)[0] : asset.medias[0]);
    }, [asset.id])

    useEffect(() => {
        if (!media) return
        if (media?.extraUrl?.lg?.size) {
            setLinks(media?.extraUrl)
        } else if (asset.type === 3 && media.vimeoId) {
            fetchLinks()
        } else if (asset.type === 1) {
            fetchMedia()
            // setLinks(media.extraUrl)
        }
    }, [media])

    const fetchLinks = () => {
        setLoading(true)
        apiFetchEntity('video-download', media.id, response => {
            // console.log('response', response)
            if (!response?.original) {
                message.error('Impossible de récupérer les liens de téléchargement')
            } else {
                setLinks(response)
            }
            setLoading(false)
        })
    }

    const fetchMedia = () => {
        setLoading(true)
        apiFetchEntity('media_objects', media.id, response => {
            if (!response?.extraUrl) {
                message.error('Impossible de récupérer les liens de téléchargement')
            } else {
                setLinks(response.extraUrl)
            }
            setLoading(false)
        })
    }

    function downloadMedia(e) {
        // console.log('media', media)
        e.stopPropagation()
        if (asset.type === 3 && media?.vimeoId) {
            return window.open(links[toDownload].url, '_blank')
        }
        let url, filename;
        if (toDownload === 'original') {
            let ext = '.' + media.contentUrl.split('.').pop()
            url = media.contentUrl;
            filename = asset.usageName + ext;
        } else {
            let it = links[toDownload];
            url = it.url;
            let ext = '.' + url.split('.').pop()
            filename = `${asset.usageName}-${toDownload}${ext}`;
        }
        try {
            downloadResource(url, filename)
            const data = {
                type: "download",
                userEntity: "/api/users/" + auth.id,
                assetEntity: asset['@id'],
                mediaEntity: media['@id'],
                accountEntity: accountState.account['@id'],
                cropType: toDownload,
                size: links?.[toDownload] ? String(links[toDownload].size) : String(media.fileSize),
                quantity: 1
            }
            apiPostEntity('statistics', data, response => {
                //console.log(response)
            })
        } catch (e) {
            console.log(e)
        }
        setToDownload(null)
    }

    //determine if a quality should be displayed
    //mainly used to display menu in visitor mode
    function shouldDisplayLink(quality) {
        // console.log('quality, media', quality, media)
        if (media.mimeType === 'image/gif') return false
        if (quality === 'th'
            || qualityRights[quality] > qualityRights[maxQuality]) return false

        return true
    }

    const copyLink = (e) => {
        e.stopPropagation()
        const ext = '.' + media.fileName.split('.').pop()
        console.log('asset', asset)
        let size = toDownload && asset?.type === 1 ? `?size=${toDownload}` : ''
        let link = `${api_endpoint}share/${asset.uuid}/${asset.usageName}${ext}${size}`
        navigator.clipboard.writeText(link);
        message.success('Lien copié dans le presse papier')
    }

    if (loading) {
        return <Spin spinning={loading} />
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            {media?.dimensions &&
                <div>
                    {
                        asset.type !== 2 &&
                        <Radio.Group value={toDownload} onChange={e => setToDownload(e.target.value)}>
                            {
                                (asset.type === 1 || !media.vimeoId) && (maxQuality === 'original' || media.mimeType === 'image/gif') &&
                                <Radio className="radio_drop"
                                    value={'original'}>Original {media?.dimensions.length ? `(${media.dimensions[0]} x ${media.dimensions[1]})` : ''}</Radio>
                            }
                            {
                                links && Object.keys(links).map(key => {
                                    if (shouldDisplayLink(key)) {
                                        return (
                                            <Radio className="radio_drop" key={key}
                                                value={key}>{`${key} (${links[key].width} x ${links[key].height})`}</Radio>
                                        )
                                    }
                                })
                            }
                        </Radio.Group>
                    }
                    <div className="flex between" style={{ margin: '.5em' }}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={e => downloadMedia(e)}
                        >
                            {t('Télécharger')}
                        </Button>
                        {
                            asset.type !== 3 &&
                            <Button
                                type="primary"
                                size="small"
                                onClick={e => copyLink(e)}
                            >
                                {t('Lien')}
                            </Button>
                        }
                    </div>
                </div>}
        </div>
    )
}
