import React from "react";

export default function Csv(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width='100%'
    >
      <path
        fill={props.fill}
        d="M365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448ZM225 293L257 389C259 396 265 400 272 400S285 396 287 389L319 293C322 285 317 276 309 273C301 270 292 275 289 283L272 333L255 283C252 275 243 270 235 273C227 276 222 285 225 293ZM183 304H192C201 304 208 297 208 288S201 272 192 272H183C161 272 144 289 144 311C144 321 148 331 155 338L174 357C175 358 176 360 176 361C176 365 173 368 169 368H160C151 368 144 375 144 384S151 400 160 400H169C191 400 208 383 208 361C208 351 204 341 197 334L178 315C177 314 176 312 176 311C176 307 179 304 183 304ZM112 304C121 304 128 297 128 288S121 272 112 272C86 272 64 294 64 320V352C64 378 86 400 112 400C121 400 128 393 128 384S121 368 112 368C103 368 96 361 96 352V320C96 311 103 304 112 304Z"      />
    </svg>
  )
}
