import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Input, message } from 'antd';
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from '../providers/ApiProvider.js';
import { useTranslation } from "react-i18next";
import { checkSimilarTags } from "../helpers";
import { useAuthContext } from '../providers/AuthProvider.js';
import { useMediaQuery } from 'react-responsive';

const { Search } = Input;
const { Option, OptGroup } = AutoComplete;

// liste toutes les commandes existantes
const commands = ['reset:account', 'reset:assets', 'reset:tags']

const SearchInput = ({ visible, callback = () => { }, mobileMode = false }) => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter, apiFetchCollection } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { filter, resetFilter, rerender } = accountDispatch;
  const [value, setValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [apiResults, setApiResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const searchRef = useRef('');

  useEffect(() => {
    if (visible) document.getElementById('searchInput').focus()
  }, [visible])

  useEffect(() => {
    searchRef.current = value;
  }, [value]);

  // Appelé lors de la validation de la recherche
  const handleValidation = (value, event) => {
    event.stopPropagation()

    if (commands.includes(value) && auth.roles.includes('ROLE_ADMIN'))
      return hackResetAccount(value);

    if ((event.key === 'Enter' && searchResult.length > 0) ||
      value.trim().length < 1) return

    let filtre = {
      id: "search_" + value,
      name: 'usageName',
      type: 'search',
      value: value.trim()
    };

    filter(filtre);
    setSearchResult([])
    setValue('');
    callback()
  };

  function formattedDate() {
    function pad(s) {
      return (s < 10) ? '0' + s : s;
    }

    let d = new Date()
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
  }

  function hackResetAccount(command) {
    const response = prompt('Ou peut-on avoir a faire a du CO2 compressé ?')
    const action = command.split(':').pop()
    if (response.toLowerCase().trim().includes('plage')) {
      const params = {
        id: accountState.account.id,
        filters: [
          { name: 'hash', value: formattedDate() },
          { name: 'action', value: action }
        ]
      }
      apiFetchSubResourceWithFilter('accounts', params, 'reset_account', response => {
        resetFilter()
        rerender()
      })
      return message.success('Salutation camarade')
    }
    message.error('Instruisez vous plus a l\'avenir')
  }

  // Récupère les tags en fonction de la valeur de l'input
  const getTags = (value) => {
    console.log('value', value)
    const params = {
      id: accountState.account.id,
      filters: [
        { id: 'tag', name: 'type', value: 0 },
        { id: 'name', name: 'name', value: value }
      ]
    }

    if (accountState.globalSearch) {
      params.itemsPerPage = 30;
      if (accountState.accountActive) {
        params.filters = params.filters.concat({ name: 'account[]', value: accountState.accountActive['@id'] });
      } else {
        params.filters = params.filters.concat({ name: 'account[]', value: accountState.account['@id'] });
        accountState.account.childAccount.map(account => {
          params.filters = params.filters.concat({ name: 'account[]', value: account['@id'] });
        })
      }
      apiFetchCollection('tags', params, (response) => handleApiResponse(response))
    } else {
      apiFetchSubResourceWithFilter('accounts', params, 'tags', (hydra_response) => {
        handleApiResponse(hydra_response)
      })
    }
  }

  function handleApiResponse(response) {

    if (response['@type'] === 'hydra:Collection') {
      let results = response['hydra:member'].filter(item => item.assets.length > 0)

      if (results.length && searchRef?.current) {
        if (accountState.globalSearch) {
          results = checkSimilarTags(results);
        }
        const options = buildDataSource(results, value);
        setApiResults([...results])
        setSearchResult(prev => [prev[0], options])
      }
      setLoading(false)
    }
  }

  //Appelé à chaque changement de l'input, lance la recherche de correspondance
  const handleSearch = (search) => {
    setLoading(true)
    clearTimeout(searchTimeout)
    setValue(search);
    search = search.trim();
    if (!search) {
      setLoading(false)
      return setSearchResult([]);
    }
    setSearchResult([<OptGroup key="keyword" label={t('Mot Clé :')}>
      <Option key="word">{search}</Option>
    </OptGroup>])
    setSearchTimeout(setTimeout(() => {
      getTags(searchRef.current)
    }, 300))

  }

  //Prépare les options à afficher
  const buildDataSource = (results) => {
    return <OptGroup key="tagDescriptif" label={t('Tag descriptif') + ' :'}>
      {results.map(item => (
        <Option key={item['id']}>
          {item.name}
          <span className="certain-search-item-count">{item.assets.length} {t('Media(s)')}</span>
        </Option>
      ))}
    </OptGroup>;
  }

  // Filtre lors de la selection d'une option proposée
  const handleSelection = (value, option) => {
    resetFilter();
    let filtre = {};
    let isWordSearch = option.key === "word"

    filtre = {
      id: isWordSearch ? "search_" + option.props.children : Number(value),
      value: isWordSearch ? option.props.children.trim() : Number(value),
      name: isWordSearch ? 'usageName' : 'tags.name[]',
      tagName: isWordSearch ? null : option.props.children[0],
      type: isWordSearch ? 'search' : 'tag'
    };

    if (accountState.globalSearch) {
      let ids = apiResults.filter(item => item.id == value);
      if (ids.length) {
        filtre.ids = apiResults.filter(item => item.id == value)[0].ids
      }
    }

    filter(filtre);
    setSearchResult([])
    setValue('');
    callback()
  }

  const handleMobileSelection = (value, item) => {
    resetFilter();
    let filtre = {
      id: !item ? "search_" + value : Number(value),
      value: !item ? value : Number(value),
      name: !item ? 'usageName' : 'tags.name[]',
      tagName: !item ? null : item.name,
      type: !item ? 'search' : 'tag'
    };

    if (accountState.globalSearch) {
      let ids = apiResults.filter(item => item.id == value);
      if (ids.length) {
        filtre.ids = apiResults.filter(item => item.id == value)[0].ids
      }
    }

    filter(filtre);
    setSearchResult([])
    setValue('');
    callback()
  }

  return (
    <>
      <AutoComplete
        defaultOpen
        className="global-search"
        onSearch={handleSearch}
        onSelect={handleSelection}
        optionLabelProp="text"
        dataSource={isTabletOrMobile ? [] : searchResult}
      >
        <Search id="searchInput" className="searchbar" placeholder={t('Rechercher un nom, un tag')}
          onSearch={handleValidation} loading={loading}
          style={{ fontSize: '16px' }} autoComplete="off" value={value} inputMode="search"
          onClick={(e) => e.stopPropagation()}
        />
      </AutoComplete>
      {
        mobileMode &&
        <div>
          {
            apiResults.map(item => (
              <div onClick={() => handleMobileSelection(item.id, item)} key={item.id} className='search-mobile-option'>
                {item.name}
                <span className="certain-search-item-count">{item.assets.length} {t('Media(s)')}</span>
              </div>
            ))
          }
        </div>
      }
    </>
  );
};

export default SearchInput;
