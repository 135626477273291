import React from 'react'
import {Checkbox} from 'antd';
import {useTranslation} from "react-i18next";

function CheckboxTagPermission({tag, _item, onChange, lvl, checkProperty}) {

  const {t, i18n} = useTranslation();

  return (
    <div style={{marginLeft: '1.5em'}}>
      <Checkbox
        checked={_item[checkProperty].includes(tag['@id'])}
        onChange={(e) => onChange(e, tag, checkProperty)}
        disabled={(_item.tags.includes(tag.parentTag) && !_item.share) || _item.isGlobal}
      >
        {tag.name}
      </Checkbox>
      {
        tag.childTags.length > 0 ?
          tag.childTags.map(child =>
            <CheckboxTagPermission key={child.id} tag={child} onChange={onChange} _item={_item} lvl={lvl+1} checkProperty={checkProperty}/>
        )
          : null
      }
    </div>
  )
}

export default CheckboxTagPermission
