import React, { useState } from 'react';
import { useApiContext } from '../../../providers/ApiProvider.js';
import { Spin, Icon, Button, message, Menu, Dropdown } from 'antd';
import BtnModalCreateCart from '../../modal/BtnModalCreateCart.js'
import { useTranslation } from "react-i18next";
import CartList from "./CartList";
import CartDetails from './CartDetails.js';
import { useAccountContext } from '../../../providers/AccountProvider.js';

/**
 * -Front
 * --Contenu onglet Paniers
 */
const Cart = () => {

  const [accountState, accountDispatch] = useAccountContext();
  const { setter } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiDeleteEntity } = apiDispatch;
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation();
  const [selectedCart, setSelectedCart] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  function beforeDelCart(value) {
    if (value.key === "no") {
      return;
    }
    setIsDeleting(true)
    apiDeleteEntity('tags', value.key, response => {
      message.info(t('Panier supprimé'))
      setter('cartsNeedUpdate', (new Date()).getTime());
      setIsDeleting(false)
    })
  }

  const menu = (
    <Menu onClick={beforeDelCart}>
      {
        accountState.carts.map(cart => (
          <Menu.Item key={cart.id}>
            <a>{cart.name}</a>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  if (selectedCart)
    return <CartDetails
      selectedCart={selectedCart}
      setSelectedCart={setSelectedCart}
    />

  return (
    <Spin spinning={accountState.cartsLoading}>
      <div className="tab-topbar">
        <div className='flex'>
          <BtnModalCreateCart
            visible={visible}
            setVisible={setVisible}
            btnVisible={true}
          />
          {
            accountState.carts.length > 0 ?
              <Dropdown overlay={menu} trigger={['click']} disabled={isDeleting}>
                <Button style={{ marginLeft: 15 }} size="small" type="danger">
                  {t('Supprimer')} {isDeleting ? <Icon type="loading" spinning /> : <Icon type="down" />}
                </Button>
              </Dropdown>
              : null
          }
        </div>
      </div>
      <CartList accountCarts={accountState.carts} setSelectedCart={setSelectedCart} />
    </Spin>
  );
}

export default Cart;
