import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom'
import {Form, Icon, Input, Checkbox, Button, Spin, message} from 'antd'
import ModalForgetPassword from '../components/modal/ModalForgetPassword.js'
import {useAuthContext} from '../providers/AuthProvider.js';
import {useApiContext} from '../providers/ApiProvider.js';
import Register from "./Register";

const Login = ({form}) => {

  const [apiDispatch] = useApiContext();
  const {postRequestBasic} = apiDispatch;
  const {t} = useTranslation();
  const {getFieldDecorator} = form;

  const [authState, authDispatch] = useAuthContext();
  const {authLogin, authIsLogged} = authDispatch;

  const [processing, setProcessing] = useState(false);
  const [visible, setVisible] = useState(false);

  const [isLogin, setIsLogin] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setProcessing(true);
        // console.log('Received values of form: ', values);
        authLogin(values.username, values.password, (response) => {
          if (response.error) {
            message.error(response.error)
            setProcessing(false)
          }
        });

      }
    });
  };

  if (authIsLogged())
    return <Redirect to="/dashboard"/>

  return (
    isLogin ?
      <div id="login-form" className='login-container' style={{marginTop: "5%"}}>
        <h2>{t('Connexion')}</h2>

        <Spin tip={t('Connexion en cours ...')} spinning={processing}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{required: true, message: t('Entrez votre email!')}],
              })(
                <Input
                  prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                  placeholder="Ex: monadresse@mail.com"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{required: true, message: t('Entrez votre mot de passe!')}],
              })(
                <Input
                  prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                  type="password"
                  placeholder="Mot de passe"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>{t('Se souvenir de moi')}</Checkbox>)}
              <a className="login-form-forgot" onClick={() => setVisible(true)}>
                {t('Mot de passe oublié')}
              </a>
              <Button type="primary" htmlType="submit" className="login-form-button">
                {t('Connexion')}
              </Button>
              {t('Ou')}<Button type="link"
                               onClick={() => setIsLogin(false)}>{t('Inscrivez-vous maintenant')}!</Button>
            </Form.Item>
          </Form>
          <ModalForgetPassword visible={visible} setVisible={setVisible} postRequestBasic={postRequestBasic}/>
        </Spin>

      </div> : <Register setIsLogin={setIsLogin}/>
  );
};

export default Form.create()(Login);
