import React, { useState } from 'react';
import { Badge, Button, Drawer, Dropdown, Icon, Menu } from 'antd';
import { useAuthContext } from '../../providers/AuthProvider.js';
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";
import { NotificationDropdown } from "../dropdown/NotificationDropdown";
import SearchInput from "../SearchInput";
import AppMenu from './AppMenu.js';

const HeaderMenu = ({ setIsDownloadModalVisible }) => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { authIsLogged } = authDispatch;

  const [accountState, accountDispatch] = useAccountContext();
  const [isNotifDrawerVisible, setIsNotifDrawerVisible] = useState(false);

  const [isSearching, setIsSearching] = useState(false)

  const searchMenu = (
    <Menu>
      <Menu.Item key={"search"}>
        <SearchInput visible={isSearching} />
      </Menu.Item>
    </Menu>
  )

  const handleVisibleChange = (e) => {
    setIsSearching(e)
  }

  if (!authIsLogged()) return null

  return (
    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
      <>
        {accountState.account &&
          <Dropdown overlay={searchMenu} placement="bottomRight" trigger={['click']}
            onVisibleChange={handleVisibleChange}>
            <Button className="ml-10" shape="circle"
              style={{ backgroundColor: 'black', color: 'white', borderColor: 'black' }}
            >
              <Icon type="search" />
            </Button>
          </Dropdown>}
        <Drawer
          placement="right"
          closable="true"
          visible={isNotifDrawerVisible}
          onClose={() => setIsNotifDrawerVisible(false)}
          title={t('Notifications')}
          width={400}
        >
          {<NotificationDropdown />}
        </Drawer>
        <Badge count={accountState.notifications.length}>
          <Button className="ml-10" shape="circle"
            style={{ backgroundColor: '#aaa', color: 'white' }}
            onClick={() => setIsNotifDrawerVisible(true)}>
            <Icon type="notification" />
          </Button>
        </Badge>
      </>
      <Dropdown overlay={<AppMenu setIsDownloadModalVisible={setIsDownloadModalVisible} />} placement="bottomRight" trigger={['click']}>
        <Button className="ml-10" shape="circle" style={{
          backgroundColor: "#0cc800",
          color: 'white',
          border: "#0cc800"
        }}>
          {auth.username[0].toUpperCase()}
        </Button>
      </Dropdown>
    </div>
  )
}

export default HeaderMenu;
