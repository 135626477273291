import React from 'react';
import { Checkbox, Dropdown, Icon, Tag } from "antd";
import Stars from "../../../Stars";
import TagPopup from "../../../asset/TagPopup";
import { handleDblClick } from "../../helpers";
import ContextMenu from '../ContextMenu';
import MediaView from '../MediaView';
import { useTranslation } from 'react-i18next';
import AssetDraggable from '../../../asset/AssetDraggable';
import { useAccountContext } from '../../../../providers/AccountProvider';

const Masonry = ({ item, openFullscreen, isSelected, selected, setSelected, selectable, selectionPreview = false, setLoading, draggable = false }) => {
  
  const [accountState] = useAccountContext();
  const { appearance } = accountState;
  const { t } = useTranslation()
  const isTranscoding = item.type === 3 && item?.['medias'][0]?.status === 'transcoding';

  function handleChange() {
    if (selectable) {
      setSelected(prev => {
        const next = new Set(prev);
        if (isSelected) {
          next.delete(item.id)
        } else {
          next.add(item.id)
        }
        return next;
      })
    }
  }

  return (
    <Dropdown overlay={<ContextMenu asset={item} />} trigger={item.type !== 3 ? ['contextMenu'] : []}>
      <AssetDraggable
        duplicate={false}
        asset={item}
        itemsChecked={selected}
        setItemsChecked={setSelected}
        setLoading={setLoading}
        draggable={draggable}
      >
        <div
          className={`item-card pointer selectable-asset${isSelected ? ' card-selected' : ''}`}
          data-key={item.id} onDoubleClick={() => { !selectionPreview ? handleDblClick(item) : console.log('nop') }}
        >
          {(selectable) &&
            <Checkbox
              className={`item-card-checkbox${isSelected ? '' : ' see-on-hover'}`}
              checked={isSelected} onChange={handleChange}
            />}
          {
            !selectionPreview &&
            <Icon
              className="logo_fs_range see-on-hover black full-top-right"
              type="fullscreen"
              onClick={openFullscreen}
            />
          }
          <MediaView asset={item} title />
          <div className="see-on-hover asset-actions">
            <p title={item.usageName}>{item.usageName}</p>
            {
              appearance?.size !== 'small' &&
              <div className="flex between align_center">
                <Stars stars={item.note} inTooltip disabled />
                <TagPopup tags={item.tags.filter(tag => tag.type === 0)} />
              </div>
            }
          </div>
          <div className='hide-on-hover asset-status' style={{ position: 'absolute', bottom: '.5em', right: '.5em' }}>
            {isTranscoding ? <Tag color="red" title={t('En cours d\'optimisation')}>{t('Optimisation')}</Tag> : null}
          </div>
        </div>
      </AssetDraggable>
    </Dropdown>
  );
};

export default Masonry;
