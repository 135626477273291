import React, {useState} from "react";
import {Card, Input, List} from "antd";

export default function DocumentationList({apiEndpoint, setRequestUrl}) {

  const [list, setList] = useState(apiEndpoint ? apiEndpoint.params : [])

  const handleInputChange = (item, e) => {
    let newList = [...list]
    newList.map(elem => {
      if (elem.name === item.name) elem.value = e.target.value.trim()
    })
    setList(newList)
  }

  const handleTry = () => {
    let url = apiEndpoint.url
    let isFirst = true;

    list.map(item => {
      if (url.includes(`{${item.name}}`)) {
        url = url.replace(`{${item.name}}`, encodeURIComponent(item.value))
      } else if (item.value) {
        url += `${isFirst ? '' : '&'}${item.name}=${item.value}`
        if (isFirst) isFirst = false
      }
    })
    // console.log(url)
    setRequestUrl(url)
  }

  return (
    <Card title={apiEndpoint?.title} extra={<a onClick={handleTry}>Try</a>}>
      <h4>Query parameters</h4>
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={item => (
          <List.Item actions={[
            <Input name={item.name} value={item.value} onChange={(e) => handleInputChange(item, e)}/>,
          ]}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Card>

  )
}
