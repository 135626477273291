import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import EditDirectories from "./EditDirectories";
import DirectoryOverview from "./DirectoryOverview";
import {Divider, TreeSelect, PageHeader} from "antd";
import {useApiContext} from "../providers/ApiProvider";
import {formatFolderForSelect} from "../helpers";
import DropdownTagAdmin from "./tag/DropdownTagAdmin";

export default function DirectoriesOverview({activeTab}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(0)
  const [orderedDirectories, setOrderedDirectories] = useState([]);

  const params = useParams();

  useEffect(() => {
    if (activeTab === 'dossier') {
      setSelectedDirectory(null)
      fetchOrderedDirectories();
    }
  }, [needUpdate, activeTab]);


  const fetchOrderedDirectories = () => {
    let requestParams = {
      filters: [{
        name: 'account',
        value: params.account_id,
      }]
    };
    apiFetchCollection('get_folders', requestParams, (response) => {
      setOrderedDirectories(response);
    });
  }

  const onChange = (value) => {
    let id = value.replace('/api/tags/', '');
    setSelectedDirectory(id)
  };

  return (
    <div className="contenu-tab">
      {
        selectedDirectory === null ?
          <>
            <div style={{margin: '16px 0 '}}>
              <PageHeader title={t('Modifier les dossiers')} ghost={false}/>
              <Divider>{t('Editer un dossier')}</Divider>
              <span style={{marginRight: '15px'}}>{t('Quel dossier éditer')} : </span>
              <TreeSelect
                style={{width: '50%'}}
                value={selectedDirectory ? selectedDirectory['@id'] : null}
                dropdownStyle={{maxHeight: 300, overflow: 'auto'}}
                placeholder="Choisissez un dossier"
                allowClear
                showSearch={false}
                treeDefaultExpandAll
                onChange={onChange}
              >
                {formatFolderForSelect(orderedDirectories, 0)}
              </TreeSelect>
            </div>
            <EditDirectories setParentNeedUpdate={setNeedUpdate} parentNeedUpdate={needUpdate}/>
          </>
          :
          <DirectoryOverview selectedDirectory={selectedDirectory} setSelectedDirectory={setSelectedDirectory}
                             setNeedUpdate={setNeedUpdate} needUpdate={needUpdate}/>
      }

    </div>
  )

}
