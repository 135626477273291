import React from "react";
import { Icon, Card, Empty } from 'antd';
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";

const { Meta } = Card;


export function NotificationDropdown() {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { deleteNotification } = accountDispatch;

  if (!accountState.notifications.length)
    return (
      <div key="empty">
        <Card
          size="small"
          style={{ whiteSpace: 'normal' }}>
          <Empty description={t('Vous n\'avez aucune notification')} />
        </Card>
      </div>
    )

  return (
    <div style={{ overflow: 'auto' }}>
      {
        accountState.notifications.map((item, index) =>
          <div key={index} >
            <Card
              title={item.message}
              size="small"
              extra={<Icon type="close" onClick={() => {
                deleteNotification(item)
              }} />}
              style={{ whiteSpace: 'normal', marginBottom: '1em' }}>
              <Meta description={item.description} />
            </Card>
          </div>
        )
      }
    </div>
  )

}
