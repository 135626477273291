import React, { useEffect, useRef, useState } from 'react'
import { useApiContext } from '../../providers/ApiProvider.js';
import { useAuthContext } from '../../providers/AuthProvider.js';
import { useAccountContext } from '../../providers/AccountProvider.js';
import { useTranslation } from "react-i18next";
import { Icon, Upload, message } from 'antd';
import { docExtensions, docTypes, imageExtensions, imageTypes } from './helpers.js';

const UpdateFile = ({ callBack, asset }) => {
  console.log('asset', asset)
  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiFetchSubResourceWithFilter, apiFetchEntity } = apiDispatch;
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(0);
  const isMounted = useRef(false);
  const maxUpload = 64;
  const [freeSpace, setFreeSpace] = useState(0)
  const acceptedType = asset.type === 2 ? docTypes : imageTypes
  const accept = asset.type === 2 ? docExtensions : imageExtensions;

  useEffect(() => {
    isMounted.current = true;
    setLoading(true)
    fetchStorage()
    return () => {
      isMounted.current = false;
    }
  }, [accountState.rerender])

  const fetchStorage = () => {
    apiFetchEntity('storages', accountState.account.storage.id, response => {
      if (isMounted.current) {
        setFreeSpace(response.maxSize - response.currentSize)
      }
      setLoading(false)
    })
  }


  const handleUpload = async ({ file }) => {
    setLoading(true);
    setIsUploading(true)

    let formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountState.account.id);
    formData.append('action', 'patch');
    formData.append('assetId', asset.id);

    apiPostEntity("media_objects", formData, (response) => {
      if (response['@type'] === "hydra:Error") {
        const error = response['hydra:description'];
        message.error(error.type ? t(error.type) + " " + error.file : t('Ce fichier ne peut être traité'))
      }
      console.log('response', response);
      close(response)

    });
  };

  const beforeUpload = (file, fileList) => {

    if (file.size > freeSpace) {
      message.error(t('Vous n\'avez plus assez d\'espace pour uploader ces images, veuillez en libérer depuis les réglages du compte ou passer a un compte prenium plus'));
      resetHooks();
      return false;
    }

    const isUnderLimit = file.size / 1024 / 1024 < maxUpload;

    const isTypeOk = acceptedType.includes(file.type);
    return new Promise((resolve, reject) => {
      if (!isTypeOk || !isUnderLimit) {
        let message = '';
        if (!isTypeOk) {
          message = t('Ce format n\'est pas accepté')
        }
        if (!isUnderLimit) {
          message = `${t('Taille max')} ${maxUpload} Mo`
        }

        message.error(message)
        reject(file);
      } else {
        file.index = fileList.indexOf(file);
        resolve(file);
      }
    });
  };

  function close(mediaObject) {

    let params = { id: accountState.account.id }
    apiFetchSubResourceWithFilter('accounts', params, 'update', response => { })
    const dataStat = {
      type: 'update',
      size: String(mediaObject.fileSize),
      accountEntity: "/api/accounts/" + accountState.account.id,
      userEntity: "/api/users/" + auth.id,
      quantity: 1,
    }
    apiPostEntity('statistics', dataStat, response => {
      
      resetHooks(mediaObject)
    })
  }

  function resetHooks(mediaObject) {

    setIsUploading(false);
    setLoading(false);
    callBack(mediaObject);
  }

  return (
    <div>
      <Upload.Dragger
        showUploadList={false}
        name="file"
        customRequest={handleUpload}
        beforeUpload={beforeUpload}
        disabled={loading}
        accept={accept}
      >
        {!isUploading ?
          <div>
            <p className="ant-upload-drag-icon">
              <Icon type="cloud-upload" />
            </p>
            <div>
              <p
                className="ant-upload-text">{t('Cliquez ici ou déplacez le nouveau média sur cette zone pour le mettre à jour')}</p>
            </div>
          </div>
          :
          <div>
            <p className="ant-upload-drag-icon">
              {isUploading ? <Icon type="sync" spin /> : <Icon type="check" />}
            </p>
            <div>
              <p
                className="ant-upload-text">{isUploading ? t('Fichier en cours de téléchargement et d\'optimisation') : t('Le traitement de vos fichiers est terminer')}</p>
            </div>
          </div>
        }
      </Upload.Dragger>
    </div>
  )
}

export default UpdateFile
