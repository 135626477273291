import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Divider, Icon, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import {useApiContext} from '../providers/ApiProvider.js';

export default function ColorPalette({id}) {

  const {t, i18n} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter} = apiDispatch;
  const [colors, setColors] = useState([])
  const isMounted = useRef(null);

  const [toolTipTitle, setToolTipTitle] = useState('Copier');
  useEffect(() => {
    isMounted.current = true;

    requestTags();
    return () => {
      isMounted.current = false;
    }
  }, [])

  const requestTags = () => {
    //console.log('id', params.media_id)
    let parameters = {id: id}
    apiFetchSubResourceWithFilter('assets', parameters, 'colors', (response) => {
      if (isMounted.current) {
        //console.log(response);
        setColors(response.colors.colors);
      }
    })
  }

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setToolTipTitle(`${str} ${t('Copié')}`);
    setTimeout(() => {
      setToolTipTitle(t('Copier'));
    }, 1000)
  };

  return (
    <div>
      <p className='bold'>{t("Couleurs")}</p>
      <div className="color-panel">
        {colors.map((color, index) =>
          <Tooltip key={'color_' + index} placement="topLeft" title={toolTipTitle} arrowPointAtCenter={true}>
            <div className="color-circle" style={{
              border: color === '#ffffff' ? 'solid 1px black' : '',
              backgroundColor: `${color}`,
              width: '35px',
              height: '35px',
            }}
                 onClick={() => copyToClipboard(color)}
            >
              <Icon type="copy" className="centered-icon"/>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

