import React from 'react';
import {Drawer, Layout} from 'antd';
import NavPrivate from './NavPrivate.js';
import { useMediaQuery } from 'react-responsive';

const Sidebar = ({collapsed, setCollapsed}) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  if (isTabletOrMobile) {
    return (
      <Drawer
        visible={!collapsed}
        onClose={() => setCollapsed(true)}
        placement="left"
        bodyStyle={{padding: 0}}
      >
        <NavPrivate />
      </Drawer>
    )
  }

  return (
    <Layout.Sider
      theme="light"
      className="search-bar-side sidebar-layout1"
      width={250}
    >
      <NavPrivate />
    </Layout.Sider>
  );
};

export default Sidebar;
