import React, { useEffect, useRef, useState } from 'react';
import { useParams, } from 'react-router-dom'

import { Button, Layout } from 'antd';
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import WidgetHeader from "./WidgetHeader";
import { useAuthContext } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";
import AccountSidebar from "../layout/AccountSidebar";
import Assets from "./v2/Assets";
import { useMediaQuery } from 'react-responsive';
import { AssetProvider } from '../../providers/AssetProvider';

const { Content } = Layout;

const WidgetLayout = ({ children, hasLayout = true, ...rest }) => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { setAuth } = authDispatch
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiPostEntity } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { accountSet, resetFilter, rerender, setActiveTab } = accountDispatch;
  const [collapsed, setCollapsed] = useState(false);
  const params = useParams();
  const [loadingAccount, setLoadingAccount] = useState(true)
  const [account, setAccount] = useState(null)

  const [error, setError] = useState(null);
  const isMounted = useRef(true);
  const [folders, setFolders] = useState([]);

  const search = new URLSearchParams(window.location.search)
  const switchedOn = search.get('switched') === 'on';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    if (params.token) {
      setAuth({ token: params.token, roles: ['ROLE_BOT'] })
    } else {
      setError("Certains éléments sont manquant")
      setLoadingAccount(false)
    }

  }, []);

  useEffect(() => {
    if (auth && auth.token === params.token) {
      fetchAccount(params.url)
    }
  }, [auth])

  const fetchAccount = () => {

    let url = document.referrer || 'test.com';
    apiPostEntity('from-token-to-account-perm', { url: url }, response => {
      if (response.id) {
        response['@id'] = `/api/accounts/${response.id}`
        accountSet(response, false, false, true)
        setAccount(response)
        let folderFilter = accountState.filters.find(filter => filter.type === 'directory')
        if (folderFilter) setActiveTab('2')

        if (!folderFilter || folderFilter.element?.account_id != response.id)
          resetFilter()
      } else {
        setError("Certains éléments sont manquant")
      }
      setLoadingAccount(false)
    })
  }

  useEffect(() => {
    if (account) {
      isMounted.current = true;
      // fetch at startup and whenever needUpdate is changed
      fetchTags({
        filters: [
          {
            name: 'account',
            value: account.id
          }
        ]
      });
    }
    return () => {
      setFolders([]);
      isMounted.current = false;
    }
  }, [account, accountState.rerender]);

  function fetchTags(params) {

    apiFetchCollection('get_folders', params, (hydra_response) => {
      if (isMounted.current) {
        setFolders(hydra_response)
      }
    });
  }

  if (loadingAccount) return 'loading'

  if (error)
    return (
      <div style={{ textAlign: 'center', padding: '2em' }}>
        <div>{t('Erreur')} : {error}</div>
        <Button type="primary" onClick={() => window.close()}>{t('Fermer')}</Button>
      </div>
    )

  return (
    <Layout className="root-layout" style={{ height: '100vh' }} hasSider={rest.sidebar}>
      <AssetProvider>
        {
          !hasLayout ?
            <Content style={{ minHeight: '100vh' }}>
              {React.cloneElement(children, { ...rest, folders: folders, accountId: account?.id })}
            </Content>
            :
            <>
              {!isTabletOrMobile &&
                <AccountSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
              }
              <Layout>
                <WidgetHeader switchedOn={switchedOn} />
                <Content className={rest.sidebar && 'has-sidebar widget'}>
                  <Assets rest={rest} folders={folders} accountId={account?.id} />
                </Content>
              </Layout>
            </>
        }
      </AssetProvider>
    </Layout>
  );
};

export default WidgetLayout;
