import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Dropdown, Layout, Menu, message, Radio } from 'antd';
import { useApiContext } from '../../providers/ApiProvider.js';
import { useTranslation } from "react-i18next";
import Hapidam_logo from '../../assets/logo_hapidam.svg'
import { resolveDownloadSizes } from "../../helpers";
import moment from "moment";
import { useAccountContext } from '../../providers/AccountProvider.js';
import { useMediaQuery } from 'react-responsive';

const HeaderSharing = ({ endDate, downloadMaxQuality, type = "share" }) => {

  const [accountState, accountDispatch] = useAccountContext()
  const { toggleVisitorView } = accountDispatch;
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { getRequestBasic } = apiDispatch;
  const [downloadLinks, setDownloadLinks] = useState({});
  const [expireText, setExpireText] = useState('');
  const [downloadQuality, setDownloadQuality] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const params = useParams();

  useEffect(() => {
    getTimeLeft(endDate);
  }, [endDate])

  const downloadZip = () => {
    if (downloadLinks.hasOwnProperty(downloadQuality)) {
      window.location = downloadLinks[downloadQuality]
    } else {
      setDownloadLoading(true)

      let data = {
        token: params.token,
        filters: [
          { name: 'download', value: true },
          { name: 'quality', value: downloadQuality },
          { name: "accessType", value: type }
        ]
      }

      getRequestBasic('/api/tags/visitor', data, response => {

        if (response.success) {
          window.location = response.link;
          setDownloadLinks(previousLinks => {
            return { ...previousLinks, [downloadQuality]: response.link }
          });
        } else {
          message.error(t('Il y a eu un problème, si il persiste contacter un administrateur'))
        }
        setDownloadLoading(false);
      })
    }
  };

  const getTimeLeft = (expireDate) => {

    if (!expireDate) return setExpireText('');

    let endDate = moment(expireDate)
    let daysLeft = endDate.diff(moment(), 'days');
    let hoursLeft = endDate.diff(moment(), 'hours')
    let minLeft = endDate.diff(moment(), 'minutes')

    if (daysLeft >= 1) {
      setExpireText(t('Expire dans') + ' ' + daysLeft + ' ' + t('Jour(s)'))
    } else if (hoursLeft >= 1) {
      setExpireText(t('Expire dans') + ' ' + hoursLeft + ' ' + t('Heure(s)'))
    } else if (minLeft > 0) {
      setExpireText(t('Expire dans') + ' ' + minLeft + ' ' + t('Minute(s)'))
    } else {
      setExpireText(t('Expiré'));
    }
  }

  function onRadioChange(e) {
    setDownloadQuality(e.target.value)
  }

  const downloadMenu = () => {
    let sizes = resolveDownloadSizes(downloadMaxQuality)
    return (
      <Menu>
        <Menu.Item key="qualitySetting" disabled={true}>
          <Radio.Group value={downloadQuality} onChange={onRadioChange}>
            {sizes.map(size =>
              <Radio key={`dl_size_${size}`} className="radio_drop" value={size}>{size.toUpperCase()}</Radio>
            )}
          </Radio.Group>
          <Button
            style={{ display: 'block', margin: '0 auto' }}
            loading={downloadLoading}
            type="primary"
            size="small"
            onClick={() => {
              downloadZip()
            }}
          >
            {t('Télécharger')}
          </Button>
        </Menu.Item>
      </Menu>
    )
  }


  return (
    <Layout.Header className="header flex between" >
      <div style={{ width: '200px' }}>
        <img alt="Hapidam" src={Hapidam_logo} style={{ width: '75%' }} />
      </div>
      <div className='flex align_center'>

        <span>{!isTabletOrMobile && expireText}</span>
        <Button
          className='ml' title={t('Vue par dossier')}
          onClick={toggleVisitorView} icon={'bars'}
          type={accountState.visitorView === 'directory' ? 'primary' : 'default'} />
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('download-button')}
          overlay={downloadMenu}
        >
          <Button className='ml' type="primary" icon="download" id="download-button">
            {!isTabletOrMobile && t('Télécharger')}
          </Button>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default HeaderSharing;
