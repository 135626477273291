import React, { useState } from 'react';
import { Button, Dropdown, Icon } from 'antd';
import { useTranslation } from "react-i18next";
import ModalEditDirectories from "../modal/ModalEditDirectories";
import TreeTag from '../tag/TreeTag';

const FolderDropdown = ({ accountState, rerender, size }) => {

  const { t } = useTranslation();
  const [editDirectoriesModal, setEditDirectoriesModal] = useState(false);
  const [hasChildrenOpen, setHasChildrenOpen] = useState(0)
  const [forceOpen, setForceOpen] = useState(false)


  const foldersList =
    <div className="my_dropdown" style={{ minWidth: '300px' }}>
      <div style={{ maxHeight: 500, overflow: 'auto' }}  className="sidebar-content">
      <TreeTag
              setEditDirectoriesModal={setEditDirectoriesModal}
              setHasChildrenOpen={setHasChildrenOpen}
              forceOpen={forceOpen}
            />
      </div>
    </div>

  const closeEdit = () => {
    setEditDirectoriesModal(false)
    rerender()
  }

  return (
    <>
      {accountState.admin && !accountState.globalSearch ?
        <ModalEditDirectories visible={editDirectoriesModal} handleClose={closeEdit}/>
        : null
      }
      <Dropdown overlay={foldersList} trigger={['click']}>
        <Button className="ml-10" size={size}>
          {t('Dossiers')} <Icon type="down" />
        </Button>
      </Dropdown>
    </>
  );
};

export default FolderDropdown;
