import React from 'react';
import { fileConvertSize, MEDIA_COLORS } from '../helpers.js'
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { Badge } from 'antd';


export default function GraphStorage({ storage, gestionAdmin = false }) {

  const { t } = useTranslation();
  const totalByType = storage?.totalByType
  const percentUsed = Math.round(storage.currentSize * 100 / storage.maxSize)

  const typeData = buildTypeData()

  function buildTypeData() {
    if (!totalByType) return []
    return Object.keys(totalByType).map(key => {
      return { title: key, value: totalByType[key].space * 100 / storage.currentSize, color: MEDIA_COLORS[key] }
    })
  }

  function getTotalAssets() {
    return Object.entries(storage.totalByType).reduce((prev, current) => {
      return prev + current[1].total
    }, 0)
  }

  return (
    <div className='graph-container'>
      <div className='flex'>
        <div style={{ maxWidth: 200 }}>
          <PieChart
            data={[{ value: 1, key: 1, color: '#1890ff' }]}
            startAngle={180}
            reveal={percentUsed}
            viewBoxSize={[100, 60]}
            lineWidth={20}
            background="#C7E3EE"
            lengthAngle={180} animate rounded
            label={() => `${percentUsed}%`}
          />
        </div>
        <div style={{ marginLeft: '1em' }} className="flex flex-column-reverse">
          <Badge color="#C7E3EE" text={`Espace total : ${fileConvertSize(storage.maxSize)}`} />
          <Badge color="#1890ff" text={`Espace utilisé : ${fileConvertSize(storage.currentSize)}`} />
          <p>{t('Médias')} : {getTotalAssets()}</p>
        </div>
      </div>

      {
        totalByType &&
        <div className='flex'>
          <div style={{ maxWidth: 200 }}>
            <PieChart
              data={typeData}
              lineWidth={20}
              paddingAngle={18}
              rounded
              label={({ dataEntry }) => `${Math.round(dataEntry.value)}%`}
              labelStyle={(index) => ({
                fill: typeData[index].color,
                fontSize: '10px',
                fontFamily: 'sans-serif',
              })}
              labelPosition={70}
            />
          </div>
          <div style={{ marginLeft: '1em' }} className="flex flex-column-reverse">
            {
              Object.keys(storage.totalByType).map(key => (
                <Badge key={key} color={MEDIA_COLORS[key]} text={`${storage.totalByType[key].total} ${key} : ${fileConvertSize(storage.totalByType[key].space)}`} />
              ))
            }
          </div>
        </div>
      }
    </div>
  )
}
