import { Badge, Icon } from 'antd'
import React from 'react'

export default function SideBarTitle({ isSelected, handleClick, total, name, icon = null, opacity = '' }) {

    return (
        <div className={`sidebar-title flex between ${isSelected && 'selected'} ${opacity}`} onClick={handleClick}>
            <div> {icon && <Icon type={icon} theme={isSelected ? 'filled' : 'outlined'} />} {name}</div>
            <Badge
                className='assets-number'
                count={total}
                style={{ backgroundColor: '#108ee9', color: '#fff', boxShadow: '0 0 0 0' }}
            />
        </div>
    )
}
