import React, { useState } from 'react'
import { ChromePicker } from 'react-color';
import { Button, Input, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

export default function Text({ save, updateBox }) {

    const { t } = useTranslation();

    const [text, setText] = useState('default')
    const [displayPicker, setDisplayPicker] = useState(false);
    const [textStyle, setTextStyle] = useState({
        font: 'Arial',
        fontSize: '25',
        fontWeight: 'normal',
        fontStyle: 'normal',
        color: '#000'
    })

    function onTextChange(e) {
        setText(e.target.value)
        updateBox('title', e.target.value)
    }

    function handleStyleChange(key, value) {
        let styleClone = { ...textStyle };
        styleClone[key] = value;
        setTextStyle(styleClone)
        updateBox('textStyle', styleClone)
    }

    function togglePicker() {
        setDisplayPicker(!displayPicker)
    }

    return (
        <div>
            <div style={{ padding: 5 }}>
                <TextArea placeholder="Votre texte" allowClear onChange={(onTextChange)} />
            </div>
            <div style={{ padding: 5 }}>
                <Select value={textStyle.font} style={{ width: 120 }}
                    onChange={(value) => handleStyleChange('font', value)}>
                    <Option value="Arial">Arial</Option>
                    <Option value="Serif">Serif</Option>
                    <Option value="Roboto">Roboto</Option>
                    <Option value="Times New Roman">Times New Roman</Option>
                    <Option value="Times">Times</Option>
                    <Option value="Courier New">Courier New</Option>
                    <Option value="Courier">Courier</Option>
                    <Option value="Verdana">Verdana</Option>
                    <Option value="Georgia">Georgia</Option>
                    <Option value="Palatino">Palatino</Option>
                    <Option value="Garamond">Garamond</Option>
                    <Option value="Bookman">Bookman</Option>
                    <Option value="Comic Sans MS">Comic Sans MS</Option>
                    <Option value="Candara">Candara</Option>
                    <Option value="Arial Black">Arial Black</Option>
                    <Option value="Impact">Impact</Option>
                </Select>
                <InputNumber value={textStyle.fontSize} style={{ width: 120 }} min={1} max={100} defaultValue={16}
                    onChange={(value) => handleStyleChange('fontSize', value)} />
                <Select value={textStyle.fontWeight} style={{ width: 120 }}
                    onChange={(value) => handleStyleChange('fontWeight', value)}>
                    <Option value="normal">normal</Option>
                    <Option value="bold">bold</Option>
                    <Option value="lighter">lighter</Option>
                </Select>
                <Select value={textStyle.fontStyle} style={{ width: 120 }}
                    onChange={(value) => handleStyleChange('fontStyle', value)}>
                    <Option value="normal">normal</Option>
                    <Option value="italic">italic</Option>
                </Select>

                <div style={{ position: 'relative' }}>
                    <button onClick={togglePicker}>{t('Couleur')}</button>
                    {displayPicker ? <div style={{ position: 'absolute', zIndex: '20' }}>
                        <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                            onClick={togglePicker} />
                        <ChromePicker disableAlpha color={textStyle.color}
                            onChange={(color) => handleStyleChange('color', color.hex)} />
                    </div> : null}
                </div>
            </div>
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('text')} />
                </Button.Group>
            </div>
        </div>
    )
}
