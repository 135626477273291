import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from "../../providers/ApiProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import SideBarTitle from "./SideBarTitle";

export default function CartFolder({ closeMenu = () => {} }) {

  const [authState] = useAuthContext();
  const {auth} = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { setActiveTab, resetFilter } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const { t } = useTranslation();
  const isMounted = useRef(null);

  const [total, setTotal] = useState(0)
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(accountState.activeTab === '4')
  }, [accountState.activeTab])

  useEffect(() => {
    isMounted.current = true;
    getTotal()
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [accountState.account, accountState.rerender])

  function getTotal() {
    const data = {
      itemsPerPage: 1,
      page: 1,
      filters: [
        {
          name: 'account',
          value: accountState.accountActive ? accountState.accountActive.id : accountState.account.id
        },
        {
          name: 'type',
          value: 2
        }
      ]
    }
    if (!accountState.admin)
      data.filters.push({ id: 'createdBy', name: 'createdBy', value: '/api/users/' + auth.id });
      
    apiFetchCollection("tags", data, response => {
      if (isMounted.current) {
        if (response['@type'] === "hydra:Collection") {
          setTotal(response['hydra:totalItems'])
        }
      }
    })
  }

  function handleClick() {
    resetFilter()
    setActiveTab("4")
    setIsSelected(!isSelected);
    closeMenu()
  }

  return (
    <SideBarTitle
      isSelected={isSelected}
      opacity={accountState.activeTab === '1' ? 'opacity-medium' : ''}
      handleClick={handleClick} name={t('Mes paniers')}
      total={total}
      icon='shopping' />
  )
}
