import React, {useState, useEffect} from 'react'
import { useApiContext } from '../providers/ApiProvider.js';
import {useParams} from 'react-router-dom';
import { Alert, Form, Input, Button, message, Spin } from 'antd';
import {useTranslation} from "react-i18next";

function NewPassword({form}) {

  	const {t, i18n} = useTranslation();
	const [apiDispatch] = useApiContext();
  	const { postRequestBasic } = apiDispatch;
  	const params = useParams();
  	const {getFieldDecorator, getFieldValue, validateFields, validateFieldsAndScroll, resetFields} = form;

  	const [message, setMessage] = useState({visible: false, type: null, content: null})
  	const [confirmDirty, setConfirmDirty] = useState()
  	const [loading, setLoading] = useState(true)

  	useEffect(() => {
		checkToken()
  	}, [])

  	function checkToken() {
  		postRequestBasic(`/new-password`, {token: params.token}, response => {
  			if (response.status === "error") { setMessage({visible: true, type: 'error', content: t(response.message)}); return; }
  			setLoading(false)
  		})
  	}

  	const compareToFirstPassword = (rule, value, callback) => {
	    if (value && value !== getFieldValue('password')) {
	      callback('Two passwords that you enter is inconsistent!');
	    } else {
	      callback();
	    }
	};

	const handleConfirmBlur = e => {
	    const {value} = e.target;
	    setConfirmDirty(confirmDirty || !!value);
	};

	function handleSubmit(e) {
		e.preventDefault();
	    validateFieldsAndScroll((err, values) => {
	      if (!err) {
	        const data = {
	        	token: params.token,
	        	password: values.password
	        }
	        updateUser(data)
	      } else {
	        message.error(t('Certains champs présentent des erreurs'))
	      }
	    });
   	}

   	const updateUser = (data) => {
	    setLoading(true)
	    postRequestBasic('/new-password', data, response => {
	        if (response.status === "error") {
	          message.error(t("Mise à jour de l'utilisateur impossible"));
	          return;
	        }

	        setLoading(false)
	        resetFields()
	        setMessage({visible: true, type: 'success', content: t('Votre mot de passe a été mise a jour, vous allez être redirigé vers la page de connexion d\'ici quelques secondes ...')})
	        goToLogin(3000)
	    })
	}

	function goToLogin(ms = 1) {
		setTimeout(() => { window.location.href = '/login'; }, ms);
	}

	return (
		<div className="form_center">
		{
			message.visible
			?
				<div>
					<Alert message={message.content} type={message.type} />
					<div style={{marginTop: 30, textAlign: 'center'}}>
						{
							message.type === "success"
							? <Spin size="large"/>
							: <Button type="primary" onClick={goToLogin}>Retour</Button>
						}
					</div>
				</div>
			:
				<Spin spinning={loading}>
					<Form onSubmit={handleSubmit}>
						<Form.Item label={t("Mot de passe")} hasFeedback>
				          {getFieldDecorator('password', {
				          	rules: [
				          		{
				          			required: true,
				          			message: t('Ce champ est obligatoire')
				          		}
				          	]
				          })(<Input.Password/>)}
				        </Form.Item>
				        <Form.Item label={t("Confirmer mot de passe")} hasFeedback>
				          {getFieldDecorator('confirm', {
				            rules: [
				              {
				              	required: true,
				                message: t('Ce champ est obligatoire'),
				              },
				              {
				                validator: compareToFirstPassword,
				              },
				            ],
				          })(<Input.Password onBlur={handleConfirmBlur}/>)}
				        </Form.Item>
				        <Form.Item>
				          <Button type="primary" htmlType="submit">
				            {t('Confirmer')}
				          </Button>
				        </Form.Item>
			        </Form>
				</Spin>
		}
		</div>
	)
}

export default Form.create()(NewPassword);
