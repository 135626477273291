import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Input, Tree} from "antd";
import DropdownTagAdmin from "../tag/DropdownTagAdmin";

const {DirectoryTree, TreeNode} = Tree;

export default function MyTree({tree, setTree, isManager = false, forceTagAdmin}) {

  const {t} = useTranslation();

  const initialRename = {name: "", key: ""}
  const [rename, setRename] = useState(initialRename)

  function makeTitle(dir, withTrash, key) {
    return (
      isManager ?
        <span>
          {dir.name}&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon
            type="edit" theme="twoTone"
            onClick={(e) => showRename(e, key, dir.name)}
          />&nbsp;&nbsp;&nbsp;&nbsp;
          {withTrash ?
            <Icon
              type="delete" twoToneColor="#eb2f96"
              theme="twoTone"
              onClick={(e) => removeDir(e, key)}
            /> : null}&nbsp;&nbsp;&nbsp;&nbsp;
          <DropdownTagAdmin forceTagAdmins={forceTagAdmin} directory={dir} hiddenTxt={true} tree={tree} setTree={setTree} treeKey={key}/>
        </span>
        : dir.name
    )
  }

  function makeTree(dir, key) {
    if (dir.children && dir.children.length) {
      return (
        <TreeNode id={key} key={key} title={makeTitle(dir, false, key)}>
          {dir.children ? dir.children.sort((a, b) => a.name.localeCompare(b.name)).map((child, newkey) => (
            makeTree(child, key + "-" + newkey)
          )) : null}
        </TreeNode>
      )
    } else {
      return (
        <TreeNode id={key} key={key} title={makeTitle(dir, true, key)}/>
      )
    }
  }

  function removeDir(e, key) {
    e.stopPropagation();
    tree.tree = remakeTree(key, null, null, "0-", tree.tree, false);
    setTree({...tree});
  }

  function showRename(e, key, name) {
    e.stopPropagation();
    rename.name = name;
    rename.key = key;

    setRename({...rename});
  }

  function renameDir(renameKey, newName) {
    tree.tree = renameDirRecursive(renameKey, newName, "0-", tree.tree)
    setTree({...tree})
    setRename(initialRename)
  }

  function drop(info) {
    const dragKey = info.dragNode.props.eventKey;
    const dropKey = info.node.props.eventKey;
    const dragValue = getDragElement(dragKey);

    tree.tree = remakeTree(dragKey, dropKey, dragValue, "0-", tree.tree, info.dropToGap);
    setTree({...tree});
  }

  function renameDirRecursive(renameKey, newName, prefix, array) {
    let newArray = [];

    array.map((t, key) => {
      let row;

      if (prefix + key === renameKey)
        row = {name: newName}
      else
        row = {name: t.name}

      if (t.tagAdmins)
        row.tagAdmins = t.tagAdmins

      if (t.children)
        row.children = renameDirRecursive(renameKey, newName, prefix + key + "-", t.children)

      newArray.push(row)
    })

    return newArray;
  }

  function remakeTree(dragKey, dropKey, dragValue, prefix, array, dropToGap) {
    let newArray = [];

    array.map((t, key) => {

      if (prefix + key !== dragKey) {
        let row = {name: t.name}
        row.children = []
        if (t.children)
          row.children = remakeTree(dragKey, dropKey, dragValue, prefix + key + "-", t.children, dropToGap)

        if (t.tagAdmins)
          row.tagAdmins = t.tagAdmins

        if (prefix + key === dropKey) {
          if (dropToGap)
            newArray.push(dragValue)
          else
            row.children = [...row.children, dragValue]
        }

        if (!row.children.length)
          delete row.children;

        newArray.push(row)
      }
    })

    return newArray;
  }

  function getDragElement(dragKey) {
    let dragelement;
    let dragKeyShift = dragKey.split("-")
    dragKeyShift.shift()
    for (let i = 0; i < dragKeyShift.length; i++) {
      if (!i)
        dragelement = tree.tree[dragKeyShift[i]]
      else
        dragelement = dragelement.children[dragKeyShift[i]]
    }

    return dragelement
  }

  function drag(props) {
    // console.log(props)
  }

  function handleChange(e) {
    rename.name = e.target.value
    setRename({...rename})
  }

  return (
    <div>
      {rename.name ?
        <div className="mt-5">{t("Renommer dossier")} :&nbsp;
          <Input className="w-250" value={rename.name} onChange={handleChange}/>
          <Button icon="check" type="primary" onClick={() => renameDir(rename.key, rename.name)}/>
        </div>
        : null}
      <DirectoryTree
        draggable={isManager}
        onDragEnter={drag}
        onDrop={drop}
        defaultExpandAll
        selectable={false}
        blockNode
        className="draggable-tree"
      >
        {tree.tree.sort((a, b) => a.name.localeCompare(b.name)).map((dir, key) => (
          makeTree(dir, "0-" + key)
        ))}
      </DirectoryTree>
    </div>
  )
}
