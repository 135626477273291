import React from 'react'
import { Icon, Progress } from 'antd'
import { useTranslation } from 'react-i18next';
import { fileConvertSize } from '../../helpers';

export default function UploadProgress({ stats }) {

    const {t} = useTranslation()

    function displayPercent(percent, status) {
        percent = parseFloat(percent)
        if (status === 'error') return <Icon type="close-circle" style={{color: 'red'}}/>
        if (percent === 100) return <Icon type='check-circle' style={{color: '#52c41a'}}/>

        return percent ? percent + '%' : 0 + '%'
    }

    return (
        <div style={{maxHeight: '70vh', overflow: 'auto'}}>
            <p>{t('Vos médias sont en cours d\'importation')}</p>
            {
                !!stats?.medias?.list?.length &&
                <div className='upload-item mb'>
                    <div className='upload-title bold'>{stats?.medias?.list?.length} fichiers en cours de traitement - {stats?.medias?.percent}%</div>
                    <Progress
                        strokeWidth={5}
                        strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#1890ff',
                        }}
                        showInfo={false}
                        percent={stats?.medias?.percent}
                        status={stats.medias.percent < 100 ? 'active' : 'success'}
                    />
                </div>
            }
            {
                stats?.videos?.list.map(item =>
                    <div className='upload-item mb' key={item.name}>
                        <div className='upload-title bold'><span>{item.name} <small>({fileConvertSize(item.size)})</small></span> <span>{displayPercent(item.percent, item.status)}</span></div>
                        {
                            item.status === 'error' &&
                            <div className='upload-subtitle'>{item.response}</div>
                        }
                        {
                            item.status !== 'error' && parseFloat(item.percent) < 100 &&
                            <>
                                <Progress
                                    strokeWidth={5}
                                    strokeColor={{
                                        '0%': '#108ee9',
                                        '100%': '#1890ff',
                                    }}
                                    showInfo={false}
                                    percent={item.percent}
                                    status={item.percent < 100 ? 'active' : 'success'}
                                />
                            </>
                        }
                    </div>
                )
            }
        </div>
    )
}
