import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Form, Icon, Input, message, Radio, Rate, Select, Switch, Tooltip } from 'antd';
import { useApiContext } from '../../providers/ApiProvider.js';
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import moment from 'moment'
import { useAccountContext } from "../../providers/AccountProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import TagsTreeSelect from '../tag/TagTreeSelect.js';

function PermissionDetail({ account, user = null, permission, form, onClose, forceTag, forceCart, selection }) {

  let defaultPerm = {
    limited: true,
    tags: forceTag ? [forceTag] : [],
    carts: forceCart ? [forceCart] : [],
    selection: selection ? selection : [],
    role: 'ROLE_VIEW',
    share: true,
    isGlobal: !!forceCart && !!forceTag
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 17 },
    },
  };

  const [_itemId, setItemId] = useState(permission ? permission.id : 'new')

  const [_item, setItem] = useState(permission ? {
    ...permission,
    limited: permission?.deletedAt,
    isGlobal: !permission.tags.length,
    carts: formatSelectValue(permission.tags.filter(item => item.type === 2)),
    tags: formatSelectValue(permission.tags.filter(item => item.type === 1)),
  } : defaultPerm)

  const { t, i18n } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { addNotification } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiUpdateEntity, apiPostEntity, apiFetchSubResourceWithFilter } = apiDispatch;

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;

  const isMounted = useRef(null);

  const [btnLoading, setBtnLoading] = useState(false)
  const [loadingTags, setLoadingTags] = useState(true)
  const [loadingCarts, setLoadingCarts] = useState(true)

  const [tags, setTags] = useState([])
  const [shareAllTags, setShareAllTags] = useState(false)
  const [shareAllCarts, setShareAllCarts] = useState(false)

  const { getFieldDecorator, getFieldValue } = form;
  const [carts, setCarts] = useState([])

  function formatSelectValue(elements) {
    return elements.map(item => {
      return { value: item.id, label: item.name }
    })
  }

  useEffect(() => {
    isMounted.current = true;
    setLoadingTags(true)
    fetchTags()
    fetchCarts()
    return () => {
      isMounted.current = false;
    }
  }, [account, user])

  async function fetchTags() {
    const params = {
      filters: [{
        id: 'directory',
        name: 'type',
        value: 1
      },
      {
        id: 'pagination',
        name: 'pagination',
        value: 'false'
      },
      {
        name: 'account',
        value: account.id
      }
      ]
    }
    apiFetchCollection('get_folders', params, (response) => {
      if (isMounted.current) {
        let items = response
        setTags(items)
        setLoadingTags(false)
      }
    })
  }

  async function fetchCarts() {
    let Data = {
      id: account.id,
      filters: [{
        id: 'directory',
        name: 'type[]',
        value: 2
      },
      {
        id: 'created',
        name: 'createdBy',
        value: auth.id
      }
      ]
    };
    apiFetchSubResourceWithFilter('accounts', Data, 'tags', (hydra_response) => {
      if (isMounted.current) {
        let items = hydra_response['hydra:member']
        setCarts(items)
        setLoadingCarts(false)
      }
    })
  }

  async function createPermission(params) {
    await apiPostEntity('permissions', params, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t(response['hydra:description']))
      } else {
        if (_item.share) {
          return addNotification({
            type: "success",
            message: t('Permission crée avec succes'),
            description: (<a href={'/visitor/' + response.token} target="_blank">{t('Voir')}</a>)
          })
        }
        message.success(params.email + ' : ' + t('Permission crée avec succes'))
      }
    })
  }

  async function handleSave() {

    let emails = getFieldValue('email');
    if (_itemId === 'new' && !_item.share && (!emails || emails.length === 0)) {
      message.error(t('Vous devez entrer au moins une adresse email'))
      return
    }

    form.validateFields(async (err, values) => {

      setBtnLoading(true)

      let hasElementChecked = _item.tags.length > 0 || _item.carts.length > 0 || selection
      if (_item.share && !hasElementChecked && !shareAllTags) {
        message.error(t('Vous devez sélectionner un dossier ou un panier pour faire un partage temporaire'))
        err = true
      }
      if (!_item.share && !_item.isGlobal && !hasElementChecked && _item.role !== 'ROLE_ADMIN') {
        message.error(t('Vous devez sélectionner un dossier ou un panier pour donner une permission sur une sélection'))
        err = true
      }

      if (!err) {
        setBtnLoading(true)
        let data = { ..._item }

        let sharedTags = shareAllTags && _item.share ? getAllIris(tags) : buildIrisFromList(_item.tags)
        let sharedCarts = buildIrisFromList(_item.carts)

        data.carts = data.share ? sharedCarts : []
        data.isGlobal = data.role === 'ROLE_ADMIN' ? true : data.isGlobal
        data.tags = data.role === 'ROLE_ADMIN' || data.isGlobal ? [] : [...sharedTags, ...data.carts]
        data.user = typeof data.user === 'object' ? data.user['@id'] : data.user
        data.account = typeof data.account === 'object' ? data.account['@id'] : account['@id']
        data.deletedAt = data.share && !values.limited ? values.deletedAt : null
        data.selection = data.selection && !!data.selection.length ? getElementFromList(data.selection, 'id') : null
        data.message = typeof data.message === 'string' ? data.message : null
        data.isActive = typeof data.isActive === 'boolean' ? data.isActive : true;
        data.minimalNote = typeof data.minimalNote === 'number' && data.role !== 'ROLE_ADMIN' && data.role !== 'ROLE_EDIT' ? data.minimalNote : 0
        data.maxDownloadSize = data.share ? data.maxDownloadSize : null;
        data.shareView = data.share ? values.shareView : null;
        data.password = data.share ? values.password : null;

        if (_itemId === 'new') {
          if (values?.email) {
            await Promise.all(values.email.map(async email => {
              await createPermission({ ...data, email: email })
            }))
          } else {
            await createPermission({ ...data })
          }
        } else {
          await updatePermission(data)
        }
        //console.log('done')
        return onClose()

      }
      setBtnLoading(false)

    });
  }

  async function updatePermission(params) {
    await apiUpdateEntity("permissions", params.id, params, (response) => {
      message.success(t('Permission mise à jour avec succès'))
    })
  }

  function getAllIris(items) {
    let iris = []
    items.map(item =>
      iris = [...iris, ...getTagChildrenIris(item)]
    )
    return iris
  }

  function buildIrisFromList(list) {
    return list.map(item => '/api/tags/' + item.value)
  }

  function getElementFromList(list, element) {
    let elements = []
    list.map(item =>
      elements.push(item[element])
    )
    return elements
  }

  function getTagChildrenIris(tag) {
    let childIris = []
    if (tag.childTags.length > 0) {
      tag.childTags.map(child => {
        childIris = [...childIris, ...getTagChildrenIris(child)]
      })
    }
    return [tag['@id'], ...childIris];
  }

  function handleCheck(values, property, extra, ctrlKey) {
    if (typeof values !== "object") return;
    // if clear clicked then set state directly
    if (!values.length) setItem({ ..._item, [property]: [...values] })

    // click without ctrl key pressed should select all children
    let additionnalValues = []
    if (!ctrlKey) {
      // get all children of the element checked
      additionnalValues = getChildCheckBox(extra.triggerNode.props, !extra.checked)
      // if the element is being unchecked then we also remove the children from the selection
      if (!extra.checked) {
        return setItem({ ..._item, [property]: values.filter(item => !additionnalValues.includes(item.value)) })
      }
    }

    setItem({ ..._item, [property]: [...values, ...additionnalValues] })
  }

  //get a flat array of the children from the antd treeselct response
  function getChildCheckBox(parent, getId) {
    return parent?.children?.reduce((accumulator, current) => {
      let children = current.props?.children ? getChildCheckBox(current.props, getId) : []
      let value = getId ? current.props.value : current.props
      return [...accumulator, value, ...children]
    }, []) || []
  }

  function onRadioChange(e) {
    _item.role = e.target.value
    setItem({ ..._item });
  }


  const handleChange = (property, value) => {
    let updates = {
      [property]: value
    }
    if (property === 'share') {
      updates.isGlobal = false
      updates.tags = []
      updates.carts = []
      updates.role = 'ROLE_VIEW'
    }
    setItem(prev => { return { ...prev, ...updates } })
  }

  const emailValidator = (rule, values, callback) => {
    if (!values) return callback()
    const emailRegex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
    const invalidInputs = values.filter((value) => !value.match(emailRegex));

    if (invalidInputs.length === 0) {
      callback()
    } else if (invalidInputs.length === 1) {
      callback(invalidInputs.join('') + ' ' + t('Is not a valid email'));
    } else {
      callback(invalidInputs.slice(0, -1).join(', ') + ' and ' + invalidInputs.slice(-1) + ' ' + t('Are not valid emails'));
    }
  }

  if (loadingTags || loadingCarts)
    return <p>loading ...</p>

  return (
    <Form {...formItemLayout}>
      {
        _itemId === 'new' && !selection.length ?
          <Form.Item label={t("Type d’accès")}>
            <Radio.Group onChange={(e) => handleChange('share', e.target.value)} value={_item.share}>
              <Radio value={false}>
                {t("Membre")}&nbsp;
                {/* <Tooltip title="Les membres pourront se connecter sur la plateforme pour accéder à ce compte">
                  <Icon type="question-circle-o" />
                </Tooltip> */}
              </Radio>
              <Radio value={true}>
                {t("Invité")}&nbsp;
                {/* <Tooltip title="Les invités peuvent voir les médias sur une page dédié sans connexion">
                  <Icon type="question-circle-o" />
                </Tooltip> */}
              </Radio>
            </Radio.Group>
          </Form.Item>
          : null
      }

      {
        _itemId === 'new' ?
          <Form.Item label={t("Email") + ' :'}>
            {getFieldDecorator('email', {
              rules: [
                {
                  validator: emailValidator,
                },
                {
                  required: !_item.share,
                  message: t('Veuillez compléter l\'email') + ' !',
                },
              ],
            })(<Select mode={"tags"} />)}
          </Form.Item>
          : null
      }
      {
        _itemId !== 'new' && !_item.share ?
          <Form.Item label={t("Active") + ' :'}>
            <Switch size="small" checked={_item.isActive !== null ? _item.isActive : true}
              onChange={(e) => handleChange('isActive', e)} />
          </Form.Item>
          : null
      }
      {
        !_item.share ?
          <Form.Item label={t("Rôle") + ' :'}>
            <Radio.Group
              name="role"
              onChange={onRadioChange}
              value={_item.role}
            >
              {
                (accountState.admin || auth.roles.includes('ROLE_ADMIN')) &&
                <Radio.Button value="ROLE_ADMIN">Admin</Radio.Button>
              }
              <Radio.Button value="ROLE_EDIT">Edit</Radio.Button>
              <Radio.Button value="ROLE_VIEW">View</Radio.Button>
            </Radio.Group>
            {
              _item.role === 'ROLE_ADMIN' ?
                <p>
                  {t('Les admins ont accès à tous les dossiers')}
                </p>
                : null
            }
          </Form.Item>
          : null
      }
      {
        !selection.length && (_item.role !== 'ROLE_ADMIN') && !_item.share && accountState.tags.length === 0 ?
          <Form.Item label={t("Type d'acces") + " :"}>
            <Radio.Group onChange={(e) => handleChange('isGlobal', e.target.value)} value={_item.isGlobal}>
              <Radio value={true}>
                {t("Tous les dossiers")}
              </Radio>
              <Radio value={false}>
                {t("Sélection")}
              </Radio>
            </Radio.Group>
          </Form.Item>
          : null
      }
      {
        _item.selection && !!_item.selection.length || _item.isGlobal || (!_item.share && _item.role === 'ROLE_ADMIN') ?
          null
          :
          <Form.Item label={t("Dossiers") + ' :'}>
            {
              tags.length > 0 ?
                <div>
                  {
                    _item.share &&
                    < Radio.Group onChange={(e) => setShareAllTags(e.target.value)} value={shareAllTags}>
                      <Radio value={true}>
                        {t("Tous les dossiers")}
                      </Radio>
                      <Radio value={false}>
                        {t("Une sélection")}
                      </Radio>
                    </Radio.Group>
                  }
                  {
                    (!shareAllTags || (!_item.share && !_item.isGlobal)) &&
                    <TagsTreeSelect tags={tags} treeCheckable onSelect={(value, label, extra, ctrlKey) => {
                      handleCheck(value, 'tags', extra, ctrlKey)
                    }} currentValue={_item.tags} isShare={_item.share} />
                  }
                </div>
                : t("Votre compte n'a aucun dossier")
            }
          </Form.Item>
      }
      {
        _item.role !== "ROLE_ADMIN" && _item.share && carts.length > 0 && !selection.length && !_item.isGlobal ?
          < Form.Item label={t("Panier") + ' :'} extra="Appuyer sur ctrl/cmd pour (dé)sélectionner un seul élément">
            <TagsTreeSelect tags={carts} treeCheckable
              onSelect={(value, label, extra, ctrlKey) => handleCheck(value, 'carts', extra, true)}
              currentValue={_item.carts} placeholder='choisissez un panier' />
          </Form.Item>
          : null
      }
      {
        !selection.length && (_item.role !== 'ROLE_ADMIN' && _item.role !== "ROLE_EDIT") ?
          <Form.Item label={t("Note minimale") + " :"}>
            <Rate defaultValue={_item.minimalNote ? _item.minimalNote : null}
              onChange={(value) => handleChange('minimalNote', value)} />
          </Form.Item>
          : null
      }

      {
        _item.share ?
          <>
            <Form.Item label={t("Accès temporaire") + ' :'}>
              <Switch size="small" checked={_item.limited} onChange={(value) => handleChange('limited', value)} />
            </Form.Item>
            {
              _item.limited &&
              <Form.Item label={t("Accès jusqu'au") + ' :'}>
                {getFieldDecorator('deletedAt', {
                  initialValue: _item.deletedAt ? moment(_item.deletedAt) : moment().add(1, 'day'),
                  rules: [
                    {
                      type: 'date',
                      validator: (rule, value) => {
                        return value.isAfter(moment())
                      },
                      message: t('La date ne peut être une date passée'),
                    }
                  ],
                })(<DatePicker allowClear={false} />)}
              </Form.Item>
            }
            <Form.Item label={t("Code d'accès") + ' :'}>
              {getFieldDecorator('password', {
                initialValue: _item?.password,
              })(
                <Input allowClear placeholder={t('L\'accès ne sera pas restraint si vide')} />
              )}
            </Form.Item>
            <Form.Item label={`${t("Affichage par défaut")} :`}>
              {getFieldDecorator('shareView', { initialValue: _item?.shareView ?? 'assets' })(
                <Radio.Group buttonStyle="solid">
                  <Radio value="assets">{t('Médias')}</Radio>
                  <Radio value="directory">{t('Dossiers')}</Radio>
                </Radio.Group>)}
            </Form.Item>
          </>
          :
          null
      }
      {
        _item.share ?
          <Form.Item label={t("Qualité") + ' :'}>
            <Select onChange={(value) => handleChange('maxDownloadSize', value)}
              value={_item.maxDownloadSize ? _item.maxDownloadSize : null}>
              <Select.Option value={null}>Original</Select.Option>
              <Select.Option value={'lg'}>lg</Select.Option>
              <Select.Option value={'md'}>md</Select.Option>
              <Select.Option value={'sm'}>sm</Select.Option>
            </Select>
          </Form.Item>
          : null
      }
      {
        _itemId === 'new' && _item.share ?
          <Form.Item label={t("Message")}>
            <TextArea
              value={_item.message}
              placeholder={t('Ajouter un message au mail (optionnel)')}
              autoSize={{ minRows: 2, maxRows: 6 }}
              maxLength={100}
              onChange={(e) => handleChange('message', e.target.value)}
            />
          </Form.Item>
          : null
      }

      <div className="flex end">
        <Button key="cancel" onClick={onClose}>
          {t("Fermer")}
        </Button>
        <Button style={{ marginLeft: 8 }} loading={btnLoading} onClick={() => handleSave()} type="primary"
          key="save">
          {t('Enregistrer')}
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(PermissionDetail)
