import { Icon } from 'antd';
import React from 'react'
import videoPlaceholder from '../../assets/video-placeholder.jpg'

export default function VideoView({ asset, onClick = () => { }, style = {}, title = false }) {

  let media = asset.currentMedia ? asset.medias.filter(media => media.id === asset.currentMedia)[0] : asset.medias[0];

  function resolvePictureLink(media) {
    // 1 = paysage  - 0 = portrait
    if (!media) return "";
    let orientation = parseFloat(media.dimensions[0]) > parseFloat(media.dimensions[1]) ? 1 : 0;
    let picDimension = orientation ? '_295x166' : '_166x295'

    return media.vimeoPictureLink + picDimension
  }

  return (
    <div className='pointer video-placeholder' onClick={onClick} style={style}>
        {
          media?.vimeoId ?
            <img alt="video-image" style={{ height: '100%' }}
              src={media.vimeoPictureLink ? resolvePictureLink(media) : videoPlaceholder} />
            :
            <video width="100%" height="100%">
              <source src={media.contentUrl} type={asset.mimeType} />
            </video>
        }
        <div className={!media.vimeoPictureLink && title ? 'asset-title' : 'centered-icon'}>
          <Icon type="play-circle" style={{ fontSize: "2em" }} />
          {
            !media.vimeoPictureLink && title &&
            <p>{asset?.usageName}</p>
          }
        </div>
    </div>
  )
}
