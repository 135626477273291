import React, {useState} from "react";
import {Col, PageHeader, Row, Tag, Typography} from 'antd';
import DocumentationList from "../components/DocumentationList";
import DocumentationTesting from "../components/DocumentationTesting";


export default function ApiDocumentation() {

  const [requestUrl, setRequestUrl] = useState(null)


  const categoriesParams = {
    title: (
      <>
        <Tag color="#87d068">GET</Tag>
        https://api.hapidam.com/categories?key={"{key}"}
      </>
    ),
    url: "https://api.hapidam.com/categories?",
    params: [
      {
        title: (<>key* <Typography.Text code>string</Typography.Text></>),
        description: "Clé d'authentification publique lié à un compte hapidam",
        name: 'key',
        value: '',
      }
    ]
  }


  const categoryParams = {
    title: (
      <>
        <Tag color="#87d068">GET</Tag>
        https://api.hapidam.com/categories/{"{category_name}"}?key={"{key}"}
      </>
    ),
    url: "https://api.hapidam.com/categories/{category_name}?",
    params: [
      {
        title: (<>category_name* <Typography.Text code>string</Typography.Text></>),
        description: 'Permet de récupérer des images appartenant à une catégorie.',
        name: 'category_name',
        value: '',
      },
      {
        title: (<>key* <Typography.Text code>string</Typography.Text></>),
        description: "Clé d'authentification publique lié à un compte hapidam",
        name: 'key',
        value: '',
      },
      {
        title: (<>rate <Typography.Text code>number</Typography.Text></>),
        description: "Permet de filtrer par note, récupérer les images avec une note minimale, ex: rate=3.",
        name: 'rate',
        value: '',
      },
      {
        title: (<>results <Typography.Text code>string</Typography.Text></>),
        description: "Permet de choisir le nombre d'image retournée par l'api. ex: results=10",
        name: 'results',
        value: '',
      }
    ]
  }
  const tagsParams = {
    title: (
      <>
        <Tag color="#87d068">GET</Tag>
        https://api.hapidam.com/tags?key={"{key}"}
      </>
    ),
    url: "https://api.hapidam.com/tags?key={key}",
    params: [
      {
        title: (<>key* <Typography.Text code>string</Typography.Text></>),
        description: "Clé d'authentification publique lié à un compte hapidam",
        name: 'key',
        value: '',
      }
    ]
  }

  const tagsSearchParams = {
    title: (
      <>
        <Tag color="#87d068">GET</Tag>
        https://api.hapidam.com/tags/search?key={"{key}"}&tags={"{[tags]}"}
      </>
    ),
    url: "https://api.hapidam.com/tags/search?",
    params: [
      {
        title: (<>key* <Typography.Text code>string</Typography.Text></>),
        description: "Clé d'authentification publique lié à un compte hapidam",
        name: 'key',
        value: '',
      },
      {
        title: (<>tags <Typography.Text code>string</Typography.Text></>),
        description: "Permet de filtrer les images pas tags. Les images qui ont tous les tags seront retournées, ex: tags=room,bed. ",
        name: 'tags',
        value: '',
      },
      {
        title: (<>rate <Typography.Text code>number</Typography.Text></>),
        description: "Permet de filtrer par note, récupérer les images avec une note minimale, ex: rate=3. ",
        name: 'rate',
        value: '',
      },
      {
        title: (<>results <Typography.Text code>number</Typography.Text></>),
        description: "Permet de choisir le nombre d'image retournée par l'api. ex: results=10",
        name: 'results',
        value: '',
      }
    ]
  }

  const shareParams = {
    title: (
      <>
        <Tag color="#87d068">GET</Tag>
        https://api.hapidam.com/share/{"{uid}"}/{name}
      </>
    ),
    url: "https://api.hapidam.com/share/{uid}/{name}?",
    params: [
      {
        title: (<>uid* <Typography.Text code>string</Typography.Text></>),
        description: 'l\'uid est l\'identifiant unique correspondant à une image',
        name: 'uid',
        value: '',
      },
      {
        title: (<>name* <Typography.Text code>string</Typography.Text></>),
        description: 'Le nom de l\'image',
        name: 'name',
        value: '',
      },
      {
        title: (<>mode <Typography.Text code>string</Typography.Text></>),
        description: 'Le mode permet de retailler ou rogner une image à la volé. Il doit être utilisé avec les autres paramètres (w, h, coi). Valeures possibles: "contain", "crop", "resize". Defaut: "resize". ex : mode=crop',
        name: 'mode',
        value: '',
      },
      {
        title: (<>w <Typography.Text code>number</Typography.Text></>),
        description: "La largeur de l'image une fois retaillée ou rognée. ex: w=1500",
        name: 'w',
        value: '',
      },
      {
        title: (<>h <Typography.Text code>number</Typography.Text></>),
        description: "La hauteur de l'image une fois retaillée ou rognée. ex: h=1000",
        name: 'h',
        value: '',
      },
      {
        title: (<>coi <Typography.Text code>number</Typography.Text></>),
        description: "Le centre d'intérêt de l'image. Utilisé avec les autres paramètres, il permet par exemple de centrer une image sur un point précis. Ces valeurs sont en pourcentages. ex: coi=10,10 (x,y)",
        name: 'coi',
        value: '',
      },
      {
        title: (<>size <Typography.Text code>string</Typography.Text></>),
        description: 'Size permet la récupération d\'une des tailles utilisé par Hapidam. Ce paramètre écrase les autres paramètres falcultatif. Valeur possible: "lg", "md", "sm". ex: size=lg',
        name: 'size',
        value: '',
      }
    ]
  }

  return (
    <div className="contenu-tab">
      <PageHeader
        title="Hapidam REST API v1"
      />



      <Row gutter={[48, 16]}>
        <Col span={16}>
          <p>
            L'API Hapidam vous permet de récupérer des informations et ressources d'un compte. Il est possible de filtrer ces ressources et de récupérer simplement les éléments nécessaire pour afficher les images voulues.
          </p>

          <h2 className="mt-20">Categories</h2>

          <h3 className="m-20">Liste des categories</h3>

          <p>Permet la récupération de la liste des catégories qui ont des images liées</p>
          <DocumentationList apiEndpoint={categoriesParams} setRequestUrl={setRequestUrl}/>

          <h3 className="m-20">Categorie</h3>

          <p>Permet la récupération des images liées à une catégorie</p>
          <DocumentationList apiEndpoint={categoryParams} setRequestUrl={setRequestUrl}/>

          <h2 className="mt-20">Tags</h2>

          <h3 className="m-20">Liste des tags</h3>

          <p>Permet la récupération des tags qui sont liés à des images</p>

          <DocumentationList apiEndpoint={tagsParams} setRequestUrl={setRequestUrl}/>

          <h3 className="m-20">Recherche par tag</h3>

          <p>Permet la récupération des images liés à un ou plusieurs tags.</p>

          <DocumentationList apiEndpoint={tagsSearchParams} setRequestUrl={setRequestUrl}/>


          <h2 className="mt-20">Image display</h2>

          <p>La route share permet d'afficher une image. Plusieurs mode sont disponible et permettent de retailler ou rogner une image. Afficher une image avec une taille précise ou à une taille prédéfini</p>

          <DocumentationList apiEndpoint={shareParams} setRequestUrl={setRequestUrl}/>

        </Col>
        <Col span={8}
             style={{position: 'sticky', top: "100px", height: "80vh", backgroundColor: "#272822", overflow: "auto"}}>
          <DocumentationTesting url={requestUrl} />
        </Col>
      </Row>
    </div>
  )
}
