import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';

const languages = ['fr', 'en', 'it', 'es', 'de'];

export default function MultiLngInput({ value, placeholder = '', title, name, onChange, forceLng = null }) {

    const [internalValue, setInternalValue] = useState()
    const [currentLng, setCurrentLng] = useState(forceLng ? forceLng : 'fr')

    useEffect(() => {
        if (value !== internalValue) {
            setInternalValue(value)
        }
        if (forceLng)
            setCurrentLng(forceLng)
    }, [value, forceLng])

    const handleChange = (e) => {
        // console.log(e.target.value)
        let val = e.target.value
        setInternalValue(prev => { return { ...prev, [currentLng]: val } })
    }

    const handleSave = () => {
        if (name && typeof onChange === 'function')
            onChange(internalValue)
    }

    return (
        <div style={{ margin: '1em 0' }}>
            <div style={{ marginBottom: '.5em', display: 'flex', justifyContent: 'space-between' }}>
                {
                    title &&
                    <div>{title}</div>
                }
                <Button.Group>
                    {
                        languages.map(lng =>
                            <Button key={lng} onClick={() => setCurrentLng(lng)} type={currentLng === lng ? "primary" : 'default'} size="small" >{lng}</Button>
                        )
                    }
                </Button.Group>
            </div>
            <div>
                <Input value={internalValue?.[currentLng] || ''} onChange={handleChange} placeholder={placeholder} onBlur={handleSave} />
            </div>
        </div>
    );
}
