import React from "react";

export default function Zip(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width='100%'
    >
      <path
        fill={props.fill}
        d="M365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448ZM144 128H80C71 128 64 135 64 144S71 160 80 160H144C153 160 160 153 160 144S153 128 144 128ZM144 64H80C71 64 64 71 64 80S71 96 80 96H144C153 96 160 89 160 80S153 64 144 64ZM144 192H80C71 192 64 199 64 208S71 224 80 224H144C153 224 160 217 160 208S153 192 144 192ZM144 256H80C71 256 64 263 64 272V320C64 346 86 368 112 368S160 346 160 320V272C160 263 153 256 144 256ZM128 320C128 329 121 336 112 336S96 329 96 320V288H128V320Z"/>
    </svg>
  )
}
