import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Button, Spin, message} from 'antd'
import {useAuthContext} from '../providers/AuthProvider.js';
import {useApiContext} from '../providers/ApiProvider.js';

const Register = ({form, setIsLogin}) => {

  const [apiDispatch] = useApiContext();
  const {postRequestBasic} = apiDispatch;
  const {t} = useTranslation();
  const {getFieldDecorator, getFieldValue} = form;

  const [authState, authDispatch] = useAuthContext();
  const {authLogin} = authDispatch;

  const [processing, setProcessing] = useState(false);

  function errorMessage(content) {
    setProcessing(false);
    message.error(content);
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setProcessing(true);
        postRequestBasic('/register', values, response => {
          if (!response.success)
            return errorMessage(t(response.message));

          authLogin(values.email, values.password, response => {
            if (response.error)
              errorMessage(response.error)
          })
        })
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(t('Les deux mots de passe ne correspondent pas'));
    } else {
      callback();
    }
  };

  return (
    <div id="login-form" className='login-container' style={{marginTop: "5%"}}>
      <h2>{t('Inscription')}</h2>
      <Spin tip={t('Validation en cours ...')} spinning={processing}>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('firstname', {
              rules: [{required: true, message: t('Entrez votre prénom')}],
            })(
              <Input
                placeholder={t("Prénom")}
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('lastname', {
              rules: [{required: true, message: t('Entrez votre nom')}],
            })(
              <Input
                placeholder={t("Nom")}
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{required: true, message: t('Entrez votre nom d\'utilisateur!')}],
            })(
              <Input
                placeholder={t("Nom d'utilisateur")}
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{required: true, message: t('Entrez votre email!')}],
            })(
              <Input
                placeholder={t('Email')}
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('accountName', {
              rules: [{required: true, message: t('Entrez le nom du compte!')}],
            })(
              <Input
                placeholder={t('Nom du compte')}
              />,
            )}
          </Form.Item>

          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [{required: true, message: t('Entrez votre mot de passe!')}],
            })(
              <Input.Password
                placeholder={t('Mot de passe')}
              />,
            )}
          </Form.Item>

          <Form.Item hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {required: true, message: t('Confirmez votre mot de passe!')},
                {validator: compareToFirstPassword},
              ],
            })(<Input.Password
              placeholder={t('Confirmer mot de passe')}
            />)}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              {t('Valider')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>

      <div className="flex center">
        <Button
          type="link"
          onClick={() => setIsLogin(true)}
        >
          {t('J\'ai déjà un compte')} HapiDam
        </Button>
      </div>
    </div>
  );
};

export default Form.create()(Register);
