import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom'
import { Tabs } from 'antd';
import { useAuthContext } from '../providers/AuthProvider.js';
import UserInfos from '../components/user/UserInfos';
import Advanced from '../components/user/Advanced.js';
import { useTranslation } from "react-i18next";
import { useApiContext } from '../providers/ApiProvider.js';
import UserPermissions from "../components/user/UserPermissions";

const { TabPane } = Tabs;

const UserOverview = () => {

  const [apiDispatch] = useApiContext();
  const { apiDeleteEntity } = apiDispatch;
  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const size = window.innerWidth > 768 ? "large" : "small";
  const [userEmail, setUserEmail] = useState('')

  const params = useParams();
  const isSuper = auth.roles.includes('ROLE_ADMIN');

  if (!isSuper && params.user_id !== auth.id) {
    return <Redirect to={{
      pathname: "/erreur",
      state: { status: '403', subtitle: t('Vous n\'êtes pas autorisé à accéder à cette page') }
    }} />
  }

  return (
    <Tabs animated={false} size={size}
      tabBarExtraContent={window.innerWidth > 768 ? userEmail : null}>
      <TabPane className="scrollPan" tab={t('Infos')} key="1">
        <UserInfos idUser={params.user_id} setUserEmail={setUserEmail} auth={auth} />
      </TabPane>
      {isSuper ?
        <TabPane className="scrollPan" tab={t('Permissions')} key="2">
          <UserPermissions user={params.user_id} />
        </TabPane> : null
      }
      {isSuper ?
        <TabPane className="scrollPan" tab={t('Avancé')} key="3">
          <Advanced idUser={params.user_id} apiDeleteEntity={apiDeleteEntity} />
        </TabPane> : null
      }
    </Tabs>
  );
};

export default UserOverview;
