import React, { useState } from 'react'
import { Button, Form, Icon, Input, InputNumber, message, Modal, Radio, Select, Spin, Tooltip, TreeSelect } from 'antd'
import { useTranslation } from 'react-i18next';
import { fileConvertSize, slugify } from '../../helpers';
import { useAuthContext } from '../../providers/AuthProvider';
import { useApiContext } from '../../providers/ApiProvider';
import { docExtensionsArray, imageExtensionsArray, videoExtensionArray } from '../Upload/helpers';

const formItemLayout = {
    labelAlign: 'left',
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
}
const { confirm } = Modal

function EditAccount({ account, visible, form, handleClose }) {

    const { t } = useTranslation()
    const [authState] = useAuthContext();
    const { auth } = authState;
    const IS_SUPER = auth?.roles?.includes('ROLE_ADMIN')
    const [apiDispatch] = useApiContext();
    const { apiUpdateEntity, apiFetchSubResourceWithFilter } = apiDispatch;
    const { getFieldDecorator } = form;
    const [loading, setLoading] = useState(false)

    function handleSubmit(e) {
        e.preventDefault();

        form.validateFields(async (err, values) => {
            if (!err) {
                values.prefix = values?.prefix?.trim() ?? null
                values.renameExt = handleExt(values.renameExt)
                console.log(values);
                // return
                setLoading(true)
                await update('accounts', account.id, values);

                if (IS_SUPER && (values?.maxSize !== account?.storage?.maxSize ||
                    values?.unsplashRequest != account?.storage?.unsplashRequest)) {
                    values.maxSize = String(getSizeInOctet(values))
                    await update('storages', account.storage.id, {
                        maxSize: values.maxSize,
                        unsplashRequest: values.unsplashRequest
                    });
                }
                let accountPrefix = account?.prefix
                if (values?.prefix !== accountPrefix) {
                    return showRenameConfirm(values.prefix);
                }
                setLoading(false)
                handleClose()
            }
        });
    }

    function handleExt(extensions) {
        let extSet = new Set([...extensions]);
        let addExt = []
        if (extSet.has('images')) {
            extSet.delete('images')
            addExt = [...imageExtensionsArray];
        }
        if(extSet.has('documents')) {
            extSet.delete('documents')
            addExt = [...addExt,  ...docExtensionsArray];
        }
        if(extSet.has('videos')) {
            extSet.delete('videos')
            addExt = [...addExt,  ...videoExtensionArray];
        }
        return [...(new Set([...extSet, ...addExt]))]

    }
 
    function showRenameConfirm(prefix) {
        confirm({
            title: 'Voulez vous renommer les images avec le nouveau préfixe ?',
            content: 'Cette action va modifier le nom de vos images déjà triées avec le renommage automatique, en remplaçant l\'ancien préfixe par le nouveau.',
            okText: 'Oui remplacer',
            cancelText: 'Non conserver l\'ancien',
            onOk() {
                const params = {
                    id: account.id,
                    filters: [
                        { name: 'change_prefix', value: true },
                        { name: 'new_prefix', value: prefix ? prefix : account.name }
                    ]
                }
                apiFetchSubResourceWithFilter('accounts', params, 'rename_assets', response => {
                    //console.log(response)
                    setLoading(false)
                    handleClose()
                })
            },
            onCancel() {
                setLoading(false)
                handleClose()
            },
        });
    }

    async function update(entity, id, data) {
        // setLoading(true)
        await apiUpdateEntity(entity, id, data, (response) => {
            //console.log(response)
            if (response['@type'] === 'hydra:Error') {
                message.error('Une erreur est survenue. Veuillez réessayer plus tard ...');
                return;
            }
            message.success('Les paramètres du compte ont été mise à jour');
        })

    }

    function getSizeInOctet(values) {
        let unit = values?.unit || "mo";
        if (unit.toLowerCase() === "go") {
            return 1073741824 * values.maxSize
        }
        return 1048576 * values.maxSize
    }

    return (
        <Modal visible={visible} centered width={600}
            bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
            title={t('Modifier votre compte')}
            onCancel={handleClose}
            footer={[
                <Button key="close" onClick={handleClose}>{t("Fermer")}</Button>,
                <Button key="save" type="primary" onClick={handleSubmit}>{t("Enregistrer")}</Button>,
            ]}>
            <Spin spinning={loading} >
                <Form onSubmit={handleSubmit} layout='horizontal'>
                    {IS_SUPER &&
                        <>
                            <Form.Item {...formItemLayout} label={<span>{t('Nom du compte')}</span>}>
                                {getFieldDecorator('name', {
                                    initialValue: account.name,
                                    rules: [{ required: true, message: t('Le nom du compte est requis'), whitespace: true }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={t("Type de contrat")}>
                                {getFieldDecorator('contractType', {
                                    initialValue: account?.contractType || 'freemium'
                                })(
                                    <Radio.Group>
                                        <Radio value="freemium">{t('Freemium')}</Radio>
                                        <Radio value="premium">{t('Premium')}</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>
                        </>
                    }
                    <Form.Item {...formItemLayout} label={t("Renommer lors du classement")}>
                        {getFieldDecorator('renameOn', {
                            initialValue: account?.renameOn || false
                        })(
                            <Radio.Group>
                                <Radio value={false}>{t('Non')}</Radio>
                                <Radio value={true}>{t('Oui')}</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={t("Renommage par extensions")}>
                        {getFieldDecorator('renameExt', {
                            initialValue: account?.renameExt || ['images', 'documents', 'videos']
                        })(<TreeSelect treeCheckable showCheckedStrategy="SHOW_PARENT" onChange={val => console.log('val', val)} maxTagCount={2}>
                            <TreeSelect.TreeNode value="images" title="Images" key="images">
                                {
                                    imageExtensionsArray.map(item =>
                                        <TreeSelect.TreeNode value={item} title={item} key={item} />
                                    )
                                }
                            </TreeSelect.TreeNode>
                            <TreeSelect.TreeNode value="documents" title="Documents" key="documents">
                                {
                                    docExtensionsArray.map(item =>
                                        <TreeSelect.TreeNode value={item} title={item} key={item} />
                                    )
                                }
                            </TreeSelect.TreeNode>
                            <TreeSelect.TreeNode value="videos" title="Videos" key="videos">
                                {
                                    videoExtensionArray.map(item =>
                                        <TreeSelect.TreeNode value={item} title={item} key={item} />
                                    )
                                }
                            </TreeSelect.TreeNode>
                        </TreeSelect>)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={<span>{t("Taille d'optimisation")}</span>} >
                        {getFieldDecorator('sizeCropLg', {
                            initialValue: account?.sizeCropLg,
                        })(<InputNumber min={1400} max={3000}
                            formatter={value => `${value}px`}
                            parser={value => value.replace('p', '').replace('x', '')}
                        />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={<span>{t('Prefix pour le renommage')}</span>} >
                        {getFieldDecorator('prefix', {
                            initialValue: account.prefix,
                        })(<Input placeholder={slugify(account?.name)} />)}
                    </Form.Item>
                    {IS_SUPER &&
                        <>
                            <Form.Item {...formItemLayout} label={t("Video")}>
                                {getFieldDecorator('video', {
                                    initialValue: account?.video || false
                                })(
                                    <Radio.Group>
                                        <Radio value={false}>{t('Non')}</Radio>
                                        <Radio value={true}>{t('Oui')}</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={<span>{t('Requete unsplash quotidienne')}</span>} >
                                {getFieldDecorator('unsplashRequest', {
                                    initialValue: account.storage.maxUnsplashRequest || 0,
                                })(<InputNumber min={0} max={200} />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={<span>{t('Stockage')}</span>} >
                                {getFieldDecorator('maxSize', {
                                    initialValue: fileConvertSize(account?.storage?.maxSize, false),
                                })(<InputNumber />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={<span>{t('Unité de Stockage')}</span>} >
                                {getFieldDecorator('unit', {
                                    initialValue: fileConvertSize(account?.storage?.maxSize).match(/[a-z]+/gi)?.[0] || 'mo',
                                })(<Select style={{ width: 80 }} >
                                    <Select.Option value="mo">Mo</Select.Option>
                                    <Select.Option value="go">Go</Select.Option>
                                </Select>)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={<span>
                                {t("Widget seul")}&nbsp;
                                <Tooltip title="Le compte sera uniquement accessible par le widget, le widget permettra de faire l'essentiel des modifications">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                            }>
                                {getFieldDecorator('widgetStandAlone', {
                                    initialValue: account?.widgetStandAlone
                                })(
                                    <Radio.Group>
                                        <Radio value={false}>{t('Non')}</Radio>
                                        <Radio value={true}>{t('Oui')}</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>
                        </>
                    }
                    <Form.Item {...formItemLayout} label={<span>
                        {t("Droit du widget")}&nbsp;
                        <Tooltip title="Le widget aura le droit d'éditer si le compte est externe">
                            <Icon type="question-circle-o" />
                        </Tooltip>
                    </span>
                    }>
                        {getFieldDecorator('widgetRights', {
                            initialValue: account?.widgetRights
                        })(
                            <Radio.Group>
                                <Radio value={'EDIT'}>{t('Éditer')}</Radio>
                                <Radio value={'VIEW'}>{t('Visualiser')}</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default Form.create()(EditAccount);
