import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, message, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import MyTree from "./MyTree";

const {confirm} = Modal;

export default function Trees({trees, selected, setSelected, isManager = false, rerender, showModal}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiDeleteEntity} = apiDispatch;

  function handleClick(id) {
    if (selected === id)
      return setSelected(0);

    setSelected(id);
  }

  function showDeleteConfirm(id) {
    confirm({
      title: t('Êtes-vous sûre de vouloir supprimer cette arborescence ?'),
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        apiDeleteEntity("trees", id, response => {
          message.success(t("Arborescence supprimer avce succès"))
          rerender()
        })
      },
      onCancel() {
      },
    });
  }

  return (
    <div className="flex wrap">
      {trees.map(tree => (
        <div key={tree.id} className="tree_choice_container m-10">
          {isManager ?
            <div className="action_manager">
              <Button
                className="mr-5" type="primary"
                size="small" icon="edit"
                onClick={() => showModal(tree)}
              />
              <Button
                className="mr-5" type="danger"
                size="small" icon="delete"
                onClick={() => showDeleteConfirm(tree.id)}
              />
            </div> :
            <Checkbox
              className="check_tree"
              onClick={() => handleClick(tree.id)}
              checked={tree.id === selected}
            />}
          <h3>{tree.name}</h3>
          <div key={tree.id} className="tree_choice_card">
            <MyTree tree={tree}/>
          </div>
        </div>
      ))}
    </div>
  )
}
