import React, { useEffect, useState, useRef } from 'react';
import { Layout } from 'antd';
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './MobileMenu';
import { useAuthContext } from '../../providers/AuthProvider';

const { Content } = Layout;

const MainLayout = ({ children, ...rest }) => {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiPostEntity } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { resetFilter, rerender, filter, setActiveAccount, addNotification } = accountDispatch;
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedSidebar, setCollapsedSidebar] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const isMounted = useRef(true);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (accountState.account) {
      isMounted.current = true;
      fetchTags();
    }
    return () => {
      isMounted.current = false;
    }
  }, [accountState.account, accountState.rerender]);

  function fetchTags() {
    let params = {
      filters: [{ name: 'account', value: accountState.account.id }]
    }
    if (accountState.globalSearch && accountState.accountActive) {
      params.filters = [{ name: 'account', value: accountState.accountActive.id }];
    }

    //console.time('fetch')
    apiFetchCollection('get_folders', params, (response) => {
      if (isMounted.current) {
        setFolders(response)
        if (auth.roles.includes('ROLE_ADMIN')) checkHapiFolder(response)
      }
    });
  }

  function checkHapiFolder(folders) {

    const hapiFolder = folders.find(item => item.name === 'hapiv2')

    if (hapiFolder) {
      apiPostEntity('check-folder', { folderId: hapiFolder.id, checkOnly: true }, response => {
        if (response?.success === 1 && response?.step === 1) {
          let message = `${response.deleted} assets supprimés sur ${response.hapiAssets} en ${response.executionTime} min`
          addNotification({ id: 'checkFolder', message: response.status, description: message, type: 'success' })
        }
      })
    }
  }

  return (
    <Layout className="root-layout" style={{ height: '100vh' }} hasSider={true}>
      {rest.sidebar ? <Sidebar collapsed={collapsedSidebar} setCollapsed={setCollapsedSidebar} /> : ''}
      <Layout>
        {
          !isTabletOrMobile &&
          <Header className="header1" toggle={rest.sidebar ? setCollapsedSidebar : setCollapsed} filter={filter} folders={folders}
            collapsed={rest.sidebar ? collapsedSidebar : collapsed} accountState={accountState} resetFilter={resetFilter} rerender={rerender} setActiveAccount={setActiveAccount} />
        }
        <Content style={{ minHeight: accountState.isMobile ? '100vh' : 'Calc(100vh-64px)' }}
          className={rest.sidebar || rest.searchbar ? 'has-sidebar' : ''}>
          {children}
        </Content>
      </Layout>
      {
        isTabletOrMobile && <MobileMenu accountPage={false} />
      }
    </Layout>
  );
};

export default MainLayout;
