import React from 'react';
import { Checkbox, Dropdown } from 'antd';
import CardList from "./CardList";
import AssetDraggable from "../AssetDraggable";
import { useAssetContext } from "../../../providers/AssetProvider";
import ContextMenu from '../../Widget/v2/ContextMenu';
import { useAccountContext } from '../../../providers/AccountProvider';

const Cards = (props) => {

  const [accountState] = useAccountContext();
  const { appearance } = accountState
  const [assetState, assetDispatch] = useAssetContext();
  const { duplicate } = assetState;
  const { setLoading } = assetDispatch;

  const onChange = (e) => {
    let checked = e.target.checked
    let id = e.target.data

    props.setSelected((prev) => {
      const next = new Set(prev);
      if (checked) {
        next.add(id)
      } else {
        next.delete(id)
      }
      return next;
    });
  }

  function openFullscreen(e, assetId) {
    e.stopPropagation()
    e.preventDefault()
    props.setAssetId(assetId)
    props.setSelected(new Set())
  }

  return (
    !!props.assets.length &&
    <div className="assets-flex list">

      {props.assets.map(item => (
        <Dropdown key={item.id} overlay={<ContextMenu asset={item} original/>} trigger={item.type === 3 ? [] : ['contextMenu']}>
          <div
            className={`card-list selectable-asset ${props.selected.has(item.id) ? 'card-selected' : ''}`}
            data-key={item.id}>
            <AssetDraggable
              duplicate={duplicate}
              asset={item}
              itemsChecked={props.selected}
              setItemsChecked={props.setSelected}
              setLoading={setLoading}
              draggable={props.selectable}
            >
              <CardList
                item={item}
                openFullscreen={openFullscreen}
                checkbox={(props.selectable && <Checkbox
                  checked={props.selected.has(item.id)}
                  data={item.id}
                  onChange={onChange}
                />)}
                setAssetId={props.setAssetId}
              />
            </AssetDraggable>
          </div>
        </Dropdown>
      ))}
    </div>
  )
}

export default Cards;
