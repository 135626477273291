import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import WidgetLayout from "./WidgetLayout";

const WidgetRoute = ({ component: Component, sidebar, hasLayout, ...rest }) => {


  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} >
      <WidgetLayout hasLayout={hasLayout} sidebar={sidebar} private={false} {...rest}><Component {...rest} /></WidgetLayout>
    </Route>
  );
};

export default WidgetRoute;
