import React, {useEffect, useState, useRef} from 'react';
import {useDrop} from 'react-dnd'
import {useApiContext} from '../../providers/ApiProvider';
import {useAccountContext} from '../../providers/AccountProvider';
import SideBarTitle from './SideBarTitle';

export default function TrashFolder ({tag, closeMenu = () => {}}) {

  const [accountState, accountDispatch] = useAccountContext();
  const {filter, setActiveTab} = accountDispatch;
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter} = apiDispatch;

  const [isSelected, setIsSelected] = useState(false);
  const [total, setTotal] = useState(null)
  const isMounted = useRef(null);

  useEffect(() => {
    const selected = accountState.filters.filter(item => item.id === tag.id && item.type === 'trash');
    setIsSelected(selected.length === 1);
  }, [accountState.filters, accountState.accountActive])

  useEffect(() => {
    isMounted.current = true;
    fetchItemNumber()

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [accountState.account, accountState.rerender])

  const fetchItemNumber = () => {
    if (accountState.account) {
      const data = {
        id: accountState.accountActive ? accountState.accountActive.id : accountState.account.id,
        itemsPerPage: 1,
        page: 1,
        filters: [{name: 'status', value: 2}]

      }

      apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
        if (isMounted.current && response['@type'] === "hydra:Collection")
          setTotal(response['hydra:totalItems']);
      })
    } else {
      console.log('account state non défini')
    }
  }

  const [{isOver, canDrop}, drop] = useDrop({
    accept: ['asset'],
    drop: () => ({action: 'moveToTrash'}),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  let backgroundColor = '';
  let transform = '';
  if (isActive) {
    backgroundColor = 'orange';
    transform = 'scale(1.1)';
  } else if (canDrop) {
    backgroundColor = 'skyblue'
  }

  const handleClick = (e) => {
    let filtre = {
      id: tag.id,
      name: 'status',
      value: 2,
      type: 'trash',
      element: tag
    }
    filter(filtre);
    setIsSelected(!isSelected);
    setActiveTab('2')
    closeMenu()
  }

  return (
    <SideBarTitle
      isSelected={isSelected}
      opacity={accountState.activeTab === '1' ? 'opacity-medium' : ''}
      handleClick={handleClick} name={tag.name}
      total={total}
      icon='delete'/>
  );
};

