import React from 'react';
import { Layout } from 'antd';
import Hapidam_logo from "../../assets/logo_hapidam.png";

const { Content } = Layout;

const PublicLayout = ({ children }) => {

  return (
    <Layout className="root-layout" style={{ minHeight: '100vh' }} >
      <Layout>
        <Layout.Header className="header">
          <div style={{ textAlign: 'center', height: '100%' }}>
            <img alt="Hapidam" src={Hapidam_logo} style={{ height: '75%' }} />
          </div>
        </Layout.Header>
        <Content >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
