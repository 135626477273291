import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import { useAccountContext } from "../../providers/AccountProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import MoveActions from './actions/MoveActions'
import TaggingAction from '../TaggingAction'
import CartAction from '../CartAction'
import RestoreAction from '../RestoreAction'
import DeleteAction from '../DeleteAction'
import SuperDeleteAction from '../SuperDeleteAction'
import RateAction from "../RateAction"
import EmptyingTrash from "../EmptyingTrash"
import { useTranslation } from "react-i18next";
import { useAssetContext } from "../../providers/AssetProvider";

export default function ActionBar({ sortSelection, setSortSelection }) {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const [assetState, assetDispatch] = useAssetContext();
  const { duplicate } = assetState;
  const { setLoading, setDuplicate } = assetDispatch;

  const [isTrashShowing, setIsTrashShowing] = useState(false);

  useEffect(() => {
    checkTrashFilters()
  }, [accountState.filters]);

  const checkTrashFilters = () => {
    const isTrash = accountState.filters.some(filter => filter.type === 'trash' || filter.type === 'deleted');
    setIsTrashShowing(isTrash);
  }


  function resetSelection() {
    setSortSelection(new Set())
  }

  return (
    <div
      className={(!accountState.globalSearch || accountState.accountActive !== null) && sortSelection.size ? "tab-topbar action-bar active option-two" : "tab-topbar action-bar"}
    >
      <div className="actions flex">
        {
          isTrashShowing ?
            <>
              <RestoreAction
                selection={[...sortSelection].map(item => { return { id: item } })}
                setSelection={resetSelection}
                rerender={rerender}
              />
              {accountState.filters.some(filter => filter.type === 'trash') ?
                <EmptyingTrash
                  selection={[...sortSelection].map(item => { return { id: item } })}
                  setSelection={resetSelection}
                  rerender={rerender}
                /> : null}
              {auth.roles.includes('ROLE_ADMIN') ?
                <SuperDeleteAction
                  selection={[...sortSelection].map(item => { return { id: item } })}
                  setSelection={resetSelection}
                  rerender={rerender}
                  accountId={accountState.account.id}
                /> : null}
            </>
            :
            <>
              {accountState.editor && accountState.tags.length === 1 ? null :
                <MoveActions
                  sortSelection={sortSelection}
                  setSortSelection={setSortSelection}
                  setLoading={setLoading}
                />
              }
              <TaggingAction selection={[...sortSelection].map(item => { return { id: item } })} />
              <CartAction
                selection={[...sortSelection].map(item => { return { id: item } })}
              />
              <RateAction selection={[...sortSelection].map(item => { return { id: item } })} rerender={rerender} />
              <DeleteAction
                selection={[...sortSelection].map(item => { return { id: item } })}
                setSelection={resetSelection}
                rerender={rerender}
                accountState={accountState}
              />
            </>
        }
      </div>

      {
        isTrashShowing || (accountState.editor && accountState.tags.length === 1) ? null :
          <div className='no-mobile'>
            <Radio.Group size="small" value={duplicate} onChange={(e) => setDuplicate(e.target.value)}>
              <Radio.Button value={false}>{t('Déplacer')}</Radio.Button>
              <Radio.Button value={true}>{t('Dupliquer')}</Radio.Button>
            </Radio.Group>
          </div>
      }

      <div className='flex align_center no-mobile' style={{ color: 'white' }}>
        {sortSelection.size} {t('Élément(s)')}
      </div>
    </div>
  )
}
