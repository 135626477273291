import React, {useEffect, useState} from 'react'
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Table} from 'antd';
import {useTranslation} from 'react-i18next';

const UserPermissions = ({user}) => {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiDeleteEntity} = apiDispatch;
  const [loadingPermanent, setLoadingPermanent] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(0);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: 'bottom',
    size: "small"
  })


  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchPermissions()
  }, [needUpdate]);

  const columns = [
    {
      title: t('Compte'),
      dataIndex: 'account',
      render: (account) => <>
        <span>{account.name}</span> <br/>
        <small>
          <a href={`/account/${account.id}`}>
            {t("Accéder au compte")}
          </a>
        </small>
      </>,
    },
    {
      title: t('Rôle'),
      dataIndex: 'role',
      render: (role) => <span>{role}</span>,
    },
    {
      title: t('Type d\'accès'),
      dataIndex: 'tags',
      render: (tags) => <span>{tags.length > 0 ? t("Limité") : t("Global")}</span>
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      render: (actions, record) => <span><Button
        size="small" onClick={() => handleDelete(record.id)}>{t('Supprimer')}</Button></span>,
    }
  ];

  const handleDelete = (permissionID) => {
    apiDeleteEntity('permissions', permissionID, response => {
      setNeedUpdate(needUpdate + 1)
    })

  }

  const fetchPermissions = () => {
    setLoadingPermanent(true)
    const params = {
      filters: [{
        name: 'user.id',
        value: user
      }]
    }
    apiFetchCollection('permissions', params, (response) => {
      if (response['@type'] === "hydra:Collection") {
        setPermissions(response['hydra:member'])
      }
      setLoadingPermanent(false)
    })
  }

  function handleTableChange(table_pagination) {
    // update current pagination
    const pager = {...table_pagination};
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  }


  return (
    <div className="contenu-tab">
      <Table columns={columns}
             bordered={true}
             dataSource={permissions}
             rowKey={record => record['@id']}
             pagination={pagination}
             loading={loadingPermanent}
             onChange={handleTableChange}
      />
    </div>
  )

}

export default UserPermissions;
