import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Rotate({ rotation, setRotation, save }) {

    const { t } = useTranslation();


    function rotate(action, degrees = 15) {
        let currentRotation = rotation;
        if (Math.abs(currentRotation) === 360) {
            currentRotation = 0;
        }
        switch (action) {
            case 'up':
                setRotation(currentRotation + degrees);
                break;
            case 'down':
                setRotation(currentRotation - degrees);
                break;
            case 'reset':
                setRotation(0);
                break;
        }
    }

    return (
        <div style={{ padding: 10 }}>
            <Button.Group>
                <Button type="primary" title="rotate" onClick={() => rotate('reset')}>{t('Réinitialiser')}</Button>
                <Button type="primary" title="rotate" onClick={() => rotate('down', 90)}>-90° </Button>
                <Button type="primary" title="rotate" onClick={() => rotate('up', 90)}>+90° </Button>
            </Button.Group>
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('rotate')} />
                </Button.Group>
            </div>
        </div>
    )
}
