import React from 'react'
import { PageHeader } from 'antd';
import AccountPermissions from "./AccountPermissions";
import { useTranslation } from 'react-i18next';
import ApiPermissions from '../ApiPermissions';
import { useAuthContext } from '../../providers/AuthProvider';

const LinkUser = ({ account, update = null }) => {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;

  return (
    <div className="contenu-tab">
      <PageHeader
        ghost={false}
        title={t("Gérer les permissions")}
      >
      </PageHeader>
      <AccountPermissions account={account} update={update} />
      {
        auth.roles.includes('ROLE_ADMIN') && <ApiPermissions account={account} />
      }
    </div>
  )

};

export default LinkUser;
