import React from "react";
import { message, TreeSelect } from "antd";
import Placeholder from "./assets/img_placeholder.svg";
const { TreeNode } = TreeSelect;
const api_endpoint = process.env.API_ENTRYPOINT + '/';

export const MEDIA_COLORS = { pictures: '#0088FE', videos: '#00C49F', documents: '#FFBB28' }


export function slugify(text) {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return text.toString().toLowerCase()
    .replace(p, c =>
      b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const makeUsageName = (dropResult, accountName, asset) => {

  let usageName, path;
  let prefix = slugify(accountName);
  let id = dropResult.idFile;
  if (dropResult.parentName != null) {
    let tabParentName = slugify(dropResult.parentName).split('-')
    let tabName = slugify(dropResult.name).split('-')
    if (tabParentName[0] === tabName[0] || tabParentName[0] === tabName[0] + 's') {
      path = slugify(dropResult.name)
    } else {
      path = slugify(dropResult.parentName) + "-" + slugify(dropResult.name)
    }
  } else {
    path = slugify(dropResult.name);
  }
  usageName = id === 0 ? prefix + '-' + path : prefix + '-' + path + '-' + pad(id, 3)

  // console.log(usageName)
  return usageName;
}

function pad(num, size) {
  var s = "00" + num;
  return s.substr(s.length - size);
}

export const moveToTrash = async (itemsChecked, apiUpdateEntity, external_dispatcher, tagFilter = null) => {
  let data = { tagId: tagFilter }
  const p1 = itemsChecked.map(async item => {
    await apiUpdateEntity('assets/delete', item.id, data, (response) => {
      // console.log('del', response)
    })
  })
  Promise.all(p1).then(() => {
    external_dispatcher('success')
  })
}

export function idFile(tag) {
  var id = [];
  id.push(0)
  if (tag.assets.length > 0) {
    tag.assets.map((asset) => {
      let test = asset.usageName.split('-');
      if (isNumeric(test[test.length - 1]) && Math.max(...id) <= parseFloat(test[test.length - 1])) {
        id.push(parseFloat(test[test.length - 1]) + 1)
      } else {
        id.push(1);
      }
    })
  }
  return Math.max(...id);
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function definePageSelected(Tabs, pageSelected, setPageSelected, currentPage) {
  if (Tabs.length > 0 && !pageSelected.includes(currentPage)) {
    setPageSelected([
      ...pageSelected,
      currentPage
    ])
  } else if (Tabs.length === 0) {
    setPageSelected(pageSelected.filter(page => page !== currentPage))
  }
}

export function downloadResource(url, filename, stat = null) {
  if (!filename) filename = url.split('\\').pop().split('/').pop();
  fetch(url, {
    headers: new Headers({
      'Origin': location.origin
    }),
    mode: 'cors'
  })
    .then(response => response.blob())
    .then(blob => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
      if (stat) {
        stat.data.size = String(blob.size)
        stat.post('statistics', stat.data, response => {
          // console.log(response)
        })
      }
    })
    .catch(e => console.error(e));
}

export function forceDownload(blob, filename) {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function fileConvertSize(bytes, formatter = true) {
  var thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = ['ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return formatter ? bytes.toFixed(2) + ' ' + units[u] : bytes.toFixed(1);
}

export function stringDate(date) {
  let toStr = new Date(date)
  let str = toStr.toLocaleString('fr-FR')
  return str;
}

export function checkSimilarTags(tags) {
  let tagList = []
  tags.map(tag => {
    let currentTag = tag;
    if (tagList.filter(tagItem => tagItem.name.toLowerCase() === currentTag.name.toLowerCase()).length === 0) {
      currentTag.ids = [tag.id]
      tags.map(item => {
        if (item.id !== currentTag.id && currentTag.name.toLowerCase() === item.name.toLowerCase()) {
          currentTag.ids.push(item.id)
          currentTag.assets = [...currentTag.assets, ...item.assets]
        }
      })
      tagList.push(currentTag)
    }
  })
  return tagList;
}

export function formatFolderForSelect(folders, baseKey) {
  return folders.map((folder, index) => {
    let folderKey = baseKey + "-" + index;
    return (
      <TreeNode value={folder['@id']} title={folder.name} key={folderKey}>
        {folder.childTags.length > 0 ? formatFolderForSelect(folder.childTags, folderKey) : null}
      </TreeNode>
    )
  })
}

export function resolveDownloadSizes(downloadMaxQuality) {
  let sizes = ["original", "hd", "lg", "md", "sm"]
  downloadMaxQuality = downloadMaxQuality ? downloadMaxQuality : 'original';
  if (downloadMaxQuality) {
    let index = sizes.indexOf(downloadMaxQuality)
    return sizes.slice(index, sizes.length)
  }
  return sizes;
}


export const mediaLinkResolver = (media, error = true, asset, share) => {
  if (!media?.extraUrl && error && !share) return Placeholder;
  let size = window.innerWidth < 768 ? 'md' : 'lg'
  if (media.mimeType === 'image/gif') return share ? `https://api.dam.mmcreation.com/share/${asset?.uuid}/${asset?.usageName}` : media.contentUrl

  return share ? `https://api.dam.mmcreation.com/share/${asset?.uuid}/${asset?.usageName}?size=${size}` : media?.extraUrl?.[size].url
}


export const mediaShareResolver = (asset, forceSize) => {
  const media = asset.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset.medias[0];
  let size = window.innerWidth < 768 ? 'md' : 'lg';
  if (media.mimeType === 'image/gif') size = 'original'
  if (forceSize) size = forceSize;

  return api_endpoint + 'share/' + asset.uuid + '/' + asset.usageName + '?size=' + size;
}

export function resolveVimeoPictureLink(media) {
  // 1 = paysage  - 0 = portrait
  if (!media) return "";
  let orientation = parseFloat(media.dimensions[0]) > parseFloat(media.dimensions[1]) ? 1 : 0;
  let picDimension = orientation ? '_295x166' : '_166x295'

  return media.vimeoPictureLink + picDimension
}

export function copyLink(link) {

  navigator.clipboard.writeText(link).then(function () {
      /* clipboard successfully set */
      message.success('Lien copié avec succès')
  }, function () {
      /* clipboard write failed */
      message.error('Il y a eu une erreur')
  });
}