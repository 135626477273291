import { Icon } from "antd";
import React from "react"
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../providers/AccountProvider";
import DeleteConfirmBtn from "../../button/DeleteConfirmBtn";

const platformData = {
  1: {
    embedUrl: "https://www.youtube.com/embed/",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    url: "https://www.youtube.com/watch?v="
  },
  2: {
    embedUrl: "https://player.vimeo.com/video/",
    allow: "autoplay; fullscreen",
    url: "https://vimeo.com/"
  }
}

export default function PlatformVideos({ videos }) {

  const { t } = useTranslation()
  const [accountState] = useAccountContext();

  function copyToClipboard(url) {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(url);
  }

  return (
    <>
      {
        videos.map((item) => (
          <div key={item.id} style={{ width: '33%' }}>
            <div className={`item-card`}
              style={{ height: 'auto' }}
              data-key={item.id}>
              <div style={{ height: '200px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src={platformData[item.platform].embedUrl + item.networkId}
                  frameBorder="0"
                  allow={platformData[item.platform].allow}
                  allowFullScreen
                />
              </div>
              <div style={{ padding: '.5em 1em', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '.8em 0 0', fontSize: '1.2em' }}>
                  <Icon type='link' onClick={() => copyToClipboard(platformData[item.platform].url + item.networkId)} />
                  {/* <Icon type="edit" key="edit" style={{ marginLeft: '.5em' }} /> */}
                  {accountState.admin &&
                    <DeleteConfirmBtn
                      title={t("Êtes-vous sur de vouloir supprimer cette vidéo ?")}
                      endpoint="social_networks"
                      id={item.id}
                    />}
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </>
  )
}
