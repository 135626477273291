import React, { useMemo } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { moveToTrash } from "../../helpers.js"
import { useAccountContext } from '../../providers/AccountProvider.js';
import { useApiContext } from '../../providers/ApiProvider.js';
import Gallery from '../../assets/gallery.png'

const AssetDraggable = ({ asset, itemsChecked, setItemsChecked, children, setLoading, duplicate, draggable, renameOn = null, isSelected = false }) => {

  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity, patch } = apiDispatch;
  let rename = renameOn === null ? accountState.account.renameOn : renameOn

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id: asset.id, name: asset.usageName, type: 'asset' },
    begin: (monitor) => {
      let id = asset.id

      if (!itemsChecked.has(id)) {
        // console.log(itemsChecked)
        setItemsChecked(prev => {
          const next = new Set(prev);
          next.clear()
          next.add(id)
          return next
        })
      }
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        setLoading(true);
        if (dropResult.action === 'moveToTrash') {
          moveSelectionToTrash(itemsChecked, dropResult)
        } else {
          handleUploadMultiple({ dropResult, itemsChecked })
        }
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const handleUploadMultiple = ({ dropResult, itemsChecked }) => {

    const data = {
      assetIds: [...itemsChecked],
      dirId: dropResult.id,
      rename: rename,
      duplicate: duplicate
    }

    patch(`/api/account/${accountState.account.id}/sort-assets`, data, response => {
      setItemsChecked(new Set());
      rerender();
      console.timeEnd("sort");
    })
  };

  const moveSelectionToTrash = (itemsChecked) => {
    let tagFilter = accountState.filters.find(item => item.type === "directory")

    moveToTrash(itemsChecked, apiUpdateEntity, (result) => {
      setItemsChecked(new Set())
      rerender();
    }, tagFilter?.value)
  }

  const cardStyle = useMemo(
    () => ({
      opacity: isDragging ? 0.4 : 1,
    }),
    [isDragging],
  )

  return (
    <>
      {asset.medias[0] && asset.medias[0].extraUrl && asset.type === 1 && isSelected ?
        <DragPreviewImage connect={preview} src={asset.medias[0].extraUrl.th.url} />
        : <DragPreviewImage connect={preview} src={Gallery} />}
      <div ref={window.innerWidth > 768 && draggable ? drag : null} style={cardStyle}>
        {children}
      </div>
    </>
  );
};

export default AssetDraggable;
