import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Empty, message, Spin } from 'antd';
import VisitorAssetsList from '../components/asset/VisitorAssetsList.js';
import VisitorDirectoryView from '../components/asset/VisitorDirectoryView.js';
import { useParams } from 'react-router-dom';
import { useApiContext } from '../providers/ApiProvider.js';
import { useAccountContext } from '../providers/AccountProvider.js';

export default function Visitor({ downloadMaxQuality, type = "share" }) {

  const [accountState] = useAccountContext()
  const [apiDispatch] = useApiContext();
  const { getRequestBasic } = apiDispatch;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();
  const [tags, setTags] = useState([])

  const [visibleTags, setVisibleTags] = useState(1);
  const hasMoreTag = visibleTags < tags.length

  useEffect(() => {
    fetchTags()
  }, [])

  async function fetchTags() {
    let data = {
      token: params.token,
      filters: [
        { name: "accessType", value: type }
      ]
    }

    await getRequestBasic('/api/tags/visitor', data, response => {
      console.log('response', response)
      if (response['@type'] === "hydra:Error") {
        setError(true)
        message.error(response['hydra:description'])
        return;
      }

      if (response['hydra:member'])
        setTags(cleanTags(response['hydra:member']))
    })
  }

  function cleanTags(tags) {
    return tags.filter(tag => {
      tag.childTags = tag.type === 1 ? cleanTags(tag?.childTags) : []
      if (tag.nbAssets === 0 && (tag.childTags.length === 0 && tag.type === 1)) return false
      return true
    })
  }
console.log('accountState.visitorView', accountState.visitorView)
  const observer = useRef();
  const loaderTag = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreTag) {
          setTimeout(() => {
            setVisibleTags(next => next + 1)
          }, [500])
        }
      }, { rootMargin: '15%' });
      if (node) observer.current.observe(node);
    },
    [hasMoreTag, visibleTags]
  );

  if (error)
    return <div className="loading_container_virgin"><Empty /></div>

  return (
    <Spin spinning={loading} style={{ padding: '1em 0' }}>
      {
        accountState.visitorView === 'assets' &&
        <VisitorAssetsList mode={'masonry'} downloadMaxQuality={downloadMaxQuality} type={type}/>
      }
      {
        accountState.visitorView === 'directory' &&
        <div style={{ padding: '1em' }}>
          {
            tags.map((tag, index) =>
              index < visibleTags &&
              <VisitorDirectoryView tag={tag} mode={'masonry'} key={tag.id} type={type}/>
            )
          }
        </div>
      }
      {
        !loading && accountState.visitorView === 'directory' &&
        <div ref={loaderTag} style={{ width: 1, height: 1 }} />
      }
    </Spin>
  )
}
