import React from "react";

export default function Word(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512" width='100%'
    >
      <path
        fill={props.fill}
        d="M177 250L133 360L112 253C110 244 102 239 93 240C84 242 79 250 80 259L112 419C114 426 119 431 127 432C134 433 140 429 143 422L192 299L241 422C244 428 250 432 256 432C256 432 257 432 257 432C265 431 270 426 272 419L304 259C305 250 300 242 291 240C282 238 274 244 272 253L251 360L207 250C202 238 182 238 177 250ZM365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448Z"
      />
    </svg>
  )
}
