import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Layout } from 'antd';
import HeaderMenu from './HeaderMenu.js';
import { useTranslation } from "react-i18next";
import AccountSelectDropdown from "../dropdown/AccountSelectDropdown";
import { useAuthContext } from "../../providers/AuthProvider";
import Hapidam_logo from "../../assets/logo_hapidam.png";
import ModalUploadFiles from "../modal/ModalUploadFiles";
import ModalUserPermission from "../modal/ModalUserPermission";
import ModalDownloadAssets from "../modal/ModalDownloadAssets";
import { useAccountContext } from "../../providers/AccountProvider";
import Filters from '../Filters.js';
import { useMediaQuery } from 'react-responsive';

const Header = () => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { authIsLogged } = authDispatch;
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { resetFilter, setActiveAccount, setActiveTab, setGlobalSearch, setFreemiumOptions } = accountDispatch;
  const history = useHistory()

  const [visible, setVisible] = useState(false)
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    setVisible(false)
  }, [accountState.filters])

  const openDownloadModal = () => {
    if (accountState.account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN'))
      return setFreemiumOptions({ visible: true, type: 'download' });

    let folder = accountState.filters.filter(item => item.type === 'directory');
    if (folder.length === 1) {
      setCurrentFolder(folder[0].id)
    } else {
      setCurrentFolder(null)
    }
    setIsDownloadModalVisible(true)
  }

  if (!authIsLogged())
    return (
      <Layout.Header className="header">
        <div style={{ textAlign: 'center', height: '100%' }}>
          <img alt="Hapidam" src={Hapidam_logo} style={{ height: '75%' }} />
        </div>
      </Layout.Header>
    )

  function home() {
    setActiveTab("2")
    resetFilter()
    history.push(`/account/${accountState.account.id}`)
  }

  function openShareModal() {
    if (accountState.account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN'))
      return setFreemiumOptions({ visible: true, type: 'share' });

    setVisible(true);
  }

  return (
    <Layout.Header className="header flex between">
      <div className='flex align_center'>
        {
          isTabletOrMobile && !accountState.account && <><img alt="Hapidam" src={Hapidam_logo} style={{ height: '75%' }} /></>
        }
        {
          accountState.account &&
          <div style={{ width: 'auto', marginRight: '1em' }}>
            {accountState.group ?
              <AccountSelectDropdown accountState={accountState} setActiveAccount={setActiveAccount}
                setGlobalSearch={setGlobalSearch} />
              : <h2 className="pointer m-0" onClick={home}> {accountState?.account?.name}</h2>}
          </div>
        }
        <div className='flex align_center'>
          {(!accountState.globalSearch && accountState.account)
            ?
            <>
              {
                accountState.editor ?
                  <>
                    <div className="ml">
                      <ModalUploadFiles />
                    </div>
                    <Button
                      className="ml"
                      type="primary"
                      shape={'round'}
                      icon="share-alt"
                      onClick={openShareModal}
                    >
                      {t('Partager')}
                    </Button>
                    <ModalUserPermission
                      visible={visible}
                      setVisible={setVisible}
                    />
                  </> : null
              }
              <Button
                type="primary"
                className="ml"
                shape={'round'}
                icon="download"
                onClick={openDownloadModal}>
                {t('Télécharger')}
              </Button>
              {
                isDownloadModalVisible ?
                  <ModalDownloadAssets
                    visible={isDownloadModalVisible}
                    closeModal={() => setIsDownloadModalVisible(false)}
                    folder={currentFolder}
                  />
                  : null
              }

            </>
            : null
          }
          { accountState.editor && accountState.globalSearch
            && location.pathname.match(/^\/account-group\/[0-9]|^\/support|^\/admin\/account-overview\/[0-9]*/)
            ?
            <div className="flex between" style={{ width: 300 }}>
              <Filters />
            </div>
            : null
          }
        </div>
      </div>
      <div style={{ width: '20%' }}>
        <HeaderMenu setIsDownloadModalVisible={setIsDownloadModalVisible} />
      </div>
    </Layout.Header>
  );
};

export default Header;
