import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import TagsTreeSelect from "../tag/TagTreeSelect";
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";

export default function FolderChoice({ folders, targetFolder, onSelect, resetTagSelect, modalVisible }) {

  const {t} = useTranslation()
  const [visible, setVisible] = useState(false);
  const [accountState] = useAccountContext();
  const { filters } = accountState;

  useEffect(() => {
    if (filters.some(f => f.type === "directory") && folders.length) {
      setVisible(true)
      onSelect(filters.filter(f => f.type === "directory")[0].id)
    }
  }, [filters, folders])

  useEffect(() => {
    if (!modalVisible)
      setVisible(false);
  }, [modalVisible])

  function onChange(e) {
    setVisible(e.target.checked);

    if (!e.target.checked)
      resetTagSelect();
  }

  return (
    <div style={{ paddingBottom: 10 }}>
      <Checkbox
        checked={visible}
        onChange={onChange}
      >
        {t('Importer dans un dossier directement')}
      </Checkbox>
      {visible ?
        <TagsTreeSelect
          style={{ marginBotton: '1em' }} tags={folders} onSelect={onSelect}
          currentValue={targetFolder ? targetFolder.id : ''}
        /> : null}
    </div>
  )
}
