import React, {useEffect, useState} from "react";
import {Button, Divider, Input, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import MyTree from "../TreeChoice/MyTree";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModaEditTree({visible, setVisible, tree, rerender}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiPostEntity, apiUpdateEntity, apiFetchCollection} = apiDispatch;

  const initialTree = {name: "", tree: []}

  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [myTree, setMytree] = useState(tree ? tree : initialTree);
  const [tagAdmins, setTagAdmins] = useState([])

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    const data = {
      filters: [
        {name: "pagination", value: false},
        {name: "exists[parent]", value: false}
      ]
    }
    apiFetchCollection("tag_admins", data, response => {
      setTagAdmins(response["hydra:member"])
    })
  }

  useEffect(() => {
    if (tree)
      setMytree(tree);
  }, [tree])

  function close() {
    rerender()
    setFolderName("")
    setMytree(initialTree)
    setLoading(false);
    setVisible(false);
  }

  function onOk() {
    if (!myTree.name)
      return message.error(t("Nommer son arborescence est fortement recommandé"));

    if (!myTree.tree.length)
      return message.error(t("Créer des dossiers est requis pour enregistrer"));

    setLoading(true)

    if (tree)
     update();
    else
      post();
  }

  function post() {
    apiPostEntity("trees", myTree, response => {
      close()
    })
  }

  function update() {
    const data = {
      name: myTree.name,
      tree: myTree.tree
    }

    apiUpdateEntity("trees", myTree.id, data, response => {
      close()
    })
  }

  function createFolder(e) {
    myTree.tree = [...myTree.tree, {name: e.target.value}];

    setMytree({...myTree});
    setFolderName("");
  }

  function setName(e) {
    myTree.name =  e.target.value;
    setMytree({...myTree});
  }

  return (
    <Modal
      width={600}
      visible={visible}
      title={t("Éditer une arborescence")}
      closable={true}
      destroyOnClose={true}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('Annuler')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onOk}
          loading={loading}
        >
          {t('Confimer')}
        </Button>
      ]}
    >
      <div>
        {t("Nommer votre arborescence :")}&nbsp;
        <Input className="w-250" value={myTree.name} onChange={setName}/>
      </div>
      <Divider/>
      <div>
        {t("Créer un dossier :")}&nbsp;
        <Input className="w-250" value={folderName} onChange={(e) => setFolderName(e.target.value)} onPressEnter={createFolder}/>
      </div>
      <MyTree
        tree={myTree}
        setTree={setMytree}
        isManager={true}
        forceTagAdmin={tagAdmins}
      />
    </Modal>
  )
}
