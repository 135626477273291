import React from "react";
import CartCard from "./CartCard";
import EmptyPlaceholder from "../../EmptyPlaceholder";

function CartList({accountCarts, setSelectedCart}) {

  if (accountCarts.length === 0) 
  return <EmptyPlaceholder message="Aucun Panier"/>

  return (
    <div className="assets-square-grid flex">
      {
          accountCarts.map((cart) => (
            <CartCard key={cart.id} cart={cart} setSelectedCart={setSelectedCart}/>
          ))
      }
    </div>
  )
}

export default CartList;
