import React, { useEffect, useState } from 'react';
import { Icon, Input, message, Modal, Popover, Tree } from "antd";
import { useTranslation } from "react-i18next";
import DropdownTagAdmin from "./DropdownTagAdmin";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from '../../providers/AccountProvider';

const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
const { confirm } = Modal;


const TagsTreeDraggable = ({ defaultDirectory, setDefaultDirectory, addTag, updateTag, deleteTag }) => {

  const [accountState] = useAccountContext();
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [tagAdmins, setTagAdmins] = useState([])
  const [addLoading, setAddLoading] = useState(false)
  const [addTagValue, setAddTagValue] = useState('')

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    const data = {
      filters: [
        { name: "pagination", value: false },
        { name: "exists[parent]", value: false }
      ]
    }
    apiFetchCollection("tag_admins", data, response => {
      setTagAdmins(response["hydra:member"])
    })
  }

  //Création de la popup de modif du nom du dossier
  const makePopUp = (item) => {
    return (<Search
      onClick={(e) => { e.stopPropagation(); }}
      defaultValue={item.name}
      enterButton={t('Éditer')}
      size="small"
      onSearch={(value, event) => editItem(value.trim(), 'name', event, item)}
    />)
  };

  //Création de la popup d'ajout dossier enfant
  const makeChildFolderPopup = (item) => {
    return (<Search
      value={addTagValue}
      onChange={e => setAddTagValue(e.target.value)}
      onClick={(e) => { e.stopPropagation(); }}
      placeholder={t('Nom du dossier enfant')}
      enterButton={t('Ajouter')}
      size="small" loading={addLoading}
      onSearch={() => handleAdd(item)}
    />)
  };

  const handleAdd = async (item) => {
    setAddLoading(true)
    await addTag(addTagValue, item['@id'])
    setAddTagValue("")
    setAddLoading(false)
  }

  // Modification du nom d'un dossier
  const editItem = (value, property, event, item) => {
    console.log('item', item)
    event.stopPropagation();
    if (item.key.split('-').length > 2) {
      const data = [...defaultDirectory];
      data.map(dir => {
        editChildren(dir, item, value, property);
      });
      setDefaultDirectory(data);
    } else if (item[property] !== value) {
      let tag = item
      tag[property] = value
      updateTag(tag, { [property]: value })
      const data = defaultDirectory.map(dir => {
        if (dir === item) {
          dir[property] = value
        }
        return dir
      });
      setDefaultDirectory(data)
    }
  };

  //Modification du nom d'un dossier enfant
  const editChildren = (parent, children, value, property) => {
    const hasChildren = parent.childTags.filter(tag => tag === children);
    if (hasChildren.length === 1) {
      let tag = hasChildren[0]
      if (tag[property] !== value) {
        updateTag(tag, { [property]: value, parent: parent['@id'] })
        tag[property] = value
      }
    } else {
      parent.childTags.map(dir => {
        editChildren(dir, children, value, property);
      })
    }
  }

  //création du contenu d'une ligne de l'arbo avec le titre et les icones
  const createTitle = (item) => {
    let popUp = makePopUp(item);
    let newFolder = makeChildFolderPopup(item)
    return (
      <span>
        {item.name}
        {
          <>
            <Popover content={newFolder} trigger="click" title={t("Ajouter un dossier enfant")}>
              <Icon className="ml" type="folder-add"
                title={t("Ajouter un dossier enfant")}
                onClick={(e) => { e.stopPropagation(); }} />
            </Popover>
            <Popover content={popUp} trigger="click" >
              <Icon className="ml-sm" type="edit"
                title={t("Renommer")}
                onClick={(e) => { e.stopPropagation(); }} />
            </Popover>
            <Icon className="ml-sm"
              title={t("Supprimer")}
              type="delete" onClick={(e) => onDel(e, item)} />
            {
              accountState.admin &&
              <Icon className="ml-sm" style={{ color: item.isPrivate ? "#f5222d" : '#0cc800' }}
                title={t("Rendre Privé")}
                type={item.isPrivate ? "lock" : "unlock"} onClick={(e) => editItem(!item.isPrivate, 'isPrivate', e, item)} />
            }
            {/* <span className='ml-sm'>
              <DropdownTagAdmin directory={item} hiddenTxt={true} forceTagAdmins={tagAdmins} />
            </span> */}
          </>
        }
      </span>
    );
  };

  function showDeleteConfirm(item) {
    confirm({
      title: 'Êtes-vous sur de vouloir supprimer ce dossier?',
      content: 'Tous les dossiers et images a l\'intérieur seront supprimé',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        deleteDirectory(item)
      },
      onCancel() {
      },
    });
  }

  //Gestion du delete d'un dossier
  const onDel = (e, item) => {
    e.stopPropagation();
    if (item.childTags.length || (item.assets && item.assets.some(asset => asset.status === 1)))
      return showDeleteConfirm(item);

    deleteDirectory(item);
  };

  const deleteDirectory = (item) => {
    if (item['@id']) {
      deleteTag(item);
    }

    if (item.key.split('-').length > 2) {
      const data = [...defaultDirectory];
      data.map(dir => {
        deleteChildren(dir, item);
      });
      setDefaultDirectory(data);
    } else {
      setDefaultDirectory(defaultDirectory.filter(dir => dir !== item))
    }
  };

  //Gestion du delete des dossiers enfants
  const deleteChildren = (parent, children) => {
    const hasChildren = parent.childTags.filter(tag => tag === children);
    if (hasChildren.length === 1) {
      parent.childTags = parent.childTags.filter(item => item != children);
    } else {
      parent.childTags.map(dir => {
        deleteChildren(dir, children);
      })
    }
  };

  // Crée l'arborescence à afficher, en gérant dynamiquement les clés (type : 0-0-0) afin d'assurer le bon fonctionnement du drag en drop antd
  const loop = (defaultTree, count) => {
    return defaultTree.map((item, index) => {
      if (item === undefined) {
        return
      }
      if (count !== undefined) {
        index = count + '-' + index;
        item.key = index;
      } else {
        index = '0-' + index;
        item.key = index;
      }

      let title = createTitle(item);
      if (item.childTags && item.childTags.length) {
        return (
          <TreeNode selectable={false} key={item.key} title={title}>
            {loop(item.childTags, index)}
          </TreeNode>
        );
      } else {
        return <TreeNode selectable={false}
          key={item.key}
          title={title} />;
      }
    });
  };

  const onDragEnter = info => {
    setExpandedKeys(info.expandedKeys);
  };

  // Fonction antd gérant les drops
  const onDrop = info => {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    if (accountState.editor && accountState.tags.length > 0 && dropPos.length === 2 && info.dropToGap)
      return message.warning(t('Vous en pouvez pas placé un dossier à la racine'));

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.childTags.length > 0) {
          return loop(item.childTags, key, callback);
        }
      });
    };
    const data = [...defaultDirectory];
    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.childTags = item.childTags || [];
        // where to insert
        item.childTags.push(dragObj);
        let newTag = dragObj;
        let parentTag = '/api/tags/'+item['id'];
        updateTag(newTag, { name: newTag.name, parentTag: parentTag })
      });
    } else if (
      (info.node.props.childTags || []).length > 0 && // Has childTags
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.childTags = item.childTags || [];
        // where to insert
        item.childTags.unshift(dragObj);
      });
    } else {

      let ar;
      let i;
      loop(data, dropKey, async (item, index, arr) => {
        ar = arr;
        i = index;
        let newTag = dragObj;
        let parentTag = item.parentTag === undefined ? null : '/api/tags/'+item.parentId;
        await updateTag(newTag, { name: newTag.name, parentTag: parentTag })

      });

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setDefaultDirectory(data);
  };

  return (
    <DirectoryTree
      defaultExpandedKeys={expandedKeys}
      draggable={true}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
    >
      {loop(defaultDirectory)}
    </DirectoryTree>
  );
}
  ;

export default TagsTreeDraggable;
