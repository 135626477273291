import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {Button} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import Trees from "../TreeChoice/Trees";
import ModalEditTree from "../modal/ModalEditTree";
import MyPagination from "../MyPagination";

export default function TreeManager({}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [trees, setTrees] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const itemsPerPage = 8
  const [needUpdate, setNeedUpdate] = useState(0)
  const [tree, setTree] = useState(null)
  const [visible, setVisible] = useState(false)
  const isMounted = useRef(null);


  useEffect(() => {
    isMounted.current = true;

    fetch();
    return () => {
      isMounted.current = false;
    }
  }, [needUpdate, page])

  function fetch() {
    apiFetchCollection("trees", {page: page, itemsPerPage: itemsPerPage}, response => {
      if (isMounted.current) {
        setTrees(response["hydra:member"]);
        setTotal(response["hydra:totalItems"])
      }
    })
  }

  function showModal(tree) {
    setTree(tree)
    setVisible(true)
  }

  return (
    <div className="contenu-tab">
      <Button type="primary" icon="plus" onClick={() => showModal(null)}>{t("Créer nouvelle arborescence")}</Button>
      <Trees
        trees={trees}
        isManager={true}
        showModal={showModal}
        rerender={() => setNeedUpdate(needUpdate + 1)}
      />
      <MyPagination
        current={page}
        changePage={setPage}
        totalItem={total}
        itemPerPage={itemsPerPage}
      />
      <ModalEditTree
        visible={visible}
        setVisible={setVisible}
        tree={tree}
        rerender={() => setNeedUpdate(needUpdate + 1)}
      />
    </div>
  )
}
