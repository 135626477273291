import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { Button, message, notification } from 'antd';
import { useApiContext } from "./ApiProvider";
import { useTranslation } from "react-i18next";

// Active Tab Values (v2) : 
// 1: assets a trier 
// 2: Dossier 
// 4: Panier 
// 5: Document
// 6: Videos
// 7: Social medias  


// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
let _account = null;
let _admin = false;
let _editor = false;
let _tags = [];
let _filters = [{
  id: 'status',
  type: 'status',
  name: 'status',
  value: '1'
}];
let _group = null;
let _accountActive = null;
let _activeTab = "2";
let _cart = null;
let _page = 1;
let _pageCart = 1;
let _globalSearch = false;
let _appearance = {
  card: 'masonry',
  size: 'large',
  background: 'square'
}

try {
  _account = window.localStorage.getItem('account') || null;
  _account = _account ? JSON.parse(_account) : null;
  _admin = window.localStorage.getItem('admin') || null;
  _admin = _admin ? JSON.parse(_admin) : null;
  _editor = window.localStorage.getItem('editor') || null;
  _editor = _editor ? JSON.parse(_editor) : null;
  _tags = window.localStorage.getItem('tags') || null;
  _tags = _tags ? JSON.parse(_tags) : null;
  _group = window.localStorage.getItem('group') || null;
  _group = _group ? JSON.parse(_group) : null;
  _globalSearch = window.localStorage.getItem('globalSearch') || null;
  _globalSearch = _globalSearch ? JSON.parse(_globalSearch) : null;
  _accountActive = window.localStorage.getItem('accountActive') || null;
  _accountActive = _accountActive ? JSON.parse(_accountActive) : null;
  _filters = window.localStorage.getItem('filters') ? JSON.parse(window.localStorage.getItem('filters')) : _filters;
  _activeTab = window.localStorage.getItem('activeTab') ? window.localStorage.getItem('activeTab') : _activeTab;
  _cart = window.localStorage.getItem('cart') || null;
  _cart = _cart ? JSON.parse(_cart) : null;
  _page = window.localStorage.getItem('page') || _page;
  _pageCart = window.localStorage.getItem('pageCart') ? window.localStorage.getItem('pageCart') : _pageCart;
  _appearance = window.localStorage.getItem('appearance') ? JSON.parse(window.localStorage.getItem('appearance')) : _appearance;
} catch (e) {
  // nothing to do ^^
}

function checkActiveTab() {
  if (_filters.find(item => item.type === 'directory') && _activeTab != '2') {
    return '2'
  }
  return _activeTab
}

const defaultState = {
  account: _account,
  accountActive: _accountActive,
  activeTab: checkActiveTab(),
  admin: _admin,
  cart: _cart,
  carts: [],
  cartsNeedUpdate: 1,
  cartsLoading: true,
  editor: _editor,
  filters: _filters,
  freemiumOptions: { visible: false, type: '' },
  globalSearch: _globalSearch,
  group: _group,
  isMobile: window.innerWidth <= 768,
  notifications: [],
  page: _page,
  pageCart: _pageCart,
  rerender: 1,
  rerenderTags0: 1,
  selection: [],
  tags: _tags,
  visitorView: 'assets',
  standAloneWidget: true,
  appearance: _appearance
};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const AccountProvider = ({ children }) => {
  const [state, dispatch] = useImmer({ ...defaultState });
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
  const state = useContext(StateContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  return state;
}

function useDispatchContext() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;
  const { t } = useTranslation();

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function accountSet(value, globalSearch = false, store = true, isWidget = false) {

    let admin = value.admin
    let editor = value.admin || value.editor
    let tags = value.tags || [];
    resetNotifications()
    if (isWidget) {
      if (value?.widgetStandAlone || value?.widgetRights === 'EDIT') {
        editor = true
      }
    }

    if (value.notifications && value.notifications.length && !isWidget && admin)
      makeNotif(value.notifications);

    if (store) {
      delete value.admin
      delete value.editor
      delete value.tags
      delete value.notifications;

      localStorage.setItem('account', JSON.stringify(value));
      localStorage.setItem('admin', JSON.stringify(admin));
      localStorage.setItem('editor', JSON.stringify(editor));
      localStorage.setItem('tags', JSON.stringify(tags));
      localStorage.setItem('globalSearch', JSON.stringify(globalSearch));
    }

    dispatch(draft => {
      draft.account = value;
      draft.admin = admin;
      draft.editor = editor;
      draft.tags = tags;
      draft.globalSearch = globalSearch;
      draft.isWidget = isWidget
    });
  }

  function makeNotif(notifications) {
    // console.log(notifications)
    notifications.map((notification) => {
      const description = notification.assetId ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: t(notification.description) }} />
          <div className="mt-5">
            <img style={{ maxWidth: 150, maxHeight: 150 }} src={notification.thumb} alt="image" />
          </div>
          <div>
            <Button
              className="mt-5"
              onClick={() => restore(notification.assetId, notification.id)}
            >
              {t('Restaurer')}
            </Button>
          </div>
        </div>
      ) : <div dangerouslySetInnerHTML={{ __html: t(notification.description) }} />;

      addNotification({
        id: notification.id,
        type: notification.type,
        message: t(notification.message),
        description: description
      }, false)
    })

    if (notifications.length) {
      let text = notifications.length > 1 ? 'notifications nécessitent votre attention' : 'notification nécessite votre attention';
      addNotification({
        id: 'more',
        type: 'warning',
        message: notifications.length + ' ' + t(text),
        description: t('Cliquez sur le bouton de notification pour voir le détail de toutes les notifications.')
      }, true, false)
    }

  }

  function restore(assetId, notificationId) {
    apiPostEntity('restore-asset', { assetId: assetId, notificationId: notificationId }, (response) => {
      message.success(t('Média réstauré'));
      rerender();
    })
  }

  function addNotification(item, display = true, save = true) {
    dispatch(draft => {
      let notifications = draft.notifications;
      if (notifications.filter(notif => notif.id === item.id).length === 1) {
        updateNotification(item);
      } else {
        if (save)
          draft.notifications = [item, ...draft.notifications];

        let type = item.type ? item.type : 'info'
        if (display) {
          notification[type]({
            message: item.message,
            description: item.description,
            placement: 'bottomRight'
          });
        }
      }
    });
  }

  function updateNotification(notif) {
    dispatch(draft => {
      draft.notifications = [notif, ...draft.notifications.filter(item => item.id !== notif.id)]
    })
  }

  function deleteNotification(notif) {
    dispatch(draft => {
      draft.notifications = [...draft.notifications.filter(item => item.id !== notif.id)]
    })
  }

  function resetNotifications() {
    dispatch(draft => {
      draft.notifications = []
    })
  }


  function resetFilter() {
    // console.log("reseting filters")
    dispatch(draft => {
      draft.filters = [{
        id: 'status',
        type: 'status',
        name: 'status',
        value: '1'
      }]
    })
    localStorage.removeItem('filters');
  }

  function filter(filter) {
    if (filter.type === 'directory' || filter.type === 'trash') {
      resetFilter();
      // localStorage.removeItem('filters');
    }
    dispatch(draft => {
      let filters = draft.filters;
      const filterExist = filters.filter(item => item.id === filter.id && item.value === filter.value);
      if (filterExist.length > 0) {
        filters = filters.filter(item => item.id !== filter.id)
      } else {
        filters.push(filter);
      }
      draft.filters = [...filters];
      if (draft.activeTab === '1' || filter.type === 'directory')
        draft.activeTab = '2';
      localStorage.setItem('filters', JSON.stringify(filters));
    })
  }

  function clearFilterByTypes(type) {
    type = typeof type === 'string' ? [type] : type
    dispatch(draft => {
      let filters = draft.filters;

      filters = filters.filter(item => !type.includes(item.type))

      draft.filters = [...filters];
      // draft.activeTab = '2';
      localStorage.setItem('filters', JSON.stringify(filters));
    })
  }

  function changeAssetType(type) {
    dispatch(draft => {
      draft.filters = [{
        id: 'status',
        type: 'status',
        name: 'status',
        value: '1'
      }, {
        id: 'type',
        type: 'type',
        name: 'type',
        value: type
      }]

      localStorage.setItem('filters', JSON.stringify(draft.filters));
    })
  }

  function setActiveAccount(iri) {
    localStorage.setItem('accountActive', iri === "reset" ? null : JSON.stringify(iri));
    const newValue = iri === 'reset' ? null : iri;

    dispatch(draft => {
      draft.accountActive = newValue;
    });
    resetFilter()
    rerender()
  }

  function setGlobalSearch(isGlobalSearch) {
    localStorage.setItem('globalSearch', isGlobalSearch);
    dispatch(draft => {
      draft.globalSearch = isGlobalSearch
    });
    resetFilter()
  }

  function resetState() {
    Object.keys(localStorage).map(key => {
      if (key !== "i18nextLng" && key !== 'auth' && key !== 'useV2') {
        localStorage.removeItem(key);
      }
    })

    dispatch(draft => {
      draft.account = null;
      draft.notifications = [];
      draft.activeTab = '2';
      draft.admin = null;
      draft.editor = null;
      draft.tags = [];
      draft.isMobile = window.innerWidth <= 768;
      draft.selection = [];
      draft.globalSearch = false
      draft.accountActive = null;
      draft.group = null;
      draft.page = 1;
      draft.cart = null
      draft.pageCart = 1;
      draft.freemiumOptions = { visible: false, type: '' }
    });
    resetFilter();
  }

  function setActiveTab(activeTab) {
    localStorage.setItem('activeTab', activeTab);
    dispatch(draft => {
      draft.activeTab = activeTab;
    })
  }

  function rerender() {
    dispatch(draft => {
      draft.rerender = draft.rerender + 1;
    })
  }

  function rerenderTags0() {
    dispatch(draft => {
      draft.rerenderTags0 = draft.rerenderTags0 + 1;
    })
  }

  function setSelection(selection) {
    dispatch(draft => {
      draft.selection = selection
    })
  }

  function setGroup(group) {
    localStorage.setItem('group', JSON.stringify(group));
    dispatch(draft => {
      draft.group = group
    })
  }

  function setPage(page) {
    localStorage.setItem('page', page);
    dispatch(draft => {
      draft.page = page
    })
  }

  function setPageCart(page) {
    localStorage.setItem('pageCart', page);
    dispatch(draft => {
      draft.pageCart = page
    })
  }

  function setFreemiumOptions(value) {
    dispatch(draft => {
      draft.freemiumOptions = value
    })
  }

  function toggleVisitorView() {
    dispatch(draft => {
      draft.visitorView = draft.visitorView === 'assets' ? 'directory' : 'assets'
    })
  }

  function setter(property, value) {
    dispatch(draft => {
      draft[property] = value
    })
  }

  function updateAppearance(property, value) {
    dispatch(draft => {
      let appearance = { ...draft.appearance, [property]: value };
      draft.appearance = { ...appearance }
      localStorage.setItem('appearance', JSON.stringify(appearance));
    })
  }

  return {
    accountSet,
    addNotification,
    deleteNotification,
    changeAssetType,
    clearFilterByTypes,
    filter,
    resetFilter,
    resetState,
    rerender,
    rerenderTags0,
    setActiveAccount,
    setActiveTab,
    setFreemiumOptions,
    setGlobalSearch,
    setGroup,
    setPage,
    setPageCart,
    setSelection,
    setter,
    toggleVisitorView,
    updateAppearance
  };
}

const useAccountContext = () => {
  return [useStateContext(), useDispatchContext()]
};

export { useAccountContext, AccountProvider, StateContext, DispatchContext };
