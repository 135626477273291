export const imageTypes = ["image/jpeg", "image/png", "image/svg+xml", "image/gif", "image/tiff", "image/x-bmp", "image/bmp"];
export const docTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/x-zip-compressed",
    "application/octet-stream",
    "image/x-photoshop",
    "image/vnd.adobe.photoshop",
    "application/postscript",
    "image/x-eps",
    ""
];
export const videoTypes = ["video/mp4", "video/quicktime", "video/webm", "video/x-flv", "video/x-msvideo", "video/x-ms-wmv"]

export const imageExtensionsArray = ["bmp", "dib", "gif", "jpeg", "jpg", "png", "svg", "tga", "tiff", "tif"]
export const imageExtensions = ".gif,.jpeg,.jpg,.png,.svg,.tga,.tiff,.tif,.bmp,.dib"
export const docExtensionsArray = ["ai", "csv", "doc", "docx", "epi", "eps", "epsf", "epsi", "pdf", "ppt", "pptx", "psd", "pub", "xls", "xlsx", "zip"]
export const docExtensions = ",.doc,.docx,.csv,.xls,.xlsx,.ppt,.pptx,.pdf,.pub,.zip,.ai,.psd,.eps,.epsi,.epsf,.epi"
export const videoExtensionArray = ["avi", "flv", "mov", "mp4", "webm", "wmv"]
export const videoExtension = ",.mp4,.mov,.wmv,.avi,.flv,.webm"

export function getAcceptedTypes(hasVideo) {
    let allowedTypes = [...imageTypes, ...docTypes]
    if (hasVideo)
        allowedTypes = [...allowedTypes, ...videoTypes]

    return allowedTypes;
}