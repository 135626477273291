import React from 'react'
import {Tabs} from 'antd'
import AdminTagProposition from '../components/gestion_admin/AdminTagProposition'
import {useApiContext} from '../providers/ApiProvider.js';
import {useAuthContext} from "../providers/AuthProvider";
import {Redirect} from "react-router-dom";
import RestoreAccount from "../components/gestion_admin/RestoreAccount";
import ApiStorage from "../components/gestion_admin/ApiStorage";
import Log from "../components/gestion_admin/Log";
import {useTranslation} from "react-i18next";
import TreeManager from "../components/gestion_admin/TreeManager";

const {TabPane} = Tabs

export default function GestionAdmin() {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiUpdateEntity, apiPostEntity, apiDeleteEntity} = apiDispatch;
  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const isSuper = auth.roles.includes('ROLE_ADMIN');
  const size = window.innerWidth > 768 ? "large" : "small";

  if (!isSuper) {
    return <Redirect to={{
      pathname: "/erreur",
      state: {status: '403', subtitle: t('Vous n\'êtes pas autorisé à accéder à cette page')}
    }}/>
  }

  return (
    <Tabs
      animated={false}
      size={size}
      defaultActiveKey="arborescences"
    >
      <TabPane
        className="scrollPan"
        tab={t('Arborescences')}
        key="arborescences"
      >
        <TreeManager/>
      </TabPane>
      <TabPane
        className="scrollPan"
        tab={t('Proposition de tag')}
        key="prop"
      >
        <AdminTagProposition
          apiFetchCollection={apiFetchCollection}
          apiUpdateEntity={apiUpdateEntity}
          apiPostEntity={apiPostEntity}
          apiDeleteEntity={apiDeleteEntity}
        />
      </TabPane>
      <TabPane
        className="scrollPan"
        tab={t('Restaurer compte')}
        key="restore"
      >
        <RestoreAccount apiFetchCollection={apiFetchCollection} apiUpdateEntity={apiUpdateEntity}/>
      </TabPane>
      <TabPane
        className="scrollPan"
        tab={t('Stockage API')}
        key="global_storage"
      >
        <ApiStorage apiFetchCollection={apiFetchCollection}/>
      </TabPane>
      <TabPane
        className="scrollPan"
        tab={t('Log')}
        key="log"
      >
        <Log apiFetchCollection={apiFetchCollection}/>
      </TabPane>
    </Tabs>
  )
}
