import { Button, Slider } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Manual({ setManual, save }) {

    const { t } = useTranslation();

    function formatter(value) {
        return `${value}%`;
    }

    function handleChange(property, value) {
        setManual(prev => { return { ...prev, [property]: value } })
    }

    return (
        <div>
            <span>{t('Contraste')}</span>
            <Slider tipFormatter={formatter} defaultValue={0} min={-100} max={100}
                onChange={(value => handleChange('contrast', value + 100))} />
            <span>{t('Luminosité')}</span>
            <Slider defaultValue={0} min={-100} max={100} onChange={value => handleChange('brightness', (value + 100) / 100)} />
            <span>{t('Hue-rotate')}</span>
            <Slider tipFormatter={(value) => {
                return `${value} deg`
            }} defaultValue={0} min={-360} max={360} onChange={value => handleChange('hue', value)} />
            <span>{t('Invert')}</span>
            <Slider defaultValue={0} min={0} max={100} onChange={value => handleChange('invert', value / 100)} />
            <span>{t('Saturate')}</span>
            <Slider defaultValue={0} min={-100} max={100} onChange={value => handleChange('saturate', value + 100)} />
            <span>{t('Sépia')}</span>
            <Slider defaultValue={0} min={0} max={100} onChange={value => handleChange('sepia', value)} />
            <span>{t('Grayscale')}</span>
            <Slider defaultValue={0} min={0} max={100} onChange={value => handleChange('grayscale', value)} />
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('manual')} />
                </Button.Group>
            </div>
        </div>
    )
}
