import React, {useState} from 'react'
import {Modal, Input, Button} from 'antd'
import {useTranslation} from 'react-i18next';
const { Search } = Input;

export default function ModalForgetPassword({visible, setVisible, postRequestBasic}) {

	const {t} = useTranslation();
	const [loading, setLoading] = useState(false)
	const initialStatus = {type: null, message: null}
	const [status, setStatus] = useState(initialStatus)

	function search(value) {
		if (validateEmail(value)) {
			searchUser(value)
			setStatus(initialStatus)
		} else {
			setStatus({type: 'error', message: 'format email non valide'})
		}
	}

	function validateEmail(email) {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
	}

	function searchUser(email) {
		setLoading(true)
		const params = {
			email: email
		}
		postRequestBasic('/forget-password', params, response => {
			//console.log(response)
			setStatus({type: response.status, message: response.message})
			setLoading(false)
		})
	}

	return (
        <Modal
          title={t('Mot de passe oublié')}
          centered
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key="close" type="primary" onClick={() => setVisible(false)}>
              {t('Fermer')}
            </Button>,
          ]}
        >
        	<div>
        		<p>{t('Vous avez oublié votre mot de passe ?')}</p>
        		<p>{t('Veuillez entrer votre email avec lequel vous êtes inscrit dans le champs ci-dessous')}</p>
        	</div>
		    <Search
		      style={{marginBottom: 15}}
		      placeholder={t("Ex : nicolasdupond@email.com")}
		      enterButton={t("Envoyer")}
		      size="large"
		      onSearch={search}
		      loading={loading}
		    />
		    <div style={{minHeight: 21}}>
		    {
		    	status.message
		    	? <p style={{marginBottom: 0, color: status.type === "error" ? 'red' : null}}>{t(status.message)}</p>
		    	: null
		    }
		    </div>
        </Modal>
	)
}
