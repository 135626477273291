import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Icon, Input, message, Radio, Select, Tooltip } from 'antd';
import { useApiContext } from "../../providers/ApiProvider";

const CreateAccountForm = ({ form, setAccounts, widgetCallBack }) => {

  const { t } = useTranslation();
  const { Option } = Select;
  const { getFieldDecorator } = form;
  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiFetchCollection } = apiDispatch;
  const [existingAccounts, setExistingAccounts] = useState([]);
  const [value, setValue] = useState([]);

  const fetchAccounts = (value) => {
    setExistingAccounts([]);
    const params = {
      filters: [
        { name: 'name', value: value },
        { name: 'exists[parentAccount]', value: false }
      ]
    }
    apiFetchCollection('accounts', params, (hydra_response) => {
      const results = (hydra_response['hydra:member'])
      const data = results.map(account => ({
        text: account.name,
        value: account.id,
        iri: account['@id']
      }));

      setExistingAccounts(data);
    });
  };

  const handleChange = value => {
    setValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err)
        createAccount(values);
    });
  };

  const createAccount = (data) => {
    data.tags = [];
    if (value.length > 0)
      data.parentAccount = value;

    apiPostEntity('accounts', data, (response) => {
      if (response['@type'] === "ConstraintViolationList")
        return message.error(t(response["hydra:description"]));

      if (response['@type'] === "hydra:Error")
        return message.warning(t('Il y eu une erreur'));

      message.success(t('Compte crée avec succes'));
      setValue([])
      form.resetFields();

      if (widgetCallBack) {
        return widgetCallBack(response)
      }
      setAccounts(accounts => [...accounts, response]);
    })
  };

  return (
    <Card title={t("Créer un nouveau compte")} className={"create-account-card"}>
      <Form onSubmit={handleSubmit}>
        <Form.Item label={<span>{t('Nom du compte')}</span>} >
          {getFieldDecorator('name', {
            rules: [{ required: true, message: t('Le nom du compte est requis'), whitespace: true }],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          name="parentAccount"
          label={<span>{t('Compte parent')}</span>}>
          <Select showSearch value={value}
            placeholder={t('Rechercher un compte parent')}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={fetchAccounts}
            onChange={handleChange}
            notFoundContent={null}
            allowClear
          >
            {existingAccounts.map(d => {
              return <Option key={d.iri}>{d.text}</Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("Type de contrat")}>
          {getFieldDecorator('contractType', {
            rules: [{ required: true, message: t('Ce champs est requis') }],
            initialValue: 'freemium'
          })(
            <Radio.Group>
              <Radio value="freemium">{t('Freemium')}</Radio>
              <Radio value="premium">{t('Premium')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item label={t("Renommer les photos ?")}>
          {getFieldDecorator('renameOn', {
            rules: [{ required: true, message: t('Ce champs est requis') }],
            initialValue: true
          })(
            <Radio.Group>
              <Radio value={false}>{t('Non')}</Radio>
              <Radio value={true}>{t('Oui')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item label={<span>
          {t("Widget seul")}&nbsp;
          <Tooltip title="Le compte sera uniquement accessible par le widget, le widget permettra de faire l'essentiel des modifications">
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
        }>
          {getFieldDecorator('widgetStandAlone', {
            rules: [{ required: true, message: t('Ce champs est requis') }],
            initialValue: false
          })(
            <Radio.Group>
              <Radio value={false}>{t('Non')}</Radio>
              <Radio value={true}>{t('Oui')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item label={<span>
          {t("Droit du widget")}&nbsp;
          <Tooltip title="Le widget aura le droit d'éditer si le compte est externe">
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
        }>
          {getFieldDecorator('widgetRights', {
            rules: [{ required: true, message: t('Ce champs est requis') }],
            initialValue: 'EDIT'
          })(
            <Radio.Group>
              <Radio value={'EDIT'}>{t('Éditer')}</Radio>
              <Radio value={'VIEW'}>{t('Visualiser')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" icon="plus">
            {t('Ajouter')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )

};

export default Form.create()(CreateAccountForm);
