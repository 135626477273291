import { Button, Radio } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';

const radioStyle = {
    display: 'block',
    height: '25px',
    lineHeight: '25px',
};


export default function Filter({ filters, setFilters, save }) {

    const { t } = useTranslation();

    function handleFilter(e) {
        let value = e.target.value
        if (filters === value) {
            setFilters(null)
        } else {
            setFilters(value)
        }
    }

    return (
        <div>
            <div style={{ padding: 10 }}>
                <Radio.Group onChange={handleFilter} value={filters}>
                    <Radio style={radioStyle} value={'grayscale(100%)'}>{t('Noir et blanc')} </Radio>
                    <Radio style={radioStyle} value={'sepia(60%)'}>{t('Sépia')}              </Radio>
                    <Radio style={radioStyle} value={'brightness(0.4)'}>{t('Brightness')}    </Radio>
                    <Radio style={radioStyle} value={'contrast(200%)'}>{t('Contrast')}       </Radio>
                    <Radio style={radioStyle} value={'invert(100%)'}>{t('Invert')}           </Radio>
                    <Radio style={radioStyle} value={'saturate(30%)'}>{t('Saturer')}         </Radio>
                    <Radio style={radioStyle} value={'hue-rotate(90deg)'}>{t('Hue-rotate')}  </Radio>
                </Radio.Group>
            </div>
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('filter')} >{t('Valider')}</Button>
                </Button.Group>
            </div>
        </div>
    )
}
