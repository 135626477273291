import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom'

import Router from './components/Router.js';

import {AuthProvider} from './providers/AuthProvider';
import {ApiProvider} from './providers/ApiProvider';
import {AccountProvider} from "./providers/AccountProvider";

import "./styles/antd.less";
import "./styles/App.scss";
import "./styles/Appv2.scss";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

function App() {
  return (
    <Suspense fallback="loading">
      <AuthProvider>
        <ApiProvider>
          <AccountProvider>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <Router/>
              </BrowserRouter>
            </DndProvider>
          </AccountProvider>
        </ApiProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App
