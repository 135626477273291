import React, { useEffect, useState } from 'react'
import { Button, Card, Collapse, Icon, Spin } from 'antd'
import DescriptionTabs from './imageData/DescriptionTabs';
import TagsTabs from './imageData/TagsTabs';
import MetaDataTabs from './imageData/MetaDataTabs';
import SEOTabs from './imageData/SEOTabs';
import StatTabs from './imageData/StatTabs';
import { useTranslation } from 'react-i18next';
import UpdateTabs from './imageData/UpdateTabs';
import Stars from '../Stars';

const customPanelStyle = {
    background: '#fff',
    border: 0,
    borderBottom: '1px solid #e8e8e8',
    overflow: 'hidden',
};

export default function MediaSidebar({ asset, canEdit, updateEntity, iconeRename, toggleGalery, setAsset, isWidget, updateAsset }) {

    const { t } = useTranslation();
    const [media, setMedia] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (asset)
            setMedia(asset.medias.find(media => media.id === asset.currentMedia))
    }, [asset]);

    async function handleStars(value) {
        setLoading(true)
        await updateEntity('assets', asset.id, { note: value })
        setLoading(false)
    }

    async function handleRename() {
        setLoading(true)
        let data = { usageName: media.originalName }
        await updateEntity('assets', asset.id, data, 'Renommage effectué')
        setLoading(false)
    }

    return (
        <div className={`media-sidebar`} >
            <Card title="Informations">
                <Spin spinning={loading}>
                    <div>
                        {asset?.usageName} {canEdit && iconeRename} {canEdit && asset?.usageName !== media?.originalName && <Icon type="history" title={t("Revenir au nom d'origine")} onClick={handleRename} />}
                    </div>
                    <Stars inTooltip={false} itemId={null} stars={asset?.note} setStars={handleStars} disabled={!canEdit} />
                </Spin>
                {!isWidget && asset?.type === 1 && <Button onClick={toggleGalery} type="primary" className="mt-5" size="small">{t('Réseaux sociaux')}</Button>}
            </Card>
            {
                asset &&
                <Collapse bordered={false} expandIconPosition='right' defaultActiveKey={['1']} accordion>
                    <Collapse.Panel header={<span className='bold'>{t('Détails')}</span>} style={customPanelStyle} key='1'>
                        <DescriptionTabs
                            asset={asset}
                            media={media}
                            canEdit={canEdit}
                            updateEntity={updateEntity}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel header={<span className='bold'>Tags</span>} style={customPanelStyle} key='2'>
                        <TagsTabs tags={asset.tags} canEdit={canEdit} asset={asset} />
                    </Collapse.Panel>
                    <Collapse.Panel header={<span className='bold'>Metadata</span>} style={customPanelStyle} key='3'>
                        <MetaDataTabs assetId={asset?.id} media={media} canEdit={canEdit} />
                    </Collapse.Panel>
                    {
                        (canEdit)
                            ?
                            <Collapse.Panel header={<span className='bold'>{t('Référencement')}</span>} key="5" style={customPanelStyle}>
                                <SEOTabs asset={asset} updateEntity={updateEntity} />
                            </Collapse.Panel>
                            :
                            null
                    }
                    {
                        (canEdit) && asset.type !== 3
                            ?
                            <Collapse.Panel header={<span className='bold'>{t('Versions')}</span>} key="6" style={customPanelStyle}>
                                <UpdateTabs
                                    asset={asset}
                                    mimeType={media?.mimeType}
                                    setAsset={setAsset}
                                    updateAsset={updateAsset}
                                />
                            </Collapse.Panel>
                            :
                            null
                    }
                    {
                        canEdit && asset.type === 1
                            ?
                            <Collapse.Panel
                                header={<span className='bold'>{t('Statistiques')}</span>} key="7" style={customPanelStyle}>
                                <StatTabs asset={asset} />
                            </Collapse.Panel>
                            :
                            null
                    }
                </Collapse>
            }
        </div>
    )
}
