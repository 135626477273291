import React, { useEffect, useState } from 'react'
import { InputNumber, Select, Button } from 'antd'
import TagsTreeSelect from '../tag/TagTreeSelect'
import { useApiContext } from '../../providers/ApiProvider';
import WidgetGalery from './WidgetGalery';

export default function WidgetRequest({ index, requestId, request, setRequest, pics, folders, accountId, handleDelete, isFirst, isLast, onMove }) {

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;

    const [currentFolder, setCurrentfolder] = useState(request?.tags.length ? request.tags[0] : null)
    const [quantity, setQuantity] = useState(request?.quantity || 1)
    const [sortRule, setSortRule] = useState(request?.sort || 'prefer_main')
    const [needUpdate, setNeedUpdate] = useState(0)
    const [picsResult, setPicsResult] = useState([])
    const [format, setFormat] = useState(request?.format)
    const [duplicate, setDuplicate] = useState(typeof request?.duplicate === 'number' ? request.duplicate : 1)
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState(request?.tags.length > 1 ? [...request.tags].splice(1) : [])
    const [folderName, setFolderName] = useState(request?.meta?.folderName)
    const [tagsLabels, setTagsLabels] = useState(request?.meta?.tags || [])
    const [needTags, setNeedTags] = useState(0)

    useEffect(() => {
        if (needUpdate) {
            setRequest(requestId, {
                tags: currentFolder ? [currentFolder, ...selectedTags] : [],
                quantity: quantity,
                sort: sortRule,
                format: format,
                duplicate: duplicate,
                meta: {
                    folderName: folderName,
                    tags: currentFolder ? tagsLabels : []
                }
            })
        }
    }, [needUpdate])

    useEffect(() => {
        if (currentFolder) {
            console.log('tags', currentFolder)
            getTags()
        }
    }, [needTags])

    useEffect(() => {
        if (pics) {
            setPicsResult(pics?.results)
        }
    }, [pics])

    function getTags() {

        let params = {filters: [{ name: 'tagId', value: currentFolder }]}

        apiFetchCollection('get_descriptive_tags', params, (hydra_response) => {
            console.log('hydra_response', hydra_response) 
            if (hydra_response['success']) {
                setTags(hydra_response['tags'])
            }
        });
    }

    function updateData(setter, data, isFolder = false) {
        if (isFolder) {
            setFolderName(isFolder[0])
            setSelectedTags([])
            setNeedTags((new Date()).getTime())
        }
        setter(data)
        setNeedUpdate((new Date()).getTime())
    }

    function updateTag(data, options) {
        setTagsLabels(options.map(item => item.props.children))
        setSelectedTags(data)
        setNeedUpdate((new Date()).getTime())
    }

  const search = new URLSearchParams(window.location.search)
  const quantityMax = search.get('limit') ? Number(search.get('limit')) : 300;

    return (
        <div className='request-container'>
        	<div style={{position:'absolute',marginTop:'-5px'}}>
	        	<Button type="default" icon="up" onClick={() => onMove(index, 'up')} />
	        	<Button type="default" icon="down" onClick={() => onMove(index, 'down')} />
	        </div>
            <Button type="danger" icon="delete"
                style={{ position: 'absolute', right: '-12px', top: '50%', transform: 'translate(100%, -50%)' }}
                onClick={() => handleDelete(requestId)} />
            <div className='request-content flex flex-column center'>
                <div className="flex align_baseline wrap">
                    <div className='mr mb'>
                        <span className='mr'>Je souhaite afficher</span>
                        <InputNumber value={quantity} onChange={value => updateData(setQuantity, value)} min={1} max={quantityMax}/>
                    </div>
                    <div className='mr mb'>
                        <span className='mr'>photos maximum de type</span>
                        <div style={{ display: 'inline-block', width: '200px' }}>
                            <TagsTreeSelect tags={folders} currentValue={currentFolder} onSelect={(val, label) => updateData(setCurrentfolder, val, label)} />
                        </div>
                    </div>
                    {
                        currentFolder && !!tags.length &&
                        <div style={{ width: '200px', marginBottom: '1em' }}>
                            <Select mode="multiple"
                                placeholder="Tags descriptifs"
                                value={selectedTags}
                                onChange={updateTag}
                                style={{ width: '100%' }}
                            >
                                {
                                    tags.map(tag => <Select.Option value={tag.id} key={tag.id}>{tag.name}</Select.Option>)
                                }
                            </Select>
                        </div>
                    }
                </div>
                <div className="flex align_baseline wrap">
                    <div className='mr mb'>
                        <span className='mr'> au format</span>
                        <Select onChange={(val) => updateData(setFormat, val)} value={format} allowClear style={{ width: 100 }} placeholder="format">
                            <Select.Option value={1}>Paysage</Select.Option>
                            <Select.Option value={2}>Portrait</Select.Option>
                            <Select.Option value={3}>Carré</Select.Option>
                        </Select>
                    </div>
                    <div className='mr mb'>
                        <span className='mr'>et qu'il s'agisse</span>
                        <Select onChange={(val) => updateData(setSortRule, val)} value={sortRule} allowClear style={{ width: 200 }} placeholder="format">
                            <Select.Option value={'prefer_main'}>Des meilleures photos</Select.Option>
                            <Select.Option value={'random'}>De photo au hasard</Select.Option>
                        </Select>
                    </div>
                    <div className='mr mb'>
                        <span className='mr'>et</span>
                        <Select onChange={(val) => updateData(setDuplicate, val)} value={duplicate} style={{ width: 100, marginRight: '1em' }} >
                            <Select.Option value={1}>permettre</Select.Option>
                            <Select.Option value={0}>éviter</Select.Option>
                        </Select>
                        <span>les doublons</span>
                    </div>
                </div>
            </div>
            
            <div className='request-preview'>
                <WidgetGalery images={picsResult} />
            </div>
        </div>
    )
}
