import React from 'react';
import { useAccountContext } from "../../../providers/AccountProvider";
import CardList from "./card/CardList";
import Masonry from "./card/Masonry";
import InfiniteScroll from '../../asset/InfiniteScroll';

const DisplayCards = ({ assets, lastAssetElement = null, assetId, setAssetId, updateAssetId, updateAsset, selected, setSelected, multiple, selectionPreview = false, infiniteScroll = true, ...props }) => {

  const [accountState] = useAccountContext();
  const { appearance } = accountState;

  return (
    <>
      {
      appearance.card === 'list' ?
        <div className="assets-flex list">
          {assets.map((asset, index) => (
            <div key={asset.id} data-key={asset.id}
              className={`card-list selectable-asset${selected.has(asset.id) ? ' card-selected' : ''}`}>
              <CardList
                selectionPreview={selectionPreview} selected={selected} setLoading={props.setLoading}
                item={asset} openFullscreen={() => setAssetId(asset.id)} selectable={multiple || accountState.editor}
                isSelected={selected.has(asset.id)} setSelected={setSelected} draggable={accountState.editor}
              />
            </div>
          ))}
        </div>
        :
        <div className="assets-flex list">
          <div className={`assets-${appearance?.card}-grid ${appearance?.size}`}>
            {assets.map((asset) => (
              <div key={asset.id} className="grid-item">
                <Masonry
                  selectionPreview={selectionPreview} selected={selected} setLoading={props.setLoading}
                  item={asset} openFullscreen={() => setAssetId(asset.id)} selectable={multiple || accountState.editor}
                  isSelected={selected.has(asset.id)} setSelected={setSelected} draggable={accountState.editor}
                />
              </div>
            ))}
          </div>
        </div>
      }

      {infiniteScroll &&
        <InfiniteScroll
          page={props.page}
          setPage={props.setPage}
          loading={props.loading}
          hasMore={props.hasMore}
        />}
    </>
  )
};

export default DisplayCards;
