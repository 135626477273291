import React from 'react';
import { Button, Icon, Popover } from "antd";
import { useTranslation } from 'react-i18next';

import { ReactComponent as Masonry } from '../../assets/view-masonry.svg';
import { ReactComponent as Square } from '../../assets/view-grid.svg';
import { ReactComponent as List } from '../../assets/view-list.svg';
import { useAccountContext } from '../../providers/AccountProvider';

const CardViewChoice = () => {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext()
  const { appearance } = accountState
  const { updateAppearance } = accountDispatch

  function handleClick(property, value) {
    updateAppearance(property, value)
  }

  const content = (
    <div>
      <div className='mb'>
        <p>{t("Format")}</p>
        <div>
          <Button onClick={() => handleClick('card', 'masonry')} className={`mr square-btn ${appearance?.card === 'masonry' && 'card-selected'}`} ><Masonry /></Button>
          <Button onClick={() => handleClick('card', 'square')} className={`mr square-btn ${appearance?.card === 'square' && 'card-selected'}`} ><Square /></Button>
          <Button onClick={() => handleClick('card', 'list')} className={`square-btn ${appearance?.card === 'list' && 'card-selected'}`} ><List /></Button>
        </div>
      </div>
      <div className='mb'>
        <p>{t("Taille")}</p>
        <div>
          <Button onClick={() => handleClick('size', 'small')} className={`mr square-btn ${appearance?.size === 'small' && 'card-selected'}`} title={t("Petit")}>S</Button>
          <Button onClick={() => handleClick('size', 'medium')} className={`mr square-btn ${appearance?.size === 'medium' && 'card-selected'}`} title={t("Moyen")}>M</Button>
          <Button onClick={() => handleClick('size', 'large')} className={`square-btn ${appearance?.size === 'large' && 'card-selected'}`} title={t("Large")}>L</Button>
        </div>
      </div>
      <div className='mb'>
        <p>{t("Fond")}</p>
        <div>
          <Button onClick={() => handleClick('background', 'square')} className={`mr square-btn ${appearance?.background === 'square' && 'card-selected'}`}><div className='square-btn bg-pattern-square'></div></Button>
          <Button onClick={() => handleClick('background', 'dot')} className={`mr square-btn ${appearance?.background === 'dot' && 'card-selected'}`} ><div className='square-btn bg-pattern-dot'></div></Button>
          <Button onClick={() => handleClick('background', 'iso')} className={`mr square-btn ${appearance?.background === 'iso' && 'card-selected'}`}><div className='square-btn bg-pattern-iso'></div></Button>
          <Button onClick={() => handleClick('background', 'white')} className={`square-btn ${appearance?.background === 'white' && 'card-selected'}`}><div className='square-btn bg-pattern-white' style={{ backgroundColor: 'f1f1f1' }}></div></Button>
        </div>
      </div>
    </div>
  )

  return (
    <Popover content={content} title={t("Apparence")} trigger="click" placement="leftBottom">
      <Button className='ml-10' size="small" type="primary" shape='circle' icon='appstore' />
    </Popover>
  )
};

export default CardViewChoice;
