import React from 'react';

import { Progress } from 'antd';

const PercentCard = ({ title, percent, actionText, action }) => {


  return (
    <div key={title} style={{margin: '10px 0'}}>
          <div><span style={{fontSize:'14px', fontWeight: 'bold'}}>{title}</span> {action && actionText ? <span style={{cursor: 'pointer', float: 'right'}} onClick={() => action()} >{actionText}</span> : ''}</div>
          <Progress percent={Math.round(percent) === 100 ? Math.round(percent)-0.1 : Math.round(percent)} size="small" />
        </div>

  );
}

export default PercentCard;
