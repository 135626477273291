import React, {useEffect, useState} from 'react';
import {Button, Icon} from 'antd';
import {useTranslation} from "react-i18next";

const ColorFilter = ({accountState, filter, setCollapsed}) => {

  const {t} = useTranslation();
  const [activeFilter, setActiveFilter] = useState({})

  useEffect(() => {
    setActiveFilter(accountState.filters.filter(item => item.id === 'color')[0]);
  }, [filter]);

  function onChange(value, colorCode) {
    if (activeFilter) {
      filter(activeFilter);
    }
    let newFilter = {
      id: 'color',
      name: 'colors',
      value: value,
      type: 'color',
      color: colorCode
    };
    filter(newFilter);
    setActiveFilter(newFilter);

  }

  function filterBNW() {
    if (activeFilter) {
      filter(activeFilter);
    }
    let newFilter = {
      id: 'color',
      name: 'blackAndWhite',
      value: true,
      type: 'color'
    };
    //console.log(newFilter);
    filter(newFilter);
    setActiveFilter(newFilter);

  }

  const colors = [
    ['white', 'rgb(256, 256, 256'],
    ['black', 'rgb(77, 77, 77)'],
    ['yellow', 'rgb(252, 220, 0)'],
    ['orange', 'rgb(254, 146, 0)'],
    ['red', 'rgb(244, 78, 59)'],
    ['purple', 'rgb(123, 100, 255)'],
    ['pink', 'rgb(171, 20, 158)'],
    ['green', 'rgb(164, 221, 0)'],
    ['teal', 'rgb(104, 204, 202)'],
    ['blue', 'rgb(0, 156, 224)']
  ];

  return (
    <>
      <div style={{width: '90%', textAlign: 'center', margin: '0 0 15px'}}>
        <Button onClick={filterBNW}
                      type={activeFilter !== undefined && activeFilter.name && activeFilter.name === 'blackAndWhite' ? 'primary' : 'default'}
                      color={'default'}>{t('Noir et blanc')}</Button>
      </div>
      <div style={{display: 'flex', width: '90%', flexWrap: 'wrap', paddingLeft: '24px'}}>
        {colors.map(color => (
          <div key={color} style={{width: '20%', marginBottom: '5px'}}>
            <div style={{
              border: color[0] === 'white' ? 'solid 1px black' : 'none',
              backgroundColor: color[1],
              width: 25,
              height: 25,
              borderRadius: '100%',
              cursor: 'pointer',
              position: 'relative'
            }} onClick={() => {
              onChange(color[0], color[1])
            }}>
              {activeFilter && activeFilter.value === color[0] ? <Icon type="check-circle" style={{
                color: activeFilter.value == 'white' ? '#000' : '#fff',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}/> : ''}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ColorFilter;
