import React, {useState} from "react";
import {Form, Input, Modal, Tabs} from "antd";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const ModalRenameAssetComponent = ({visible, onCancel, onCreate, form, setOptionRename, assetName}) => {

  const {t, i18n} = useTranslation();
  const {getFieldDecorator} = form;
  const prefix = assetName.replace(/-\d*$/, '-');
  const [keyTab, setKeyTab] = useState('1')

  function callback(key) {
    setOptionRename(key)
    setKeyTab(key)
  }

  const rules =  [
      {
        required: true,
        message: t('Ce champs est obligatoire!')
      }
    ]

  return (
    <Modal
      visible={visible}
      title={t('Renommer votre image')}
      okText={t('Renommer')}
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab={t('Renommer l\'index')} key="1">
            <Form.Item label={t('Nom')}>
              {getFieldDecorator("index", {
                rules: keyTab === '1' ? rules : null
              })(<Input addonBefore={prefix} placeholder="mysite"/>
              )}
            </Form.Item>
          </TabPane>
          <TabPane tab={t('Renommage complet')} key="2">
            <Form.Item label={t('Nom')}>
              {getFieldDecorator("nomComplet", {
                initialValue: assetName,
                rules: keyTab === '2' ? rules : null
              })(<Input/>)}
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

const ModalRenameAsset = Form.create({name: "modal_form"})(ModalRenameAssetComponent);

export default ModalRenameAsset;
