import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {Form, message, Spin, Typography, Button, Input} from 'antd';
import {useApiContext} from "../../providers/ApiProvider";

const {Text} = Typography;

const formItemLayout = {
  labelCol: {
    sm: {span: 24},
    md: {span: 10},
    lg: {span:6},
    xl: {span:5},
    xxl: {span:3},
  },
  wrapperCol: {
    sm: {span: 24},
    md: {span: 14},
    lg: {span:10},
    xl: {span:8},
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    sm: { span: 24, offset: 0 },
    md: { span: 19, offset: 10},
    lg: { span: 19, offset: 6},
    xl: { span: 19, offset: 5},
    xxl: { span: 19, offset: 3},
  },
};

const UpdateUserForm = ({form, user, setUser}) => {

  const {t} = useTranslation();
  const {getFieldDecorator, getFieldValue, validateFields, validateFieldsAndScroll, resetFields} = form;
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;
  const [confirmDirty, setConfirmDirty] = useState()
  const [loading, setLoading] = useState(false)

  const handleConfirmBlur = e => {
    const {value} = e.target;
    console.log(!!value)
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      validateFields(['confirm'], {force: true});
    }
    callback();
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        updateUser(formatData(values))
      } else {
        console.log(values)
        message.error(t('Certains champs présentent des erreurs'))
      }
    });
  };


  const updateUser = (data) => {
    if (Object.keys(data).length > 0) {
      setLoading(true)
      apiUpdateEntity('users', user.id, data, (response) => {
        if (response['@type'] === "hydra:Error") {
          message.error(t("Mise à jour de l'utilisateur impossible"));
        } else {
          setUser(response)
        }
        setLoading(false)
        resetFields()
      })
    }
  }


  // Return object formatted for api patch action
  const formatData = (values) => {
    let data = {}
    for (const property in values) {
      if (values[property] != undefined && values[property].trim().length != 0) {
        if (property === 'password' && values[property] != getFieldValue('confirm')) {
          message.error(t('Les mots de passe ne correspondent pas et ne serons pas modifier'))
          continue;
        }
        if (property === 'confirm') {
          continue;
        }
        if (values[property] === user[property]) {
          continue
        }
        data[property] = values[property];
      }
    }
    return data;
  }

  return (
    <Spin spinning={loading}>
      <h3>{t('Modifier mes informations')}</h3>
      <Text>{t('Laissez un champ vide si vous ne souhaitez pas le modifier')}</Text>

      <Form {...formItemLayout} onSubmit={handleSubmit} style={{margin: 20}}>

        <Form.Item label={t("Prénom")}>
          {getFieldDecorator('firstname', {initialValue: user.firstname})(<Input/>)}
        </Form.Item>

        <Form.Item label={t("Nom")}>
          {getFieldDecorator('lastname', {initialValue: user.lastname})(<Input/>)}
        </Form.Item>

        <Form.Item label={t("Email")}>
          {getFieldDecorator('email', {
            rules: [{type: 'email', message: t('Cet E-mail n\'est pas valide!')}],
            initialValue: user.email
          })(<Input/>)}
        </Form.Item>

        <Form.Item label={t("Nom d'utilisateur")}>
          {getFieldDecorator('username', {initialValue: user.username})(<Input/>)}
        </Form.Item>

        <Form.Item label={t("Mot de passe")} hasFeedback>
          {getFieldDecorator('password')(<Input.Password/>)}
        </Form.Item>

        <Form.Item label={t("Confirmer mot de passe")} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                message: t('Please confirm your password!'),
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur}/>)}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t('Enregistrer')}
          </Button>
        </Form.Item>

      </Form>

    </Spin>
  )

};

export default Form.create()(UpdateUserForm);
