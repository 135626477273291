import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModalForgetPassword from '../../components/modal/ModalForgetPassword.js'
import {useAuthContext} from '../../providers/AuthProvider.js';
import {useApiContext} from '../../providers/ApiProvider.js';
import WidgetAccountSelection from "./WidgetAccountSelection";
import Login from "../../pages/Login";

const WidgetConnect = () => {

  const [apiDispatch] = useApiContext();
  const {postRequestBasic} = apiDispatch;
  const {t} = useTranslation();

  const [authState, authDispatch] = useAuthContext();
  const {authIsLogged, authLogout} = authDispatch;

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    authLogout()
  }, [])


  if (!authIsLogged())
    return <Login />

  return (
    <div>
      <WidgetAccountSelection />
      <ModalForgetPassword visible={visible} setVisible={setVisible} postRequestBasic={postRequestBasic}/>
    </div>
  );
};

export default WidgetConnect;
