import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider.js';
import AccountLayout from './layout/AccountLayout.js';

const PrivateAccountRoute = ({ component: Component, sidebar, ...rest }) => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { authIsLogged, authLogout } = authDispatch

  const renderer = (props) => {

    if (authIsLogged() && !auth.visitor)
      return <AccountLayout sidebar={sidebar} {...props}><Component {...props} /></AccountLayout>
    authLogout();
    window.location.href = '/login';
  }

  return (
    <Route {...rest} render={renderer.bind(null)} />
  );
};

export default PrivateAccountRoute;
