import React, {useState} from 'react'
import {Button, message, Modal} from 'antd'
import UnsplashComponent from "../UnsplashComponent";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {useHistory} from "react-router-dom";
import {useAuthContext} from "../../providers/AuthProvider";

export default function ModalUsplash({setIsModalVisible, targetFolder = {}}) {

  const {t} = useTranslation();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiPostEntity, patch} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {setActiveTab, rerender, addNotification} = accountDispatch;
  const history = useHistory()

  const [visible, setVisible] = useState(false);

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [folderId, setFolderId] = useState(0);

  function post() {
    setLoading(true);

    let formData = new FormData();
    formData.append('urls', JSON.stringify(selected));
    formData.append('accountId', accountState.account.id);

    apiPostEntity("post-from-unsplash", formData, response => {

      if (folderId) {
        const data = {
          mediaIds: response.medias.map(({id}) => id),
          dirId: folderId,
          rename: accountState.account.renameOn,
          duplicate: false
        }

        patch("/api/sort-assets-by-medias", data, responseBis => {
          notifDispatcher(response);
          hardClosing();
        })
      } else {
        notifDispatcher(response);
        hardClosing();
      }

    })
  }

  function hardClosing() {
    setLoading(false);
    setSelected([]);
    rerender();
    setVisible(false);
    setIsModalVisible(false);

    if (!accountState.isWidget && !folderId) {
      setActiveTab("1");
      history.push(`/account/${accountState.account.id}`);
    }
  }

  function notifDispatcher(responseApi) {
    let descr = [];
    if (responseApi.medias.length)
      descr = [...descr, (
        <p key="result">{responseApi.medias.length} {t('Fichier(s) Téléchargé(s) avec succès')}</p>)];

    if (responseApi.errors.length) {
      responseApi.errors.map((err, index) => {
        descr = [...descr, (<p key={index} style={{color: 'red'}}>{t('Erreur')} : {t(err.type)} - {err.file}</p>)];
      })
    }

    const content = {id: 'upload', message: `vous avez importé des fichiers`, description: descr};
    addNotification(content);
  }

  return (
    accountState.account.storage.maxUnsplashRequest > accountState.account.storage.unsplashRequest || auth.roles.includes("ROLE_ADMIN") ?
      <div>
        <Modal
          style={{
            top: 20
          }}
          width="80vw"
          centered
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <div
            style={{height: "80vh", overflow: "auto", position: "relative"}}
          >
            <UnsplashComponent selected={selected} setSelected={setSelected}/>

            {selected.length ?
              <Button style={{position: "fixed", bottom: 100, left: "45%"}} type="primary" onClick={post}
                      loading={loading}>{t("Importer")}</Button>
              : null}
            {selected.length ?
              <Button style={{position: "fixed", bottom: 100, left: "55%"}} type="danger"
                      onClick={() => setSelected([])}>{t("Annuler")}</Button>
              : null}
          </div>
        </Modal>

        {!visible ?
          <Button
            style={{marginTop: 10}}
            type="primary"
            onClick={() => {
              if (accountState.isWidget && !targetFolder.name)
                return message.error(t('Vous devez sélectionner un dossier'));
              else if (targetFolder.id)
                setFolderId(targetFolder.id)
              else
                setFolderId(0)

              setVisible(true);
            }}
          >
            {t("Importer depuis")} Unsplash
          </Button> : null}
      </div> : <div style={{color: "orange"}}>{t("Nombre de requete quotidienne depassé sur unsplash")}</div>
  )
}
