import React, { useState } from 'react'
import { message, Typography, Button, Icon, Tooltip, Spin } from 'antd';
import ColorPalette from '../../ColorPalette'
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useApiContext } from '../../../providers/ApiProvider';
import { useEffect } from 'react';

const { Paragraph, Text } = Typography;

export default function MetaDataTabs({ asset, media, canEdit }) {

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiUpdateEntity, apiPostEntity } = apiDispatch;
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [datePickerOg, setDatePickerOg] = useState(false)
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState({})

  useEffect(() => {
    fetchMetaData()
  }, [media])

  const fetchMetaData = () => {
    setLoading(true)
    apiFetchSubResource('media_objects', { id: media.id }, 'meta_data', response => {
      if (response['id']) {
        setMetadata(response)
      } else {
        setMetadata({})
      }
      setLoading(false)
    })
  }

  function stringDate(date) {
    let toStr = new Date(date)
    return toStr.toLocaleString('fr-FR');
  }

  async function handleChange(property, value) {
    if (typeof value === 'string' && value.length < 3 && value.length > 100) {
      message.error(t('Nom trop court ou trop long'))
    }
    console.log('metadata', metadata)
    let values = { [property]: value }
    if (!metadata?.id) {
      return createMeta(values)
    }
    update(values);
  }

  async function update(values) {
    setLoading(true)
    await apiUpdateEntity('meta_datas', metadata.id, values, (response) => {
      if (response.error) {
        console.log(response.error);
        message.error(t('Une erreur est survenue'));
      } else {
        setMetadata(response)
        message.success(t('Données mise à jour avec succès'));
      }
      setDatePickerOg(false)
      setLoading(false)
    });
  }

  async function createMeta(data) {
    setLoading(true)
    data.media = media['@id']

    await apiPostEntity('meta_datas', data, response => {
      setMetadata(response)
      message.success(t('Données mise à jour avec succès'));
      setDatePickerOg(false)
      setLoading(false)
    })
  }

  function showDatePicker() {
    setDatePickerOg(!datePickerOg)
    if (metadata?.datetimeOriginal) {
      setStartDate(new Date(metadata.datetimeOriginal))
    }
  }

  return (
    <Spin spinning={loading} >
      <div className='tabs-assets'>
        <div className="flex  between">
          <span>{t('Appareil')} :&nbsp;</span>
          <Text editable={canEdit ? { onChange: (value) => handleChange('make', value) } : false}>
            {metadata && metadata.make ? metadata.make : ''}
          </Text>
        </div>
        <div className="flex  between">
          <span>{t('Modèle')} :&nbsp;</span>
          <Paragraph
            editable={canEdit ? { onChange: (value) => handleChange('model', value) } : null}
          >
            {metadata && metadata.model ? metadata.model : ''}
          </Paragraph>
        </div>
        <div className="flex  between">
          <span>{t('Date')} :&nbsp;</span>
          <div
            className="flex  between"
          >
            {metadata && metadata.datetimeOriginal && !datePickerOg ?
              stringDate(metadata.datetimeOriginal) : null}
            {datePickerOg && canEdit ?
              <div className="flex  between">
                <DatePicker width={{ width: 400 }} selected={startDate} onChange={(date) => setStartDate(date)}
                  showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" />
                <Button type="primary" size="small" icon="check" onClick={() => handleChange('datetimeOriginal', startDate)} />
              </div> : null}
            {canEdit ?
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Tooltip title="Edit">
                  <Icon type="edit" theme="twoTone" onClick={showDatePicker} />
                </Tooltip>
              </> : null}
          </div>
        </div>
        <div className="flex  between">
          <span>{t('Logiciel')} :&nbsp;</span>
          <Paragraph
            editable={canEdit ? { onChange: (value) => handleChange('software', value) } : false}
          >
            {metadata && metadata.software ? metadata.software : ''}
          </Paragraph>
        </div>
        <div className="flex  between">
          <span>{t('Date de modification')} :&nbsp;</span>
          {metadata && metadata.datetime ? stringDate(metadata.datetime) : "-"}
        </div>
        <div className="flex  between">
          <span>{t('Auteur')} :&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Paragraph
            editable={canEdit ? { onChange: (value) => handleChange('author', value) } : null}
          >
            {metadata && metadata.author ? metadata.author : ''}
          </Paragraph>
        </div>
        <div className="flex  between">
          <span>{t('Localisation')} :&nbsp;</span>
          <Paragraph
            editable={canEdit ? { onChange: (value) => handleChange('localisation', value) } : null}
          >
            {metadata && metadata.localisation ? metadata.localisation : ''}
          </Paragraph>
        </div>
        {
          asset?.type === 1 &&
          <ColorPalette id={asset.id} />
        }
      </div>
    </Spin>
  )
}
