import React, { useEffect, useState } from 'react';
import { Button, Rate } from 'antd';
import { useTranslation } from "react-i18next";


const StarFilter = ({ accountState, filter, setCollapsed }) => {

  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState({})

  useEffect(() => {
    setActiveFilter(accountState.filters.filter(item => item.id === 'stars')[0]);
  }, [filter]);

  const desc = [t('À partir de 1'), t('À partir de 2'), t('À partir de 3'), t('À partir de 4'), t('Égale à 5')];

  function onChange(value, isBtn = false) {

    if (activeFilter) {
      filter(activeFilter);
    }
    if (value === 0 && !isBtn) { return }
    let newFilter = {
      id: 'stars',
      name: value === 0 ? 'note' : 'note[gte]',
      value: value,
      type: 'stars',
    };

    filter(newFilter);
    setActiveFilter(newFilter);
  }

  return (
    <>
      <div style={{ width: '90%', textAlign: 'center', margin: '0 0 15px' }}>
        <div>
          <Button onClick={() => onChange(0, true)}
            type={activeFilter !== undefined && activeFilter.value === 0 ? 'primary' : 'default'}
            color={'default'}>{t('Sans note')}</Button>
        </div>
        <Rate value={activeFilter ? activeFilter.value : null} onChange={onChange} tooltips={desc} />
      </div>
    </>
  );
};

export default StarFilter;
