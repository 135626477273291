import React from "react";

export default function Publisher(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{maxWidth: '80%'}}
      viewBox="0 0 493.000000 480.000000" width='100%'
    >
      <g
        transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)"
        fill={props.fill} stroke="none"
      >
        <path
          d="M2510 4790 c-25 -5 -592 -103 -1260 -219 -668 -117 -1223 -214 -1232
-217 -17 -5 -18 -106 -18 -1944 l0 -1938 27 -6 c16 -3 458 -80 983 -171 525
-91 1080 -188 1232 -215 265 -48 285 -50 457 -50 l181 0 0 275 0 274 668 3
667 3 53 24 c106 48 114 88 113 538 l-1 321 228 5 c149 3 240 9 264 17 34 12
38 18 48 64 7 35 9 482 8 1398 -3 1340 -3 1347 -24 1375 -11 15 -42 39 -70 53
l-49 25 -555 3 c-631 4 -773 -4 -832 -43 -96 -64 -118 -134 -118 -382 l0 -183
-200 0 -200 0 0 500 0 500 -162 -1 c-90 -1 -183 -5 -208 -9z m2268 -1822 l2
-1278 -200 0 -200 0 0 1003 0 1003 -79 52 c-43 29 -164 98 -268 153 -224 120
-290 159 -363 214 -62 47 -122 106 -115 113 12 12 297 19 735 18 l485 -1 3
-1277z m-613 -778 l0 -1445 -640 0 -640 0 -3 168 -2 167 475 0 475 0 0 110 0
110 -475 0 -475 0 0 140 0 140 475 0 475 0 0 110 0 110 -475 0 -475 0 0 140 0
140 475 0 475 0 0 110 0 110 -475 0 -475 0 0 140 0 140 475 0 475 0 0 385 0
385 -472 2 -473 3 -3 129 c-1 72 0 136 2 143 4 11 125 13 643 11 l638 -3 0
-1445z m-2600 1155 c97 -20 206 -76 250 -128 49 -57 91 -145 116 -246 18 -70
21 -104 17 -200 -7 -147 -38 -249 -109 -355 -117 -175 -265 -246 -514 -246
l-105 0 0 -325 c0 -254 -3 -325 -12 -324 -7 0 -83 8 -168 17 l-155 17 -3 886
-2 885 177 13 c98 7 180 15 182 17 11 11 263 2 326 -11z"
        />
        <path
          d="M1220 2884 c0 -82 3 -206 7 -276 l6 -128 36 0 c62 0 162 20 201 40
76 39 115 138 108 274 -6 106 -32 165 -89 203 -39 26 -51 28 -156 32 l-113 3
0 -148z"
        />
      </g>
    </svg>
  )
}
