import React from 'react';
import AccountList from '../components/account/AccountList.js';
import { useTranslation } from "react-i18next";

const Accounts = () => {

  const { t } = useTranslation();

  return (
    <div className="contenu-tab">
      <h2>{t('Gestion des comptes')}</h2>
      <AccountList />
    </div>
  );
};

export default Accounts;
