import React, { useState, useEffect } from 'react'
import GraphStorage from '../GraphStorage.js'
import { Badge, Icon, Input, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ApiCollectionTable from '../ApiCollectionTable.js'
import { fileConvertSize, MEDIA_COLORS } from '../../helpers.js'
import { Link } from 'react-router-dom';
import { useApiContext } from '../../providers/ApiProvider.js';
import VimeoTable from './VimeoTable.js';

const { Search } = Input;

export default function ApiStorage() {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiFetchSubResourceWithFilter } = apiDispatch;
  const { t } = useTranslation();
  const [storage, setStorage] = useState(null)
  const [nbAssets, setNbAssets] = useState(0)
  const [nbActiveAccount, setNbActiveAccount] = useState(0)
  const initialFilters = [{ name: 'name', value: '' }]
  const [filters, setFilters] = useState(initialFilters)
  const [updatingStats, setUpdatingStats] = useState(false)
  const [needUpdate, setNeedUpdate] = useState(0)
  const [order, setOrder] = useState({})

  useEffect(() => {
    fetchActiveAccount()
  }, [needUpdate])

  useEffect(() => {
    fetchStorage()
    fetchAssets()
  }, [])

  function fetchStorage() {
    const params = {
      filters: [
        {
          name: 'name',
          value: 'global_storage'
        }
      ]
    }
    apiFetchCollection('storages', params, response => {
      if (response['hydra:totalItems'] === 1) {
        setStorage(response['hydra:member'][0])
      }
    })
  }

  function fetchAssets() {
    apiFetchCollection('assets', {}, response => {
      if (response['@type'] === "hydra:Collection") {
        setNbAssets(response['hydra:totalItems']);
      }
    })
  }

  function fetchActiveAccount() {
    const params = {
      filters: [
        {
          name: 'exists[assets]',
          value: true
        }
      ]
    }
    apiFetchCollection('accounts', params, response => {
      if (response['@type'] === "hydra:Collection") {
        setNbActiveAccount(response['hydra:totalItems']);
      }
    })
  }

  function updateStat(id) {
    setUpdatingStats(true)
    apiFetchSubResourceWithFilter('accounts', { id: id }, 'update', response => {
      console.log('response', response)
      setUpdatingStats(false)
      setNeedUpdate((new Date()).getTime())
    })

  }

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Capacité max',
      dataIndex: 'storage.maxSize',
      sorter: true,
      render: maxSize => <span>{fileConvertSize(maxSize)}</span>,
    },
    {
      title: 'Total utilisé',
      dataIndex: 'storage.currentSize',
      sorter: true,
      render: currentSize => <span>{fileConvertSize(currentSize)}</span>,
    },
    {
      title: 'Répartitions',
      dataIndex: 'storage',
      render: storage =>
        storage.totalByType && Object.keys(storage.totalByType).map(key => {
          return <>
            <Badge key={key} color={MEDIA_COLORS[key]} text={`${storage.totalByType[key].total} ${key} : ${fileConvertSize(storage.totalByType[key].space)}`} /><br />
          </>
        })
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: id => <>
        <Link to={`./account/${id}/`}>
          <Icon type="global" title="Voir le compte" className="mr-sm" />
        </Link>
        <Link to={`./admin/account-overview/${id}/`}>
          <Icon type="setting" title="Voir les réglages du compte" className="mr-sm" />
        </Link>
        <Icon type="sync" onClick={() => updateStat(id)} title="Actualiser le stockage" />
      </>,
    }
  ];

  const vimeoColumns = [
    {
      title: 'User',
      dataIndex: 'user',
    },
    {
      title: 'Espace',
      dataIndex: 'storage',
      render: storage => <span>{fileConvertSize(storage)}</span>,
    },
    {
      title: 'Espace utilisé',
      dataIndex: 'used',
      render: used => <span>{fileConvertSize(used)}</span>,
    },
  ];

  const handleClose = () => {
    setFilters(initialFilters);
  }

  const handleSearch = (e) => {
    setFilters([{ name: 'name', value: e.trim() }])
  }


  return (
    <div className="contenu-tab">
      <div>
        <h3>{t('Nombre de compte(s) actif(s)')} : {nbActiveAccount}</h3>
      </div>
      {
        storage
          ? <GraphStorage storage={storage} />
          : null
      }
      <div className='table-header'>
        <span>{t('Recherche')} : </span>
        <Search
          placeholder={t("Nom de compte")}
          onSearch={handleSearch}
          style={{ width: 200 }}
        />
        {
          filters[0].value ?
            <Tag closable onClose={handleClose}>
              {filters[0].value}
            </Tag>
            : ''
        }
      </div>
      <ApiCollectionTable endpoint="accounts" columns={columns} filtres={filters} reload={needUpdate} />

     <VimeoTable />
    </div>
  )
}
