import React, {useState} from 'react'
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../providers/ApiProvider";
import {useAccountContext} from "../providers/AccountProvider";
const { confirm } = Modal;

export default function EmptyingTrash({selection, setSelection, rerender}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity, apiFetchSubResourceWithFilter} = apiDispatch;
  const [accountState] = useAccountContext();

  const [loading, setLoading] = useState(false);

  function showDeleteConfirm() {
    confirm({
      title: t('Êtes-vous sûr de vouloir supprimer ces images?'),
      content: 'Si ces images sont utilisées, elles ne seront plus visible.',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
       deleteAsset()
      },
      onCancel() {
      },
    });
  }

  const deleteAsset = async () => {
    setLoading(true)
    const p1 = selection.map(async item => {
      await apiUpdateEntity('assets', item.id, {status: 3}, () => {})
    })
    Promise.all(p1).then(() => {
      setLoading(false)
      setSelection([])
      rerender()
      storage();
    })
  }

  function storage() {
    apiFetchSubResourceWithFilter('accounts', {id: accountState.account.id}, 'update', response => {
    })
  }

  return (
    <Button
      style={{marginRight: '1em'}}
      size="small" shape="round"
      loading={loading}
      onClick={showDeleteConfirm}
      icon="delete"
    >
      {t('Supprimer définitivement')}
    </Button>
  )
}
