import React, { useState } from 'react'
import { Button, Modal, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAccountContext } from '../../providers/AccountProvider'
import { useApiContext } from '../../providers/ApiProvider'

import ButtonSelectPage from './actions/ButtonSelectPage'
import MyPagination from '../MyPagination'
import ModalSortSelection from '../modal/ModalSortSelection'

export default function NoSortBar({ renameOn, setRenameOn, selected, setSelected, setLoading, totalItems, page, setPage, items, setMode }) {

  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiDeleteEntity, apiFetchSubResource } = apiDispatch;
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false)

  function switchRename(e) {
    setRenameOn(prev => !prev)
  }

  function deleteAssets() {
    Modal.confirm({
      title: t('Êtes-vous sûr ?'),
      content: t('Vous allez supprimer') + ' ' + selected.size + ' ' + t('Élément(s)').toLowerCase(),
      okText: t('Continuer'),
      cancelText: t('Annuler'),
      onOk: () => {
        let targetIds = [...selected]
        Promise.all(targetIds.map(async id => {
          await apiDeleteEntity('assets', id, response => {
            console.log('done');
          })
        })).then(() => {
          console.log('promise')
          rerender();
          updateStorage();
          setSelected(new Set())
        })
      }

    })
  }

  function updateStorage() {
    apiFetchSubResource('accounts', { id: accountState.account.id, withoutExt: true }, 'update', r => {
    })
  }

  return (
    <div className="tab-topbar">
      <div className="flex">
        <div className='mr no-mobile'>
          <Button icon={renameOn ? "check" : "poweroff"} type={renameOn ? 'primary' : ''} size="small" onClick={switchRename}
            title={renameOn ? t('Utilise les règles du compte lors du tri') : t('Les médias ne seront pas renommés lors du tri')}>
            {renameOn ? t('Renommage actif') : t('Renommage inactif')}
          </Button>
        </div>
        {
          selected && selected.size > 0 &&
          <>
            <Button size='small' className='mr' onClick={() => { setIsModalVisible(true) }}>{t('Trier')}</Button>
            <Button size='small' className='mr' onClick={deleteAssets}>{t('Supprimer')}</Button>
          </>
        }
      </div>
      <div style={{ display: 'flex' }}>

        <ButtonSelectPage
          setSelected={setSelected}
          selected={selected}
          items={items}
        />

        <Button className='ml' size='small' type="primary" shape="circle" icon="appstore" onClick={() => setMode(prev => prev === 'square' ? 'masonry' : 'square')} />

        <MyPagination
          current={page}
          totalItem={totalItems}
          itemPerPage={60}
          changePage={setPage}
        />
      </div>
      <ModalSortSelection
        visible={isModalVisible}
        duplicate={false}
        setVisible={setIsModalVisible}
        selected={selected}
        setSelected={setSelected}
        renameOn={renameOn}
        setRenameOn={setRenameOn}
        setLoading={setLoading}
      />
    </div>
  )
}
