import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Error = ({ location, history }) => {

  const {t} = useTranslation()

  return (
    <Result
      status={location?.state?.status || "404"}
      title={location?.state?.title || location?.state?.status || "404"}
      subTitle={location?.state?.subtitle || t('La page demandée n\'existe pas')}
      extra={!!location?.state?.page ? <Link key="title" to={location?.state?.page}><Button type="primary">{t('Retour')}</Button></Link> :
        <Button type="primary" onClick={history.goBack}>{t('Retourner a l\'accueil')}</Button>}
    />
  );

};

export default Error;
