import React, { useEffect, useRef, useState } from 'react';
import { Layout, Spin } from 'antd';
import { useAccountContext } from "../../providers/AccountProvider";
import Header from './Header.js';
import AccountSidebar from './AccountSidebar.js';
import { Redirect, useParams } from "react-router-dom";
import { useApiContext } from "../../providers/ApiProvider";
import { useTranslation } from "react-i18next";
import { AssetProvider } from "../../providers/AssetProvider";
import ModalFreemium from '../modal/ModalFreemium'
import MobileMenu from './MobileMenu';
import { useMediaQuery } from 'react-responsive'
import { useAuthContext } from '../../providers/AuthProvider';

const { Content } = Layout;

const AccountLayout = ({ children, ...rest }) => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { accountSet, setGroup, setActiveAccount } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource } = apiDispatch;

  const isMounted = useRef(null);
  const params = useParams();

  const [loading, setLoading] = useState(true)
  const [redirection, setRedirection] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    isMounted.current = true;
    fetch();
    return () => {
      isMounted.current = false
    }
  }, [params.account_id])

  function fetch() {
    setLoading(true)
    const data = { id: params.account_id, withoutExt: true }

    apiFetchSubResource('accounts', data, "get-account-with-perm", (hydra_response) => {
      if (!isMounted.current || hydra_response['@type'] === "hydra:Error" || (!auth.roles.includes("ROLE_ADMIN") && hydra_response['widgetStandAlone'])) return setRedirection(true);

      accountSet(hydra_response);
      if (hydra_response['groupAdmin']) {
        const group = hydra_response.group.id ? hydra_response.group : hydra_response
        setGroup(group)
        setActiveAccount(hydra_response)
      }

      setLoading(false);
    });
  }

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?",
      state: { status: '404', subtitle: t('Ce compte n\'existe pas'), page: './dashboard' }
    }} />

  if (loading)
    return <Spin spinning={loading} className="spin-fullscreen-centered" />;

  return (
    <Spin spinning={loading}>
      <ModalFreemium />

      <Layout className="root-layout" style={{ height: '100vh' }} hasSider={true}>
        {!isTabletOrMobile && rest.sidebar && <AccountSidebar />}
        <Layout>
          {
            !isTabletOrMobile &&
            <Header />
          }
          <Content className={rest.sidebar && 'has-sidebar'}>
            <AssetProvider>
              {children}
            </AssetProvider>
          </Content>
        </Layout>
        {
          isTabletOrMobile && <MobileMenu />
        }
      </Layout>

    </Spin>
  );
};

export default AccountLayout;
