import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from "../../providers/ApiProvider";
import SideBarTitle from "./SideBarTitle";

export default function VideoFolder({ closeMenu }) {

  const [accountState, accountDispatch] = useAccountContext();
  const { resetFilter, setActiveTab } = accountDispatch;
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;

  const [total, setTotal] = useState(0)
  const [isSelected, setIsSelected] = useState(false);
  const [isSocialSelected, setIsSocialSelected] = useState(false);
  const [socialVideos, setSocialVideos] = useState(0)

  const isMounted = useRef(null);

  useEffect(() => {
    setIsSelected(accountState.activeTab === '6')
    setIsSocialSelected(accountState.activeTab === '7')
  }, [accountState.activeTab])

  useEffect(() => {
    isMounted.current = true;
    getTotal()

    return () => {
      isMounted.current = false;
    }
  }, [accountState.account, accountState.rerender])

  function getTotal() {
    const accountId = accountState.accountActive ? accountState.accountActive.id : accountState.account.id;
    apiFetchCollection(`total-account-video/${accountId}`, {}, response => {
      if (response.success && isMounted.current)
        setTotal(response.total)
      setSocialVideos(response.social)
    })
  }

  function handleClick(tab) {
    resetFilter()
    setActiveTab(tab)
    closeMenu()
  }

  return (
    <>
      <SideBarTitle
        isSelected={isSelected}
        opacity={accountState.activeTab === '1' ? 'opacity-medium' : ''}
        handleClick={() => handleClick('6')} name={t('Vidéos')}
        total={total}
        icon='video-camera' />
      {
        !!socialVideos && !accountState.isWidget && accountState.isAdmin &&
        <SideBarTitle
          isSelected={isSocialSelected}
          opacity={accountState.activeTab === '1' ? 'opacity-medium' : ''}
          handleClick={() => handleClick('7')} name={t('Legacy Videos')}
          total={socialVideos}
          icon='youtube' />
      }
    </>
  )
}
