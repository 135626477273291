import React, { useState, useEffect } from 'react'
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { fileConvertSize } from '../../helpers.js'
import { useApiContext } from '../../providers/ApiProvider.js';

export default function VimeoTable() {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const { t } = useTranslation();
  const [storage, setStorage] = useState(null)

  useEffect(() => {
    fetchAccounts()
  }, [])

  function fetchAccounts() {

    apiFetchCollection('vimeo-accounts', {}, response => {
        setStorage(response)
    })
  }

  const vimeoColumns = [
    {
      title: 'User ID',
      dataIndex: 'user',
      render: (user) => <a target="_blank" href={`https://vimeo.com/user${user}`}>
        {user}
      </a>
    },
    {
      title: 'Espace',
      dataIndex: 'storage',
      render: storage => <span>{fileConvertSize(storage)}</span>,
    },
    {
      title: 'Espace utilisé',
      dataIndex: 'used',
      render: used => <span>{fileConvertSize(used)}</span>,
    },
  ];


  return (
    <div>
      <h3>Vimeo accounts</h3>
      <Table dataSource={storage} columns={vimeoColumns} />
    </div>
  )
}
