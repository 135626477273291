import React from 'react';
import { Icon, Rate } from 'antd';
import Download from './Download';
import MediaView from './MediaView';


function VisitorAssetCard({ item, handleClick, maxQuality }) {

  let media = item.currentMedia ? item.medias.filter(media => media.id === item.currentMedia)[0] : item.medias[0];

  return (
    <div className={`selectable-asset item-card`}
      data-key={item.id}>
      {
        media && <MediaView asset={item} title handleClick={(e) => handleClick(e, item.id)} share/>
      }
      <div className="logo_fs_range see-on-hover black full-top-right">
        <Download asset={item} maxQuality={maxQuality} />
        <Icon
          style={{ marginLeft: '.5em' }}
          type="fullscreen"
          onClick={e => handleClick(e, item.id)}
        />
      </div>
      <div className="see-on-hover asset-actions">
        <p title={item.usageName}>{item.usageName}</p>
        <div className="flex between align_center">
          <Rate value={item.note} disabled style={{ fontSize: '1em' }} />
        </div>
      </div>
    </div>
  )
}

export default VisitorAssetCard

