import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.availableLanguages = [
  {lng:'fr',label:'Français'},
  {lng:'en',label:'English'},
  {lng:'de',label:'Deutsch'},
  {lng:'es',label:'Español'},
  {lng:'it',label:'Italiano'},
];

import en from '../locales/en/translation.json';
import de from '../locales/de/translation.json';
import es from '../locales/es/translation.json';
import it from '../locales/it/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'fr',
    ns: ["translation"],
    defaultNS: "translation",
    nsSeparator:'::',
    keySeparator: false, // we do not use keys in form messages.welcome
    debug: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
     en: { translation: en },
     de: { translation: de },
     es: { translation: es },
     it: { translation: it },
    }
  }, (err, t) => {
    if (err) return console.error(err)
  });


export default i18n;
