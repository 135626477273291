import React, { useCallback, useState } from 'react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DraggableTextBox from '../../DraggableTextBox';
import MediaEditSidebar from '../MediaEditSidebar';
import { useDrop } from 'react-dnd'
import { Button, message } from 'antd';
import { useApiContext } from '../../../providers/ApiProvider';
import { useAccountContext } from '../../../providers/AccountProvider';
import { downloadResource } from "../../../helpers";
import { useHistory } from "react-router-dom";

const defaultSettings = { brightness: 1, contrast: 100, hue: 0, invert: 0, saturate: 100, sepia: 0, grayscale: 0 };
const defaultCopyright = { position: [null, '5px', '0', null], color: 'white', text: 'My company' };
const defaultCrop = { width: 100, height: 100, unit: 'px', x: 0, y: 0 }
const validExtension = ['jpg','jpeg','png'];

export default function ImageEdit({ media, assetId, handleClose, usageName }) {
  
  const mediaExt = media?.fileName?.split('.')?.pop();

  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;
  const [accountState, accountDispatch,] = useAccountContext();
  const { setActiveTab } = accountDispatch;
  const history = useHistory();

  const [image, setImage] = useState(null)
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 0, y: 0,
    width: 0, height: 0
  });
  const [loading, setLoading] = useState(true)
  const [rotation, setRotation] = useState(0)
  const [mirror, setMirror] = useState([1, 1])
  const [copyright, setCopyright] = useState({ ...defaultCopyright })
  const [filters, setFilters] = useState(null)
  const [manual, setManual] = useState({ ...defaultSettings })

  const [activeSetting, setActiveSetting] = useState(null)
  const [src, setSrc] = useState(validExtension.includes(media?.fileName?.split('.')?.pop()) ? [media.contentUrl] : [media.extraUrl.lg.url])
  const [myBlob, setMyBlob] = useState(['nothing'])
  const [active, setActive] = useState(0)
  const [box, setBox] = useState({ id: 1, top: 0, left: 0, title: '', textStyle: {} })

  function haveFilter() {
    if (activeSetting === 'filter') {
      return filters;
    }
    if (activeSetting === 'manual') {
      return `brightness(${manual?.brightness}) contrast(${manual?.contrast}%) hue-rotate(${manual?.hue}deg) invert(${manual?.invert}) saturate(${manual?.saturate}%) sepia(${manual?.sepia}%) grayscale(${manual?.grayscale}%)`
    }
    return null;
  }

  function onImageLoaded(image) {
    setImage(image)
    setLoading(false)
  }

  function onChange(crop) {
    setCrop(crop)
  }

  const makeImage = async (mode, params) => {
    let canvas = null;

    switch (mode) {
      case 'crop':
        canvas = getCroppedImg();
        break;
      case 'filter':
        canvas = getFilterImg();
        break;
      case 'rotate':
        canvas = getRotatedImg();
        break;
      case 'mirror':
        canvas = getReverseImg();
        break;
      case 'manual':
        canvas = getSettingsImg();
        break;
      case 'text':
        canvas = getImgWithText();
        break;
      case 'copyright':
        canvas = getCopyrightImg();
        break;
      case 'resize':
        canvas = getResizeImg(params);
        break;
      default:
        return;
    }
    reset()
    if (!canvas) {
      return;
    }

    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }
      implementSrc(canvas)
      implementBlob(blob)
    }, 'image/jpeg', 0.8);
  }

  function createCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const ctx = canvas.getContext('2d');
    return { canvas, ctx };
  }

  function implementBlob(newBlob) {
    if (active !== myBlob.length - 1) {
      myBlob.splice(active + 1)
    }
    setMyBlob([...myBlob, newBlob])
  }

  function implementSrc(newSrc) {
    setActiveSetting(null);
    if (active !== src.length - 1) {
      setSrc(src.splice(active + 1))
    }
    setSrc([...src, newSrc.toDataURL('image/jpeg')])
    setActive(active + 1)
  }

  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  const getCroppedImg = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return canvas;
  }

  const getResizeImg = (params) => {
    const canvas = document.createElement('canvas');
    canvas.width = params.width;
    canvas.height = params.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, params.width, params.height);
    return canvas
  }

  const getFilterImg = () => {
    let { canvas, ctx } = createCanvas();

    switch (filters) {
      case 'sepia(60%)':
        ctx.filter = 'sepia(0.6)';
        break;
      case 'grayscale(100%)':
        ctx.filter = 'grayscale(1)';
        break;
      case 'brightness(0.4)':
        ctx.filter = 'brightness(0.4)';
        break;
      case 'contrast(200%)':
        ctx.filter = 'contrast(2)';
        break;
      case 'invert(100%)':
        ctx.filter = 'invert(1)';
        break;
      case 'saturate(30%)':
        ctx.filter = 'saturate(0.3)';
        break;
      case 'hue-rotate(90deg)':
        ctx.filter = 'hue-rotate(90deg)';
        break;
      default:
        return;
    }

    ctx.drawImage(image, 0, 0);

    return canvas;
  }

  const getRotatedImg = () => {
    if (rotation === 0) {
      return false;
    }
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    let wInfo;
    let hInfo;
    if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270) {
      hInfo = image.height * scaleX;
      wInfo = image.width * scaleY;
      canvas.width = hInfo;
      canvas.height = wInfo;
    } else {
      hInfo = image.height * scaleY;
      wInfo = image.width * scaleX;
      canvas.width = wInfo;
      canvas.height = hInfo;
    }

    const ctx = canvas.getContext('2d');
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(rotation * Math.PI / 180);
    ctx.drawImage(image, -wInfo / 2, -hInfo / 2);

    return canvas;
  }

  const getReverseImg = () => {
    if (mirror[0] === 1 && mirror[1] === 1) {
      return false;
    }
    let { canvas, ctx } = createCanvas();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.scale(mirror[0], mirror[1]);

    ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2);

    return canvas;
  }

  const getSettingsImg = () => {
    if (manual?.brightness === 1 && manual?.contrast === 100
      && manual?.hue === 0 && manual?.invert === 0 && manual?.saturate === 100 && manual?.sepia === 0 && manual?.grayscale === 0) {
      return false;
    }
    let { canvas, ctx } = createCanvas();

    ctx.filter = `contrast(${manual?.contrast}%) brightness(${(manual?.brightness)}) hue-rotate(${manual?.hue}deg) invert(${manual?.invert}) saturate(${manual?.saturate}%) sepia(${manual?.sepia}%) grayscale(${manual?.grayscale}%)`;

    ctx.drawImage(image, 0, 0);

    return canvas;
  }

  const [, drop] = useDrop({
    accept: 'BOX',
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset()
      let left = Math.round(item.left + delta.x)
      let top = Math.round(item.top + delta.y)
      moveBox(item, left, top);
      return undefined
    },
  })
  const moveBox = useCallback(
    (item, left, top) => {
      let boxClone = { ...item };
      boxClone.left = left;
      boxClone.top = top;
      setBox(boxClone);
    },
    [box],
  )

  const updateBox = (key, value) => {
    setBox(prev => {
      return { ...prev, [key]: value }
    });
  }

  const getImgWithText = () => {
    let { canvas, ctx } = createCanvas();
    let imgRatio = image.naturalWidth / image.width;
    let fontSize = box.textStyle.fontSize * imgRatio;

    ctx.drawImage(image, 0, 0);

    ctx.font = box.textStyle.fontStyle + ' ' + box.textStyle.fontWeight + ' ' + fontSize + 'px ' + box.textStyle.font;
    let top = box.top * imgRatio;
    let left = box.left * imgRatio;
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    ctx.fillStyle = box.textStyle.color
    ctx.fillText(box.title, left, top);

    return canvas;
  }

  function getCopyrightImg() {
    let { canvas, ctx } = createCanvas();
    ctx.drawImage(image, 0, 0);

    let scale = image.naturalWidth / image.width
    let size = 14 * scale

    let top = document.getElementById('copyright_edit').offsetTop * scale
    let left = document.getElementById('copyright_edit').offsetLeft * scale

    ctx.font = size + "px Arial"
    ctx.fillStyle = copyright.color;
    var widthText = ctx.measureText(`© ${copyright.text}`);
    ctx.fillText(`© ${copyright.text}`, left, top + (15 * scale))

    return canvas;
  }

  function undo() {
    setActive(active - 1)
  }

  function redo() {
    setActive(active + 1)
  }

  function update(mode) {
    postMedia(myBlob[active], 'nouvelle-image.jpeg', 'image/jpeg', mode);
  }

  function resolveName(suffix) {
    return `${usageName.replace(/\.[^/.]+$/, "")}-${suffix}.jpg`
  }

  function download() {
    downloadResource(src[active], resolveName(`newversion`))
  }

  function postMedia(blob, fileName, mimeType, mode) {
    setLoading(true)
    let file = new File([blob], fileName, { type: mimeType, lastModified: Date.now() })
    let formData = new FormData()
    formData.append('file', file);
    formData.append('action', mode);
    formData.append('accountId', accountState.account.id);
    if (mode === 'patch') {
      formData.append('assetId', assetId);
    }

    apiPostEntity("media_objects", formData, (response) => {
      setLoading(false)
      if (response['@type'] === "hydra:Error") {
        console.log(response)
      } else {
        if (mode === 'create') {
          history.push(`/account/${accountState.account.id}`)
          setActiveTab('1')
        } else {
          message.success('Média modifier avec succès')
          reset()
          handleClose()
        }

      }
    });
  }

  function reset() {
    setActiveSetting(null)
    setCrop({ ...defaultCrop });
    setRotation(0)
    setMirror([1, 1])
    setCopyright({ ...defaultCopyright })
    setFilters(null)
    setManual({ ...defaultSettings })
    setSrc([media.contentUrl])
    setMyBlob(['nothing'])
    setActive(0)
    setBox({ id: 1, top: 0, left: 0, title: '', textStyle: {} })
  }

  return (
    <>
      <div className='media-container'>
        <div style={{ position: 'absolute', right: '1em', top: '.5em' }}>
          <Button.Group>
            <Button type="primary" icon="undo" title="undo" disabled={!active} onClick={undo} />
            <Button type="primary" icon="redo" title="redo" disabled={active === src.length - 1} onClick={redo} />
          </Button.Group>
        </div>
        <div ref={drop} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          position: 'relative'
        }}>
          <div style={{ position: "relative", maxHeight: '90vh' }}>
            {
              activeSetting === 'copyright'
                ?
                <span
                  id="copyright_edit"
                  className={image.height}
                  style={{
                    fontFamily: 'Arial',
                    position: 'absolute',
                    top: copyright.position[0],
                    right: copyright.position[1],
                    bottom: copyright.position[2],
                    left: copyright.position[3],
                    zIndex: 20,
                    color: copyright.color
                  }}>
                  © {copyright.text}
                </span>
                :
                null
            }
            {
              activeSetting === 'text' && <DraggableTextBox box={box} />
            }
            <ReactCrop
              src={src[active]}
              crop={activeSetting === 'crop' ? crop : {}}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onChange={onChange}
              disabled={activeSetting !== 'crop'}
              keepSelection={true}
              crossorigin="anonymous"
              imageStyle={{
                maxHeight: '90vh',
                margin: '0 auto',
                filter: haveFilter(),
                maxWidth: Math.abs(rotation) === 90 || Math.abs(rotation) === 270 ? '90vh' : '100%',
                transform: activeSetting === 'mirror' && `scale(${mirror[0]}, ${mirror[1]})`,
              }}
              style={{ transform: `rotate(${activeSetting === 'rotate' ? rotation : 0}deg)` }}
            />
          </div>
        </div>
      </div>
      <MediaEditSidebar
        activeKey={activeSetting} setActiveKey={setActiveSetting}
        filters={filters} setFilters={setFilters}
        rotation={rotation} setRotation={setRotation}
        mirror={mirror} setMirror={setMirror}
        manual={manual} setManual={setManual}
        copyright={copyright} setCopyright={setCopyright}
        image={image} loading={loading} updateBox={updateBox}
        crop={crop} setCrop={setCrop} download={download}
        save={makeImage} update={update} active={active} />
    </>
  )
}
