import React, {useEffect, useState} from 'react'
import {Button, message} from 'antd';
import {useApiContext} from '../providers/ApiProvider';
import WidgetRequest from '../components/Widget/WidgetRequest';
import background from '../assets/request-background.png'
import plusImage from '../assets/image-plus.png'
import {useHistory, useParams} from "react-router-dom";

import widget from "../components/Widget/WidgetComponent.js"; // client/widget component

export default function RequestBuilder({folders, accountId}) {

  const [apiDispatch] = useApiContext();
  const {customPostRequest} = apiDispatch;

  const [localQueries, setLocalQueries] = useState([])
  const [pics, setPics] = useState([])

  useEffect(() => {
    if (localQueries.length)
      getRequestResults()
  }, [localQueries])

  useEffect(() => {
    createNewQuery()
    if (!localQueries.length) {
      if (window.opener) {
        if(widget.isDefaultsAvailable())
        {
          handleParamQueries(widget.getDefaults());
        }
        else
        {
          widget.on('widget.default.init',handleParamQueries);
        }
      }
    }
  }, [])

  const handleParamQueries = (params) => {
    try {
      let data = typeof params ==='string' ? JSON.parse(params) : params;
      data = data.length>0 ? data : [];

      let date = (new Date()).getTime()
      let newQueries = data.map((item, index) => {
        return {id: date + index, query: [item]}
      })
      // console.log(newQueries)
      setLocalQueries(newQueries)
    } catch (e) {
      message.error('Les paramètre n\'ont pas pu être récupéré')
    }
  }


  const createNewQuery = () => {
    setLocalQueries(previousState => [...previousState, {
      id: (new Date()).getTime(),
      query: [{tags: [], quantity: 1, sort: 'prefer_main', duplicate: 1}]
    }])
  }

  const getRequestResults = () => {

    let params = {
      queries: localQueries,
      isWidget: true
    }
    customPostRequest('smart/assets', params, (response) => {
      //console.log(response)
      if (response.success && response.results.length) {
        setPics(response.results)
      }
    })
  }

  const updateQuery = (id, data) => {
    if (data)
      setLocalQueries(prev => prev.map(item => item.id !== id ? item : {...item, query: [data]}))
  }

  const saveQueries = () => {
    let queries = localQueries.reduce((accumulator, current) => {
      return [...accumulator, ...current.query]
    }, [])
    widget.fire('select',queries,'broadcast');
  }

  const handleDelete = (id) => {
    setLocalQueries(prev => prev.filter(item => item.id !== id))
  }

  const search = new URLSearchParams(window.location.search)
  const switchedOn = search.get('switched') === 'on';
  const params = useParams()
  const history = useHistory()

  function switchWidget(widget) {
    history.push(`/${widget}/${params.token}${window.location.search}`)
  }

  function arrayMove(array, index, delta) {
    var newIndex = index + delta;
    if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
    var indexes = [index, newIndex].sort((a, b) => a - b); //Sort the indixes (fixed)
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
  }

  function handleRequestMove(index,direction) {
    let r = [...localQueries]
    if(direction=='up')
    {
      arrayMove(r,index,-1)
    }
    else if(direction=='down')
    {
      arrayMove(r,index,+1)
    }
    setLocalQueries(r)
  }

  const limit = search.get('limit') ? Number(search.get('limit')) : 300;

  return (
    <div className='request-page'>
      <div className='request-header' style={{backgroundImage: `url('${background}')`}}>
        <h1 className='request-title' style={{}}><img src={plusImage} className='mr'/>Vos requêtes de photos
        </h1>

        {!!(switchedOn && location.pathname.includes(('/widget-builder/'))) &&
          <Button
            style={{position: "absolute", right: 50, top: 35}}
            type="primary" onClick={() => switchWidget('widget')}>Mode classique</Button>}

      </div>

      {/* <Button style={{ position: 'absolute', top: '1em', right: '1em' }}>Fermer sans Sauvegarder</Button> */}
      <div style={{width: '90%', margin: '2em auto 0'}}>
        {
          localQueries.map((localQuery, index) =>
            <WidgetRequest key={localQuery.id || index}
                           requestId={localQuery.id}
                           index={index}
                           folders={folders} request={localQuery.query[0]}
                           handleDelete={handleDelete}
                           setRequest={updateQuery} accountId={accountId}
                           pics={pics.find(item => item?.id === localQuery.id)}
                           isFirst={index==0}
                           isLast={index==localQueries.length-1}
                           onMove={handleRequestMove}
                           />
          )
        }

        {localQueries.length < limit &&
          <div style={{marginTop: '2em'}}>
            <Button icon="plus" onClick={createNewQuery} ghost type="primary">Ajouter une requête</Button>
          </div>}
      </div>


      <div className='bottom-bar'>
        <Button type="primary" icon='check-square' onClick={saveQueries}>Valider les requêtes</Button>
        <div style={{position: 'absolute', right: '1em', bottom: '1em', opacity: '.3'}}>Version 0.1</div>
      </div>
    </div>
  )
}
