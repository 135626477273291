import React from "react";

export default function Pdf(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width='100%'
    >
      <path
        fill={props.fill}
        d="M365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448ZM203 289C213 257 213 232 213 227C213 208 197 192 178 192S142 208 142 227C142 227 142 257 157 291C149 311 140 330 130 350C107 360 89 373 74 387C68 393 64 402 64 413C64 432 80 448 99 448C111 448 122 443 128 434C134 425 147 407 160 383C178 377 197 371 218 367C230 375 260 393 282 393C303 393 320 376 320 354C320 333 302 316 281 316C265 316 240 319 229 321C218 312 210 301 203 289ZM105 417C102 421 92 420 92 411C92 410 93 408 94 407C104 398 115 390 128 383C118 399 110 411 105 417ZM178 220C182 220 185 223 185 227C185 243 181 263 179 270C171 247 171 229 171 227C171 223 174 220 178 220ZM161 353C168 339 175 324 181 307C188 319 196 330 207 340C192 343 176 347 161 353ZM281 344C287 344 292 349 292 355C292 360 287 365 282 365C273 365 251 354 241 348C242 348 268 344 281 344Z"
      />
    </svg>
  )
}
