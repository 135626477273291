import React, { useEffect, useState } from 'react';
import { Icon } from "antd";
import VisitorAssetsList from './VisitorAssetsList';
import { useApiContext } from '../../providers/ApiProvider';
import { useParams } from 'react-router-dom';

const VisitorDirectoryView = ({ tag, mode, parent = null, type = "share" }) => {

  const [apiDispatch] = useApiContext();
  const { getRequestBasic } = apiDispatch;
  const [children, setChildren] = useState(tag?.children || [])
  const [loading, setLoading] = useState(false)
  const params = useParams();

  useEffect(() => {
    if (tag?.type === 2)
      fetchCartFolders()
  }, [])

  function fetchCartFolders() {
    setLoading(true)
    let data = {
      token: params.token,
      filters: [
        { name: "accessType", value: type }
      ]
    }

    getRequestBasic(`/get-cart-folders/${tag.id}`, data, response => {
      console.log('response', response)
      if (response['@type'] === "hydra:Error") {
        setError(true)
        message.error(response['hydra:description'])
        return;
      }

      if (response['hydra:member'])
        setChildren(response['hydra:member'])

      setLoading(true)
    })

  }

  // if cart then loop other tags
  return (
    <>
      <div style={{ padding: '0 2em' }}>
        <h4 style={{ paddingLeft: 50, margin: 0 }}>
          <Icon type="folder-open" />&nbsp;
          <span>
            {parent && parent.type !== 2 && `${parent.name} > `} {tag.name}
          </span>
        </h4>
        {
          tag?.type !== 2 &&
          <VisitorAssetsList mode={mode} baseFilter={[{ name: 'tags.id[]', value: tag.id }]} type={type} cartId={parent?.type === 2 ? parent.id : null}/>
        }
        {
          children.map((item) =>
            <VisitorDirectoryView tag={item} mode={mode} parent={tag} key={item.id} type={type} />
          )
        }
      </div>
    </>
  );
};

export default VisitorDirectoryView;
