import React, { useEffect, useRef, useState } from 'react'
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from '../../providers/ApiProvider.js';
import { Spin } from 'antd'
import ActiveFilters from "./ActiveFilters";
import DisplayCards from '../asset/DisplayCards';
import { useAssetContext } from '../../providers/AssetProvider';
import Media from '../../pages/Media';
import Selectable from "../asset/Selectable";
import SubDirectory from './SubDirectory';

export default function AssetsListGroup({ account }, props) {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { setSelection } = accountDispatch;
  const { filters } = accountState
  const [assetState, assetDispatch] = useAssetContext();
  const { assets, itemsPerPage, loading, total, page } = assetState;
  const { setAssets, setLoading, setTotal, setPage, updateAsset } = assetDispatch;

  const [selected, setSelected] = useState(() => new Set());
  const [firstLoading, setFirstLoading] = useState(true);
  const [assetId, setAssetId] = useState(0);
  const scrollerRef = useRef(null);

  const hasMore = !(total / itemsPerPage < page);
  const filterDirectoryActive = filters.some(filter => filter.type === 'directory')

  useEffect(() => {

    let idList = assets.map(item => item.id)
    let next = accountState.selection.filter(item => !idList.includes(item.id))
    let newAssets = assets.filter(item => selected.has(item.id))

    setSelection([...new Set([...next, ...newAssets])])

  }, [selected])

  useEffect(() => {
    setLoading(true)
    setFirstLoading(true);
    fetch(1);
    if (page > 1) setPage(1);
  }, [accountState.filters, accountState.rerender]);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      fetch(page);
    }
  }, [page]);

  function fetch(page) {
    setLoading(true)
    let params = {
      id: account.id,
      itemsPerPage: itemsPerPage,
      page: page,
      filters: accountState.filters
    }

    if (accountState.accountActive) {
      params.filters = params.filters.concat({ name: 'account[]', value: accountState.accountActive['@id'] });
    } else {
      let tagFilters = params.filters.filter(filter => filter.type === 'tag');
      if (tagFilters.length > 0) {
        tagFilters.map(filtre => {
          filtre.ids.map(item => {
            params.filters = params.filters.concat({ name: 'tags.name[]', value: item });
          })
        })
      }
      params.filters = params.filters.concat({ name: 'account[]', value: account['@id'] });
      account.childAccount.map(account => {
        params.filters = params.filters.concat({ name: 'account[]', value: account['@id'] });
      })
    }

    apiFetchCollection('assets', params, (response) => {
      hydrateAssets(response, page);
    })
  }

  function hydrateAssets(response, page) {

    if (response['@type'] === 'hydra:Error') return;

    if ((response['hydra:totalItems'] / itemsPerPage) <= page - 1)
      setPage(1);

    if (page === 1)
      setAssets(response['hydra:member']);
    else
      setAssets([...assets, ...response['hydra:member']]);

    setTotal(response['hydra:totalItems']);
    setFirstLoading(false)
    setTimeout(() => setLoading(false), 1500)
  }

  function unSelectAll() {
    setSelected(new Set())
    setSelection([])
  }

  function updateAssetId(value) {
    let index = assets.findIndex(item => item.id === value);
    let loadMore = false;

    if ((index >= assets.length - 5) && total > assets.length)
      loadMore = true;

    setAssetId(value)
    if (loadMore)
      setPage(page => page + 1);
  }

  return (
    <Spin spinning={firstLoading}>
      <ActiveFilters
        selection={selected}
        setSelection={setSelected}
        unselectAll={unSelectAll}
        totalItem={total}
        pageRange={accountState.page}
        activeActionBar={false}
      />
      <Selectable setSelected={setSelected} scrollerRef={scrollerRef} continueSelect />
      <div className="assets-list selectable-container" ref={scrollerRef}>
        <DisplayCards
          {...props}
          selected={selected}
          setSelected={setSelected}
          selectable
          continueSelect
          assets={assets}
          page={page}
          setPage={setPage}
          loading={loading}
          hasMore={!(total / itemsPerPage < page)}
          setAssetId={updateAssetId}
        />
        {filterDirectoryActive && !hasMore && !loading &&
          <SubDirectory
            {...props}
            selected={selected}
            setSelected={setSelected}
            id={filters.find(filter => filter.type === 'directory').id}
          />}

      </div>

      {!!assetId && <Media assets={assets} assetId={assetId} setAssetId={setAssetId} updateAsset={updateAsset} />}
    </Spin>
  )
}
