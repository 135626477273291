import React, {useState} from "react";
import {Button, Form, Input, Modal, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";

const ModalUser = ({form, reload}) => {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;

  const {getFieldDecorator, resetFields} = form;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true)
        let data = {...values};

        data.roles = data.roles ? ["ROLE_ADMIN"] : ["ROLE_USER"];
        data.password = "mmc/2021"

        apiPostEntity('users', data, response => {

          reload();
          resetFields();
          setLoading(false);
          setVisible(false);
        })
      }
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
      >
        {t('Créer')} +
      </Button>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title={t('Créer un utilisateur')}
      >

        <Form
          style={{margin: 20}}
          onSubmit={handleSubmit}
        >

          <Form.Item label={t('Prénom')}>
            {getFieldDecorator('firstname', {})(
              <Input
                placeholder={t('Prénom')}
              />,
            )}
          </Form.Item>

          <Form.Item label={t('Nom')}>
            {getFieldDecorator('lastname', {})(
              <Input
                placeholder={t('Nom')}
              />,
            )}
          </Form.Item>

          <Form.Item label={t('Nom d\'utilisateur')}>
            {getFieldDecorator('username', {
              rules: [{required: true, message: t('Entrez un nom d\'utilisateur!')}],
            })(
              <Input
                placeholder={t('Nom d\'utilisateur')}
              />,
            )}
          </Form.Item>

          <Form.Item label={t('Email')}>
            {getFieldDecorator('email', {
              rules: [
                {type: 'email', message: 'The input is not valid E-mail!',},
                {required: true, message: t('Entrez votre email!')}
              ],
            })(
              <Input
                placeholder="Ex: monadresse@mail.com"
              />,
            )}
          </Form.Item>

          <Form.Item label={t('Mot de passe')}>
            {getFieldDecorator('password', {
              initialValue: 'mmc/2021',
              rules: [
                {required: true, message: t('Entrez votre mot de passe!')}
              ],
            })(
              <Input
                placeholder="password/pw-2021"
              />,
            )}
          </Form.Item>

          <Form.Item label="Super admin">
            {getFieldDecorator('roles', {
              valuePropName: "checked",
              initialValue: false,
              rules: [{required: true, message: t('Choisissez un role!')}],
            })(
              <Switch/>,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('Valider')}
            </Button>
          </Form.Item>

        </Form>

      </Modal>
    </>
  )
}

export default Form.create()(ModalUser);

