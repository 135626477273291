import React, { useRef } from 'react'
import { Modal, Carousel, Icon, Button } from 'antd'

export default function WidgetSlider({ images, visible, handleClose }) {

    const car = useRef();

    const handleChange = (direction) => {
        if (direction === 'prev') {
            car.current.prev()
        } else {
            car.current.next()
        }
    }

    return (
        <Modal visible={visible} width="90vw" onCancel={handleClose} centered
            footer={[
                <Button key="close" onClick={handleClose}>
                    Fermer
                </Button>
            ]}>
            <Carousel ref={car}>
                {
                    images.map(image => <div style={{ maxWidth: '100%' }} key={image.uid} >
                        <img style={{ maxHeight: '70vh', margin: '0 auto' }} src={image.display.native.lg} />
                    </div>)
                }
            </Carousel>
            {

            }
            <Icon type="left" onClick={(e) => handleChange('prev')} className="chevron left" />
            <Icon type="right" onClick={(e) => handleChange('next')} className="chevron right" />
        </Modal>
    )
}
