import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function YoutubeChannel({ channelId, deleteBtn }) {

  const requestVideo = "https://www.googleapis.com/youtube/v3/videos?id=f13d8E8CAWc&key=AIzaSyC_KgFud0_PElSIICWPlEelCGX9z-ZZdyc&part=snippet,contentDetails,statistics,status"
  const requestChannel = "https://www.googleapis.com/youtube/v3/channels?&key=AIzaSyC_KgFud0_PElSIICWPlEelCGX9z-ZZdyc&part=contentDetails&part=snippet&id="
  const requestChannel2 = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&key=AIzaSyC_KgFud0_PElSIICWPlEelCGX9z-ZZdyc&playlistId="

  const { t } = useTranslation();

  const [channel, setChannel] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [prev, setPrev] = useState("")
  const [next, setNext] = useState("")
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [totalResults, setTotalResults] = useState(0)

  useEffect(() => {
    getVideosChannel(null, true);
  }, [channelId])

  async function get(url, callback) {
    try {
      let response = await axios({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/ld+json',
        },
      });
      callback(response.data);
    } catch (e) {
      callback(e);
    }
  }

  function getVideosChannel(pageToken = null, newChannel = false) {
    get(requestChannel + channelId, (res) => {
      if (!res.items) {
        setLoading(false);
        return setError('Channel doesn\'t exist')
      }
      const uploads = res.items[0].contentDetails.relatedPlaylists.uploads;
      const channelName = res.items[0].snippet.title;

      let endpoint = requestChannel2 + uploads;
      if (pageToken)
        endpoint += pageToken;

      get(endpoint, (result) => {
        const data = {
          name: channelName,
          items: result.items
        };

        if (newChannel)
          setTotalPage(Math.ceil(result.pageInfo.totalResults / result.items.length));
        if (newChannel)
          setTotalResults(result.pageInfo.totalResults);
        if (result.nextPageToken)
          setNext("&pageToken=" + result.nextPageToken);
        if (result.prevPageToken)
          setPrev("&pageToken=" + result.prevPageToken);

        setChannel(data);
        if (newChannel)
          setPage(1);

        setLoading(false);
      })
    })
  }

  function changePage(action) {
    resetPrevNext();

    switch (action) {
      case 'prev':
        getVideosChannel(prev);
        setPage(page - 1);
        break;
      case 'next':
        getVideosChannel(next);
        setPage(page + 1);
        break;
    }
  }

  function resetPrevNext() {
    setPrev("");
    setNext("");
  }

  if (error)
    return (
      <div className="flex align_baseline">
        <h3 >{error} ({channelId})</h3>
        {deleteBtn}
      </div>
    )

  return (
    loading ? <div style={{ minHeight: 614 }}>{t('Chargement')} ...</div> :
      <div className="w-100per">
        <div className="flex align_baseline">
          <h3 >{channel.name} ({totalResults})</h3>
          {deleteBtn}
        </div>
        <div className="flex wrap">
          {channel.items.map((item, index) => (
            <div key={index} className="channel-video">
              <div className="video-container">
                <iframe
                  src={"https://www.youtube.com/embed/" + item.contentDetails.videoId}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex center align_center" style={{ paddingBottom: 10 }}>
          <Button icon="left" disabled={!prev} onClick={() => changePage("prev")} />
          <Button>{`${page} / ${totalPage}`}</Button>
          <Button icon="right" disabled={!next} onClick={() => changePage("next")} />
        </div>
      </div>
  )
}
