import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Menu, Icon } from 'antd';
import {useTranslation} from "react-i18next";
import Hapidam_logo from '../../assets/logo_hapidam.svg'
import { useAuthContext } from '../../providers/AuthProvider';

const NavPrivate = () => {

  const {t} = useTranslation();
  const [authState] = useAuthContext();
  const {auth} = authState;
  let location = useLocation()

  return (
    <Menu mode="inline" selectedKeys={[location.pathname]}>
      <Menu.Item key="hapidam">
        <Link to={`/dashboard`}><img alt="Hapidam" src={Hapidam_logo} style={{width:'75%'}}/></Link>
      </Menu.Item>
      <Menu.Item key="/dashboard">
        <Link to="/dashboard">
          <Icon type="dashboard" />
          <span>{t('Dashboard')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/user">
        <Link to={`/admin/user-overview/${auth.id}`}>
          <Icon type="user" />
          <span>{t('Mon profil')}</span>
        </Link>
      </Menu.Item>
      {auth.roles.includes("ROLE_ADMIN") ?
        <Menu.Item key="/accounts">
          <Link to="/accounts">
            <Icon type="lock" />
            <span>{t('Gestion des comptes')}</span>
          </Link>
        </Menu.Item>
        : ""
      }
      {auth.roles.includes("ROLE_ADMIN") ?
        <Menu.Item key="/users">
          <Link to="/users">
            <Icon type="team" />
            <span>{t('Gestion des utilisateurs')}</span>
          </Link>
        </Menu.Item>
        : ""
      }
      {auth.roles.includes("ROLE_ADMIN") ?
        <Menu.Item key="/gestion-admin">
          <Link to="/gestion-admin">
            <Icon type="control" />
            <span>{t('Gestion administrateur')}</span>
          </Link>
        </Menu.Item>
        : ""
      }
      {auth.roles.includes("ROLE_ADMIN") ?
        <Menu.Item key="/create-account">
          <Link to="/admin/create-account">
            <Icon type="plus-square" />
            <span>{t('Création de compte')}</span>
          </Link>
        </Menu.Item>
        : ""
      }
      <Menu.Item key="/logout">
        <Link to="/logout">
          <Icon type="logout" />
          <span>{t('Déconnexion')}</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default NavPrivate;
