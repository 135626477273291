import React, { useEffect, useRef, useState } from 'react';
import { useAccountContext } from "../providers/AccountProvider";
import { Button, Dropdown, Icon } from 'antd';
import { useTranslation } from "react-i18next";
import AccountFilterDropdown from "./dropdown/AccountFilterDropdown";
import FolderDropdown from "./dropdown/FolderDropdown";
import SidebarFilters from './layout/SidebarFilters';


const Filters = () => {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender, setActiveAccount, accountSet } = accountDispatch

  const [visibleDropDown, setVisibleDropDown] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const node = useRef(null)

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])

  const handleClick = e => {
    // inside click
    if (!node.current || node.current.contains(e.target)) return;    
    // outside click
    setVisibleDropDown(false);
  }

  const advanced = (
    <div ref={node} className="my_dropdown" style={{ minWidth: 300 }}>
      <SidebarFilters filtering inDropDown />
    </div>
  )

  const size = accountState.globalSearch ? "default" : "small"

  return (
    <>
      {accountState.group && accountState.globalSearch
        ?
        <AccountFilterDropdown accountState={accountState} setActiveAccount={setActiveAccount} setAccount={accountSet} />
        : null}
      {(accountState.group && accountState.accountActive !== null) || !accountState.globalSearch ?
        <FolderDropdown
          accountState={accountState} rerender={rerender}
          size={size} />
        : null}
      <Dropdown
        visible={visibleDropDown}
        overlay={advanced}
        trigger={['click']}
        onClick={e => {
          e.preventDefault()
          if (isOpen)
            setVisibleDropDown(false);
          else
            setVisibleDropDown(true);

          setIsOpen(!isOpen)
        }}
      >
        <Button className="ml-10" size={size}>
          {t('Filtres')} <Icon type="down" />
        </Button>
      </Dropdown>
    </>
  )
}

export default Filters;
