import React from 'react';
import ApiCardList from "../components/ApiCardList";
import {useTranslation} from "react-i18next";

const Dashboard = () => {
  const {t} = useTranslation();

  return (
    <div className="dashboard" style={{padding: window.innerWidth > 768 ? '24px 48px' : '24px 10px'}}>
      <h2 style={{textAlign: 'center'}}>{t('Bienvenue sur votre dashboard')}</h2>
      <ApiCardList endpoint="accounts" />
    </div>
  );
};

export default Dashboard;
