import React, {useState} from 'react'
import {Button} from 'antd';
import {useApiContext} from '../providers/ApiProvider.js';
import {useTranslation} from "react-i18next";

export default function RestoreAction({selection, setSelection, rerender}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;
  const [isLoading, setIsLoading] = useState(false)

  const restoreSelection = async () => {
    setIsLoading(true)
    const p1 = selection.map(async item => {
      await apiUpdateEntity('assets/restore', item.id, {}, (response) => {})
    })
    Promise.all(p1).then(() => {
      setIsLoading(false)
      setSelection([])
      rerender()
    })
  }

  return (
    <Button
      style={{marginRight: '1em'}}
      size="small" icon="undo"
      shape="round" loading={isLoading}
      onClick={restoreSelection}
    >
      {t('Restaurer')}
    </Button>
  )
}
