import React, { useEffect, useState } from 'react'
import { Menu, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApiContext } from '../../../providers/ApiProvider';

const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function ContextMenu({ asset, original = false }) {

    const { t } = useTranslation();
    const [apiDispatch] = useApiContext();
    const { apiFetchEntity } = apiDispatch;

    const media = asset.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset.medias[0];
    const ext = media.fileName.split('.').pop()
    const [links, setLinks] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (asset.type === 1 && media.mimeType !== 'image/gif') {
            setLinks({})
            fetchMedia()
        }
    }, [asset])

    const fetchMedia = () => {
        setLoading(true)
        apiFetchEntity('media_objects', media.id, response => {
            if (!response?.extraUrl) {
                message.error(t('Impossible de récupérer les liens de téléchargement'))
            } else {
                setLinks(response.extraUrl)
            }
            setLoading(false)
        })
    }

    function copyLink({ key }) {
        if (key==='none') return;
        key = key === 'text' ? '' : key; 
        let link = `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}.${ext}?size=${key}`

        navigator.clipboard.writeText(link).then(function () {
            /* clipboard successfully set */
            message.success('Lien copié avec succès')
        }, function () {
            /* clipboard write failed */
            message.error('Il y a eu une erreur')
        });
        return true;
    }

    if (!media || asset.type === '3') return null;

    return (
        <Menu onClick={copyLink}>
            {
                asset.type === 2 ? <Menu.Item key="text">{t("Copier le lien de ce document")}</Menu.Item>
                    : <Menu.Item key="none">{t("Copier un lien ci dessous")}</Menu.Item>
            }
            {
                asset.type === 1 && (media.mimeType === 'image/gif' || !!original) &&
                <Menu.Item key='original'>{`Original (${media.dimensions[0]} x ${media.dimensions[1]})`}</Menu.Item>
            }
            {
                loading && <Menu.Item><Spin spinning>{t('Chargement')}</Spin></Menu.Item>
            }
            {
                asset.type === 1 && (media.mimeType !== 'image/gif') && links &&
                Object.keys(links).map(key => {
                    if (key !== 'th') {
                        return (
                            <Menu.Item key={key}>{`${key} (${links[key].width} x ${links[key].height})`}</Menu.Item>
                        )
                    }
                })
            }
        </Menu >
    )
}
