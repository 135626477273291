import React from 'react'
import { Button, Popover } from 'antd';
import { useAccountContext } from "../providers/AccountProvider";
import { useTranslation } from "react-i18next";
import TagAutoComplete from "./tag/TagAutoComplete";
import { useMediaQuery } from 'react-responsive';


export default function TaggingAction({ selection }) {

  const { t } = useTranslation();

  const [accountState, accountDispatch] = useAccountContext();
  const { rerenderTags0 } = accountDispatch;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const taggingMenu = (
    <TagAutoComplete assets={selection} refresh={rerenderTags0} />
  )

  return (
    <Popover className="ant-dropdown-trigger" content={taggingMenu} title={t('Ajouter un tag à la sélection')} trigger={"click"} placement="bottomLeft">
      <Button style={{ marginRight: '1em' }} size="small" shape="round" icon={'tag'}>
        {!isTabletOrMobile && t('Taguer')}
      </Button>
    </Popover>
  )
}
