import React, { useEffect, useState } from "react";
import { useApiContext } from "../providers/ApiProvider";
import { Button, Icon, PageHeader, Switch, Table } from "antd";
import { useTranslation } from "react-i18next";
import ModalApiPermission from "./modal/ModalApiPermission";


export default function ApiPermissions({ account, update }) {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter, apiDeleteEntity, apiUpdateEntity } = apiDispatch;
  const [loadingPermanent, setLoadingPermanent] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [visible, setVisible] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: 'bottom',
    size: "small"
  })

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchPermissions()

    // fetchData();
  }, [needUpdate, update]);


  const handleSwitch = (id, isActive) => {
    let data = { isActive }
    apiUpdateEntity('permissions', id, data, response => {
      // console.log(response)
    })
  }

  const handleDelete = (permissionID) => {
    apiDeleteEntity('permissions', permissionID, response => {
      setNeedUpdate(needUpdate + 1)
    })

  }

  const fetchPermissions = () => {
    setLoadingPermanent(true)
    const params = {
      id: account.id,
      filters: [
        {
          name: 'exists[domain]',
          value: true
        }
      ]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'permissions', (response) => {
      if (response['@type'] === "hydra:Collection") {
        setPermissions(response['hydra:member'])
      }
      setLoadingPermanent(false)
    })
  }

  const columns = [
    {
      title: t('Nom de domaine'),
      dataIndex: 'domain',
      render: (domain, record) => <> <span>{domain}</span></>,
    },
    {
      title: t('Clé d\'acces privée'),
      dataIndex: 'token',
      render: (token) => <span>{token}</span>
    },
    {
      title: t('Clé d\'acces public'),
      dataIndex: 'publicToken',
      render: (publicToken) => <span>{publicToken}</span>
    },
    {
      title: t('Actions'),
      dataIndex: 'isActive',
      render: (isActive, record) =>
        <span>
          <Switch size="small" defaultChecked={isActive}
            onChange={(e) => handleSwitch(record.id, e)} />
          <Icon type={'delete'} onClick={() => handleDelete(record.id)} />
        </span>,
    }
  ];

  function handleTableChange(table_pagination) {
    // update current pagination
    const pager = { ...table_pagination };
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title={t("Autorisation externe (API)")}
      >
      </PageHeader>
      <ModalApiPermission visible={visible} setVisible={setVisible} needUpdate={needUpdate} setNeedUpdate={setNeedUpdate} account={account} />
      <Table columns={columns}
        title={(currentPageData) => <div className="flex between">
          <b>{t('Api connectée')}</b>
          <Button type={"primary"} size="small" onClick={() => {
            setVisible(true)
          }}>+</Button>
        </div>}
        bordered={true}
        dataSource={permissions}
        rowKey={record => record['@id']}
        pagination={pagination}
        loading={loadingPermanent}
        onChange={handleTableChange}
      />
    </>
  )

}
