import React from "react";

export default function Illustrator(props) {
  return (
    <svg viewBox="0 0 176 176" xmlns="http://www.w3.org/2000/svg" width="100%">
      <g id="Layer_2" data-name="Layer 2">
        <g id="_1.Adobe_Illustrator" data-name="1.Adobe Illustrator">
          <g id="background">
            <path id="background-2" d="m0 0h144.46a31.54 31.54 0 0 1 31.54 31.54v144.46a0 0 0 0 1 0 0h-144.46a31.54 31.54 0 0 1 -31.54-31.54v-144.46a0 0 0 0 1 0 0z" fill="#36261a" data-name="background" />
            <path id="shade" d="m144.46 8a23.57 23.57 0 0 1 23.54 23.54v136.46h-136.46a23.57 23.57 0 0 1 -23.54-23.54v-136.46zm0-8h-144.46v144.46a31.54 31.54 0 0 0 31.54 31.54h144.46v-144.46a31.54 31.54 0 0 0 -31.54-31.54z" fill="#ff7f18" />
          </g>
          <g id="icon" fill="#ff7f18">
            <path d="m87.81 103.28h-20.88l-5.22 15.72q-.09.72-1 .72h-9.44q-.9 0-.63-.9l18.72-53.43a15.32 15.32 0 0 0 .81-5q0-.54.63-.54h13a.81.81 0 0 1 .72.45l20.88 58.68q.27.81-.54.81h-10.66a.92.92 0 0 1 -.9-.63zm-18.54-8.73h16.2q-.36-1.26-3.74-11.48t-4.45-14h-.09q-1.08 4.6-7.92 25.48z" />
            <path d="m123.81 66.83a6.21 6.21 0 1 1 -4.32-10.53 5.67 5.67 0 0 1 4.32 1.7 6.15 6.15 0 0 1 1.62 4.41 6 6 0 0 1 -1.62 4.42zm-10.17 52.11v-42.3a.63.63 0 0 1 .72-.72h10a.64.64 0 0 1 .72.72v42.36a.64.64 0 0 1 -.72.72h-9.9a.72.72 0 0 1 -.82-.78z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
