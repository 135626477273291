import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tag, message, Spin } from 'antd';
import TagsPropositions from '../../tag/TagsPropositions.js'
import { useTranslation } from "react-i18next";
import TagAutoComplete from "../../tag/TagAutoComplete";
import { useApiContext } from '../../../providers/ApiProvider';

const TagsTabs = ({ tags, canEdit, asset }) => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiUpdateEntity, apiFetchSubResourceWithFilter } = apiDispatch;
  const [tagsNeedUpdate, setTagsNeedUpdate] = useState(0)
  const params = useParams();
  const [updateTags, setUpdateTags] = useState(0)
  const [searchResult, setSearchResult] = useState([]);
  const [assetTags, setAssetTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true)
  const media = asset.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset.medias[0];
  const extension = media?.fileName?.split('.')?.pop()

  console.log('canEdit', extension)
  useEffect(() => {
    getAssetTags()
  }, [updateTags, asset]);

  const getAssetTags = () => {
    setLoadingTags(true)
    let data = {
      id: asset.id,
      filters: [
        {
          name: 'type',
          value: 0
        }
      ]
    }
    apiFetchSubResourceWithFilter('assets', data, 'tags', response => {
      if (response['@type'] !== 'hydra:Error') {
        setAssetTags(response['hydra:member'])
      }
      setLoadingTags(false)
    })
  }


  // Ajoute un nouveau tag type 0 (description)
  const newTag = (name) => {
    let data = {
      name: name,
      type: 0,
      account: '/api/accounts/' + params.account_id,
      assets: [`/api/assets/${asset.id}`]
    }

    apiPostEntity("tags", data, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t('Il y a eu un problème'));
      } else {
        setAssetTags([...assetTags, response])
      }
      setTagsNeedUpdate(tagsNeedUpdate + 1)
      setSearchResult([])
    })
  }

  // Update les tags liés à l'asset
  const patchAsset = (tags) => {
    let Data = {
      "tags": tags
    }
    apiUpdateEntity("assets", asset.id, Data, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t('Il y a eu un problème'));
      } else {
        setAssetTags(response.tags.filter(item => item.type === 0))
      }
      setTagsNeedUpdate(tagsNeedUpdate + 1);
      setSearchResult([])
    });
  }

  const handleAdd = async (value, event) => {
    if (event && event.key === 'Enter' && searchResult.length > 0) {
      return
    }
    event ? event.stopPropagation() : '';
    value = value.trim();

    if (value.length > 0) {
      const hasTag = assetTags.filter(item => item.name === value && item.type === 0).length > 0;
      if (hasTag) {
        message.info(t('Cet asset a déja ce tag'));
        return
      }
      newTag(value);
    }
  }

  const handleRemove = (tag) => {
    let allTags = [...assetTags, ...tags];
    const newTags = allTags.filter(item => item['@id'] !== tag['@id']).map(item => item['@id'])
    patchAsset(newTags);
  }


  return (
    <div className="tabs-assets">
      {canEdit ?
        <TagAutoComplete
          assets={[{ id: asset.id }]}
          assetTags={assetTags} setAssetTags={setAssetTags}
          refresh={setUpdateTags} />
        : null}
      <Spin spinning={loadingTags}>
        {assetTags.length > 0 ?
          assetTags.map((tag) => (
            canEdit ?
              <Tag color={tag.normalized ? '#0cc800' : "#2395d3"} key={tag['@id']} closable
                onClose={() => handleRemove(tag)}>{tag.name}</Tag>
              : <Tag color="#108ee9" key={tag['@id']}>{tag.name}</Tag>
          ))
          : t("Ce média  n'a pas encore de tag")
        }
      </Spin>
      {canEdit && asset?.type === 1 && extension !== 'gif' ?
        <TagsPropositions handleAdd={handleAdd} assetId={asset.id} />
        : ''
      }
    </div>
  );
}

export default TagsTabs;
