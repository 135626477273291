import React, { useState } from 'react'
import { Button, Input, Form, Icon } from 'antd';
import { useTranslation } from 'react-i18next';


export default function PasswordPage({ handleForm }) {

    const { t } = useTranslation();
    const [password, setPassword] = useState('')

    function handleSubmit(e) {
        e.preventDefault()
        handleForm(password)
    }

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <h2>{t('Entrer le code d\'accès')}</h2>
            <Form.Item>
                <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text" value={password} onChange={e => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                />
            </Form.Item>
            <Button className="login-form-button" onClick={handleSubmit}>{t('Valider')}</Button>
        </Form>
    )
}
