import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Icon, Input, Tag } from 'antd';
import ApiCollectionTable from '../ApiCollectionTable.js';
import {useTranslation} from "react-i18next";
const { Search } = Input;

const AccountList = () => {

  const {t, i18n} = useTranslation();
  const initialFilters = [{name: 'name', value: ''}, {name: 'status', value: '1'}]
  const [filters, setFilters] = useState(initialFilters)

  const columns = [{
      title: t('Nom du compte'),
      dataIndex: 'name',
      render: (name, reccord) => <Link to={`./admin/account-overview/${reccord.id}`}><Icon type="edit" /> {name}</Link>,
    },
    {
      title: t('Compte parent'),
      dataIndex: 'parentAccount',
      render: (parentAccount) => (
        parentAccount ?
        <Link to={`./admin/account-overview/${ parentAccount.id }`}><Icon type="edit" /> {parentAccount ? parentAccount.name : null }</Link> :
        null),
    },
  ];

  const handleClose = () => {
    setFilters(initialFilters);
  }

  const handleSearch = (e) => {
    // console.log(e)
    setFilters([{name: 'name', value: e.trim()}, {name: 'status', value: '1'}])
  }

  return (
    <div>
      <div className='table-header'>
        <span>{t('Recherche')} : </span>
        <Search
          placeholder={t("Nom de compte")}
          onSearch={handleSearch}
          style={{ width: 200}}
        />
        {
          filters[0].value ?
              <Tag closable onClose={handleClose}>
                {filters[0].value}
              </Tag>
          : ''
        }
        </div>
    <ApiCollectionTable
      endpoint="accounts"
      columns={columns}
      filtres={filters}
      />
      </div>
  );
};

export default AccountList;
