import React from 'react';
import { Route } from 'react-router-dom';

import PublicLayout from "./layout/PublicLayout";
import ShareLayout from './layout/ShareLayout';

const PublicRoute = ({component: Component, layout, ...rest}) => {

  const renderer = (layout,props) => { 
    if (rest?.visitor)
      return <ShareLayout {...rest}><Component /></ShareLayout>
      
    return <PublicLayout {...props}><Component {...props}/></PublicLayout> 
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={renderer.bind(null,layout)} />
  );
};

export default PublicRoute;
