import React, { useEffect } from 'react';
import { useApiContext } from '../../providers/ApiProvider.js';
import { useAuthContext } from '../../providers/AuthProvider.js';
import { useParams } from 'react-router-dom';
import AssetsSort from '../asset/AssetsSort.js';
import Cart from './cart/Cart.js';
import { useAccountContext } from "../../providers/AccountProvider";
import AssetNoSort from './AssetNoSort.js';
import Videos from './video/Videos'

const Assets = () => {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const params = useParams();
  const [accountState, accountDispatch] = useAccountContext();
  const { setter } = accountDispatch;

  useEffect(() => {
    fetchCarts()
  }, [accountState.cartsNeedUpdate, accountState.account])

  function fetchCarts() {
    setter('cartsLoading', true)
    let data = {
      id: params.account_id,
      filters: [{ id: 'cart', name: 'type', value: 2 }]
    }

    if (!accountState.admin)
      data.filters.push({ id: 'createdBy', name: 'createdBy', value: '/api/users/' + auth.id });

    apiFetchSubResourceWithFilter('accounts', data, 'tags', (hydra_response) => {
      setter('carts', hydra_response['hydra:member'])
      setter('cartsLoading', false)
    });
  }

  return (
    <div className='assets-container'>
      {accountState.activeTab === "1" && <AssetNoSort />}

      {accountState.activeTab === "2" && <AssetsSort />}

      {!!(accountState.activeTab === "4" && accountState.account.contractType !== 'freemium') &&
        <Cart />}

      {accountState.activeTab === "5" &&
        <AssetsSort baseFilters={[{ "name": "type", "value": "2" }]} />
      }

      {accountState.activeTab === "6" &&
        <AssetsSort baseFilters={[{ "name": "type", "value": "3" }]} />
      }
      {accountState.activeTab === "7" && accountState.admin &&
        <Videos />
      }

    </div>
  );
}

export default Assets;
