import { Spin } from 'antd'
import React from 'react'
import DocumentView from './DocumentView'
import Galery from './Galery'

const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function MediaView({ canEdit = false, asset, galeryMode = false, share = false }) {

    const media = asset?.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset?.medias?.[0];
    const isSvg = media?.mimeType === 'image/svg' || media?.mimeType === 'image/svg+xml'

    function resolveMaxSize() {
        if (!media) return {}
        return { maxHeight: media.dimensions[0] + 'px', maxWidth: media.dimensions[1] + 'px' }
    }

    function getLink() {

        if (!media?.extraUrl && error && !share) return Placeholder;
        let size = window.innerWidth < 768 ? 'md' : 'lg'
        if (media.mimeType === 'image/gif') return share ? `${shareLink}${asset?.uuid}/${encodeURIComponent(asset?.usageName)}` : media.contentUrl

        return share ? `${shareLink}${asset?.uuid}/${encodeURIComponent(asset?.usageName)}?size=${size}` : media?.extraUrl?.[size].url
    }

    if (!asset)
        return <Spin spinning />

    if (galeryMode)
        return <Galery canEdit={canEdit} assetId={asset.id} />

    return (
        <>
            {
                asset?.type === 1 &&
                <img key={asset?.currentMedia}
                    className="media" loading="lazy"
                    style={{ ...resolveMaxSize(asset), background: isSvg ? '' : `center / contain no-repeat url('${share ? `${shareLink}${asset?.uuid}/${asset?.usageName}?size=sm` : media?.extraUrl?.th?.url}')` }}
                    src={getLink()}
                />
            }
            {
                asset?.type === 3 &&
                <div className='flex center align_center' style={{ height: '95%', width: '95%' }}>
                    {
                        media?.vimeoId ?
                            <iframe
                                width="100%" height="100%"
                                src={"https://player.vimeo.com/video/" + media?.vimeoId + '?loop=1'}
                                frameBorder="0"
                                allow={"autoplay; fullscreen"}
                                allowFullScreen
                            />
                            :
                            <video key={media.id} width="auto" height="auto" style={{ maxHeight: '100%', maxWidth: '100%' }}
                                controls controlsList="nodownload" loop>
                                <source src={media.contentUrl} type={asset.mimeType} />
                            </video>
                    }
                </div>
            }
            {
                asset.type === 2 &&
                <DocumentView asset={asset} />
            }
        </>
    )
}
