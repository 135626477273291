import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd';
import ModalSortSelection from "../../modal/ModalSortSelection";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';


export default function MoveActions({ sortSelection, setSortSelection, setLoading }) {

  const { t } = useTranslation();
  const [duplicate, setDuplicate] = useState(null)
  const [isModalSortVisible, setIsModalSortVisible] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const moveMenu = (
    <Menu>
      <Menu.Item key="/moveIt"
        onClick={() => {
          setIsModalSortVisible(true);
          setDuplicate(false);
        }}>
        {t('Déplacer')}
      </Menu.Item>
      <Menu.Item key="/duplicateIt"
        onClick={() => {
          setIsModalSortVisible(true);
          setDuplicate(true);
        }}>
        {t('Dupliquer')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {
        isModalSortVisible ?
          <ModalSortSelection
            visible={isModalSortVisible}
            setVisible={setIsModalSortVisible}
            duplicate={duplicate}
            selected={sortSelection}
            setSelected={setSortSelection}
            setLoading={setLoading}
          />
          : null
      }

      <Dropdown
        overlay={moveMenu} trigger={["click"]}
      >
        <Button className='mr' size="small" shape="round" onClick={e => e.preventDefault()} icon="copy">
          {!isTabletOrMobile && t('Classer')}
        </Button>
      </Dropdown>
    </>
  )
}
