import React, {useEffect, useState} from 'react';
import {Button, PageHeader, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from '../../providers/ApiProvider.js';
import CroppingProfile from "../CroppingProfiles";

const CropProfileList = ({activeTab}) => {

  const {t, i18n} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter, apiDeleteEntity} = apiDispatch;
  const [data, setData] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState({name: '', cropSizes: []});
  const [mode, setMode] = useState('list');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: 'both',
    size: "small"
  })

  useEffect(() => {
    if (activeTab === 'cropping') {
      setSelectedItem({name: '', cropSizes: []})
      setMode('list')
      fetch()
    }
  }, [needUpdate, activeTab])

  const columns = [{
    title: t('Nom'),
    dataIndex: 'name',
    render: (name) => <span>{name}</span>,
  },
    {
      title: t('Dossiers liés'),
      dataIndex: 'tags',
      render: (tags) => <span>{tags.length}</span>,
    },
    {
      title: t('Nb de taille'),
      dataIndex: 'cropSizes',
      render: (cropSizes) => <span>{cropSizes.length}</span>
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      render: (actions, record) => <span> <Button size="small" onClick={() => editing(record)}>{t('Editer')}</Button> <Button
        size="small" onClick={() => handleDelete(record.id)}>{t('Supprimer')}</Button></span>,
    }
  ];

  function editing(record) {
    setMode('update');
    setSelectedItem(record)
  }

  function fetch() {
    setLoading(true);
    const params = {
      id: accountState.account.id
    }
    apiFetchSubResourceWithFilter('accounts', params, 'cropping_profiles', (hydra_response) => {
      // console.log(hydra_response);
      const pager = {...pagination};
      pager.total = hydra_response['hydra:totalItems'];

      setData(hydra_response['hydra:member']);
      setPagination(pager)
      setLoading(false);
    });
  }

  function handleTableChange(table_pagination) {
    // update current pagination
    const pager = {...table_pagination};
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  };

  const handleDelete = (id) => {
    apiDeleteEntity('cropping_profiles', id, response => {
      setNeedUpdate(needUpdate + 1)
    })
  }


  return (
    <div className="contenu-tab">
      {
        mode === 'list' ?
          <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <PageHeader title={t('Liste des Profils')} ghost={false}/>
              <Button size="small" type="primary" onClick={() => setMode('create')}>
                {t('Créer un profil')}
              </Button>
            </div>
            <Table columns={columns}
                   dataSource={data}
                   rowKey={record => record['@id']}
                   pagination={pagination}
                   loading={loading}
                   onChange={handleTableChange}
            />
          </>
          :
          <div>
            <CroppingProfile profile={selectedItem} setSelectedItem={setSelectedItem} mode={mode} setMode={setMode}
                             setNeedUpdate={setNeedUpdate} needUpdate={needUpdate}/>
          </div>
      }
    </div>

  )
}

export default CropProfileList;
