import React, {useCallback, useEffect, useRef, useState} from "react"
import axios from "axios";
import {Button, Checkbox, Input, message, Select} from "antd";
import TemplateColumn from "./TemplateColumn";
import {useTranslation} from "react-i18next";
import {isObserverAvailable} from "./Widget/helpers";
import {useAccountContext} from "../providers/AccountProvider";
import {useApiContext} from "../providers/ApiProvider";
import {useAuthContext} from "../providers/AuthProvider";

const {Option} = Select;
const isObserverWorking = isObserverAvailable();
const keyWords = ["Paris", "Nice", "Cannes", "Arc de triomphe", "Montmartre"];

export default function UnsplashComponent({selected, setSelected}) {

  const {t} = useTranslation();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [accountState] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;

  const [images, setImages] = useState([])
  const [page, setPage] = useState(1)

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);

  const [keyWord, setKeyWord] = useState(arrayRandom(keyWords))
  const [orientation, setOrientation] = useState(undefined)

  const [unsplashRequest, setUnsplashRequest] = useState(accountState.account.storage.unsplashRequest)

  const observer = useRef()

  useEffect(() => {
    get("search/photos", {
      filters: [{name: "query", value: keyWord}, {name: "per_page", value: 30}, {
        name: "page",
        value: page
      }]
    }, response => {
      setHasMore(page * 30 < response.total);
      setImages(response.results);
      updateStorage();
    })
  }, [])

  function updateStorage() {
    if (auth.roles.includes("ROLE_ADMIN"))
      return setLoading(false);

    const value = unsplashRequest + 1;
    setUnsplashRequest(value);
    apiUpdateEntity("storages", accountState.account.storage.id, {unsplashRequest: value}, response => {
      setLoading(false);
    })
  }

  const lastAssetElement = useCallback(node => {
    if (!isObserverWorking) return;
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        if (unsplashRequest < accountState.account.storage.maxUnsplashRequest || auth.roles.includes("ROLE_ADMIN"))
          next();
        else
          message.error(t("Vous avez dépassé votre quota quotidien de requête unsplash"))
      }
    })
    if (node) observer.current.observe(node);
  }, [loading, hasMore])

  async function get(endpoint, params, callback) {
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map((filter, key) => {
        if (!key)
          filtres += '?' + filter['name'] + '=' + filter['value']
        else
          filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: "https://api.unsplash.com/" + endpoint + '/' + filtres,
        headers: {
          "Authorization": "Client-ID j1rPcBsErfo4BojsupycBOx2kyxrSVFBuq9Bt3L3d20"
        }
      });
      callback(response.data);
    } catch (e) {
      callback(e.response.data);
    }
  }


  function next() {
    setLoading(true)
    const filters = [{name: "query", value: keyWord}, {name: "page", value: page + 1}, {name: "per_page", value: 30}]
    if (orientation)
      filters.push({name: "orientation", value: orientation})

    get("search/photos", {filters: filters}, response => {
      setHasMore(page * 30 < response.total);
      setImages(images => [...images, ...response.results])
      setPage(page + 1)
      updateStorage()
    })
  }

  function search() {
    if (!keyWord)
      message.warning(t("Saisissez un mot-clé dans le champ pour faire une recherche"))

    const filters = [{name: "query", value: keyWord}, {name: "page", value: 1}, {name: "per_page", value: 30}]
    if (orientation)
      filters.push({name: "orientation", value: orientation})

    get("search/photos", {filters: filters}, response => {
      setImages(response.results)
      setPage(1)
    })
  }

  function handleClick(url) {
    if (selected.includes(url))
      setSelected(selected.filter(s => s !== url));
    else
      setSelected([...selected, url]);
  }

  function displayImage(item, key, column, columnLength) {
    return (
      <div
        ref={column === 1 && columnLength === key + 1 ? lastAssetElement : null}
        key={key}
        className="img_container pointer"
        onClick={() => handleClick(item.urls.full)}
        style={
          selected.includes(item.urls.full) ?
            {boxShadow: "orange 0 0 10px"} : null
        }
      >
        <Checkbox
          style={{position: "absolute", top: 10, right: 10}}
          checked={selected.includes(item.urls.full)}
        />
        <img
          src={item.urls.small}
          alt={item.alt_description}
        />
      </div>
    )
  }

  function onChange(value) {
    setOrientation(value)
  }

  function arrayRandom(array) {
    return array[Math.floor(Math.random() * array.length)]
  }

  return (
    <div>

      <div className="flex center">
        <div>
          <Input
            className="w-250"
            placeholder={t("Rechercher par mot-clé")}
            value={keyWord}
            onChange={(e) => setKeyWord(e.target.value)}
            onPressEnter={search}
          />
          <Select
            value={orientation}
            style={{width: 200}}
            placeholder={t("Choisir une orientation")}
            onChange={onChange}
            allowClear
          >
            <Option value="landscape">{t("Paysage")}</Option>
            <Option value="portrait">{t("Portrait")}</Option>
            <Option value="squarish">{t("Carré")}</Option>
          </Select>
          <Button type="primary" icon="search" onClick={search}/>
        </div>
      </div>

      <div className="galery" style={{maxWidth: 1400}}>
        <TemplateColumn images={images} loading={false} displayImage={displayImage}/>
      </div>

    </div>
  )
}
