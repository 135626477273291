import React from 'react';
import { Dropdown, Icon } from 'antd';
import AssetDraggable from "../AssetDraggable";
import CardNoRange from "../CardNoRange";
import { useAssetContext } from "../../../providers/AssetProvider";
import ContextMenu from '../../Widget/v2/ContextMenu';
import { useAccountContext } from '../../../providers/AccountProvider';

const MosaicCards = ({ mode, ...props }) => {

  const [accountState] = useAccountContext();
  const { appearance } = accountState
  const [assetState, assetDispatch] = useAssetContext();
  const { duplicate } = assetState;
  const { setLoading } = assetDispatch;

  const onChange = (e) => {
    let checked = e.target.checked
    let id = e.target.data

    props.setSelected((prev) => {
      const next = new Set(prev);
      if (checked) {
        next.add(id)
      } else {
        next.delete(id)
      }
      return next;
    });
  }

  function openFs(e, assetId) {
    e.stopPropagation()
    e.preventDefault()
    props.setAssetId(assetId)
    props.setSelected(new Set())
  }


  function handleClick(e, checked, id) {
    if (e.ctrlKey || e.metaKey) {
      return toggleSelect(checked, id)
    }
    props.setAssetId(id)
  }

  const toggleSelect = (checked, id) => {
    props.setSelected((prev) => {
      const next = new Set(prev);
      if (checked) {
        next.add(id)
      } else {
        next.delete(id)
      }
      return next;
    });
  }

  return (
    !!props.assets.length &&
    <div className="assets-flex list">

      <div className={`assets-${appearance.card}-grid ${appearance.size}`}>

        {props.assets.map(item => (
          <Dropdown key={item.id} overlay={<ContextMenu asset={item} original/>} trigger={item.type === 3 ? [] : ['contextMenu']}>
            <div className="grid-item">
              <AssetDraggable
                duplicate={duplicate}
                asset={item}
                itemsChecked={props.selected}
                setItemsChecked={props.setSelected}
                setLoading={setLoading}
                draggable={props.selectable}
                isSelected={props.selected.has(item.id)}
              >
                <CardNoRange
                  item={item} isSelected={props.selected.has(item.id)}
                  handleCheck={onChange} selectable={props.selectable}
                  fullscreen={<Icon
                    className="logo_fs_range see-on-hover black full-top-right"
                    type="fullscreen"
                    onClick={e => openFs(e, item.id)}
                  />}
                  seeActions={appearance?.size !== 'small'}
                  handleClick={handleClick}
                />
              </AssetDraggable>
            </div>
          </Dropdown>
        ))}
      </div>

    </div>
  )
}

export default MosaicCards;
