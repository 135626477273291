import React, { useState } from "react"
import { Icon, Popconfirm } from "antd";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";

export default function DeleteConfirmBtn({ title, endpoint, id }) {

  const [apiDispatch] = useApiContext();
  const { apiDeleteEntity } = apiDispatch;
  const [loading, setLoading] = useState(false)
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;

  function remove() {
    setLoading(true)
    apiDeleteEntity(endpoint, id, response => {
      setLoading(false)
      rerender()
    })
  }

  return (
    <Popconfirm
      title={title}
      onConfirm={remove}
      okText="Oui"
      cancelText="Non"
    >
      <Icon style={{ marginLeft: '.5em' }} type={loading ? "loading" : "delete"} spin={loading ? true : false} />
    </Popconfirm>
  )
}
