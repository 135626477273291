import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Col, Spin, Icon } from 'antd'
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";

const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function ModalDouble({ assetList, visible, setVisible, folderId }) {

    const { t } = useTranslation();
    const [internalList, setInternalList] = useState(null)
    const [current, setCurrent] = useState(0)
    const [currentSimilar, setCurrentSimilar] = useState(0)
    const [main, setMain] = useState(0)
    const [loading, setLoading] = useState(false)
    const [apiDispatch] = useApiContext();
    const { apiPostEntity } = apiDispatch;

    useEffect(() => {
        if (assetList) {
            setInternalList(assetList)
        }
    }, [assetList])

    useEffect(() => {
        if (internalList && internalList.length) {
            let currentAsset = current
            if (internalList.length - 1 < current) {
                currentAsset = current - 1
                setMain(fullList[currentAsset])
                setCurrent(currentAsset)
            } else {
                currentAsset = 0
                setMain(internalList[0])
                setCurrent(0)
            }
            if (internalList[currentAsset].similar.length - 1 < currentSimilar) {
                setCurrentSimilar(currentSimilar - 1)
            }
        }
    }, [internalList])

    const nextAsset = () => {
        setLoading(true)
        if (internalList.length > current) {
            setCurrent(current + 1)
            setMain(internalList[current + 1])
        }
        setLoading(false)
    }

    const nextDouble = () => {
        setLoading(true)
        if (internalList[current].similar[currentSimilar].length > currentSimilar) {
            setCurrentSimilar(currentSimilar + 1)
        }
        setLoading(false)
    }

    const prevAsset = () => {
        setLoading(true)
        if (current > 0) {
            setCurrent(current - 1)
            setMain(internalList[current - 1])
        }
        setLoading(false)
    }

    const prevDouble = () => {
        setLoading(true)
        if (currentSimilar > 0) {
            setCurrentSimilar(currentSimilar - 1)
        }
        setLoading(false)
    }

    const handleValidation = () => {
        setLoading(true)
        let uuid = main.similar[currentSimilar].uuid
        apiPostEntity('redirect-asset', { targetUuid: uuid, redirectTo: main.id }, response => {
            console.log(response)
            if (response.success) {
                //update list
            }
            cleanData(uuid)
        })

    }

    const cleanData = (uuid) => {
        let doubleList = main.similar.filter(item => item.uuid !== uuid)
        let fullList = [...internalList]
        if (doubleList.length) {
            fullList[current].similar = doubleList
        } else {
            fullList = fullList.filter(item => item.id != main.id) || []
        }
        console.log(fullList)
        setInternalList(fullList)
        apiPostEntity('update-folder-results', { folderId: folderId, list: fullList }, response => {
            console.log(response)
            setLoading(false)
        })

    }

    const handleClose = () => {
        setVisible(false)
    }
    return (
        <Modal closable={true}
            width="80vw"
            footer={[<Button onClick={handleClose} >Terminer</Button>]}
            centered
            destroyOnClose={true}
            onCancel={handleClose}
            title={t("Relier des assets")}
            visible={visible}>
            <Spin spinning={loading} >
                {
                    main && internalList[current] ?
                        <div>
                            {
                                internalList.length > 1 &&
                                <Button.Group className="m-20" style={{ textAlign: 'center' }}>
                                    <Button key='prevA' onClick={prevAsset} disabled={current <= 0}>
                                        <Icon type="left" />
                                        Asset Précédant
                                    </Button>
                                    <Button key='nextA' onClick={nextAsset} disabled={internalList.length - 1 <= current}>
                                        Asset Suivant
                                        <Icon type="right" />
                                    </Button>
                                </Button.Group>
                            }
                            <Row>
                                <Col span={12} >
                                    <img key={'img1'} style={{ maxWidth: '100%', maxHeight: 400 }} src={`${shareLink}${main.uuid}/testing?size=md`} />
                                </Col>
                                <Col span={12} >
                                    <img key={'img2'} style={{ maxWidth: '100%', maxHeight: 400 }} src={`${shareLink}${main.similar[currentSimilar].uuid}/testing?size=md`} />
                                </Col>
                            </Row>
                            <div className="m-20" style={{ textAlign: 'center' }}>
                                {main.similar.length > 1 &&
                                    <Button.Group className="m-20" style={{ textAlign: 'center' }}>
                                        <Button key='prevD' onClick={prevDouble} disabled={currentSimilar <= 0}>
                                            <Icon type="left" />
                                            Double Précédant
                                        </Button>
                                        <Button key='nextD' onClick={nextDouble} disabled={internalList[current].similar.length - 1 <= currentSimilar} >
                                            Double Suivant
                                            <Icon type="right" />
                                        </Button>
                                    </Button.Group>
                                }
                                <Button key='updateD' type="primary" onClick={handleValidation}>Rediriger et supprimer ce double</Button>
                            </div>
                        </div>
                        :
                        <p>Aucun asset</p>
                }
            </Spin>
        </Modal>
    )
}
