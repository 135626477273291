import React, { useState, useEffect } from 'react'
import { message, Button, Modal, Spin } from 'antd'
import ModalImage from '../../modal/ModalImage'
import { useTranslation } from "react-i18next";
import { stringDate } from '../../../helpers.js'
import UpdateFile from '../../Upload/UpdateFile';
import { useApiContext } from '../../../providers/ApiProvider';
import { useAuthContext } from '../../../providers/AuthProvider';

const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function UpdateTabs({ asset, setAsset, mimeType, updateAsset }) {

	const [authState, authDispatch] = useAuthContext();
	const { auth } = authState;
	const [apiDispatch] = useApiContext();
	const { apiFetchSubResource, apiUpdateEntity, apiPostEntity } = apiDispatch;
	const { t } = useTranslation();
	const [iris, setIris] = useState([])
	const [visible, setVisible] = useState(false)
	const [image, setImage] = useState(null)
	const [title, setTitle] = useState(null)
	const [currentMedia, setCurrentMedia] = useState(null)
	const [mediaToUp, setMediaToUp] = useState(null)
	const [isLoading, setIsLoading] = useState(false);
	const [historic, setHistoric] = useState([])

	useEffect(() => {
		setCurrentMedia(asset ? asset?.medias.find(item => item.id === asset.currentMedia) : null)
		fetchHistory()
	}, [asset, asset.currentMedia])

	const fetchHistory = () => {
		setIsLoading(true)
		apiFetchSubResource('assets', { id: asset.id }, 'historic_assets', response => {
			if (response['@type'] === "hydra:Collection") {
				setHistoric(response['hydra:member'])
				setIsLoading(false)
			}
		})
	}

	useEffect(() => {
		let Tabs = []
		asset.medias.map(media => {
			Tabs.push(media['@id'])
		})
		setIris(Tabs)
	}, [asset.currentMedia])

	function handleSuccess(mediaObject) {
		setAsset({ ...asset, currentMedia: mediaObject.id, medias: [...asset.medias, mediaObject] })
		message.success(t('Média mis à jour avec succès'));
	}

	function showModal(newMedia) {
		if (asset.type === 2) {
			return showConfirm(newMedia)
		}
		setImage(newMedia.extraUrl.md.url)
		setTitle(newMedia.originalName)
		setMediaToUp(newMedia)
		setVisible(true)
	}

	function showConfirm(media) {
		console.log('media', media)
		Modal.confirm({
			title: 'Revenir à ce média?',
			content: media.originalName,
			okText: t('Mettre à jour'),
			cancelText: t('Non'),
			onOk() {
				update(media)
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const update = (newMedia) => {
		setIsLoading(true);
		let Data = { currentMedia: newMedia.id }
		apiUpdateEntity('assets', asset.id, Data, response => {
			if (response['@type'] === "hydra:Error") {
				// console.log(response)
			} else {
				clearCache()
				updateAsset(response)
				setAsset(response);
				setVisible(false)
			}
			setIsLoading(false);
		})
	}

	function clearCache() {
		const ext = '.' + currentMedia.fileName.split('.').pop()
		const shareUrls = asset ? [
			`${shareLink}${asset.uuid}/${asset.usageName}`,
			 `${shareLink}${asset.uuid}/${asset.usageName}${ext}`,
			 `${shareLink}${asset.uuid}/${asset.usageName}?size=original`,
			 `${shareLink}${asset.uuid}/${asset.usageName}${ext}?size=original`,
			 `${shareLink}${asset.uuid}/${asset.usageName}${ext}?size=lg`,
			 `${shareLink}${asset.uuid}/${asset.usageName}${ext}?size=md`,
		] : []
		purgeCacheCloudflare(shareUrls)

	}

	function purgeCacheCloudflare(files) {
		apiPostEntity('purge-cache-cloudflare', { files: files }, response => {
			console.log(response)
		})
	}

	return (
		<div className="tabs-assets">
			<UpdateFile callBack={handleSuccess} multiple={false} mode={'patch'} asset={asset} />
			<p className='bold'>{t('Historique')} :</p>
			<Spin spinning={isLoading} >
				{
					historic.slice(0).reverse().map((histo, id) => (
						<a
							key={id}
							style={{ display: 'block', margin: '5px 0' }}
							disabled={asset.currentMedia === histo.newMedia}
							onClick={
								() => {
									showModal(asset.medias.find(media => media.id === histo.newMedia))
								}
							}
						>
							{t('Version')} {asset.currentMedia === histo.newMedia ? ' ' + t('Actuelle').toLowerCase() : historic.length - id} :
							&nbsp;{asset.medias.filter(media => media.id === histo.newMedia)[0]?.originalName} le {stringDate(histo.created)}{histo.user ? ', ' + t('Par') + ' ' + histo.user : null}
						</a>
					))
				}
				{
					auth.roles.includes("ROLE_ADMIN") &&
					<Button onClick={clearCache}>Clear cache</Button>
				}
			</Spin>
			<ModalImage
				title={title}
				visible={visible} setVisible={setVisible}
				image={image} mimeType={mimeType}
				footer={[
					<Button key="back" onClick={() => setVisible(false)}>
						{t('Retour')}
					</Button>,
					<Button key="submit" type="primary" loading={isLoading} onClick={() => update(mediaToUp)}>
						{t('Revenir à cette version')}
					</Button>,
				]}
			/>
		</div>
	)
}
