import React, { useState } from 'react';
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import AddVideo from "./AddVideo";
import { useAccountContext } from "../../../providers/AccountProvider";

const ModalAddVideo = () => {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch

  const [visible, setVisible] = useState();


  function close() {
    setVisible(false);
    rerender();
  }

  return (
    <>
      <Button
        size="small" icon="plus" type="primary"
        onClick={() => setVisible(true)}
      >
        {t('Ajouter')}
      </Button>


      <Modal
        destroyOnClose
        title={t('Ajouter une vidéo depuis les réseaux sociaux')}
        visible={visible}
        onOk={close}
        onCancel={close}
        footer={[
          <Button onClick={close} key="close">
            {t('Fermer')}
          </Button>
        ]}
      >
        <AddVideo />
      </Modal>
    </>
  );
};

export default ModalAddVideo;
