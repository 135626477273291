import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useApiContext } from '../../providers/ApiProvider.js';
import { useAuthContext } from '../../providers/AuthProvider.js';
import { Spin, Button, Icon, PageHeader, Tag, Row, Col } from 'antd'
import { fileConvertSize, slugify } from '../../helpers.js'
import { useTranslation } from 'react-i18next';
import EditAccount from './EditAccount.js';
import GraphStorage from '../GraphStorage.js';


const AccountInfos = () => {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [needUpdate, setNeedUpdate] = useState(0);

  const [editing, setEditing] = useState(false);

  const params = useParams();

  useEffect(() => {
    fetchData();
  }, [needUpdate]);

  function fetchData() {
    apiFetchEntity('accounts', params.account_id, (hydra_response) => {
      setAccount(hydra_response)
      setLoading(false);
    });
  }


  function handleEditClose() {
    setNeedUpdate((new Date()).getTime())
    setEditing(false)
  }

  if (loading)
    return <div className="contenu-tab empty"><Icon type="loading" /></div>

  return (
    <Spin spinning={loading}>
      <div className="contenu-tab">
        <Row gutter={32}>
          <Col sm={24} md={12} lg={9} xl={9}>
            <PageHeader title={t('Informations générales')} ghost={false} />
            <div className='account-infos' >
              <div>
                <h3 className='info-line'><span>{account?.name}</span><Button size="small" icon='setting' onClick={() => setEditing(true)} /></h3>

                <div className='info-line'>
                  <span>Contrat :</span> <Tag color="#108ee9">{account?.contractType}</Tag>
                </div>
                {
                  account?.parentAccount?.name &&
                  <div className='info-line'>
                    <span>Compte parent:</span> <span>{account?.parentAccount?.name}</span>
                  </div>
                }
                <div className='info-line'>
                  <span>{t('Stockage')} :</span> <span>{fileConvertSize(account.storage.maxSize)}</span>
                </div>

                {
                  auth.roles.includes('ROLE_ADMIN') &&
                  <>
                    <div className='info-line'>
                      <span>{t('Video')} :</span> <Tag color={account.video ? '#87d068' : '#f50'}>{account.video ? 'on' : 'off'}</Tag>
                    </div>
                    <div className='info-line'>
                      <span>{t('Widget seul')} :</span> <Tag color={account?.widgetStandAlone ? '#87d068' : '#f50'}>{account?.widgetStandAlone ? 'on' : 'off'}</Tag>
                    </div>
                  </>
                }
                 <div className='info-line'>
                      <span>{t('Droit widget')} :</span> <Tag color={account?.widgetRights === 'EDIT' ? '#87d068' : '#108ee9'}>{account?.widgetRights === 'EDIT' ? 'Editer' : 'Visualiser'}</Tag>
                    </div>
              </div>
              <div>
                <h3>Upload</h3>
                <div className='info-line'>
                  <span>{t("Taille d'optimisation")} :</span> <span>{account?.sizeCropLg}px</span>
                </div>
                <div className='info-line'>
                  <span>{t('Renommage automatique')} :</span> <Tag color={account?.renameOn ? '#87d068' : '#f50'}>{account?.renameOn ? 'on' : 'off'}</Tag>
                </div>
                <div className='info-line'>
                  <span>{t('Prefix pour le renommage')} :</span> {account?.prefix || slugify(account?.name)}
                </div>
                <div className='info-line'>
                  <span>{t('Requête unsplash')} :</span> {account?.storage?.maxUnsplashRequest || 0}
                </div>
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={15} xl={15}>
            <PageHeader title={t('Stockage')} ghost={false} />
            <GraphStorage storage={account.storage} />
          </Col>
        </Row>
      </div>
      <EditAccount account={account} visible={editing} handleClose={handleEditClose} />
    </Spin>
  )
}

export default AccountInfos
