import React, { useState } from 'react'
import { Button, message } from 'antd';
import { useApiContext } from '../providers/ApiProvider.js';

export default function SuperDeleteAction({ selection, setSelection, rerender, accountId }) {

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiPostEntity } = apiDispatch;
  const [isLoading, setIsLoading] = useState(false)

  const openModal = () => {
    let r3 = prompt('Qu\'est ce qu\'il y a dans les pommes ?');
    if (r3.toLowerCase().trim().includes('pectine')) {
      deleteSelection();
      return message.success('Un genie est parmi nous')
    }
    message.info('Toujours lire les conditions ^^');
  }

  const deleteSelection = () => {

    let ids = selection.reduce((acc, current) => [...acc, current.id], [])
    setIsLoading(true)
    apiPostEntity('asset-bulk-remove', { ids: ids }, (response) => {

      setIsLoading(false)
      setSelection([])
      apiFetchSubResource('accounts', {id: accountId, withoutExt: true}, 'update', r => {
        rerender()
      })
    })
  };

  return (
    <Button
      className="no-mobile" style={{ marginRight: '1em' }}
      size="small" icon="delete" shape="round"
      loading={isLoading} onClick={openModal}
    >
      Suppression super admin
    </Button>
  )
}
