import React from "react";
import {Divider} from "antd";
import PrismCode from "../components/Prismjs";

export default function WidgetDocumentation() {
  return (
    <div className="contenu-tab">
      <h2 style={{textAlign: "center"}}>Hapidam Widget v1</h2>
      <div style={{maxWidth: "1100px", margin: "2em auto"}}>
        <p className="mb">
          Hapidam permet une intégration rapide et simple dans votre projet web.
          Il permet de proposer à vos utilisateurs d'utiliser ses médias
          disponible sur son compte Hapidam dans votre application. Cela vous
          permettra également de ne pas avoir a gérer les médias de vos
          utilisateurs directement.
        </p>

        <Divider orientation="left">Introduction</Divider>

        <p>
          Afin de pouvoir ouvrir le widget Hapidam, vous devrez disposer d'une{" "}
          <strong>clé privé</strong>. Cette clé privé est lié au compte Hapidam
          de l'utilisateur et permet d'obtenir un token public temporaire pour se connecter
          au widget.
        </p>
        <p>
          Pour ce faire, il faut faire une requête 'POST' sur l'url "
          <strong>https://api.hapidam.com/loginAPI</strong>". Nous vous
          conseillons de récupérer un token public en faisant une requête sur
          votre serveur et non dans le navigateur.
        </p>
        <p>Exemple de fonction php pour récupérer un token public :</p>
        <PrismCode
          code={`
function connect($privateKey) {
  //url hapidam permettant d'obtenir une clé public temporaire
  $_loginURL = 'https://api.hapidam.com/loginAPI';

  $data = array(
      'token' => $privateKey,
  );

  $crl = curl_init($_loginURL);
  curl_setopt($crl, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($crl, CURLINFO_HEADER_OUT, true);
  curl_setopt($crl, CURLOPT_POST, true);
  curl_setopt($crl, CURLOPT_POSTFIELDS, json_encode($data));
  curl_setopt($crl, CURLOPT_HTTPHEADER, array(
      'Content-Type: application/json'
  ));
  $result = curl_exec($crl);

  if ($result === false) {
      throw new Exception('Hapidam connect error: ' . curl_error($crl));
  } else {
      $response = json_decode($result);
      return $response;
  }
}

          `}
          language="php"
          plugins={["line-numbers"]}
        />

        <p>Réponse de l'api :</p>

        <PrismCode
          code={`
          //Clé public temporaire utilisable pour ouvrir le widget hapidam
[
  'success' => 1,
  'token' => 'afd6bdb9-6nnne5bd-58d4ec31-de7ba7a3-4ebfaf5e-1ded6b92-19b0d380-809d7cf3'
]
          `}
          language="php"
          plugins={["line-numbers"]}
        />

        <p>
          Hapidam dispose d'un script javascript à insérer sur votre site. Il
          permet, grace à une classe, de se connecter simplement au widget et de
          gérer son ouverture / fermeture, etc...
        </p>

        <PrismCode
          code={`<script src="https://hapidam.com/client.js"></script>`}
          language="js"
          plugins={["line-numbers"]}
        />

        <Divider orientation="left">Event listener</Divider>

        <p>
          Le client reponse sur un systeme d'event qui permet de récupérer les
          informations une foi la selection effectué
        </p>
        <p>
          L'évenement <strong>select</strong> permet de récupérer les éléments
          requis
        </p>

        <h4>Hapidam.on(event, callback)</h4>
        <p>Assigne une fonction de callback à un évènement</p>

        <PrismCode
          code={`HapidamClient.on('select', handleResponse);`}
          language="js"
          plugins={["line-numbers"]}
        />
        <h4>Hapidam.off(event, callback)</h4>
        <p>Retire une fonction de callback à un évènement</p>
        <PrismCode
          code={"HapidamClient.off('select', handleResponse);"}
          language="js"
          plugins={["line-numbers"]}
        />

        <Divider orientation="left">Initialiser Hapidam</Divider>

        <p>
          Afin de pouvoir utiliser les méthodes, il faut Initialiser la classe
          Hapidam.
        </p>

        <PrismCode
          code={
            "const HapidamClient = new Hapidam(token);\n//le token n'est pas requis lors de l'initialisation, mais sera indispensable pour utiliser le widget de sélection."
          }
          language="js"
          plugins={["line-numbers"]}
        />

        <h4>Hapidam.connect()</h4>
        <p>
          Si vous ne disposez pas de token public, vous pouvez utiliser la
          méthod <strong>connect()</strong> qui perment à l'utilisateur de
          sélectionner son compte hapidam
        </p>
        <PrismCode
          code={`const HapidamClient = new Hapidam();\n// on ajoute un listener pour récupérer la réponse du connect\nHapidamClient.on('select', handleResponse);
          \n//le connect ouvre une page de connexion et renverra une clé privé ainsi que le nom du compte sélectionné\nHapidamClient.connect();
          \n//exemple de réponse renvoyer au format json:\n{\ntoken: 'XXXX-XXXX-XXXX',\naccount: 'nom du compte'\n}
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <h4>Hapidam.setToken(publicToken)</h4>

        <p>
          Si vous avez initialiser Hapidam sans token ou que vous en avez un
          nouveau, alors pour pouvez le configurer avec{" "}
          <strong>setToken()</strong>
        </p>

        <PrismCode
          code={`
            const HapidamClient = new Hapidam();\n// on ajoute un listener pour récupérer la réponse du connect
            \nHapidamClient.on('select', handleResponse);\n// le connect ouvre une page de connexion et renverra une clé privé
            \n// ainsi que le nom du compte sélectionné\nHapidamClient.connect();
            \n//Configuration de la clé public a utiliser \nHapidamClient.setToken('XXXXX-XXXXX-XXXXX-XXXXX-XXXXX);
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <Divider orientation="left">Utiliser le widget</Divider>

        <h4>Hapidam.open()</h4>

        <p>
          Lorsque Hapidam est initialisé et que vous avec configuré le token
          public à utiliser alors vous pouver ouvrir le widget avec :{" "}
          <strong>open()</strong>
        </p>

        <PrismCode
          code={`
            // initialisation avec le token public\nconst HapidamClient = new Hapidam('XXXXX-XXXXX-XXXXX-XXXXX-XXXXX');
            \n// on ajoute un listener pour récupérer la réponse du widget\nHapidamClient.on('select', handleResponse);
            \n//On ouvre le widget\nHapidamClient.open();
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <p>Exemple de réponse lors de la sélection d'un visuel unique</p>
        <PrismCode
          code={`
{
  "uid": "7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e",
  "type": "image",
  "ctime": 1622020648,
  "mtime": 1622193210,
  "name": "le-golden-blue-rooftop",
  "description": "",
  "alt": {
    "fr": "Restaurant rooftop"
  },
  "legend": {
    "fr": "Vu du restaurant sur le toit"
  },
  "copyright": "",
  "tags": [
    "Rooftop"
  ],
  "file": {
    "name": "testhotel-rooftop-60ae122753c72986849864.jpeg",
    "ext": "jpeg",
    "mimetype": "image/jpeg",
    "hash": "7c9758d3edebd42149e7e0ddb116ad46afe95a3c2d5056c71044c752a97164b2",
    "size": 291338
  },
  "image": {
    "width": "2000",
    "height": "1123",
    "colors": [
      "#666666",
      "#999999",
      "#333333"
    ],
    "coi": [
      50,
      50
    ]
  },
  "display": {
    "native": {
      "original": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg",
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=lg",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=md",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=sm",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=th"
    },
    "square": {
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=1123&h=1123&mode=crop",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=505&h=505&mode=crop",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=253&h=253&mode=crop",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=56&h=56&mode=crop"
    },
    "custom": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?mode={mode}&w={width}&h={height}&coi={x},{y}"
  },
  "selectedFormat": null
}
  `}
          language="js"
          plugins={["line-numbers"]}
        />

        <p>
          Certain paramètres optionels peuvent être ajouté dans un objet (chacun de ces paramètres sont optionnels)
        </p>

        <PrismCode
          code={`
\n//On ouvre le widget
HapidamClient.open({
     switched: 'on', // autorise de switcher entre le widget de requete intelligente et le widget classique
     limit: 5, // nombre limite d'asset recuperable par la requete
     multiple: true // active la selection multiple dans le widget classique
});
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <p>Exemple de réponse lors d'une sélection multiple</p>

        <PrismCode
          code={`
[
{
  "uid": "7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e",
  "type": "image",
  "ctime": 1622020648,
  "mtime": 1622193210,
  "name": "le-golden-blue-rooftop",
  "description": "",
  "alt": {
    "fr": "Restaurant rooftop"
  },
  "legend": {
    "fr": "Vu du restaurant sur le toit"
  },
  "copyright": "",
  "tags": [
    "Rooftop"
  ],
  "file": {
    "name": "testhotel-rooftop-60ae122753c72986849864.jpeg",
    "ext": "jpeg",
    "mimetype": "image/jpeg",
    "hash": "7c9758d3edebd42149e7e0ddb116ad46afe95a3c2d5056c71044c752a97164b2",
    "size": 291338
  },
  "image": {
    "width": "2000",
    "height": "1123",
    "colors": [
      "#666666",
      "#999999",
      "#333333"
    ],
    "coi": [
      50,
      50
    ]
  },
  "display": {
    "native": {
      "original": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg",
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=lg",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=md",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=sm",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=th"
    },
    "square": {
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=1123&h=1123&mode=crop",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=505&h=505&mode=crop",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=253&h=253&mode=crop",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=56&h=56&mode=crop"
    },
    "custom": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?mode={mode}&w={width}&h={height}&coi={x},{y}"
  },
  "selectedFormat": null
},
{
  "uid": "7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e",
  "type": "image",
  "ctime": 1622020648,
  "mtime": 1622193210,
  "name": "le-golden-blue-rooftop",
  "description": "",
  "alt": {
    "fr": "Restaurant rooftop"
  },
  "legend": {
    "fr": "Vu du restaurant sur le toit"
  },
  "copyright": "",
  "tags": [
    "Rooftop"
  ],
  "file": {
    "name": "testhotel-rooftop-60ae122753c72986849864.jpeg",
    "ext": "jpeg",
    "mimetype": "image/jpeg",
    "hash": "7c9758d3edebd42149e7e0ddb116ad46afe95a3c2d5056c71044c752a97164b2",
    "size": 291338
  },
  "image": {
    "width": "2000",
    "height": "1123",
    "colors": [
      "#666666",
      "#999999",
      "#333333"
    ],
    "coi": [
      50,
      50
    ]
  },
  "display": {
    "native": {
      "original": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg",
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=lg",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=md",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=sm",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?size=th"
    },
    "square": {
      "lg": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=1123&h=1123&mode=crop",
      "md": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=505&h=505&mode=crop",
      "sm": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=253&h=253&mode=crop",
      "th": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?&w=56&h=56&mode=crop"
    },
    "custom": "https://api.hapidam.com/share/7b6151dc-e4f5-4a23-b6d2-eb0c3554ae9e/le-golden-blue-rooftop.jpeg?mode={mode}&w={width}&h={height}&coi={x},{y}"
  },
  "selectedFormat": null
}
]
  `}
          language="js"
          plugins={["line-numbers"]}
        />

        <h4>Hapidam.openBuilder()</h4>

        <p>
          Vous pouvez aussi utiliser notre système de requête intelligente directement en ouvrant directement avec cette
          fonction :
          <strong> openBuilder()</strong>
        </p>

        <PrismCode
          code={`
\n//On ouvre le widget
HapidamClient.openBuilder();
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <p>
          Certain paramètres optionels peuvent être ajouté (chacun de ces paramètres sont optionnels)
        </p>

        <PrismCode
          code={`
\n// Le premier parametre est pour ouvrir le widget avec une requete (lui passer le resultat d'une requete)
// Le second parmètre est le même que pour la fonction open
HapidamClient.openBuilder([{
    "id": 1649860810437,
    "query": [
      {
        "tags": [
          727
        ],
        "quantity": 5,
        "sort": "prefer_main",
        "duplicate": 1,
        "meta": {
          "folderName": "Spa",
          "tags": []
        }
      }
    ]
  }],
{
     switched: 'on', // autorise de switcher entre le widget de requete intelligente et le widget classique
     limit: 5, // nombre limite d'asset recuperable par la requete
     multiple: true // active la selection multiple dans le widget classique
});
            `}
          language="js"
          plugins={["line-numbers"]}
        />

        <p>Exemple de réponse lors de la validation d'une requête Hapidam</p>
        <PrismCode
          code={`
[
    {
        "tags": [
            4654
        ],
        "quantity": 5,
        "sort": "prefer_main",
        "duplicate": 1,
        "meta": {
            "folderName": "ludo",
            "tags": []
        }
    }
]
  `}
          language="js"
          plugins={["line-numbers"]}
        />

        <h4>Hapidam.close()</h4>

        <p>
          La méthode <strong>close()</strong> permet de fermer le widget
        </p>

        <PrismCode
          code={`
            // initialisation avec le token public\nconst HapidamClient = new Hapidam('XXXXX-XXXXX-XXXXX-XXXXX-XXXXX');
            \n// on ajoute un listener pour récupérer la réponse du widget\nHapidamClient.on('select', handleResponse);
            \n//On ouvre le widget\nHapidamClient.open();
            \n//On Retire l'évènement surveillé puis on ferme le widget\nHapidamClient.off('select', handleResponse);\nHapidamClient.close();
            `}
          language="js"
          plugins={["line-numbers"]}
        />


        <p>Exemple de réponse lors d'une sélection par le mode requête</p>


        <Divider orientation="left">Exemple complet Hapidam</Divider>

        <PrismCode
          code={`
const hapidamClient = new Hapidam()
//permet a l'utilisateur de se connecter lui même
function handleConnect() {
    // on ajoute le listener qui permettra de récupérer la réponse du widget
    hapidamClient.on('select', displayResults)
    hapidamClient.connect()
    //la reponse retournera une clé privé qui vous permettra de générer des tokens public pour utiliser le widget
}
function displayResults(response) {
    //on retire le listener
    hapidamClient.off('select', displayResults)
    console.log(response.data)
}
//La méthode open() fonctionne uniquement si un token public est configuré dans la classe
function handleOpen() {
    hapidamClient.on('select', displayResults)
    hapidamClient.open()
}
  `}
          language="js"
          plugins={["line-numbers"]}
        />

      </div>
    </div>
  );
}
