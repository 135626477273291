import { Button, Collapse } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAccountContext } from '../../providers/AccountProvider'
import TagsList from '../tag/TagsList'
import ColorFilter from '../filter/ColorFilter'
import DateFilter from '../filter/DateFilter'
import OrientationFilter from '../filter/OrientationFilter'
import StarFilter from '../filter/StarFilter'
import TypeFilter from '../filter/TypeFilter'

const { Panel } = Collapse

export default function SidebarFilters({ filtering, inDropDown = false }) {

    const { t } = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const { filter, clearFilterByTypes } = accountDispatch;

    function handleClear(e, type) {
        e.stopPropagation()
        clearFilterByTypes(type)
    }

    function displayExtra(type) {
        type = typeof type === 'string' ? [type] : type

        if (accountState.filters.find(item => type.includes(item.type))) {
            return <Button size="small" shape="round" icon="stop" onClick={e => handleClear(e, type)} />
        }
        return null
    }

    return (
        <div className={inDropDown ? '' : `sidebar-filters ${filtering && 'visible'} ${window.innerWidth > 1024 ? 'has-header' : 'no-header'}`}>
            <Collapse defaultActiveKey={['1', '2', '3', '4', '5', 'type']} bordered={false} style={{ backgroundColor: '#fff' }}>
                {
                    accountState.globalSearch && !accountState.accountActive ?
                        <></> :
                        <Panel header={<span><strong>{t('Tag')}</strong></span>} key="5" extra={displayExtra('tag')}>
                            <TagsList />
                        </Panel>

                }
                {
                    accountState.activeTab !== "5" && accountState.activeTab !== "6" &&
                    <Panel header={<span><strong>{t('Type')}</strong></span>} key="type" extra={displayExtra('orType')}>
                        <TypeFilter accountState={accountState} filter={filter} />
                    </Panel>
                }
                {
                    accountState.activeTab !== "5" &&
                    <Panel header={<span><strong>{t('Format')}</strong></span>} key="1" extra={displayExtra('orientation')}>
                        <OrientationFilter accountState={accountState} filter={filter} />
                    </Panel>
                }
                <Panel header={<span><strong>{t('Note')}</strong></span>} key="2" extra={displayExtra('stars')}>
                    <StarFilter accountState={accountState} filter={filter} />
                </Panel>
                {
                    accountState.activeTab == 2 &&
                    <Panel header={<span><strong>{t('Couleur')}</strong></span>} key="3" extra={displayExtra('color')}>
                        <ColorFilter accountState={accountState} filter={filter} />
                    </Panel>
                }
                <Panel header={<span><strong>{t('Date')}</strong></span>} key="4" extra={displayExtra(['date', 'dateBetween'])}>
                    <DateFilter filter={filter} accountState={accountState} />
                </Panel>
            </Collapse>
        </div>
    )
}
