import React, { useState } from 'react'
import moment from 'moment'
import { Button, DatePicker, Dropdown, Form, Icon, Input, Menu, message, Modal, Radio, Select, Spin, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useApiContext } from '../../../providers/ApiProvider'
import { copyLink } from '../../../helpers'

const entrypoint = process.env.ENTRYPOINT;

function CartShare({ form, cart }) {

  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { getFieldDecorator, validateFields } = form;
  const [loading, setLoading] = useState(false)
  const [isShared, setIsShared] = useState(cart?.isShared)

  const cartLink = `${entrypoint}/cart/${cart.uuid}`;

  function handleSave() {

    validateFields(async (err, values) => {
      if (err) {
        console.log('err', err)
        return;
      }
      setLoading(true)
      await apiUpdateEntity('tags', cart.id, values, (response) => {

        if (response['@type'] === 'hydra:Error') {
          message.warning(t('Une erreur s\'est produite lors de la mise à jour du panier'))
          // return setNeedUpdate(needUpdate + 1);
        } else {
          message.success('Panier modifié avec succès')
          setIsShared(values?.isShared)
        }
        setLoading(false)
      })
      console.log('Received values of form: ', values);
    });
  }

  function handleMenuClick({ item, key }) {
    if (key === 'settings') return setVisible(true)
    return window.open(cartLink, "_blank")
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="settings">{t('Réglages')}</Menu.Item>
      <Menu.Item key="open"><Icon type={isShared ? 'eye' : 'eye-invisible'} style={{ color: isShared ? 'green' : '' }} />{t('Ouvrir la page')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button size="small" className="mr"><Icon type="link" />{t('Partage')}</Button>
      </Dropdown>
      <Modal visible={visible} title={t('Partage du panier')}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="close" onClick={() => setVisible(false)}>{t('Fermer')}</Button>,
          <Button loading={loading} type="primary" key="save" onClick={handleSave}>{t('Enregistrer')}</Button>
        ]}>
        <Spin spinning={loading} >
          <Form >
            <Form.Item label={`${t("Activer le partage du panier")} :`}>
              {getFieldDecorator('isShared', { valuePropName: 'checked', initialValue: cart?.isShared })(<Switch />)}
            </Form.Item>
            <Form.Item label={`${t("Désactiver l'accès le")} :`}>
              {getFieldDecorator('deletedAt', {
                initialValue: cart?.deletedAt ? moment(cart?.deletedAt) : undefined,
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item label={t("Qualité maximum du téléchargement") + ' :'}>
              {getFieldDecorator('maxDownloadSize', {
                initialValue: cart?.maxDownloadSize || null,
              })(
                <Select>
                  <Select.Option value={null}>Original</Select.Option>
                  <Select.Option value={'lg'}>lg</Select.Option>
                  <Select.Option value={'md'}>md</Select.Option>
                  <Select.Option value={'sm'}>sm</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Code d'accès") + ' :'}>
              {getFieldDecorator('password', {
                initialValue: cart?.password,
              })(
                <Input allowClear placeholder={t('L\'accès ne sera pas restraint si vide')} />
              )}
            </Form.Item>
            <Form.Item label={`${t("Mode d'affichage par défaut")} :`}>
              {getFieldDecorator('shareView', { initialValue: cart?.shareView ?? 'assets' })(
                <Radio.Group buttonStyle="solid">
                  <Radio value="assets">{t('Médias')}</Radio>
                  <Radio value="directory">{t('Dossiers')}</Radio>
                </Radio.Group>)}
            </Form.Item>
            <p>
              {t('Lien d\'accès')} :
            </p>
            <p><Link to={`/cart/${cart.uuid}`} target="_blank">{cartLink}</Link><Icon type="copy" className="ml pointer" onClick={() => copyLink(cartLink)} title={t('Copier le lien')} /></p>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}


export default Form.create()(CartShare)