import React from 'react';
import {Button, Dropdown, Icon, Menu} from 'antd';
import {useTranslation} from "react-i18next";

const AccountFilterDropdown = ({accountState, setActiveAccount, dropdown = true}) => {

  const {t} = useTranslation();
  const handleClick = (account) => {
    setActiveAccount(account)
  }

  const accounts =
      <Menu style={{minWidth: '150'}}
            selectedKeys={accountState.accountActive ? [accountState.accountActive['@id']] : ['reset']}>
        <Menu.Item key='reset' onClick={({key}) => handleClick(null)}>Voir tout</Menu.Item>
        <Menu.Item key={accountState.account['@id']} onClick={({key}) => handleClick(accountState.account)}>{accountState.account.name}</Menu.Item>
        {
          accountState.account.childAccount.map((child, id) => (
            <Menu.Item className="drop-list pl-20" key={child['@id']} onClick={({key}) => handleClick(child)}>
              {child.name}
            </Menu.Item>
          ))
        }
      </Menu>

  if (!dropdown) {
    return accounts
  }

  return (
    <Dropdown overlay={accounts} trigger={['click']}>
      <Button>
        {t('Comptes')} <Icon type="down"/>
      </Button>
    </Dropdown>
  );
};

export default AccountFilterDropdown;
