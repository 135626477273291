import React from "react"
import YoutubeChannel from "./YoutubeChannel";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../providers/AccountProvider";
import DeleteConfirmBtn from "../../button/DeleteConfirmBtn";

export default function YoutubeChannels({ channels }) {

  const { t } = useTranslation();
  const [accountState] = useAccountContext();

  return (
    <>
      {channels.map((channel, id) => (
        <YoutubeChannel
          key={id}
          channelId={channel.networkId}
          deleteBtn={accountState.admin &&
            <DeleteConfirmBtn
              title={t('Êtes-vous sur de vouloir supprimer cette chaîne ?')}
              endpoint="social_networks"
              id={channel.id}
            />}
        />
      ))}
    </>
  )
}
