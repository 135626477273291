import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Modal} from 'antd'
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../providers/AccountProvider";
import TemplateColumn from "../TemplateColumn";
import Placeholder from "../../assets/img_placeholder.svg";
import DownloadSelectionDropdown from "../dropdown/DownloadSelectionDropdown";
import ModalSortByAccount from "./ModalSortByAccount";
import ModalUserPermission from "./ModalUserPermission";
import ModalFreemium from "./ModalFreemium";

export default function ModalGroupSelection({setIsVisible, apiFetchCollection, selection, setSelection}) {

  const {t} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const {setFreemiumOptions} = accountDispatch;
  const [initialSelection, setInitialSelection] = useState([...accountState.selection])
  const [isSortModalVisible, setIsSortModalVisible] = useState(false)
  const [isShareVisible, setIsShareVisible] = useState(false)
  const [actions, setActions] = useState([])

  useEffect(() => {
    setActions(getActions)
  }, [accountState.selection])

  const onChange = (selected, asset) => {
    console.log(selection)
    setSelection(prev => {
      const next = new Set(prev);
      if (selected) {
        next.delete(asset.id)
    } else {
      next.add(asset.id)
    }
      return next;
    })
  }

  function displayImage(item, key) {
    let media = item.currentMedia ? item.medias.filter(media => media.id === item.currentMedia)[0] : item.medias[0];
    let selected = selection.has(item.id);
    let className = media && media.mimeType === 'image/png' ? 'png_transparent' : null;

    return (
      <div
        key={key}
        className="img_container pointer"
        onClick={(e) => onChange(selected, item)}
        style={
          selected ?
            {boxShadow: "orange 0 0 10px"} : null
        }
      >
        <Checkbox
          style={{position: "absolute", top: 10, right: 10}}
          checked={selected}
        />
        <img
          className={className}
          src={media != null && media.extraUrl ? media.extraUrl.sm.url : Placeholder}
        />
      </div>
    )
  }

  const handleClose = () => {
    setIsVisible(false)
  }

  const getActions = () => {
    let actions = [
      <Button key={"cancel"} onClick={handleClose}>
        {t('Fermer')}
      </Button>
    ]

    if (selection.size) {
      actions = [...actions, ...[
        <Button key="move" onClick={() => setIsSortModalVisible(true)}>{t('Déplacer vers un compte')}</Button>,
        <DownloadSelectionDropdown key="download" apiFetchCollection={apiFetchCollection}/>,
        <Button key="share" onClick={openModalShare}>
          {t('Partager')}
        </Button>]]
    }
    return actions
  }

  function openModalShare() {
    if (accountState.account.contractType === 'freemium')
      return setFreemiumOptions({visible: true, type: 'share'})

    setIsShareVisible(true);
  }


  return (
    <Modal
      width="80vw"
      centered
      visible={true}
      footer={actions}
      onCancel={handleClose}
      bodyStyle={{height: "70vh", overflow: "auto", position: "relative"}}
      title={"Ma Sélection " + ' : ' + selection.size + ' ' + (selection.size > 1 ? 'éléments' : 'élément')}
    >

      <div>
        <TemplateColumn images={initialSelection} loading={false} displayImage={displayImage}/>
      </div>
      {
        isSortModalVisible ?
          <ModalSortByAccount
            visible={isSortModalVisible}
            setVisible={setIsSortModalVisible}
            accountState={accountState}
            itemsChecked={accountState.selection}
            setItemsChecked={setSelection}
          />
          : null
      }
      {
        isShareVisible ?
          <ModalUserPermission
            visible={isShareVisible}
            setVisible={setIsShareVisible}
            selection={accountState.selection}
          />
          : null
      }
      <ModalFreemium/>
    </Modal>
  )
}
