import React, { useEffect, useState } from "react";
import { useApiContext } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom"
import { Drawer, Typography, Button, Popconfirm, Spin, Empty, Upload, message, Divider, Popover } from "antd";
import { downloadResource, fileConvertSize, stringDate } from "../../helpers"
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";
import MyPagination from "../MyPagination";
import ActiveFilterLight from "../ActiveFilterLight";
import Pdf from "../Svg/Pdf";
import Excel from "../Svg/Excel";
import Word from "../Svg/Word";
import PowerPoint from "../Svg/PowerPoint";
import Csv from "../Svg/Csv";
import Zip from "../Svg/Zip";
import Publisher from "../Svg/Publisher";

const { Paragraph } = Typography;
const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function Documents() {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiUpdateEntity, apiDeleteEntity, apiPostEntity } = apiDispatch;
  const [accountState] = useAccountContext();
  const params = useParams();

  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0)
  const isAdmin = accountState.admin;
  const itemsPerPage = 30;

  const [visible, setVisible] = useState(false);
  const [doc, setDoc] = useState({});

  const [file, setFile] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const pdf = <Pdf fill="#bb0706" width={100} />;
  const word = <Word fill="#25559a" width={100} />;
  const excel = <Excel fill="#007334" width={100} />;
  const ppt = <PowerPoint fill="#cb4525" width={100} />;
  const csv = <Csv fill="#007334" width={100} />;
  const zip = <Zip fill="rgba(0,0,0,0.75)" width={100} />;
  const pub = <Publisher fill="#077467" width={100} height={133} />;

  useEffect(() => {
    setLoading(true)
    fetch()
  }, [needUpdate, page, accountState.rerender])

  function fetch() {
    const data = {
      page: page,
      itemsPerPage: itemsPerPage,
      filters: [
        {
          name: 'account',
          value: accountState.globalSearch && accountState.accountActive ? accountState.accountActive.id : params.account_id
        },
        {
          name: 'type',
          value: 2
        }
      ]
    }
    apiFetchCollection("assets/get_docs", data, response => {
      const array = response['hydra:member'].map(item => {
        item.media = item.medias.find(media => media.id === item.currentMedia);
        return item;
      })

      setDocs(array)
      setTotalItem(response["hydra:totalItems"])
      setLoading(false)
    })
  }
  console.log(doc)
  function docAction(action) {
    switch (action) {
      case 'download':
        downloadResource(doc.media.contentUrl, doc.usageName);
        break;
      case 'see':
        window.open(`${shareLink}${doc.uuid}/${encodeURIComponent(doc.usageName)}`, '_blank');
        break;
      case 'remove':
        removeAsset(doc.id);
        break;
    }
  }

  function removeAsset(id) {
    apiDeleteEntity('assets', id, response => {
      setNeedUpdate(needUpdate + 1)
      close()
    })
  }

  function openDrawer(doc) {
    setDoc(doc)
    setVisible(true)
  }

  function updateData(key, value) {
    const data = {
      [key]: value
    }
    apiUpdateEntity("assets", doc.id, data, response => {
      doc[key] = value
      setNeedUpdate(needUpdate + 1)
    })
  }

  const paginate = (
    <MyPagination
      current={page}
      totalItem={totalItem}
      itemPerPage={itemsPerPage}
      changePage={setPage}
      bgColor="tansparent"
    />
  )

  function getIcon(ext) {
    switch (ext) {
      case "pdf":
        return pdf;
      case "doc":
        return word;
      case "docx":
        return word;
      case "xls":
        return excel;
      case "xlsx":
        return excel;
      case "ppt":
        return ppt;
      case "pptx":
        return ppt;
      case "csv":
        return csv;
      case "zip":
        return zip;
      case "pub":
        return pub;
      default:
        return excel;
    }
  }

  function beforeUpload(file) {
    setFile(file);
    return false;
  }

  function handleUpload() {
    setLoadingUpload(true);

    let formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountState.account.id);
    formData.append('action', 'patch');
    formData.append('assetId', doc.id);

    apiPostEntity("media_objects", formData, response => {
      setLoadingUpload(false);

      if (response['@type'] === "hydra:Error")
        return message.error(response['hydra:description']);

      purgeCacheCloudflare([`${shareLink}${doc.uuid}/${doc.usageName}`])
      setFile(null);
      message.success(t('Fichier mit à jour avec succès'));
      setNeedUpdate(needUpdate + 1);
      close()
    });
  }

  function close() {
    setVisible(false);
    setFile(null);
    setDoc({});
  }

  function seeLastVersion(version) {
    window.open(doc.medias.find(media => media.id === version).contentUrl, '_blank');
  }

  function rollBack(version) {
    apiUpdateEntity("assets", doc.id, { currentMedia: version }, response => {
      setNeedUpdate(needUpdate + 1)
      setDoc(docs.find(item => item.id === doc.id))

      purgeCacheCloudflare([`${shareLink}${doc.uuid}/${doc.usageName}`])
    })
  }

  function purgeCacheCloudflare(files) {
    apiPostEntity('purge-cache-cloudflare', { files: files }, response => {
      // console.log(response)
    })
  }

  return (
    <>
      <ActiveFilterLight activeFilter="documents" extra={paginate} />
      <Spin spinning={loading}>
        <div className="assets-list">
          <div className="flex wrap" style={{ padding: 20 }}>
            {totalItem ?
              docs.map(doc => (
                <div
                  key={doc.id}
                  className="document_container"
                >
                  <div
                    className="document"
                    onClick={() => openDrawer(doc)}
                  >
                    <div className="iconDoc">
                      {getIcon(doc.media.contentUrl.split('.').pop())}
                    </div>
                    <div className="nameDoc">
                      <Paragraph title={doc.usageName} ellipsis={{ rows: 2 }}>{doc.usageName}</Paragraph>
                    </div>
                  </div>
                </div>
              ))
              : <Empty style={{ margin: "50px auto" }} description={t("Aucun document")} />}
          </div>
        </div>
      </Spin>
      <Drawer
        title={
          <Paragraph
            editable={isAdmin ? { onChange: (str) => updateData('usageName', str) } : null}
          >
            {doc.usageName}
          </Paragraph>
        }
        placement="right"
        visible={visible}
        width={window.innerWidth > 768 ? 400 : ''}
        onClose={close}
      >
        {visible ?
          <div>
            <div>
              <p>
                <strong>{t('Description')} :</strong>
              </p>
              <Paragraph
                editable={isAdmin ? { onChange: (str) => updateData('description', str) } : null}
              >
                {doc.description}
              </Paragraph>
              <p><strong>{t('Poids')} :</strong> {fileConvertSize(doc.media.fileSize)}</p>
              <p><strong>{t('Ajouté le')}</strong> : {stringDate(doc.created)}</p>
            </div>
            <div className="flex mt-50 wrap">
              <Button
                type="primary"
                icon="download"
                onClick={() => docAction('download')}
                className="mr-5"
              >
                {t('Télécharger')}
              </Button>
              {
                doc.media.mimeType === "application/pdf" ?
                  <Button
                    type="primary"
                    icon="eye"
                    onClick={() => docAction('see')}
                    className="mr-5"
                  >
                    {t('Voir')}
                  </Button>
                  : null
              }
              {isAdmin ?
                <Popconfirm
                  title={t('Êtes-vous sur de vouloir supprimer ce document ?')}
                  onConfirm={() => docAction('remove')}
                  okText={t('Oui')}
                  cancelText={t('Non')}
                >
                  <Button
                    type="danger"
                    icon="delete"
                  >
                    {t('Supprimer')}
                  </Button>
                </Popconfirm>
                : null}
            </div>


            <div className="mt-50">
              <Upload
                beforeUpload={beforeUpload}
                accept={`.${doc.media.contentUrl.split('.').pop()}`}
              >
                <Button icon="upload" type="primary">{t('Mettre à jour le fichier')}</Button>
              </Upload>

              {file &&
                <Button
                  type="primary" onClick={handleUpload}
                  disabled={!file}
                  loading={loadingUpload}
                >
                  {t('Valider la mise à jour')}
                </Button>}
            </div>

            <Divider>{t('Historique')}</Divider>
            {
              doc.historicAssets.slice(0).reverse().map((histo, id) => (
                <Popover
                  key={id}
                  trigger="click"
                  content={(
                    <div>
                      <Button
                        type="primary" icon="eye"
                        onClick={() => seeLastVersion(histo.newMedia)}
                      >
                        {t('Voir')}
                      </Button>
                      <Button
                        type="primary" icon="rollback"
                        onClick={() => rollBack(histo.newMedia)}
                      >
                        {t('Revenir a cette version')}
                      </Button>
                    </div>
                  )}
                >
                  <a
                    key={id}
                    style={{ display: 'block', margin: '5px 0' }}
                    disabled={doc.currentMedia === histo.newMedia}
                  >
                    {t('Version')} {doc.currentMedia === histo.newMedia ? doc.historicAssets.length - id + ' ' + t('(version actuelle)') : doc.historicAssets.length - id} :
                    &nbsp;{doc.medias.find(media => media.id === histo.newMedia).originalName} le {stringDate(histo.created)}{histo.user ? ', ' + t('Par') + ' ' + histo.user : null}
                  </a>
                </Popover>
              ))
            }

          </div>
          : null}
      </Drawer>
    </>
  )
}
