import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Collapse, Form, Icon, message, Modal, Radio, Spin, } from 'antd'
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from "../../providers/ApiProvider";
import { useTranslation } from "react-i18next";
import TagsTreeSelect from "../tag/TagTreeSelect";
import FreemiumFallback from "../FreemiumFallback";
import { useAuthContext } from "../../providers/AuthProvider";

const { Panel } = Collapse;

const downloadOptionPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

function ModalDownloadAssets({ form, visible, closeModal, folder }) {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiDownloadSubResource, apiDownloadCollection, apiFetchCollection } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { addNotification } = accountDispatch;
  const [modalLoading, setModalLoading] = useState(false)
  const [selectedTag, setSelectedTag] = useState(undefined)
  const [tags, setTags] = useState([])
  const isMounted = useRef(null);
  const [downloadAll, setDownloadAll] = useState(false);
  const [zipLoading, setZipLoading] = useState(false)
  const [quality, setQuality] = useState('lg')

  const { getFieldDecorator, getFieldValue, setFields } = form;

  useEffect(() => {
    if (folder || !accountState.admin) {
      setSelectedTag({ id: folder });
      setDownloadAll(false)
    } else {
      setSelectedTag(undefined);
      setDownloadAll(true)
    }
  }, [folder])

  useEffect(() => {
    isMounted.current = true;
    fetchFolders()
    return () => {
      isMounted.current = false;
    }
  }, [accountState.rerender, accountState.account])

  const handleClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const fetchFolders = () => {
    setModalLoading(true)
    const params = {
      filters: [
        { name: 'type', value: 1 },
        { name: 'pagination', value: 'false' },
        { name: 'account', value: accountState.account.id }
      ]
    }
    apiFetchCollection('get_folders', params, (response) => {
      if (isMounted.current) {
        setTags(getValidTags(response))
        setModalLoading(false)
      }
    })
  }
  // Récupérer uniquement les tags qui ont des assets ou ceux dont un enfant à des assets
  const getValidTags = (tags) => {
    return tags.reduce((accumulator, current) => {
      let children = getValidTags(current.childTags)
      current.childTags = children
      if (current.total_assets_count > 0) {
        return [...accumulator, current];
      }
      return accumulator;
    }, [])
  }

  const handleDownload = () => {
    let targetAll = getFieldValue('target');
    if (!targetAll && !selectedTag) {
      return message.error(t('Vous devez sélectionner un dossier à télécharger'))
    }
    setZipLoading(true);
    let params = {
      id: !targetAll ? selectedTag?.id : null,
      filters: [
        { name: 'account', value: accountState.account.id },
        { name: 'download', value: true },
        { name: 'quality', value: getFieldValue('quality') },
        { name: 'includeTrash', value: targetAll ? getFieldValue('includeTrash') : false },
        { name: 'includeObsolete', value: getFieldValue('includeObsolete') },
        { name: 'webp', value: getFieldValue('webp') }
      ]
    }
    // return console.log('params', params)
    if (targetAll) {
      downloadZip(params)
    } else {
      downloadFolder(params);
    }
  }

  const downloadZip = (params) => {
    apiDownloadCollection('tags/taglist', params, (response) => {
      if (response.success) {
        // console.log(response);
        response.id = 'zipDownloadAll';
        window.location = response.link;
        makeNotif(response)
      } else {
        message.error(t('Il y a eu un problème, si il persiste contacter un administrateur'))
      }
      setZipLoading(false);
    })
  };

  const downloadFolder = (params) => {
    apiDownloadSubResource('tags', params, 'download', (response) => {
      if (response.success) {
        response.id = 'zip_' + params.id

        response.name = tags.filter(item => item.id === params.id)[0]?.name || ''
        window.location = response.link;

        makeNotif(response)
      } else {
        message.error(t('Il y a eu un problème, si il persiste contacter un administrateur'))
      }
      setZipLoading(false);
    })
  }

  const makeNotif = (result) => {
    const notif = {
      id: result.id
    };

    if (result.success) {
      notif.type = 'success';
      notif.message = t('Votre zip') + ' ' + (result.name ? t("Du dossier").toLowerCase() + " '" + result.name + "' " : "") + t('Est prêt').toLowerCase();
      notif.description =
        <a href={result.link}>{t('Cliquez ici pour le télécharger')} <Icon type="cloud-download" /></a>;
      addNotification(notif);
    } else {
      notif.type = 'error';
      notif.message = t('Le zip n\'a pas pu être crée');
      notif.description = result.error;
      addNotification(notif);
    }
  }

  function handleTagSelect(id) {
    let tagList = getFlatTags(tags)
    console.log('tagList', tagList)
    setFields({
      'quality': { value: 'lg' }
    })
    setQuality('lg')
    setSelectedTag(tagList.find(item => item.id === id))
  }

  function getFlatTags(tagsArray) {
    return tagsArray.reduce((accumulator, current) => {
      let children = getFlatTags(current.childTags) || []
      return [...accumulator, current, ...children];
    }, [])
  }


  return (
    <Modal
      closable={true}
      centered
      destroyOnClose={true}
      onCancel={handleClose}
      title={t("Téléchargement")}
      visible={visible}
      footer={[
        <Button key="back" onClick={handleClose}>
          {t('Annuler')}
        </Button>,
        <Button key="zip" icon="download" loading={zipLoading} type={"primary"}
          onClick={handleDownload}>{t('Générer un zip')}</Button>
      ]}
    >
      {
        accountState.account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN') ?
          <FreemiumFallback /> :
          <Spin spinning={modalLoading}>
            <Form layout="vertical">
              <Form.Item label={t('Que voulez-vous télécharger') + ' ?'}>
                {getFieldDecorator("target", {
                  initialValue: downloadAll
                })(
                  <Radio.Group name="target" onChange={(e) => setDownloadAll(e.target.value)}>
                    <Radio value={true}>{t('Tout')}</Radio>
                    <Radio value={false}>{t('Un dossier')}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {
                downloadAll ? null :
                  <Form.Item label={t('Dossier')}>
                    <TagsTreeSelect tags={tags} currentValue={selectedTag?.id} onSelect={handleTagSelect} />
                  </Form.Item>
              }
              <Collapse bordered={false}>
                <Panel header={t('Plus d\'options')} key="1" style={downloadOptionPanel}>
                  <Form.Item label={t('Format') + ' :'}>
                    {getFieldDecorator("quality", {
                      initialValue: 'lg'
                    })(
                      <Radio.Group name="quality" onChange={(e) => setQuality(e.target.value)}>
                        <Radio value={'original'}>{t('Original')}</Radio>
                        <Radio value={'lg'}>{t('Large')}</Radio>
                        {
                          selectedTag && selectedTag?.croppingProfile?.cropSizes?.length > 0 &&
                          <Radio value={'profile'}>{t('Le profil')}: "{selectedTag.croppingProfile.name}"</Radio>
                        }
                      </Radio.Group>
                    )}

                  </Form.Item>
                  {quality === 'profile' ? null :
                    <Form.Item label={t('Webp') + ' :'}>
                      {getFieldDecorator("webp", {
                        initialValue: false
                      })(
                        <Radio.Group name="webp">
                          <Radio value={true}>{t('Oui')}</Radio>
                          <Radio value={false}>{t('Non')}</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  }
                  {downloadAll && accountState.admin ?
                    <Form.Item label={t('Inclure la corbeille') + ' ?'}>
                      {getFieldDecorator("includeTrash", {
                        initialValue: false
                      })(
                        <Radio.Group name="includeTrash">
                          <Radio value={true}>{t('Oui')}</Radio>
                          <Radio value={false}>{t('Non')}</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    : null}
                  {
                    accountState.admin ?
                      <Form.Item label={t('Inclure les photos obsolètes') + ' ?'}>
                        {getFieldDecorator("includeObsolete", {
                          initialValue: false
                        })(
                          <Radio.Group name="includeObsolete">
                            <Radio value={true}>{t('Oui')}</Radio>
                            <Radio value={false}>{t('Non')}</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                      : null
                  }
                </Panel>
              </Collapse>

            </Form>
          </Spin>
      }
    </Modal>
  )
}

export default Form.create()(ModalDownloadAssets)
