import React from "react";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../providers/AccountProvider";

export default function ActiveFilterLight({activeFilter, extra}) {

  const {t} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const {resetFilter} = accountDispatch;

  const getBase = () => {
    if (accountState.group && accountState.accountActive !== null && accountState.globalSearch) {
         return accountState.accountActive.name + ' > ';
    }
  }

  return (
    <div className="tab-topbar">
      <div className="pointer" onClick={resetFilter}>{getBase()} {t(activeFilter)}</div>
      {extra}
    </div>
  )
}
