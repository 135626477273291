import React, {useState, useEffect} from 'react'
import {Anchor} from 'antd'
import Image1 from "../../assets/support/Picture1.jpeg"
import Image2 from "../../assets/support/Picture2.jpeg"
import Image3 from "../../assets/support/Picture3.jpeg"
import Image4 from "../../assets/support/Picture4.jpeg"
import Image5 from "../../assets/support/Picture5.jpeg"
import Image6 from "../../assets/support/Picture6.jpeg"
import Image7 from "../../assets/support/Picture7.jpeg"
import Image8 from "../../assets/support/Picture8.jpeg"
import Image9 from "../../assets/support/Picture9.jpeg"
import Image10 from "../../assets/support/Picture10.jpeg"
import Image11 from "../../assets/support/Picture11.jpeg"
import Image12 from "../../assets/support/Picture12.jpeg"
import Image13 from "../../assets/support/Picture13.jpeg"
import Image14 from "../../assets/support/Picture14.jpeg"
import Image18 from "../../assets/support/Picture18.jpeg"
import Image19 from "../../assets/support/Picture19.jpeg"
import {useTranslation} from "react-i18next";

const { Link } = Anchor;

export default function Help() {

  const {t, i18n} = useTranslation();
  const [height, setHeight] = useState(null)

	useEffect(() => {
	    let vh = window.innerHeight;
		let top = document.getElementById('anchor_list').getBoundingClientRect().top
		setHeight(vh-top-50)
	}, [])

	function test(e, link){
		e.preventDefault()
	}

	return (
		<div className="contenu-tab">
			<div className="support">
				<h1>HapiDam - {t('Optimiser et Renommer ses visuels')}</h1>
				<div style={{display: 'flex'}}>
					<div className="anchor_container">
						    <Anchor affix={false} onClick={test} showInkInFixed={true} getContainer={() => document.getElementById('anchor_list')}>
							    <Link href="#ajout_photos" title={t('Ajout de photos')} />
							    <Link href="#def_arbo" title={t('Définition de l`\'arborescence')} />
							    <Link href="#classer_photos" title={t('Classer les photos')} />
							    <Link href="#visu_media" title={t('Visualiser la photo après le tri') + " :"}/>
							    <Link href="#tag_sort" title={t('Classification par tag')} />
							    <Link href="#social_network" title={t('Réseaux sociaux')} />
							    <Link href="#advanced" title={t('Avancé')} />
							    <Link href="#seo" title={t('Seo').toUpperCase()} />
							    <Link href="#update_media" title={t('Mettre à jour')} />
							    <Link href="#edit_media" title={t('Editer la photo')} />
							</Anchor>
					</div>
					<div className="paragraph" id="anchor_list" style={{overflowY: 'auto', height: `${height}px`}}>
							<div id="ajout_photos" className="block_text">
								<h2>{t('Ajout de photos :')}</h2>
								<div className="img_container_support">
									<img src={Image1} />
								</div>
								<p>{t('Puis faire glisser les visuels ou bien cliquer sur la popin qui s’affiche pour aller chercher directement les visuels dans le dossier de l’ordinateur')}</p>
								<div className="img_container_support">
									<img src={Image2} />
								</div>
								<p>{t('Les images téléchargées seront affichées dans l’onglet « A trier »')}</p>
							</div>

							<div id="def_arbo" className="block_text">
								<h2>{t('Définition de l\'arborescence')} :</h2>
								<p>{t('Avant de trier les photos, il faut déterminer la catégorie de chacune des photos (chambres, extérieurs, séminaires etc..)')}</p>
								<div className="img_container_support">
									<img src={Image3} />
								</div>
								<p>{t('Cliquer sur le rouage, une popin va apparaître')} :</p>
								<ol>
									<li>{t('Rentrer le nom de la catégorie')}</li>
									<li>{t('Le nom apparaitra dans la liste, si comme dans le cas ci-dessous vous avez besoin de créer une sous-catégorie : confort doit aller dans le dossier chambre. Il suffit de faire glisser ce dossier dans le dossier chambre')}</li>
									<li>{t('Cliquez sur enregistrer')}</li>
								</ol>
								<div className="img_container_support">
									<img src={Image4} />
								</div>
							</div>

							<div id="classer_photos" className="block_text">
								<h2>{t('Classer les photos :')}</h2>
								<p>{t('Maintenant que l’arborescence est faite et que les photos sont dans le logiciel, elles peuvent être classées et renommées. Il y a deux méthodes pour cela :')}</p>
								<h3>{t('1/ Drag and drop')}</h3>
								<p>{t('Pour cela sélectionner la photo en cliquant dessus (plusieurs photos peuvent être sélectionnées) et la faire glisser dans le dossier correspondant sur la gauche de l’écran :')}</p>
								<div className="img_container_support">
									<img src={Image5} />
								</div>
								<h3>{t('2/ Bouton trier')}</h3>
								<p>{t('Sélectionner la/les photos, un bouton filtre va apparaître :')}</p>
								<div className="img_container_support">
									<img src={Image6} />
								</div>
								<p>{t('Après avoir cliqué sur ce bouton une popin va s’afficher :')}</p>
								<div className="img_container_support">
									<img src={Image7} />
								</div>
								<p>{t('Il suffira de cliquer sur la catégorie à laquelle correspond la photo.')}</p>
							</div>

							<div id="visu_media" className="block_text">
								<h2>{t('Visualiser la photo après le tri :')}</h2>
								<p>{t('Pour cela aller dans Médias > Sélectionner le dossier voulu Les photos apparaissent renommées et resizées.')}</p>
								<p>{t('Les nouvelles caractéristiques de la photo sont disponibles en cliquant sur le rouage :')}</p>
								<div className="img_container_support">
									<img src={Image8} />
								</div>
								<p>{t('Le cœur permet de signaler la photo comme favoris et le caddie mettra la photo dans l’onglet panier')}</p>
								<p>{t('Après avoir cliqué sur le rouage cette fenêtre s’ouvrira :')}</p>
								<div className="img_container_support">
									<img src={Image9} />
								</div>
								<p>{t('1/ Photo optimisée possibilité de la télécharger, le nom de la photo a changé. Il est maintenant optimisé.')}</p>
								<p>{t('2/ Caractéristique de la photo d’origine, possibilité de télécharger')}</p>
							</div>

							<div id="tag_sort" className="block_text">
								<h2>{t('Classification par tag')}</h2>
								<p>{t('Possibilité de créer un tag pour ensuite utiliser le filtre. La reconnaissance automatique permettra de suggérer des tags. Les propositions seront pré enregistrés dans l’administration.')}</p>
								<div className="img_container_support">
									<img src={Image10} />
								</div>
								<p>{t('Cette fonctionnalité pourra être utilisée ensuite dans l’onglet principal pour trier par tag :')}</p>
								<div className="img_container_support">
									<img src={Image11} />
								</div>
							</div>

							<div id="social_network" className="block_text">
								<h2>{t('Réseaux sociaux')}</h2>
								<p>{t('Cela permettra d’adapter la photo pour le réseau social sur lequel elle sera diffusée')}</p>
								<div className="img_container_support">
									<img src={Image12} />
								</div>
								<ol>
									<li>{t('Choisir le réseau social voulu')}</li>
									<li>{t('Cliquer sur le type d’utilisation par exemple pour Facebook en tant que photo de profil ou bien de couverture')}</li>
									<li>{t('Trois possibilités Voir (pré visualiser), download (télécharger) ou bien Modifier cropping (recadrer la photo)')}</li>
								</ol>
							</div>

							<div id="advanced" className="block_text">
								<h2>{t('Avancé')}</h2>
								<div className="img_container_support">
									<img src={Image13} />
								</div>
								<p>{t('Personnalisation des caractéristiques de la photo. Les différentes teintes de la photo sont disponibles dans la catégories couleurs')}</p>
							</div>

							<div id="seo" className="block_text">
								<h2>{t('Seo').toUpperCase()}</h2>
								<div className="img_container_support">
									<img src={Image14} />
								</div>
								<p>{t('Injection de balise alt et de textes de description propre à la photo.')}</p>
							</div>

							<div id="update_media" className="block_text">
								<h2>{t('Mettre à jour')}</h2>
								<p>{t('Cela permet de mettre à jour le visuel tout en conservant le même nom que la photo précédemment loadé.')}</p>
							</div>

							<div id="edit_media" className="block_text">
								<h2>{t('Editer la photo')}</h2>
								<p>{t('Dans ce même onglet propre à chaque photo vu précédemment, la photo peut être éditée à l’aide du crayon présent en haut à droite.')}</p>
								<div className="img_container_support">
									<img src={Image18} />
								</div>
								<p>{t('Différentes fonctionnalités sont présentes : filtre, rotation de l’image etc.')}</p>
								<div className="img_container_support">
									<img src={Image19} />
								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	)
}
