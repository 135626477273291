import React, { useEffect, useState } from 'react'
import { Alert, Icon, Switch, Typography } from 'antd'
import { useTranslation } from "react-i18next";
import { fileConvertSize } from '../../../helpers.js'
import { useAccountContext } from '../../../providers/AccountProvider.js';
import { useApiContext } from '../../../providers/ApiProvider.js';

const { Paragraph } = Typography;

export default function DescriptionTabs({ asset, media, canEdit, updateEntity }) {

  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;
  const { t } = useTranslation();
  const [_media, setMedia] = useState(media)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (media?.id && asset.type === 1) {
      fetchMedia()
    }

  }, [media?.id])

  const fetchMedia = () => {
    setLoading(true)
    apiFetchEntity('media_objects', media.id, response => {
      if (!response?.extraUrl) {
        message.error('Impossible de récupérer les liens de téléchargement')
      } else {
        setMedia(response)
      }
      setLoading(false)
    })
  }

  function onChange(checked) {
    let message = checked ? asset.usageName + ' ' + t('Est maintenant obsolète') : asset.usageName + ' ' + t('N\'est plus obsolète');
    updateEntity('assets', asset.id, { obsolete: checked }, message)
  }

  function onPrivateChange(checked) {
    let message = checked ? asset.usageName + ' ' + t('Est maintenant privé') : asset.usageName + ' ' + t('N\'est plus privé');
    updateEntity('assets', asset.id, { isPrivate: checked }, message)
  }

  function changeDescr(str) {
    if (str !== asset.description) {
      let Data = {
        description: str
      }
      updateEntity('assets', asset.id, Data, t('Description enregistré'))
    }
  }

  function displayImageData() {
    const diff = Math.abs(((_media?.extraUrl?.lg?.size - _media?.fileSize) / _media?.fileSize) * 100).toFixed(1);
    const isGif = media.mimeType === 'image/gif'

    return (
      <>
        <div className='mb'>
          <p className='bold'>{t('Photo originale')}</p>
          <div>{t('Nom')} : {media.originalName}</div>
          <div>{t('Dimensions')} : {`${media.dimensions[0]} x ${media.dimensions[1]} pixels`}</div>
          <div>{t('Poids')} : {fileConvertSize(media.fileSize)}</div>
          <div>{t('Type')} : {media.mimeType}</div>
        </div>
        {
          !isGif &&
          <div className='mb'>
            <p className='bold'>{t('Photo optimisée')}</p>
            <div>
              <div>{t('Nom')} : {asset.usageName}</div>
              {
                _media?.extraUrl?.lg?.size && !loading ?
                  <>
                    <div>{t('Dimensions')} : {`${_media.extraUrl.lg.width} x ${_media.extraUrl.lg.height} pixels`}</div>
                    <div>{t('Poids')} : {fileConvertSize(_media.extraUrl.lg.size)}</div>
                    <div style={{ color: "#0cc800" }}><strong>{t('Gain')} : {diff} %</strong></div>
                  </>
                  : <Icon type='loading' spin />
              }
            </div>
          </div>
        }
      </>
    )
  }

  function displayImageDataViewer() {
    return (
      <>
        <div className='mb'>
          <p className='bold'>{t('Informations')}</p>
          <div>{t('Nom')} : {asset.usageName}</div>
          <div>{t('Dimensions')} : {`${media.dimensions[0]} x ${media.dimensions[1]} pixels`}</div>
          <div>{t('Poids')} : {fileConvertSize(media.fileSize)}</div>
          <div>{t('Type')} : {media.mimeType}</div>
        </div>
      </>
    )
  }


  function displayVideoData() {
    return (
      <>
        <div className='mb'>
          <p className='bold'>{t('Informations')}</p>
          <div>
            <div>{t('Nom')} : {asset.usageName}</div>
            {
              !!media?.dimensions.length &&
              <div>{t('Dimensions')} : {`${media.dimensions[0]} x ${media.dimensions[1]} pixels`}</div>
            }
            <div>{t('Poids')} : {fileConvertSize(media.fileSize)}</div>
            <div>{t('Type')} : {media.mimeType}</div>
            <div>{t('Nom original')} : {media.originalName}</div>
          </div>
        </div>
      </>
    )
  }

  function displayDocData() {
    return (
      <>
        <div className='mb'>
          <p className='bold'>{t('Informations')}</p>
          <div>
            <div>{t('Nom')} : {asset.usageName}</div>
            <div>{t('Poids')} : {fileConvertSize(media.fileSize)}</div>
            <div>{t('Type')} : {media.mimeType}</div>
            <div>{t('Nom original')} : {media.originalName}</div>
          </div>
        </div>
      </>
    )
  }

  if (media?.id !== asset?.currentMedia)
    return <></>

  return (
    <div className="tabs-assets">
      {media.isCMYK &&
        <Alert
          type="warning" showIcon closable
          message={
            <span>
              {t('Attention cette image est encodé en')}&nbsp;
              <a href="https://fr.wikipedia.org/wiki/Quadrichromie"
                rel="noreferrer" target="_blank">
                {t('CMJN').toUpperCase()}
              </a>,&nbsp;
              {t('Les couleurs sur le navigateur peuvent être atténuées.').toLowerCase()}
            </span>
          }
        />
      }
      <div>
        <div className='mb'>
          {
            canEdit || asset.description ?
              <>
                <p className='bold'>{t('Description')}</p>
                <div style={{ display: 'flex' }}>
                  <Paragraph style={{ width: '100%' }}
                    editable={canEdit ? { onChange: changeDescr } : null}>{asset.description}</Paragraph>
                </div>
              </> : null
          }
        </div>
        {
          asset?.type === 1 && canEdit && displayImageData()
        }
        {
          asset?.type === 1 && !canEdit && displayImageDataViewer()
        }
        {
          asset?.type === 2 && displayDocData()
        }
        {
          asset?.type === 3 && displayVideoData()
        }
        {accountState.admin &&
          <div className='mb'>
            <p className='bold'>{t('Statut')}</p>
            <div>{t('Obsolète')} : <Switch size="small" defaultChecked={asset?.obsolete}
              onChange={onChange} /></div>
            <div>{t('Privé')} : <Switch size="small" defaultChecked={asset?.isPrivate} onChange={onPrivateChange} />
            </div>
          </div>}
      </div>
    </div>
  )
}
