import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, TreeSelect, Icon, Checkbox, Form, Radio, message } from "antd";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import CheckboxTagPermission from "../tag/CheckboxTagPermission";

const { TreeNode } = TreeSelect;

export default function ModalTransfer({ visible, setVisible }) {

  const [apiDispatch] = useApiContext();
  const { patch, apiFetchCollection } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { rerender } = accountDispatch;
  const { t } = useTranslation();

  const [targetAccount, setTargetAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([])
  const [allIris, setallIris] = useState([])
  const [_item, setItem] = useState({ tags: [], root: [], isGlobal: false, withAssets: false })
  const [accounts, setAccounts] = useState([])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const searchRef = useRef('');

  useEffect(() => {
    fetchTags()
  }, [accountState.account.id])

  useEffect(() => {
    fetchChildAccounts('')
  }, [])

  const fetchChildAccounts = (search) => {
    let params = {
      itemsPerPage: 10,
      filters: [
        { name: 'parentAccount', value: accountState.group.id },
        { name: 'name', value: search }
      ],
    }
    apiFetchCollection('accounts', params, response => {
      setAccounts(response['hydra:member'])
    })
  }

  //Appelé à chaque changement de l'input, lance la recherche de correspondance
  const handleSearch = (value) => {
    clearTimeout(searchTimeout)
    searchRef.current = value;

    setSearchTimeout(setTimeout(() => {
      fetchChildAccounts(searchRef.current)
    }, 500))

  }

  async function fetchTags() {
    const params = {
      filters: [
        { id: 'directory', name: 'type', value: 1 },
        { id: 'pagination', name: 'pagination', value: 'false' },
        { name: 'account', value: accountState.account.id }
      ]
    }

    apiFetchCollection('get_folders', params, (response) => {
      let items = response
      let all = getAllIris(items)

      setTags(items)
      setallIris(all);
      setLoading(false)
    })
  }

  function getAllIris(items) {
    let iris = []

    items.map(item =>
      iris = [...iris, ...getTagChildrenIris(item)]
    )

    return iris
  }

  function getTagChildrenIris(tag) {
    let childIris = []
    if (tag.childTags.length > 0) {
      tag.childTags.map(child => {
        childIris = [...childIris, ...getTagChildrenIris(child)]
      })
    }
    return [tag['@id'], ...childIris];
  }

  function onChange(data) {
    setTargetAccount(data)
  }

  function handleOk() {

    if (targetAccount && tags.length && _item.root.length) {

      setLoading(true);

      const data = {
        newAccount: targetAccount,
        tags: _item.root
      };

      if (_item.withAssets)
        data.withAssets = true

      patch("/api/copy-arbo-by-tags", data, response => {
        close()
      })

    } else {
      message.error(t("Erreur lors de l'envoie du formulaire"))
    }

  }

  function close() {
    setTargetAccount(null)
    setLoading(false)
    setTags([])
    setItem({ tags: [], root: [], isGlobal: false, withAssets: false })
    setVisible(false)
    rerender()
  }

  function onCheckChange(e, tag, property) {
    let tags = [tag['@id']]
    tags = [...getTagChildrenIris(tag)]
    if (e.target.checked) {
      _item[property] = [..._item[property], ...tags]
      _item.root.push(tag.id)
    } else {
      _item[property] = _item[property].filter(item => !tags.includes(item))
      _item.root = _item.root.filter(i => i !== tag.id)
    }
    setItem({ ..._item })
  }

  function checkAll(e, property, iris) {
    if (e.target.checked) {
      _item[property] = [...iris]
      if (!_item.isGlobal)
        _item.root = tags.map(t => t.id)
    } else {
      _item[property] = []
      _item.root = []
    }

    setItem({ ..._item })
  }

  function onSelectChange(e) {
    _item.withAssets = e.target.value;
    setItem({ ..._item });
  }

  return (
    <Modal
      visible={visible}
      title={t('Copier / transférer arborescence')}
      closable={true}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          {t('Annuler')}
        </Button>,
        <Button key="ok" onClick={handleOk}>
          {t('Valider')}
        </Button>,
      ]}
    >
      {
        loading
          ?
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon type="loading" />
          </div>
          :
          <div>
            <Form.Item>
              <Radio.Group onChange={onSelectChange} value={_item.withAssets}>
                <Radio value={false}>
                  {t("Copier")}
                </Radio>
                <Radio value={true}>
                  {t("Transférer")}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={t("Dossiers") + ' :'}>
              {tags.length ?
                <div style={{ lineHeight: 'initial', maxHeight: '200px', overflow: 'auto' }}>
                  <Checkbox
                    indeterminate={_item.tags.length > 0 && _item.tags.length < allIris.length}
                    onChange={(e) => checkAll(e, 'tags', allIris)}
                    checked={_item.tags.length >= allIris.length}
                    disabled={_item.isGlobal}
                  >
                    {t('Tout sélectionner')}
                  </Checkbox>
                  {tags.map((tag) => (
                    <CheckboxTagPermission
                      key={tag.id}
                      tag={tag}
                      onChange={onCheckChange}
                      lvl={0}
                      _item={_item}
                      checkProperty={'tags'}
                    />
                  ))}
                </div>
                : t("Votre compte n'a aucun dossier")}
            </Form.Item>

            <Form.Item label={t("Vers") + ' :'}>
              <TreeSelect
                showSearch
                onSearch={handleSearch}
                style={{ width: '100%' }}
                value={targetAccount}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={t('Sélectionner un compte')}
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeNodeFilterProp="title"
              >
                <TreeNode value={accountState.group.id} title={accountState.group.name}>
                  {
                    !!accounts.length ?
                      accounts.map(child => (
                        <TreeNode key={child.id} value={child.id} title={child.name} />
                      ))
                      : null}
                </TreeNode>
              </TreeSelect>
            </Form.Item>

            {_item.withAssets ?
              <span style={{ color: "red" }}>
                {t("Efface les dossiers et images sélectionnés sur ce compte et les copies sur le nouveau")}
              </span>
              : null}
          </div>
      }
    </Modal>
  )
}
