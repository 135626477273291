import React, {useContext} from 'react';
import {useImmer} from 'use-immer';
import {useAuthContext} from './AuthProvider';
import axios from "axios";

const api_endpoint = process.env.API_ENTRYPOINT + '/api/';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
const defaultState = {};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const DispatchContext = React.createContext();

const ApiProvider = ({children}) => {
  const [dispatch] = useImmer({...defaultState});
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
    <DispatchContext.Provider value={dispatch}>
      {children}
    </DispatchContext.Provider>
  );
};

function useDispatchContext() {
  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const {authLogout} = authDispatch;
  const authToken = auth ? auth.token : 'none';
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function responseAuthControl(api_response) {
    if (
      (api_response.error && api_response.error === "AuthenticationFailure") ||
      (api_response.message && api_response.message === "Username could not be found.")
    ) {
      authLogout();
      return false;
    }
    return true;
  }

  async function apiFetchEntity(endpoint, id, external_dispatcher) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiUpdateEntity(endpoint, id, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'patch',
        data: data,
        url: api_endpoint + endpoint + '/' + id + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function patch(endpoint, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'patch',
        data: data,
        url: process.env.API_ENTRYPOINT + endpoint,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiPostEntity(endpoint, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: api_endpoint + endpoint,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiDeleteEntity(endpoint, id, external_dispatcher) {
    try {
      let response = await axios({
        method: 'delete',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiFetchCollection(endpoint, params, external_dispatcher) {

    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function getRequestBasic(endpoint, params, external_dispatcher) {
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + endpoint + '?token=' + params.token + filtres,
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiDownloadCollection(endpoint, params, external_dispatcher) {
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '?'+ filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiDownloadSubResource(endpoint, params, resource, external_dispatcher) {
    try {
      let id = params.id;
      let filtres = '';
      if (params.filters && params.filters.length > 0) {
        params.filters.map(filter => {
          filtres += '&' + filter['name'] + '=' + filter['value']
        })
      }
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '/' + resource + '?' + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      console.log(response)
      external_dispatcher(response.data)
    } catch (e) {
      console.log(e)
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiFetchSubResource(endpoint, params, resource, external_dispatcher) {
    try {
      let id = params.id;
      let ext = params.withoutExt ? '' : '.jsonld';
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '/' + resource + ext,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
console.log(response)
      external_dispatcher(response.data)
    } catch (e) {
console.log(e)
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function apiFetchSubResourceWithFilter(endpoint, params, resource, external_dispatcher) {
    let id = params.id;
    let itemsPerPage = params.itemsPerPage || 30;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '/' + resource + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },

      });
      external_dispatcher(response.data)
    } catch (e) {
      console.log(e.response)
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function postRequestBasic(endpoint, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: process.env.API_ENTRYPOINT + endpoint,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json'
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  async function deeplTranslation(lng, texts, sourceLng, external_dispatcher) {
    try {
      lng = lng.toUpperCase();
      sourceLng = sourceLng ? sourceLng.toLocaleUpperCase() : null

      let data = new FormData();
      data.append('auth_key', process.env.DEEPL_API_KEY);
      data.append('target_lang', lng);
      for (let i = 0; i < texts.length; i++) {
        data.append('text', texts[i])
      }
      if (sourceLng) data.append('source_lang', sourceLng);

      let response = await axios({
        method: 'post',
        data: data,
        url: 'https://api.deepl.com/v2/translate',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
        },
      });
      console.log(response)
      external_dispatcher(response.data)
    } catch (e) {
      external_dispatcher(e.response.data);
    }
  }

  async function customRequest(endpoint, external_dispatcher) {
    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint,
        headers: {
          'Accept': '*',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }


  async function customPostRequest(endpoint, data, external_dispatcher) {
    try {
      let response = await axios({
        method: 'post',
        url: process.env.API_ENTRYPOINT + '/' + endpoint,
        data: data,
        headers: {
          'Accept': '*',
          'Authorization': 'Bearer ' + authToken
        },
      });
      external_dispatcher(response.data)
    } catch (e) {
      responseAuthControl(e.response.data);
      external_dispatcher(e.response.data);
    }
  }

  return {
    apiFetchEntity,
    apiUpdateEntity,
    apiFetchCollection,
    apiFetchSubResource,
    apiPostEntity,
    apiDeleteEntity,
    apiFetchSubResourceWithFilter,
    apiDownloadCollection,
    apiDownloadSubResource,
    postRequestBasic,
    deeplTranslation,
    getRequestBasic,
    patch,
    customRequest,
    customPostRequest
  };
}

const useApiContext = () => {
  return [useDispatchContext()]
}

export {useApiContext, ApiProvider, DispatchContext};
