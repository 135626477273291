import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import { useAccountContext } from "../../../providers/AccountProvider";
import { useApiContext } from "../../../providers/ApiProvider";

import Media from '../../../pages/Media';
import DisplayCards from "./DisplayCards";
import SubDirectory from "./SubDirectory";
import Selectable from '../../asset/Selectable';
import ActionBar from '../../asset/ActionBar';
import { useAssetContext } from '../../../providers/AssetProvider';

const AssetsList = ({ selected, setSelected, baseFilters = [] }) => {

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [assetState, assetDispatch] = useAssetContext();
  const { assets, itemsPerPage, loading, total, page } = assetState;
  const { setAssets, setLoading, setTotal, setPage, updateAsset } = assetDispatch;

  const [accountState, accountDispatch] = useAccountContext();
  const { filters } = accountState
  const { setSelection } = accountDispatch
  const accountEditor = accountState.editor && !accountState.tags.length

  const [firstLoading, setFirstLoading] = useState(true);
  const [assetId, setAssetId] = useState(0);
  const hasMore = !(total / itemsPerPage < page);
  const filterDirectoryActive = filters.some(filter => filter.type === 'directory')
  const scrollerRef = useRef(null);
  const search = new URLSearchParams(window.location.search)
  const multiple = search.get('multiple') && search.get('multiple') === 'true';

  useEffect(() => {
    setLoading(true)
    setFirstLoading(true);
    fetch(1);
    if (page > 1) setPage(1);
  }, [accountState.filters, accountState.rerender]);


  useEffect(() => {

    let next = accountState.selection.filter(item => selected.has(item.id))
    let newAssets = assets.filter(item => selected.has(item.id))

    setSelection([...new Set([...next, ...newAssets])])

  }, [selected])

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      fetch(page);
    }
  }, [page]);

  function fetch(page) {
    let data = {
      id: accountState.account.id,
      itemsPerPage: itemsPerPage,
      page: page,
      filters: [...accountState.filters, ...baseFilters]
    }

    if (!accountState.admin)
      data.filters = data.filters.concat({ name: 'isPrivate', value: 'false' });

    if (!accountState.admin && !accountState.editor)
      data.filters = data.filters.concat([{ name: 'obsolete', value: 'false' },]);

    apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
      hydrateAssets(response, page);
    })
  }

  function hydrateAssets(response, page) {
    if (response['@type'] === 'hydra:Error') return;

    if ((response['hydra:totalItems'] / itemsPerPage) <= page - 1)
      setPage(1);

    if (page === 1)
      setAssets(response['hydra:member']);
    else
      setAssets([...assets, ...response['hydra:member']]);

    setTotal(response['hydra:totalItems']);
    setFirstLoading(false)
    setTimeout(() => setLoading(false), 1500)
  }

  function updateAssetId(value) {
    let index = assets.findIndex(item => item.id === value);
    let loadMore = false;

    if ((index >= assets.length - 5) && total > assets.length)
      loadMore = true;

    setAssetId(value)
    if (loadMore)
      setPage(page => page + 1);
  }

  return (
    <Spin spinning={firstLoading}>
      {
        accountState.editor &&
        <ActionBar
          sortSelection={selected}
          setSortSelection={setSelected}
          cart={false}
        />
      }
      {
        (multiple || accountState.editor) &&
        <Selectable setSelected={setSelected} scrollerRef={scrollerRef} />
      }

      <div className="assets-list selectable-container no-active-bar" ref={scrollerRef}>
        <DisplayCards
          // {...props}
          multiple={multiple}
          selected={selected}
          setSelected={setSelected}
          selectable={accountState.admin || accountEditor}
          setAssetId={updateAssetId}
          assets={assets}
          page={page}
          setPage={setPage}
          loading={loading}
          setLoading={setLoading}
          hasMore={hasMore}
        />

        {filterDirectoryActive && !hasMore && !loading &&
          <SubDirectory
            // {...props}
            selected={selected}
            setSelected={setSelected}
            id={filters.find(filter => filter.type === 'directory').id}
          />}
      </div>

      {!!assetId && <Media assets={assets} assetId={assetId} setAssetId={updateAssetId} updateAsset={updateAsset} isWidget />}
    </Spin>
  );
}

export default AssetsList;
