import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon } from 'antd';
import { useTranslation } from "react-i18next";
import MediaView from '../media/MediaView';
import Download from './Download';
import { useMediaQuery } from 'react-responsive';

const ButtonGroup = Button.Group;

export default function VisitorFullscreen({ assets, assetId, setAssetId, canDownload = true, maxQuality = 'original'}) {

  const { t } = useTranslation();

  const [patternBg, setPatternBg] = useState(false)
  const isMounted = useRef(true);
  const [media, setMedia] = useState(null)
  const [placeHolder, setPlaceHolder] = useState(null)
  const [asset, setAsset] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    isMounted.current = true;

    let index = assets.findIndex(item => item.id === assetId)
    let selectedAsset = assets[index]
    setCurrentIndex(index)
    setAsset(selectedAsset)

    let currentMedia = getMedia(selectedAsset)
    if (currentMedia) {
      setPatternBg((currentMedia.mimeType === 'image/png' || currentMedia.mimeType === 'image/svg' || currentMedia.mimeType === 'image/svg+xml') && selectedAsset?.type === 1);
    }

    setMedia(currentMedia)

    return () => {
      isMounted.current = false;
    }
  }, [assetId]);

  function getMedia(asset) {
    return !!asset ? asset.medias.find(media => media.id === asset.currentMedia) : null
  }

  function updateCurrentAsset(direction) {
    let index = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
    let asset = assets[index]

    setAssetId(asset.id)
    setPlaceHolder(asset)
  }

  const extraHeader =
    <div key={'extra'} className="flex">
      {
        asset && asset.type === 1 && media && media.mimeType === 'image/png' &&
        <Button
          size="small"
          key='bg'
          className="mr-5"
          type={patternBg ? "primary" : "default"}
          icon="eye"
          title={t('Modifier le fond')}
          onClick={() => setPatternBg(!patternBg)}
        />
      }
      {asset && canDownload && <Download asset={asset} isButton maxQuality={maxQuality}/>}
    </div>
    ;

  function handleClose() {
    document.querySelector(`div[data-key="${assetId}"]`)?.scrollIntoView({ behavior: 'smooth' })
    setAssetId(null)
  }

  return (
    <>
      <div className={`media-page modal ${patternBg && 'png_bg'}`}
        style={{ height: '100%' }}>
        <div style={{ position: 'absolute', top: '.5em', left: '1em', zIndex: '2' }} className='flex align_center'>
          <Button icon='close' className='mr' onClick={handleClose} />
        </div>
        <div className='media-container'>
          <div className='bold asset-name'>
            {asset?.usageName}
          </div>
          {
            !!assets.length &&
            <div style={{ position: 'absolute', top: '.5em', left: '50%', transform: 'translateX(-50%)' }}>
              <ButtonGroup className="mr-5">
                <Button
                  size="small"
                  onClick={() => updateCurrentAsset('prev')}
                  disabled={!currentIndex}
                >
                  <Icon type="left" />
                  {!isTabletOrMobile && t('Précédent')}
                </Button>
                <Button
                  size="small"
                  onClick={() => updateCurrentAsset('next')}
                  disabled={currentIndex >= assets.length - 1}
                >
                  {!isTabletOrMobile && t('Suivant')}
                  <Icon type="right" />
                </Button>
              </ButtonGroup>
            </div>
          }
          <div style={{ position: 'absolute', top: '.5em', right: '1em' }}>
            {extraHeader}
          </div>
          <MediaView asset={asset} media={media} placeHolder={placeHolder} share/>
        </div>
      </div>
    </>
  )
}
