import React, { useEffect, useState } from 'react';
import { Icon, Tag } from 'antd';
import Stars from "../../Stars";
import { useAccountContext } from "../../../providers/AccountProvider";
import TagPopup from "../TagPopup";
import Download from '../Download';
import MediaView from '../MediaView';
import { useTranslation } from 'react-i18next';
import CartAction from '../../CartAction';

const ClassicCard = ({ item, openFullscreen, checkbox }) => {

  const { t } = useTranslation()
  const [accountState, accountDispatch] = useAccountContext();
  const { filter } = accountDispatch;

  const [allTags, setAllTags] = useState([])
  const [media, setMedia] = useState(null)
  const [directory, setDirectory] = useState(null)
  const [className, setClassName] = useState('thumb-classical')
  const [stars, setStars] = useState(0)

  const ext = media?.contentUrl ? media?.contentUrl?.split('.')?.pop() : null;
  const isTranscoding = item.type === 3 && item?.['medias'][0]?.status === 'transcoding';

  useEffect(() => {
    item.currentMedia ? setMedia(item.medias.filter(media => media.id === item.currentMedia)[0]) : setMedia(item.medias[0])
    setStars(item.note)
    fetchTags()
    setDirectory(item.tags.filter(tag => tag.type === 1)[0])
  }, [item])

  useEffect(() => {
    if (media && media.mimeType === 'image/png' && !media.vimeoId) {
      setClassName(className + ' png_transparent')
    }
  }, [media])


  function fetchTags() {
    let Tag = []
    item.tags.map((tag) => {
      Tag.push(tag['@id'])
    })
    setAllTags(Tag)
  }

  function directoryFilter() {
    if (typeof filter !== "function") {
      return;
    }
    let filtre = {
      id: directory.id,
      name: 'tags.name[]',
      value: directory.id,
      tagName: directory.name,
      type: 'directory',
      element: directory
    }
    filter(filtre);
  }

  return (
    <div className="flex align_center w-100per">
      <div className="flex align_center" style={{ width: '15%' }}>
        <div className="mr">
          {checkbox}
        </div>

        <div onClick={e => openFullscreen(e, item.id)}>
          <MediaView asset={item} />
        </div>
      </div>
      <div onClick={e => openFullscreen(e, item.id)} className="pointer" style={{ width: '45%' }}>
        <div title={`${item.usageName} (${ext})`}>{item.usageName} {ext && !ext.match('jpeg|jpg') ? `(${ext})` : null}</div>
        <div title={media?.originalName} className="light">{media?.originalName}</div>
      </div>
      <div style={{ width: '20%' }}>
        {directory &&
          <span
            onClick={directoryFilter}
            style={{ color: 'rgba(0, 0, 0, 0.65', cursor: 'pointer' }}
            key="dir"
          >
            <Icon type="folder" theme="filled" />&nbsp;
            {accountState.globalSearch ?

              <span style={{ color: "#1890ff" }}>
                {item.account.name}
              </span> : directory.parentTag !== undefined ? directory.parentTag.name + ' > ' : ''}
            {directory.name}
          </span>}
      </div>


      <div style={{ width: '20%' }}>
        <div className="flex align_center end">
          <div className="mr">
            <Download asset={item} />
          </div>

          <div className="mr">
            <Stars itemId={item.id} stars={stars} setStars={setStars} inTooltip />
          </div>

          <div className="mr">
            <TagPopup tags={item.tags.filter(tag => tag.type === 0)} />
          </div>

          <div>
            <CartAction asset={item} assetTags={allTags} setAssetTags={setAllTags} />
          </div>
        </div>
        <div className="flex align_center end asset-status">
          {isTranscoding ? <Tag color="red" title={t('En cours d\'optimisation')}>{t('Optimisation')}</Tag> : null}
          {item.obsolete ? <Tag color="geekblue">{t('Obsolète')}</Tag> : null}
          {item.isPrivate ? <Tag color="orange">{t('Privé')}</Tag> : null}
        </div>
      </div>


    </div>
  );
};

export default ClassicCard;
