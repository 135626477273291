import React, { useEffect, useRef, useState } from 'react'
import { Icon, List, message, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import UploadProgress from './UploadProgress';
import * as tus from "tus-js-client";
import { makeUsageName } from "../../helpers";
import TagsTreeSelect from '../tag/TagTreeSelect';
import FolderChoice from './FolderChoice';
import { useAccountContext } from '../../providers/AccountProvider';
import { useApiContext } from '../../providers/ApiProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import ModalUnsplash from "../modal/ModalUnsplash";
import { docExtensions, getAcceptedTypes, imageExtensions, videoExtension } from './helpers';

const acceptExtension = imageExtensions + docExtensions
let totalSize = 0;
let hashList = []
let total = 0;
let usedId = 0;

export default function UploadMedias({ isUploading, setIsUploading, visible = true, setVisible = () => { }, callBack = () => { } }) {

    const { t } = useTranslation();
    const [authState] = useAuthContext();
    const { auth } = authState;
    const [apiDispatch] = useApiContext();
    const { apiPostEntity, apiFetchEntity, apiFetchCollection, apiUpdateEntity, apiFetchSubResourceWithFilter } = apiDispatch;
    const [accountState, accountDispatch] = useAccountContext();
    const { addNotification, setActiveTab } = accountDispatch;

    const [dropResult, setDropResult] = useState(null);
    const [folders, setFolders] = useState([])
    const [targetFolder, setTargetFolder] = useState();
    const [fetchingFolders, setFetchingFolders] = useState(false)
    const [fetchingLastId, setFetchingLastId] = useState(false)

    const [stats, setStats] = useState({})
    const [freeSpace, setFreeSpace] = useState(0)
    const [uploadList, setUploadList] = useState([])
    const [currentId, setCurrentId] = useState(null);

    const accept = accountState.account.video ? acceptExtension + videoExtension : acceptExtension;
    const acceptedType = getAcceptedTypes(accountState.account.video)
    const isMounted = useRef(false);

    useEffect(() => {
        // console.log('stats', stats)
        if (stats?.medias?.percent + stats?.videos?.percent === 200) {
            close()
        }
    }, [stats.globalPercent])

    useEffect(() => {
        if (targetFolder?.id) {
            getLastId()
        } else {
            setCurrentId(null)
        }
    }, [targetFolder])

    function getLastId() {
        setFetchingLastId(true)
        apiFetchCollection('get_last_id', { filters: [{ name: 'tag', value: targetFolder.id }] }, response => {
            if (response.success) {
                setCurrentId(response.id);
                setDropResult({ name: targetFolder.name, id: targetFolder['@id'], idFile: response.id, parentName: targetFolder.parentTag ? targetFolder.parentTag.name : null });
            }
            setFetchingLastId(false)
        })
    }

    useEffect(() => {
        if (!visible)
            resetTagSelect();
    }, [visible])

    useEffect(() => {
        isMounted.current = true;
        if (!visible) {
            resetTagSelect();
        } else {
            totalSize = 0;
            fetchFolders()
            fetchStorage()
        }
        return () => {
            isMounted.current = false;
        }
    }, [accountState.rerender, visible])

    const fetchStorage = () => {
        apiFetchEntity('storages', accountState.account.storage.id, response => {
            if (isMounted.current) {
                setFreeSpace(response.maxSize - response.currentSize)
            }
        })
    }


    const fetchFolders = () => {
        setFetchingFolders(true)

        const params = {
            filters: [
                {
                    id: 'pagination',
                    name: 'pagination',
                    value: 'false'
                },
                {
                    name: 'account',
                    value: accountState.account.id
                }
            ]
        }

        apiFetchCollection('get_folders', params, (response) => {
            if (isMounted.current) {
                //filtre les tags sur lesquels l'utilisateur est éditeur
                let foldersAvailable = response || []
                setFolders(foldersAvailable);
                if (foldersAvailable.length === 1 && (accountState.editor && accountState.tags.length)) {
                    let tag = foldersAvailable[0];
                    setTargetFolder(tag);
                }
                setFetchingFolders(false)
            }
        })
    }

    const getDuplicate = (file) => {
        let hash = file.type + file.size + file.lastModified
        let isDuplicate = hashList.includes(hash)
        if (isDuplicate) {
            let duplicate = uploadList.find(item => {
                let itemHash = item.type + item.size + item.lastModified
                if (itemHash === hash && item.uid != file.uid) {
                    return true
                }
                return false
            })
            return duplicate;
        }
        return false
    }

    const checkFile = (file, onError) => {
        let hash = file.type + file.size + file.lastModified

        const duplicate = getDuplicate(file)
        const isAcceptedType = acceptedType.includes(file.type);
        const isVideo = file.type.includes('video/');
        const maxUpload = isVideo ? 250 * 1024 * 1024 * 1024 : 64 * 1024 * 1024
        const isTooBig = file.size > maxUpload;

        let message = '';

        if (!isAcceptedType) {
            message = `${file.name} - Ce format n\'est pas accepté`
        }
        if (isTooBig) {
            message = `${file.name} - Taille maximum : ${isVideo ? '250go pour une vidéo' : '32mo pour une image ou un document'}`
        }

        if (duplicate) {
            message = `${file.name} - Fichier similaire dans la liste`
        }

        if (total + file.size > freeSpace) {
            message = `${file.name} - Vous n'avez plus assez d'espace pour importer ce média, veuillez en libérer depuis les réglages du compte ou contacter MMCréation`;
        }

        // console.log('message', message)
        if (message) {
            onError(null, message)
            return false
        }

        total += file.size
        hashList.push(hash)
        return true
    }

    const beforeUpload = (file, fileList) => {
        // console.log('file', file)
        if ((accountState.isWidget || (accountState.editor && accountState.tags.length)) && !targetFolder?.name) {
            message.error(t('Vous devez sélectionner un dossier'));
            return false
        }
        if (!isUploading) setIsUploading(true)
        setUploadList(prev => [...prev, file])
        return true
    }

    function resetHooks() {
        if (setIsUploading !== null)
            setIsUploading(false);

        total = 0;
        usedId = 0;
        setUploadList([]);
        setStats({});
        callBack();
        resetTagSelect();
    }

    const handleUpload = async (uploadObject) => {

        let { file, onError } = uploadObject;
        let canUpload = checkFile(file, onError)
        if (!canUpload) return;

        if (file.type.includes('video/')) {
            uploadVideo(uploadObject)
        } else {
            uploadMedia(uploadObject)
        }
    };

    const uploadVideo = ({ file, onSuccess, onProgress, onError }) => {

        let formData = new FormData();
        formData.append('accountId', accountState.account.id);
        formData.append('size', file.size);
        formData.append('originalName', file.name);
        formData.append('mimeType', file.type);

        if (currentId !== null) {
            getFolderParams(file, formData)
        }

        apiPostEntity("post-video", formData, (response) => {
            // console.log('response', response)
            if (response['@type'] === "hydra:Error") {
                const error = response['hydra:description'];
                let message = error.type ? t(error.type) + " " + error.file : `${file.name} - ${t('Ce fichier ne peut être traité')}`
                onError(null, message);
            } else {
                // console.log('response', file)
                tusUpload(file, response, onSuccess, onProgress, onError)
            }
        });
    }

    const getFolderParams = (file, formData) => {
        // let fileIndex = uploadList.indexOf(file);
        // dropResult.idFile = currentId + fileIndex;
        console.log('file', file)
        let fileExt = file.name.split('.').pop()
        let renameExt = accountState?.account?.renameExt || null
        if (accountState.account.renameOn && (!renameExt || renameExt.includes(fileExt))) {
            dropResult.idFile = currentId + usedId++;
            let assetName = makeUsageName(dropResult, accountState.account.name, {});
            console.log('assetName', assetName)
            formData.append('name', assetName);
        }
        formData.append('tagId', targetFolder.id);
    }


    const uploadMedia = ({ file, onSuccess, onError }) => {

        let formData = new FormData();
        formData.append('accountId', accountState.account.id);
        formData.append('action', 'create');
        formData.append('file', file);

        if (currentId !== null) {
            getFolderParams(file, formData)
        }

        apiPostEntity("media_objects", formData, (response) => {
            if (response['@type'] === "hydra:Error") {
                const error = response['hydra:description'];
                let message = error.type ? t(error.type) + " " + error.file : `${file.name} - ${t('Ce fichier ne peut être traité')}`
                onError(null, message);
            } else {
                totalSize += file.size
                onSuccess('success');
            }
        });
    }

    const onChange = ({ file, fileList }) => {
        if (file.status || file.percent)
            updateStats(fileList)
    }

    const updateStats = (fileList) => {
        let videos = fileList.filter(item => item.type.includes('video/'))
        let medias = fileList.filter(item => !item.type.includes('video/'))

        let videosPercent = calculateVideoPercent(videos)
        let mediaPercent = calculatePercent(medias)
        let globalPercent = Math.floor(videosPercent + mediaPercent) / 2

        setStats({
            videos: {
                list: videos,
                percent: videosPercent
            },
            medias: {
                list: medias,
                percent: mediaPercent
            },
            globalPercent: parseFloat(globalPercent)
        })
    }

    const calculateVideoPercent = (videos) => {
        if (!videos.length) return 100;
        let cumul = videos.reduce((prev, video) => {
            if (video.status === 'done' || video.status === 'error') return prev + 100
            let currentPercent = video.percent ? parseFloat(video.percent) : 0;
            if (currentPercent >= 100) currentPercent = 99.99

            return prev + currentPercent
        }, 0)

        return cumul / videos.length
    }

    const close = () => {
        if ((accountState.admin || (accountState.editor && accountState.tags.length === 0)) && !targetFolder) {
            setActiveTab('1');
        }

        let successMedia = stats.medias.list.filter(item => item.status === 'done')
        let failedMedia = stats.medias.list.filter(item => item.status === 'error')
        let successVideo = stats.videos.list.filter(item => item.status === 'done')
        let failedVideo = stats.videos.list.filter(item => item.status === 'error')
        let successUpload = successMedia.length + successVideo.length

        updateAccountStats(successUpload)

        setTimeout(() => {
            let description = (<>
                <p key="result">{successUpload} {t('Media(s) Téléchargé(s) avec succès')}</p>
                {
                    (!!failedMedia.length || !!failedVideo.length) &&
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <div>Erreurs :</div>
                        <List
                            size="small"
                            dataSource={[...failedMedia, ...failedVideo]}
                            renderItem={item => <List.Item>{item.response}</List.Item>}
                        />
                    </div>
                }
            </>)
            let id = (new Date()).getTime()
            let feedback = {
                id: `upload_${id}`,
                message: `Vous avez importé des fichiers`,
                description: description
            };
            addNotification(feedback);
            resetHooks()
        }, 2000)
    }

    const updateAccountStats = (quantity) => {
        let params = { id: accountState.account.id }
        apiFetchSubResourceWithFilter('accounts', params, 'update', response => { })
        if (accountState.isWidget) return
        const dataStat = {
            type: 'upload',
            size: String(totalSize),
            accountEntity: "/api/accounts/" + accountState.account.id,
            userEntity: "/api/users/" + auth.id,
            quantity: quantity,
        }
        apiPostEntity('statistics', dataStat, response => { })
    }

    const calculatePercent = (list) => {
        if (!list.length) return 100;
        let done = list.filter(item => item?.status === 'done' || item?.status === 'error')
        //return a number with 2 decimal
        return Math.round((done.length * 100 / list.length) * 1e2) / 1e2
    }

    const tusUpload = (file, entity, handleSuccess, handleProgress, handleError) => {
        // console.log('data', data)
        // chunksizw should depend on file size ?
        file.up = new tus.Upload(file, {
            chunkSize: 100000000,
            uploadUrl: entity.vimeoUploadUrl,
            onError: function (error) {
                console.log("Failed because: " + error)
                handleError(error)
            },

            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                handleProgress({ percent: percentage })
                // console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: function () {
                // console.log("Download %s from %s")
                totalSize += file.size
                handleVideoSuccessUpload(entity, handleSuccess);
            }
        })
        // Check if there are any previous uploads to continue.
        file.up.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one. 
            if (previousUploads.length) {
                file.up.resumeFromPreviousUpload(previousUploads[0])
            }

            // Start the upload
            file.up.start()
        })
    }

    const handleVideoSuccessUpload = (entity, callback) => {
        apiUpdateEntity('media_objects', entity.id, { status: 'transcoding' }, response => {
            callback()
        })
    }

    function folderChange(value) {
        let tag = getFolder(value, folders)

        if (tag !== null) {
            setTargetFolder(tag);
        } else {
            resetTagSelect();
        }
    }

    function resetTagSelect() {
        setTargetFolder({ name: null });
        setCurrentId(null);
        setDropResult(null);
    }

    function getFolder(id, tags) {
        let folder = null;

        for (let i = 0; i < tags.length; i++) {
            if (!folder) {
                if (tags[i].id === id)
                    return folder = tags[i];
                else if (tags[i].childTags.length > 0)
                    folder = getFolder(id, tags[i].childTags);
            }
        }

        return folder;
    }

    function resolveUsageName() {
        let name = ''
        if (currentId !== null) {
            dropResult.idFile = currentId;
            if (accountState.account.renameOn) {
                name = makeUsageName(dropResult, accountState.account.name, {});

            }
        }
        return name;
    }

    return (
        <Spin spinning={fetchingFolders || fetchingLastId}>
            <Upload.Dragger
                style={{ display: isUploading ? 'none' : 'block' }}
                showUploadList={false} name="file"
                onChange={onChange}
                customRequest={handleUpload}
                multiple
                beforeUpload={beforeUpload}
                disabled={isUploading || fetchingFolders || fetchingLastId}
                accept={accept}
                fileList={uploadList}
            >
                <div>
                    <p className="ant-upload-drag-icon">
                        <Icon type="cloud-upload" />
                    </p>
                    <div>
                        <p
                            className="ant-upload-text">{t('Cliquez ici ou déplacez vos médias sur cette zone pour les télécharger')}</p>
                        <p className={`ant-upload-hint`}>
                            {t("Vous pouvez envoyer un ou plusieurs médias simultanément. Uniquement les fichiers png, jpeg et jpg sont acceptés.")}
                        </p>
                    </div>
                </div>
            </Upload.Dragger>

            {
                !isUploading && ((accountState.editor && accountState.tags && accountState.tags.length > 0) || accountState.isWidget) ?
                    <div style={{ marginBottom: '5px' }}>
                        {
                            accountState.tags.length === 1 ? null :
                                <>
                                    <p>{t('Vous devez choisir un dossier pour vos médias' + '*')}</p>
                                    <TagsTreeSelect style={{ marginBotton: '1em' }} tags={folders} onSelect={folderChange}
                                        currentValue={targetFolder ? targetFolder.id : ''} />
                                </>
                        }
                    </div> : null
            }
            {
                ((accountState.editor && !accountState.tags.length) || accountState.admin) && !accountState.isWidget && !isUploading ?
                    <FolderChoice
                        folders={folders}
                        onSelect={folderChange}
                        targetFolder={targetFolder}
                        resetTagSelect={resetTagSelect}
                        modalVisible={visible}
                    /> : null
            }
            {
                stats.medias &&
                <UploadProgress stats={stats} />
            }

            {(auth.roles.includes("ROLE_ADMIN") || ((accountState.admin || accountState.isWidget) && accountState.account.storage.maxUnsplashRequest)) && !isUploading ?
                <ModalUnsplash setIsModalVisible={setVisible} targetFolder={targetFolder} resolveUsageName={resolveUsageName} /> : null}
        </Spin>
    )
}
