import React from 'react';
import {Checkbox} from 'antd';
import {useTranslation} from "react-i18next";

const TypeFilter = ({accountState, filter, setCollapsed}) => {

  const {t, i18n} = useTranslation();

  function onChange(e) {
    filter({
      id: 'orType' + e.target.value,
      name: 'type[]',
      value: e.target.value,
      type: 'orType'
    })
  }

  return (
    <div>
      <Checkbox  checked={accountState.filters.filter(filtre => filtre.id === 'orType1').length === 1}
                label={t('Image')} value={1} onChange={onChange}>{t('Image')}</Checkbox>
      <Checkbox  checked={accountState.filters.filter(filtre => filtre.id === 'orType3').length === 1}
                label={t('Video')} value={3} onChange={onChange}>{t('Video')}</Checkbox>
      <Checkbox  checked={accountState.filters.filter(filtre => filtre.id === 'orType2').length === 1}
                label={t('Document')} value={2} onChange={onChange}>{t('Document')}</Checkbox>
    </div>
  );
};

export default TypeFilter;
