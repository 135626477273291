import React, { useEffect, useState } from 'react';
import { Icon, List, Pagination, Popover, Spin, Tag } from 'antd';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useApiContext } from '../../providers/ApiProvider';

const AccountSelectDropdown = ({ accountState, setActiveAccount, setGlobalSearch }) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const [accounts, setAccounts] = useState([])
  const history = useHistory()
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  })

  useEffect(() => {
    fetchChildAccounts()
  }, [pagination.current])

  const fetchChildAccounts = () => {
    let params = {
      filters: [{ name: 'parentAccount', value: accountState.group.id }],
      itemsPerPage: pagination.pageSize,
      page: pagination.current
    }
    setLoading(true)
    apiFetchCollection('accounts', params, response => {

      setAccounts(response['hydra:member'])
      setPagination({ ...pagination, total: response['hydra:totalItems'] })
      setLoading(false)
    })
  }


  const handleClick = (account, link) => {
    setIsOpen(false)
    setActiveAccount(account)
    setGlobalSearch(account === null)
    if (link) {
      history.push(link)
    }
  }

  const content = (
    <div style={{ minWidth: '300px' }}>
      <div onClick={() => handleClick(null, `/account-group/${accountState?.group?.id}`)} className={`account-list-item pointer ${accountState?.globalSearch && 'selected'}`}>
        Vue globale
      </div>
      <Spin spinning={loading}>
        <List size="small"
          dataSource={[accountState.group, ...accounts]}
          renderItem={item =>
            <div onClick={() => handleClick(item, `/account/${item.id}`)}
              className={`account-list-item pointer ${accountState?.accountActive?.id === item.id && !accountState?.globalSearch ? 'selected' : ''} ${accountState?.group?.id === item.id && 'bold group-item'}`}>
              {item.name}
            </div>
          } />
      </Spin>
      {
        pagination?.total > pagination.pageSize &&
        <div className="m flex end">
          <Pagination
            {...pagination}
            onChange={page => setPagination({ ...pagination, current: page })} />
        </div>
      }
    </div>
  )

  const handleVisibleChange = (visible) => {
    setIsOpen(visible)
  }

  return (

    <Popover content={content} trigger='click' onOpenChange={handleVisibleChange} placement='bottomLeft'
      title={t("Comptes du groupe")}
    >
      <div style={{ position: 'relative' }}>
        <h2 className="pointer m-0">
          {isOpen ?
            <Icon type="caret-down" /> :
            <Icon type="caret-right" />
          } {accountState.account.name}</h2>
        {accountState.globalSearch ?
          <Tag className="group-view-tag" color="#0cc800" style={{ margin: 0 }}>{t('Vue globale')}</Tag>
          : null}
      </div>
    </Popover>
  );
};

export default AccountSelectDropdown;
