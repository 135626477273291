import React, { useCallback, useRef } from 'react';
import { Icon } from "antd";

const InfiniteScroll = ({ page, loading, setPage, hasMore }) => {

  const observer = useRef();
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <div style={{ width: '100%', display: "flex", justifyContent: 'center', alignItems: "center", position: 'relative' }}>
        {
          loading ?
            <div style={{ height: 50 }}>{!!loading && <Icon type="loading" style={{ fontSize: 40 }} />}</div>
            :
            <div ref={loader} style={{ width: 1, height: 1, position: "relative", top: '-50vh' }} />
        }
      </div>
    </>
  )
}

export default InfiniteScroll;
