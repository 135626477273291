import React, { useEffect, useState } from 'react'
import { Input, Button, Spin } from 'antd'
import { useTranslation } from "react-i18next";
import MultiLngInput from '../../MultiLngInput';

const baseText = { fr: '' }

export default function SEOTabs({ asset, updateEntity, translations = null }) {

  const { t } = useTranslation();
  const [alt, setAlt] = useState(asset?.seoAlt ? JSON.parse(asset.seoAlt) : baseText)
  const [legend, setLegend] = useState(asset?.seoLegend ? JSON.parse(asset.seoLegend) : baseText)
  const [copyright, setCopyright] = useState(asset?.seoCopyright || '')
  const [loading, setLoading] = useState(false)
  const [forceLng, setForceLng] = useState(null)

  async function handleSave() {
    setLoading(true)
    await updateEntity('assets', asset.id, { seoAlt: JSON.stringify(alt), seoCopyright: copyright, seoLegend: JSON.stringify(legend) }, t('Données seo enregistrer avec succès'))
    setLoading(false)
  }

  useEffect(() => {
    if (translations) {
      if (!alt?.['en'] && translations?.[alt?.fr]) {
        setAlt(prev => {return {...prev, en: translations[alt.fr]?.text}})
      }
      if (!legend?.['en'] && translations?.[legend?.fr]) {
        setLegend(prev => {return {...prev, en: translations[legend.fr]?.text}})
      }
      setForceLng('en')
      console.log('in translations')
    }
  }, [translations])

  useEffect(() => {
    setAlt(asset?.seoAlt ? JSON.parse(asset.seoAlt) : baseText)
    setLegend(asset?.seoLegend ? JSON.parse(asset.seoLegend) : baseText)
    setCopyright(asset?.seoCopyright || '')
    console.log('first')
  }, [asset])

  return (
    <div className="tabs-assets">
      <Spin spinning={loading}>
        <div>
          <MultiLngInput value={legend} placeholder='Légende' title='Légende' onChange={(value) => setLegend(value)} name={'seoLegend'} forceLng={forceLng}/>
          <MultiLngInput value={alt} placeholder='Balise Alt' title='Balise alternative' onChange={(value) => setAlt(value)} name={'seoAlt'} forceLng={forceLng}/>
          <div>
            <div style={{ marginBottom: '.5em' }}>Copyright</div>
            <Input value={copyright} onChange={(e) => setCopyright(e.target.value)} />
          </div>
        </div>
        <div style={{ textAlign: 'center', margin: '1em 0' }}>
          <Button type='primary' onClick={handleSave}>{t('Enregistrer')}</Button>
        </div>
      </Spin>
    </div>
  )
}
