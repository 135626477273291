import React, {useEffect, useState} from 'react';
import {useApiContext} from "../../../providers/ApiProvider";
import DisplayCards from "./DisplayCards";
import {Button, Icon} from "antd";
import {useAccountContext} from "../../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import Media from "../../../pages/Media";

const ChildTagAssets = ({tag, loadingParent, selected, setSelected, multiple}) => {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {filter} = accountDispatch;

  const [assets, setAssets] = useState([]);
  const [total, setTotal] = useState(0);
  const [assetId, setAssetId] = useState(0);

  const hasMore = total > assets.length;

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    apiFetchSubResourceWithFilter('tags', {id: tag.id, filters: [{name: "status", value: 1}]}, 'assets', response => {
      setAssets(response['hydra:member']);
      setTotal(response['hydra:totalItems']);
      loadingParent()
    })
  }

  function handleClick(tag) {
    filter({
      id: tag.id,
      name: 'tags.name[]',
      value: tag.id,
      tagName: tag.name,
      type: 'directory',
      element: tag
    });
  }

  function updateAsset(data) {
    setAssets(assets => {
      return assets.map(item => item.id === data.id ? data : item);
    })
  }

  const displaySeparator = tag.parents.length - 1;
  return (
    !!total &&
    <>
      <div style={{paddingLeft: 50}}>
        <Icon type="folder-open"/>&nbsp;
        {tag.parents.map((parent, index) => (
          <span
            key={parent.id}
            className="pointer"
            onClick={() => handleClick(parent)}
          >
            {parent.name}{index < displaySeparator && ' > '}
          </span>
        ))}
      </div>

      <DisplayCards
        assets={assets}
        assetId={assetId}
        setAssetId={setAssetId}
        updateAsset={updateAsset}
        updateAssetId={setAssetId}
        selected={selected} setSelected={setSelected}
        multiple={multiple}
      />

      {hasMore &&
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: '0 0 30px'}}>
          <Button onClick={() => handleClick(tag)}>{t('Voir plus d\'images de')} {tag.name}</Button>
        </div>}

      {!!assetId && <Media assets={assets} assetId={assetId} setAssetId={setAssetId} updateAsset={updateAsset} isWidget/>}
    </>
  );
};

export default ChildTagAssets;
