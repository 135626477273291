import React, {useState, useEffect} from 'react';
import {Spin, Icon, Button, Popconfirm} from 'antd';

import {useApiContext} from '../../../providers/ApiProvider.js';
import {useAccountContext} from "../../../providers/AccountProvider";
import {useAssetContext} from "../../../providers/AssetProvider";
import {useTranslation} from "react-i18next";

import DisplayCards from "../DisplayCards";
import CardViewChoice from "../CardViewChoice";
import BtnModalCreateCart from "../../modal/BtnModalCreateCart";
import Media from "../../../pages/Media";
import CartShare from './CartShare.js';

/**
 * -Front
 * --Contenu onglet Paniers
 */
const CartDetails = ({selectedCart, setSelectedCart}) => {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter, apiDownloadSubResource} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {addNotification} = accountDispatch

  const [assetState, assetDispatch] = useAssetContext();
  const {visible, assets, loading, page, itemsPerPage, total} = assetState;
  const {setVisible, setAssets, setLoading, setPage, setTotal, updateAsset} = assetDispatch;

  const [firstLoading, setFirstLoading] = useState(true)
  const [selected, setSelected] = useState(() => new Set());
  const [assetId, setAssetId] = useState(0);

  useEffect(() => {
    setLoading(true)
    setFirstLoading(true);
    fetch(1);
    if (page > 1) setPage(1);
  }, []);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      fetch(page);
    }
  }, [page]);

  function fetch(page) {
    let params = {
      id: accountState.account.id,
      page: page,
      itemsPerPage: itemsPerPage,
      filters: [
        {name: 'tags.name', value: selectedCart.id},
        {name: 'status', value: 1},
        {name: 'obsolete', value: 'false'},
        {name: 'isPrivate', value: 'false'}
      ]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'assets', (hydra_response) => {
      hydrateAssets(hydra_response, page)
    });
  }

  function hydrateAssets(response, page) {
    if (response['@type'] === 'hydra:Error') return;

    if ((response['hydra:totalItems'] / itemsPerPage) <= page - 1)
      setPage(1);

    if (page === 1)
      setAssets(response['hydra:member']);
    else
      setAssets([...assets, ...response['hydra:member']]);

    setTotal(response['hydra:totalItems']);
    setFirstLoading(false)
    setTimeout(() => setLoading(false), 1500)
  }

  const downloadZip = () => {
    apiDownloadSubResource('tags', {id: selectedCart.id}, 'download', (response) => {
      //console.log(response);
      const notif = {};
      if (response.success) {
        notif.type = 'success';
        notif.message = t('Votre zip est prêt');
        notif.description =
          <a href={response.link}>{t('Cliquez ici pour le télécharger')} <Icon type="cloud-download"/></a>;
        addNotification(notif);
      } else {
        notif.type = 'error';
        notif.message = t('Il y a eu un problème');
        notif.description = response.error;
        addNotification(notif);
      }
    })
  }

  function confirm() {
    downloadZip(selectedCart.id)
  }

  function updateAssetId(value) {
    let index = assets.findIndex(item => item.id === value);
    let loadMore = false;

    if ((index >= assets.length - 5) && total > assets.length)
      loadMore = true;

    setAssetId(value)
    if (loadMore)
      setPage(page => page + 1);
  }

  return (
    <Spin spinning={firstLoading}>
      <div className="tab-topbar">

        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button size="small" style={{margin: '0 20'}} type="primary" icon='left'
                  onClick={() => setSelectedCart(null)}>{t('Retour')}</Button>
          <h4 style={{margin: '0 0 0 15px'}}><strong>{selectedCart.name}</strong></h4>
        </div>

        <div style={{display: "flex"}}>
          <CartShare cart={selectedCart}/>

          {!!total &&
            <Popconfirm
              title={t("Êtes-vous sûr de vouloir télécharger ce dossier ?")}
              onConfirm={confirm}
              okText={t("Oui")}
              cancelText={t("Non")}
            >
              <Button size="small" type="primary" icon="download">{t('Télécharger')}</Button>
            </Popconfirm>}

          <CardViewChoice/>

        </div>
      </div>

      <DisplayCards
        selected={selected}
        setSelected={setSelected}
        setAssetId={updateAssetId}
        assets={assets}
        page={page}
        setPage={setPage}
        loading={loading}
        hasMore={!(total / itemsPerPage < page)}
      />

      {!!assetId && <Media assets={assets} assetId={assetId} setAssetId={setAssetId} updateAsset={updateAsset}/>}

      <BtnModalCreateCart
        visible={visible}
        setVisible={setVisible}
        btnVisible={false}
      />
    </Spin>
  );
}

export default CartDetails;
