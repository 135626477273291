import React, { useEffect, useState } from 'react'
import { Collapse, Icon, Button } from 'antd'
import { useTranslation } from "react-i18next";
import { stringDate } from '../../../helpers.js'
import { useApiContext } from '../../../providers/ApiProvider.js';

const { Panel } = Collapse;

export default function StatTabs({ asset }) {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const [downloadHistoric, setDownloadHistoric] = useState([])
  const [downloadPage, setDownloadPage] = useState(1)
  const [totalDownload, setTotalDownload] = useState(0)
  const [cartCount, setCartCount] = useState(0)

  useEffect(() => {
    fetchStats()
  }, [downloadPage])

  useEffect(() => {
    setCartCount(asset.tags.filter(tag => tag.type === 2).length)
  }, [])

  function fetchStats() {
    const params = {
      page: downloadPage,
      filters: [
        {
          name: 'type',
          value: 'download'
        },
        {
          name: 'assetEntity.id',
          value: asset.id
        }
      ]
    }
    apiFetchCollection('statistics', params, response => {
      console.log('response', response)
      if (downloadPage > 1) {
        setDownloadHistoric(downloadHistoric => downloadHistoric.concat(response['hydra:member']))
        return;
      }
      setDownloadHistoric(response['hydra:member'])
      setTotalDownload(response['hydra:totalItems'])
    })
  }

  return (
    <div className='tabs-assets' >
      <p><Icon type="shopping" /> {t('Panier')}  <span>{cartCount}</span></p>
      <p><Icon type="download" /> {t('Téléchargement')}  <span>{totalDownload}</span></p>
      {
        downloadHistoric.length > 0 ?
          <Collapse expandIconPosition="right" bordered={false}>
            <Panel header={t('Détail des téléchargements')} key="1">
              <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                {
                  downloadHistoric.map((row, index) =>
                    <p
                      key={'key_' + index}> {`${row.userEntity?.username} | ${t('Le')} ${stringDate(row.created)} | ${t('Format')}: ${row.cropType}`}</p>
                  )
                }
                {
                  totalDownload > downloadHistoric.length
                    ? <p style={{ textAlign: 'center' }}><Button type="link"
                      onClick={() => setDownloadPage(downloadPage + 1)}>{t('Afficher plus')}</Button>
                    </p>
                    : null
                }
              </div>
            </Panel>
          </Collapse>
          : null}
    </div>
  )
}
