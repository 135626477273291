import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { useAccountContext } from "../../providers/AccountProvider";
import ModalEditDirectories from "../modal/ModalEditDirectories";
import { useTranslation } from "react-i18next";
import Hapidam_logo from "../../assets/logo_hapidam.svg";
import ModalFolderPermission from "../modal/ModalFolderPermission";
import TreeTag from "../tag/TreeTag";
import VideoFolder from "../tag/VideoFolder";
import DocFolder from "../tag/DocFolder";
import TrashFolder from "../tag/TrashFolder";
import { useAuthContext } from "../../providers/AuthProvider";
import DeleteFolder from "../tag/DeleteFolder";
import CartFolder from "../tag/CartFolder";
import SortButton from "../tag/SortButton";
import SidebarFilters from "./SidebarFilters";


const AccountSidebar = ({ closeMenu = () => { } }) => {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { resetFilter, rerender, setFreemiumOptions } = accountDispatch;

  const [isEditingPerms, setIsEditingPerms] = useState(false);
  const [editDirectoriesModal, setEditDirectoriesModal] = useState(false);

  const [hasChildrenOpen, setHasChildrenOpen] = useState(0);
  const [forceOpen, setForceOpen] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const accountEditor = accountState.admin || (accountState.editor && !accountState.tags.length)

  function toggleAll(e) {
    e.stopPropagation();
    let shouldOpen = hasChildrenOpen ? 0 : 1;
    setForceOpen((prev) =>
      typeof prev === "boolean" ? shouldOpen : !!shouldOpen
    );
    setHasChildrenOpen((prev) => (prev > 0 ? 0 : 10));
  }

  function openShareModal(e) {
    if (accountState.account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN'))
      return setFreemiumOptions({ visible: true, type: 'share' });

    setIsEditingPerms(true);
  }

  const closeEdit = useCallback(() => {
    setEditDirectoriesModal(false)
    rerender()
  }, [])

  return (
    <aside className="search-bar-side sidebar-layout1">
      <div className="sidebar-logo no-tablet">
        {accountState.isWidget ?
          <a target="_blank" href={`https://www.hapidam.com/account/${accountState.account.id}`}>
            <img alt="Hapidam" src={Hapidam_logo} />
          </a>
          : <Link to={`/dashboard`}>
            <img alt="Hapidam" src={Hapidam_logo} />
          </Link>}
      </div>
      <div className={`sidebar-menu`}>
        <div className="flex">
          {accountState.admin &&
            <div className="sidebar-menu-button" onClick={openShareModal}>
              <Icon type="lock" theme='filled' />
            </div>}

          {accountState.editor &&
            <div className="sidebar-menu-button" onClick={(e) => {
              e.stopPropagation();
              setEditDirectoriesModal(true);
            }}>
              <Icon type="folder-add" theme='filled' />
            </div>}

          <div className={`sidebar-menu-button ${filtering && 'active'}`} onClick={(e) => {
            setFiltering(prev => !prev);
          }}>
            <Icon type="filter" theme='filled' />
          </div>
          {
            accountState.filters.length > 2 ?
              <div className="sidebar-menu-button" onClick={resetFilter}>
                {t("Réinitialiser les filtres")}
              </div>
              : null
          }
        </div>
        <div onClick={closeMenu} className={`sidebar-menu-button mobile-close-menu`} >
          <Icon type="close-square" theme='filled' />
        </div>
      </div>
      <div className={`sidebar-content ${window.innerWidth > 1024 ? 'has-header' : 'no-header'}`}>
        <SidebarFilters setFiltering={setFiltering} filtering={filtering} />
        <div className={`sidebar-content ${window.innerWidth > 1024 ? 'has-header' : 'no-header'}`}>
          <>
            {accountEditor && !accountState.isWidget ?
              <SortButton closeMenu={closeMenu} /> : null}

            <TreeTag
              closeMenu={closeMenu}
              setEditDirectoriesModal={setEditDirectoriesModal}
              setHasChildrenOpen={setHasChildrenOpen}
              forceOpen={forceOpen}
            />
            <VideoFolder closeMenu={closeMenu} />
            <DocFolder closeMenu={closeMenu} />

            {
              !accountState.isWidget &&
              <CartFolder closeMenu={closeMenu} />
            }
            {
              accountState.editor &&
              <TrashFolder key={"trash"} tag={{ name: t('Corbeille'), id: 'trash' }}
                closeMenu={closeMenu} />
            }
            {
              auth.roles.includes("ROLE_ADMIN") &&
              <DeleteFolder closeMenu={closeMenu} />
            }
          </>
        </div>
      </div>

      {
        accountState.editor &&
        <ModalEditDirectories
          visible={editDirectoriesModal}
          handleClose={closeEdit}
        />
      }
      {
        accountState.admin && isEditingPerms &&
        <ModalFolderPermission
          account={accountState.account}
          setIsEditingPerms={setIsEditingPerms}
        />
      }
    </aside>
  );
};

export default AccountSidebar;
