import React, { useState } from 'react'
import { Button, Dropdown, Icon, Menu } from 'antd';
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../providers/AccountProvider";
import { useMediaQuery } from 'react-responsive'
import { useCarts } from './asset/cart/useCarts';
import { useAssetContext } from '../providers/AssetProvider';

export default function CartAction({ selection, asset = null, assetTags = [], setAssetTags = () => { }, color = '' }) {

  const { t } = useTranslation();
  const [assetState, assetDispatch] = useAssetContext();
  const { setVisible } = assetDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { setFreemiumOptions, rerender } = accountDispatch;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const { postCart } = useCarts();
  const [loading, setLoading] = useState(false)

  const handleMenuClick = async (e) => {
    e.domEvent.stopPropagation()
    if (accountState.account.contractType === 'freemium')
      return setFreemiumOptions({ visible: true, type: 'cart' });

    if (e.key === 'create_cart')
      return setVisible(true);

    setLoading(true)
    if (asset?.id) return handleAsset(e.key)
    return handleSelection(e.key);
  }

  const handleSelection = async cartId => {
    let assetIds = [];
    selection.map((item) => { assetIds.push(item['id']) })
    await postCart(cartId, assetIds, false)
    rerender()
    setLoading(false)
  }

  const handleAsset = async (cartId) => {
    let tags = [...assetTags]
    if (tags.includes(cartId)) {
      tags = tags.filter(item => item != cartId)
    } else {
      tags.push(cartId)
    }
    await postCart(cartId, [asset.id], true)
    setAssetTags(tags)
    setLoading(false)
  }


  const content = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="create_cart">{t('Créer panier')} <Icon type='plus' /></Menu.Item>
      {accountState.carts.length > 0 ? <Menu.Divider /> : null}
      {
        accountState.carts.map((cart) => (
          <Menu.Item key={cart['@id']}>
            {cart.name} {assetTags.includes(cart['@id']) ? <Icon type="check" /> : null}
          </Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown
      disabled={loading}
      overlay={content} trigger={["click"]}
    >
      {
        !asset ?
          <Button className='mr' size="small" shape="round" icon={'shopping'} loading={loading}>
            {!isTabletOrMobile && t('Ajouter au panier')}
          </Button>
          : <Icon type={loading ? 'loading' : 'shopping-cart'} style={{ color: color }} spin={loading}/>
      }
    </Dropdown>
  )
}
