import React, { useState } from 'react'
import { Badge, Button, Drawer } from 'antd'
import { useAccountContext } from '../../providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import AccountSidebar from './AccountSidebar';
import { useCallback } from 'react';
import SearchInput from '../SearchInput';
import { NotificationDropdown } from '../dropdown/NotificationDropdown';
import ModalUploadFiles from '../modal/ModalUploadFiles';
import AppMenu from './AppMenu';
import ModalDownloadAssets from '../modal/ModalDownloadAssets';
import { Link } from 'react-router-dom';

export default function MobileMenu({ accountPage = true }) {

    const { t } = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const [isSearchDrawerVisible, setIsSearchVisible] = useState(false);
    const [isNotifDrawerVisible, setIsNotifVisible] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false)

    const closeSidebar = useCallback(() => {
        setIsSidebarVisible(false)
    }, [])
    
    const closeMenu = useCallback(() => {
        setIsMenuVisible(false)
    }, [])

    const closeSearch = useCallback(() => {
        setIsSearchVisible(false)
    }, [])

    const closeNotif = useCallback(() => {
        setIsNotifVisible(false)
    }, [])

    return (
        <div className="mobile-menu">
            {
                accountState?.account &&
                <>
                    {
                        accountPage ?
                            <Button id="toggleSidebar" size="large" className="trigger" icon={'menu-fold'} onClick={() => setIsSidebarVisible(true)} />
                            : <Link
                                to={!!accountState.account.childAccount.length ? `/account-group/${accountState.account.id}` : `/account/${accountState.account.id}`}>
                                <Button id="accountPage" size="large" className="trigger" icon={'home'} />
                            </Link>
                    }
                    <Button size="large" icon="search" onClick={() => setIsSearchVisible(true)} />
                    <ModalUploadFiles showBtnText={false} />
                    <Badge count={accountState.notifications.length}>
                        <Button size="large" icon="notification" onClick={() => setIsNotifVisible(true)} />
                    </Badge>
                    <Drawer closable={false} visible={isSidebarVisible} placement='left' bodyStyle={{ padding: 0, height: '100%' }} width={'auto'} onClose={closeSidebar}>
                        <AccountSidebar closeMenu={closeSidebar} />
                    </Drawer>

                    <Drawer
                        placement='top'
                        closable={false}
                        onClose={closeSearch}
                        visible={isSearchDrawerVisible} height="auto"
                        title={t('Rechercher un média')}
                    >
                        <SearchInput callback={closeSearch} visible={isSearchDrawerVisible} mobileMode />
                    </Drawer>
                    <Drawer
                        placement="right"
                        closable="true"
                        visible={isNotifDrawerVisible}
                        onClose={closeNotif}
                        title={t('Notifications')}
                        width={"auto"}
                    >
                        <NotificationDropdown />
                    </Drawer>
                </>
            }

            <Button size="large"
                icon="user" onClick={() => setIsMenuVisible(true)}>
            </Button>


            <Drawer
                placement="right"
                closable="true"
                visible={isMenuVisible}
                onClose={() => setIsMenuVisible(false)}
                width="300"
                title={
                    <div style={{ width: 'auto', marginRight: '1em' }}>
                        <h2 className="pointer" onClick={closeMenu}> {accountState?.account?.name}</h2>
                    </div>
                }
            >
                <AppMenu setIsDownloadModalVisible={setIsDownloadModalVisible} />
            </Drawer>
            {
                isDownloadModalVisible &&
                <ModalDownloadAssets
                    visible={isDownloadModalVisible}
                    closeModal={() => setIsDownloadModalVisible(false)}
                />
            }
        </div>
    )
}
