import React, {useEffect, useState} from 'react'
import ReactCrop from 'react-image-crop';
import {Button, message, Modal} from 'antd'
import 'react-image-crop/dist/ReactCrop.css';
import {useTranslation} from "react-i18next";

export default function ModalCropImage({title, visible, setVisible, image, width, height, originalWidth, cropRequest, id, reload, croppedElem}) {

  const {t, i18n} = useTranslation();

  useEffect(() => {
    setCrop({
      unit: '%',
      x: 10,
      y: 10,
      width: 80,
      aspect: (width / height) / 1
    })
  }, [width, height])

  const [crop, setCrop] = useState({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    aspect: (width / height) / 1
  });

  function onImageLoaded(image) {
    //console.log(image)
  }

  function onCropComplete(crop) {
    //console.log(crop)
  }

  function onChange(crop) {
    //console.log(crop)
    setCrop(crop)
  }

  function onOk() {
    let ratio = originalWidth / document.getElementsByClassName('ReactCrop__image')[0].width;
    let params = {
      id: id,
      filters: [
        {
          name: 'width',
          value: width
        },
        {
          name: 'height',
          value: height
        },
        {
          name: 'mode',
          value: 'manuel'
        },
        {
          name: 'x',
          value: crop.x * ratio
        },
        {
          name: 'y',
          value: crop.y * ratio
        },
        {
          name: 'dimensionsW',
          value: crop.width * ratio > 0 ? crop.width * ratio : 1
        },
        {
          name: 'dimensionsH',
          value: crop.height * ratio > 0 ? crop.height * ratio : 1
        },
        {
          name: 'prefix',
          value: croppedElem
        }
      ]
    }
    cropRequest('assets', params, 'crop', hydra_response => {
      if (hydra_response.url) {
        setVisible(false)
        reload()
      }
      if (hydra_response['@type'] === 'hydra:Error') {
        message.error(hydra_response['hydra:description'])
      }
    })
  }

  return (
    <Modal
      centered={true}
      title={title}
      style={{
        textAlign: 'center',
        minWidth: 600
      }}
      width="auto"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          {t('Retour')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onOk}
        >
          {t('Enregistrer')}
        </Button>,
      ]}
    >
      <ReactCrop
        style={{maxHeigth: '80vh'}}
        src={image ? image : null}
        crop={crop}
        ruleOfThirds
        onImageLoaded={onImageLoaded}
        onComplete={onCropComplete}
        onChange={onChange}
        imageStyle={{maxHeight: '80vh'}}
      />
    </Modal>
  )
}
