import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom'
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from '../providers/ApiProvider.js';
import { useAuthContext } from '../providers/AuthProvider.js';
import { Icon, Tabs, Button } from 'antd'
import CartTableList from "../components/account/CartTableList";
import LinkUser from "../components/account/LinkUser";
import AccountInfos from "../components/account/AccountInfos";
import CropProfileList from "../components/account/CropProfileList";
import DirectoriesOverview from "../components/DirectoriesOverview";
import { useTranslation } from "react-i18next";
import Advanced from "../components/account/Advanced";

const { TabPane } = Tabs;

const AccountOverview = () => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchEntity, apiFetchSubResource } = apiDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { accountSet, resetState } = accountDispatch;

  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [redirection, setRedirection] = useState(false);
  const size = window.innerWidth > 768 ? "large" : "small";
  const [activeTab, setActiveTab] = useState('infos')
  const isMounted = useRef(null);
  const [tabUpdate, setTabUpdate] = useState(1);

  const params = useParams();

  useEffect(() => {
    isMounted.current = true;

    fetchAccount();
    return function cleanup() {
      resetState();
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    setState()
  }, [])

  function setState() {
    apiFetchSubResource("accounts", { id: params.account_id, withoutExt: true }, "get-account-with-perm", response => {
      accountSet(response);
      setLoading(false);
    })
  }

  function fetchAccount() {
    apiFetchEntity('accounts', params.account_id, (hydra_response) => {
      if (isMounted.current) {
        if (hydra_response['@type'] === "hydra:Error") {
          setRedirection(true);
          setLoading(false);
        } else {
          setAccount(hydra_response)
        }
      }
    });
  }

  function onTabsChange(activeKey) {
    setActiveTab(activeKey)
    if (activeKey === 'permissions') {
      setTabUpdate(tabUpdate + 1)
    }
  }

  if (loading)
    return <Icon type="loading" />;

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      state: { status: '404', subtitle: t('Ce compte n\'existe pas ou est inaccessible') }
    }} />

  if (!accountState.admin)
    return <Redirect to={{
      pathname: "/erreur",
      state: { status: '403', subtitle: t('Vous n\'êtes pas autorisé à accéder à cette page') }
    }} />

  return (
    <Tabs activeKey={activeTab}
      animated={false}
      size={size}
      onChange={onTabsChange}
      tabBarExtraContent={
        <Link
          to={!!accountState.account.childAccount.length ? `/account-group/${accountState.account.id}` : `/account/${accountState.account.id}`}>
          <Button
            style={{ marginLeft: 10 }}
            shape={window.innerWidth > 768 ? "round" : "circle"}
            size={window.innerWidth > 768 ? "default" : "small"}
            icon="home"
            key="setting">
            {window.innerWidth > 768 ? t('Accueil') : ''}
          </Button>
        </Link>
      }
    >
      <TabPane className="scrollPan" tab={t('Infos')} key="infos">
        <AccountInfos />
      </TabPane>
      <TabPane className="scrollPan" tab={t('Permissions')} key="permissions">
        <LinkUser account={accountState.account} update={tabUpdate} />
      </TabPane>
      <TabPane className="scrollPan" tab={t('Dossiers')} key="dossier">
        <DirectoriesOverview activeTab={activeTab} />
      </TabPane>
      <TabPane className="scrollPan" tab={t('Panier')} key="panier">
        <CartTableList />
      </TabPane>
      <TabPane className="scrollPan" tab={t('Profil')} key="cropping">
        <CropProfileList activeTab={activeTab} />
      </TabPane>
      {
        auth.roles.includes('ROLE_ADMIN') &&
        <TabPane className="scrollPan" tab={t('Avancé')} key="avancé">
          <Advanced account={account} />
        </TabPane>
      }
    </Tabs>
  );
};

export default AccountOverview;
