import React, {useState, useEffect, useRef} from 'react'
import {useApiContext} from '../../providers/ApiProvider.js';
import {Icon, Switch, message} from 'antd';
import {useTranslation} from "react-i18next";
import UpdateUserForm from './UpdateUserForm'

function UserInfos({idUser, setUserEmail, auth}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchEntity, apiUpdateEntity} = apiDispatch;
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    // fetch at startup and whenever needUpdate is changed
    fetchData();
    return () => {
      isMounted.current = false;
    }
  }, [idUser])

  function fetchData() {
    apiFetchEntity('users', idUser, (hydra_response) => {
      if (isMounted.current) {
        if (hydra_response['@type'] === "hydra:Error") {
          message.error(t("Récupération de l'utilisateur impossible"));
          setLoading(false);
        } else {
          setUser(hydra_response)
          setUserEmail(hydra_response.email);
          setLoading(false);
        }
      }
    });
  }

  function updateUser(data) {
    console.log(data)
    apiUpdateEntity('users', idUser, data, (response) => {
      if (response['@type'] === "hydra:Error") {
        message.error(t('Il y a eu un problème'))
      } else {
        message.success(t('Modification effectuée avec succès'))
      }
    })
  }

  function onChange(checked) {
    //console.log(`switch to ${checked}`);
    let roles = checked ? ['ROLE_ADMIN'] : ['ROLE_USER']
    let data = {
      roles
    }
    updateUser(data)
  }


  if (loading)
    return <Icon type="loading"/>

  return (
    <div className="contenu-tab">
      {
        user ?
          <div>
            <h2>{user.username}</h2>
            <p>{t('Email')} : {user.email}</p>
            {
              auth.roles.includes('ROLE_ADMIN') ?
                <p>Super : <Switch size="small" defaultChecked={user.roles.includes('ROLE_ADMIN')}
                                   onChange={onChange}/></p>
                : ''
            }
            <UpdateUserForm user={user} setUser={setUser}/>
          </div>
          : <div>{t('Il y a eu une erreur, réessayer')}</div>
      }
    </div>
  )
}

export default UserInfos
