import React from 'react'
import { Button, Icon, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '../../providers/AccountProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import DownloadMenu from './DownloadMenu';

export default function Download({ asset, isButton = false, maxQuality = 'original', }) {

    const [authState] = useAuthContext();
    const { auth } = authState;
    const { t } = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const { setFreemiumOptions } = accountDispatch;
    const isFreemium = accountState?.account?.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN');

    function showDropdownDownload() {
        if (isFreemium) return setFreemiumOptions({ visible: true, type: 'download' });
    }

    function getMinHeight() {
        let media = asset.currentMedia ? asset.medias.filter(media => media.id === asset.currentMedia)[0] : asset.medias[0];
        if (media.mimeType === 'image/gif' || (!media.vimeoId && asset.type === 3)) return '103px';
        if (asset.type === 2) return "62px";
        return '226px'
    }

    return (
        <Popover
            overlayStyle={{ minWidth: '225px', minHeight: getMinHeight() }}
            trigger={!isFreemium ? 'click' : ''}
            content={<DownloadMenu asset={asset} maxQuality={maxQuality} />}
        >
            {
                isButton ?
                    <Button
                        size="small" className="mr-5" icon="download"
                        onClick={showDropdownDownload} title={t('Télécharger')}></Button> :
                    <Icon type="download" onClick={showDropdownDownload} title={t('Télécharger')} />
            }
        </Popover>
    )
}
