import React from 'react'
import {Button, message, Modal} from 'antd'

const { confirm } = Modal

export default function Advanced({idUser, apiDeleteEntity}) {

	function displayPrompt() {
	    const response = prompt('Ou peut-on avoir a faire a du CO2 compressé ?')
	    if (response.toLowerCase().trim() === 'plage' || response.toLowerCase().trim() === 'la plage') {
	      showDeleteConfirm()
	      return message.success('Salutation camarade')
	    }
	    message.error('Instruisez vous plus a l\'avenir')
	}

	function showDeleteConfirm() {
	    confirm({
	      title: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
	      okText: 'Oui',
	      okType: 'danger',
	      cancelText: 'Non',
	      onOk() {
	        del()
	      },
	      onCancel() {
	        message.info('Sage décision');
	      },
	    });
	}

	function del() {
	    apiDeleteEntity('users', idUser, response => {
	      console.log(response)
	      if (response['@type'] === "hydra:Error") {
	        return message.error('Petit problème, réessaie plus tard.')
	      }

	      window.location.href = "/"
	    })
	}

	return (
		<div className="contenu-tab">
      		<Button type="link" style={{color: 'red'}} onClick={displayPrompt}>Supprimer cet utilisateur</Button>
		</div>
	)
}
