import React from 'react';
import { Empty } from "antd";

const EmptyPlaceholder = ({ message, content = null }) => {

  let html = (<span>{message}</span>);

  return (
    <Empty
      style={{ margin: '50px auto', width: '100%' }}
      description={html}
    >
      {content}
    </Empty>
  );
}

export default EmptyPlaceholder;
