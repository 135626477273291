import React from 'react'
import {Button, Card, Collapse, Dropdown, Icon, Menu, Spin} from 'antd'

import Crop from './ImageEdit/Crop';
import {useTranslation} from 'react-i18next';
import Resize from './ImageEdit/Resize';
import Filter from './ImageEdit/Filter';
import Rotate from './ImageEdit/Rotate';
import Mirror from './ImageEdit/Mirror';
import Manual from './ImageEdit/Manual';
import Text from './ImageEdit/Text';
import Copyright from './ImageEdit/Copyright';

export default function MediaEditSidebar({
                                           loading, image, save, updateBox,
                                           copyright, setCopyright,
                                           filters, setFilters,
                                           crop, setCrop,
                                           activeKey, setActiveKey,
                                           rotation, setRotation,
                                           manual, setManual, download,
                                           mirror, setMirror, update, active
                                         }) {

  const {t} = useTranslation();

  const customPanelStyle = {
    background: '#fff',
    border: 0,
    borderBottom: '1px solid #e8e8e8',
    overflow: 'hidden',
  };

  function handleMenuClick(e) {
    switch (e.key) {
      case 'download':
        download();
        break;
      case 'patch':
      case 'create':
        update(e.key)
        break;
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="patch">
        {t('Mettre a jour le média')}
      </Menu.Item>
      <Menu.Item key="create">
        {t('Créer nouveau média')}
      </Menu.Item>
    </Menu>
  )


  return (
    <div className={`media-sidebar`}>
      <Spin spinning={loading}>
        <Card title="Editer votre image">
          {!!active &&
            <div>
              <Dropdown overlay={menu}>
                <Button type="primary" loading={loading}>
                  Actions <Icon type="down" />
                </Button>
              </Dropdown>
              <Button onClick={download} icon='download' title={t('Télécharger')}></Button>
            </div>}
        </Card>
        {
          image &&
          <Collapse accordion bordered={false} onChange={(key) => setActiveKey(key)}
                    expandIconPosition='right' activeKey={activeKey}>
            <Collapse.Panel header={<span className='bold'>Rogner</span>}
                            style={customPanelStyle} key='crop'>
              <Crop crop={crop} setCrop={setCrop} save={save}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Redimensionner')}</span>}
                            style={customPanelStyle} key='resize'>
              <Resize save={save} image={image}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Filtres')}</span>}
                            style={customPanelStyle} key='filter'>
              <Filter save={save} image={image} filters={filters} setFilters={setFilters}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Rotation')}</span>}
                            style={customPanelStyle} key='rotate'>
              <Rotate save={save} rotation={rotation} setRotation={setRotation}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Miroir')}</span>}
                            style={customPanelStyle} key='mirror'>
              <Mirror save={save} mirror={mirror} setMirror={setMirror}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Réglage Manuel')}</span>}
                            style={customPanelStyle} key='manual'>
              <Manual save={save} manual={manual} setManual={setManual}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Texte')}</span>}
                            style={customPanelStyle} key='text'>
              <Text save={save} updateBox={updateBox}/>
            </Collapse.Panel>
            <Collapse.Panel header={<span className='bold'>{t('Copyright')}</span>}
                            style={customPanelStyle} key='copyright'>
              <Copyright save={save} copyright={copyright} setCopyright={setCopyright}/>
            </Collapse.Panel>
          </Collapse>
        }
      </Spin>
    </div>
  )
}
