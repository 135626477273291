import React, { useEffect, useState } from 'react';
import { Badge, Icon, Tag } from 'antd';
import { useDrop } from 'react-dnd'
import { useAccountContext } from "../../providers/AccountProvider";

const Folder = ({ tag, level = 2, display = true, closeMenu = () => {}, forceOpen = false, setHasChildrenOpen = null }) => {

  const [accountState, accountDispatch] = useAccountContext();
  const { filter } = accountDispatch;
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (forceOpen !== isOpen) setIsOpen(forceOpen)
  }, [forceOpen])

  useEffect(() => {
    let selected = accountState.filters.filter(item => item.id === tag.id && item.type === 'directory');
    setIsSelected(selected.length === 1);
  }, [accountState.filters, tag])

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['asset'],
    drop: () => ({ id: tag.id }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  let backgroundColor = '';
  if (isActive) {
    backgroundColor = 'orange';
    if (!isOpen) {
      setIsOpen(true);
    }
  } else if (canDrop) {
    backgroundColor = 'skyblue'
  }

  const toggleOpen = (e) => {
    e.stopPropagation();
    if (setHasChildrenOpen) setHasChildrenOpen(prev => isOpen ? prev - 1 : prev + 1)
    setIsOpen(!isOpen);
  };

  const filtrer = () => {
    if (canDrop && isOver) return;
    setIsOpen(true)

    if (accountState.filters.some(filter => filter.type === 'directory' && filter.id === tag.id)) return;
    if (setHasChildrenOpen) setHasChildrenOpen(prev => prev + 1)

    let filtre = {
      id: tag.id,
      name: 'tags.name[]',
      value: tag.id,
      tagName: tag.name,
      type: 'directory',
      element: tag
    }

    filter(filtre);
    setIsSelected(!isSelected);
    closeMenu()
  };

  return (
    <ul style={display ? { display: 'block' } : { display: 'none' }} className="folderContainer" key={tag.id}>
      <li
        className={`folderItem noselect flex between align_center ${isSelected && 'isSelected'}`}
        ref={window.innerWidth < 768 || (accountState.editor && accountState.tags.length === 1) ? null : drop}
        style={{ backgroundColor, paddingLeft: level * 15 + "px" }}
        onClick={filtrer}
      >
        {
          !!tag.isPrivate &&
          <div style={{ position: 'absolute', left: '20px' }} title='Ce dossier est privé, son contenu est visible uniquement par les admins'>
            <Badge count={<Icon type="lock" style={{ color: '#f5222d' }} />}  />
          </div>
        }
        <div style={{ marginBottom: 0, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          title={tag.name}>
          <Icon style={tag.childTags.length ? null : { opacity: 0 }} onClick={toggleOpen} type={isOpen ? "caret-down" : "caret-right"} /> <Icon
            type={isOpen ? "folder-open" : "folder"} theme={isSelected ? 'filled' : 'outlined'} />
          &nbsp;{tag.name}
        </div>
        <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
          {
            tag.total_assets_count > tag.assets_count ?
              <Tag style={{ lineHeight: '15px', margin: '0', padding: '0 4px' }}>{tag.total_assets_count}</Tag>
              : null
          }
          <Badge
            count={tag.assets_count}
            style={{ backgroundColor: '#108ee9', color: '#fff', marginLeft: '8px', boxShadow: '0 0 0 0' }}
          />
        </div>
      </li>
      {[...tag.childTags].sort((a, b) => {
        let textA = a.name.toUpperCase();
        let textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }).map((tagChild) => (
        <Folder display={isOpen}
          level={level + 1}
          key={tagChild.id}
          tag={tagChild}
          closeMenu={closeMenu}
          forceOpen={forceOpen} />
      ))}
    </ul>
  );
};

export default React.memo(Folder);
