import React, { useEffect, useRef } from "react";
import Prism from "prismjs";

export default function PrismCode({ code, plugins, language }) {
  const codeViewer = useRef();

  useEffect(() => {
    highlight();
  }, [code]);

  const highlight = () => {
    if (codeViewer && codeViewer.current) {
      Prism.highlightElement(codeViewer.current);
    }
  };

  return (
    <div className="mb-large">
    <pre className={!plugins ? "" : plugins.join(" ")}>
      <code ref={codeViewer} className={`language-${language}`}>
        {code.trim()}
      </code>
    </pre>
    </div>
  );
}
