import React, { useEffect, useState } from 'react';
import VisitorAssetCard from './VisitorAssetCard';
import { useParams } from "react-router-dom";
import { useApiContext } from '../../providers/ApiProvider';
import { message } from 'antd';
import InfiniteScroll from './InfiniteScroll';
import VisitorFullscreen from './VisitorFullscreen';

function VisitorAssetsList({ mode, downloadMaxQuality, baseFilter = [], type = 'share', cartId = null }) {

  const [apiDispatch] = useApiContext();
  const { getRequestBasic } = apiDispatch;
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [assetId, setAssetId] = useState(null);

  useEffect(() => {
    let data = {
      token: params.token,
      filters: [
        ...baseFilter,
        { name: 'page', value: page },
        { name: 'itemsPerPage', value: 30 },
        { name: "accessType", value: type }
      ]
    }
    if (!cartId) {
      fetchAssets(data)
    } else {
      fetchCartAsset(data)
    }
  }, [page])

  async function fetchAssets(data) {
    setLoading(true)
    try {

      getRequestBasic('/api/assets/visitor', data, response => {
        if (response['@type'] === "hydra:Error") {
          message.error(response['hydra:description'])
          setLoading(false)
          return;
        }

        setAssets(prev => [...prev, ...response['hydra:member']])
        setHasMore(response['hydra:totalItems'] / 30 > page)
        setLoading(false)
      })

    } catch (e) {
      console.log(e)
      setError(true)
      message.error(e.message)
      setLoading(false)
    }
  }

  function fetchCartAsset(data) {
    setLoading(true)

    getRequestBasic(`/get-cart-folders-assets/${cartId}`, data, response => {
      if (response['@type'] === "hydra:Error") {
        message.error(response['hydra:description'])
        setLoading(false)
        return;
      }
      setAssets(prev => [...prev, ...response['hydra:member']])
      setHasMore(response['hydra:totalItems'] / 30 > page)
      setLoading(false);
    })

  }
  // console.log('assets', assets)

  function openFs(e, assetId) {
    e.stopPropagation()
    setAssetId(assetId)
  }

  return (
    <>
      {
        assets.length > 0 &&
        <div className="assets-flex list" style={{ padding: '0 1em' }}>
          <div className={`assets-${mode}-grid`}>
            {assets.map(item => (
              <div key={item.id} className="grid-item">
                <VisitorAssetCard item={item} handleClick={openFs} maxQuality={downloadMaxQuality} />
              </div>
            ))}
          </div>
        </div>
      }
      <InfiniteScroll
        page={page}
        setPage={setPage}
        loading={loading}
        hasMore={hasMore}
      />
      {!!assetId && <VisitorFullscreen assets={assets} assetId={assetId} setAssetId={setAssetId} maxQuality={downloadMaxQuality} />}
    </>
  )
}

export default VisitorAssetsList

