import React, {useEffect, useState} from "react";
import PrismCode from '../components/Prismjs'
import {Modal, Spin, message, Button} from "antd";
import {useTranslation} from "react-i18next";

const dummyrequest =
  `fetch("https://api.hapidam.com/tags?key={api_key}")
  .then(response => {console.log(response);})
  .catch(err =>console.error(err))`


export default function DocumentationTesting({url}) {

  const {t} = useTranslation();
  const [request, setRequest] = useState(dummyrequest)
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)

  useEffect(() => {
    // console.log(url)
    if (url === null) return
    if (url.includes('https://api.hapidam.com/share')) {
      fetchImage()
    } else {
      setRequest(
        `fetch("${url}")
        .then(response => {console.log(response);})
        .catch(err =>console.error(err))`)
      fetchResults(url)
    }
  }, [url])

  const fetchResults = (url) => {
    setLoading(true)
    fetch(url)
      .then(response => {
        // console.log(response);
        return response.json().then(function (json) {
          // traitement du JSON
          // console.log(json)
          setResponse(json)
          setLoading(false)
        });
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const fetchImage = async () => {

    let image = new Image()
    image.src = url

    try {
      await image.decode()
      console.log(image)
      setImageUrl(url)
    } catch (e) {
      console.log(e)
      message.error('Cette image n\'existe pas')
      return {error: true}
    }

  }

  return (
    <>
      <h3 style={{color: "white"}}>Request</h3>
      <PrismCode
        code={request}
        language="js"
        plugins={["line-numbers"]}
      />
      <h3 style={{color: "white"}}>Response</h3>
      <Spin spinning={loading}>
        <PrismCode
          code={JSON.stringify(response, undefined, 2)}
          language="js"
          plugins={["line-numbers"]}
        />
      </Spin>
      <Modal title={url} visible={!!imageUrl}
             onCancel={() => setImageUrl(null)}
             width="auto"
             centered={true}
             style={{textAlign: "center"}}
             footer={[
               <Button key="back" onClick={() => setImageUrl(null)}>
                 {t('Retour')}
               </Button>
             ]}
      >
        <img src={imageUrl} alt=""
             style={{marginTop: 20, maxHeight: '80vh', maxWidth: '90vw'}}
        />
      </Modal>
    </>
  )
}
