import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../providers/AccountProvider";
import { useApiContext } from "../../../providers/ApiProvider";


export const useCarts = () => {

    const { t } = useTranslation()
    const [accountState, accountDispatch] = useAccountContext();
    const { setter } = accountDispatch;
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResourceWithFilter } = apiDispatch;

    async function postCart(cartId, assetsArray, fromCard = true) {

        let cart = accountState.carts.find(item => item['@id'] === cartId);
        let hasAsset = cart.assets.filter(asset => asset['@id'] === '/api/assets/' + assetsArray[0]).length === 1;
        let action = fromCard && hasAsset ? 'remove' : 'add'
        let data = {
            "id": cart.id,
            "filters": [
                { name: 'assets', value: assetsArray },
                { name: 'action', value: action }
            ]
        }

        await apiFetchSubResourceWithFilter("tags", data, 'updateAssets', (response) => {
            if (response['@type'] === "hydra:Error") {
                message.error(t(response['hydra:description']))
            } else {
                message.info(t('Panier mis à jour avec succès'))
            }
            setter('cartsNeedUpdate', (new Date()).getTime())
        });
    }

    return { postCart }

}