import React from "react";

export default function Eps(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <path d="m52 43v14a2.006 2.006 0 0 1 -2 2h-40a2.006 2.006 0 0 1 -2-2v-14l1-1h42z" fill="#ef7348" />
      <path d="m52 14v29h-44v-40a2.006 2.006 0 0 1 2-2h29a1.275 1.275 0 0 1 .2.01 4.511 4.511 0 0 1 3.2 1.39l8.2 8.2a4.511 4.511 0 0 1 1.39 3.2 1.275 1.275 0 0 1 .01.2z" fill="#e9edf3" /><path d="m51.99 13.8a2.006 2.006 0 0 0 -1.99-1.8h-6a3 3 0 0 1 -3-3v-6a2.006 2.006 0 0 0 -1.8-1.99 4.511 4.511 0 0 1 3.2 1.39l8.2 8.2a4.511 4.511 0 0 1 1.39 3.2z" fill="#c1cfdd" /><rect fill="#ef7348" height="6" rx="2" width="6" x="14" y="30" /><rect fill="#ef7348" height="6" rx="2" width="6" x="27" y="17" /><rect fill="#ef7348" height="6" rx="2" width="6" x="40" y="30" /><g fill="#262626"><path d="m10 60h40a3 3 0 0 0 3-3v-43a5.549 5.549 0 0 0 -1.694-4.108l-8.2-8.2a5.544 5.544 0 0 0 -4.106-1.692h-29a3 3 0 0 0 -3 3v5a1 1 0 0 0 2 0v-5a1 1 0 0 1 1-1h29a1 1 0 0 1 1 1v6a4 4 0 0 0 4 4h6a1 1 0 0 1 1 1v28h-42v-30a1 1 0 0 0 -2 0v45a3 3 0 0 0 3 3zm32-51v-5.586l7.586 7.586h-5.586a2 2 0 0 1 -2-2zm9 35v13a1 1 0 0 1 -1 1h-40a1 1 0 0 1 -1-1v-13z" /><path d="m39 52a1 1 0 1 1 -.867 1.5 1 1 0 0 0 -1.731 1 3 3 0 1 0 2.598-4.5 1 1 0 1 1 .867-1.5 1 1 0 0 0 1.731-1 3 3 0 1 0 -2.598 4.5z" /><path d="m28 56a1 1 0 0 0 1-1v-3h2a3 3 0 0 0 0-6h-3a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1zm1-8h2a1 1 0 0 1 0 2h-2z" /><path d="m19 56h5a1 1 0 0 0 0-2h-4v-2h2a1 1 0 0 0 0-2h-2v-2h4a1 1 0 0 0 0-2h-5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1z" /><path d="m48 20a1 1 0 0 0 -1-1h-13a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3h-13a1 1 0 0 0 0 2h7.281c-3.158 2.362-3.988 6.2-4.2 8h-.081a3 3 0 0 0 -3 3v2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3v-2a3 3 0 0 0 -2.928-2.993c.272-2.233 1.608-7.439 7.934-7.945a3 3 0 0 0 2.994 2.938h2a3 3 0 0 0 2.994-2.938c6.3.508 7.649 5.711 7.93 7.946a3 3 0 0 0 -2.924 2.992v2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3v-2a3 3 0 0 0 -3-3h-.077c-.216-1.8-1.046-5.638-4.2-8h7.277a1 1 0 0 0 1-1zm-29 12v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm26 0v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm-13-11a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1z" />
    </g>
    </svg>
  )
}
