import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Input, message, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalApiPermission({visible, needUpdate, setNeedUpdate, setVisible, account}) {

  const {t} = useTranslation();
  const [value, setValue] = useState(null)
  const [apiDispatch] = useApiContext();
  const {apiPostEntity} = apiDispatch;


  const onCancel = () => {
    setNeedUpdate(needUpdate + 1)
    setVisible(false);
  }

  const handleCreate = () => {
      console.log(value)
     let domain = value.trim()
    if (domain.length < 1) {return}

    apiPostEntity('permissions', {domain, account: account['@id'], tags: []}, response => {
      // console.log(response)
      if (response['@type'] === 'hydra:Error') {
        return message.error(t('Il y a eu une erreur'));
      }

      setValue('')
      message.success(t('Acces crée avec succes'))
      onCancel()

    })
  }


  const content = (
    <div>
      <p>Nom de domaine</p>
        <Input placeholder={'example.com'}  value={value} onChange={(e) => setValue(e.target.value)}/>
    </div>
  )

  return (
    <Modal
      visible={visible}
      centered
      title={t("Créer un acces API")}
      closable={true}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={handleCreate}
    >
      {content}
    </Modal>
  )
}
