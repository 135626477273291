import React, { useMemo, useEffect, useState } from 'react'
import { Divider, Input, message, Spin, Modal, Button, TreeSelect } from 'antd';
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from "../providers/ApiProvider";
import { useTranslation } from 'react-i18next';
import TagsTreeDraggable from "./tag/TagsTreeDraggable";
import { useAuthContext } from "../providers/AuthProvider";
import ModalTreeChoice from "./modal/ModalTreeChoice";

const { confirm } = Modal

const EditDirectories = ({ forcedAccount = false, showTitle = true, reload = 0, createAccount = false }) => {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const {
    apiPostEntity,
    apiFetchSubResourceWithFilter,
    apiUpdateEntity,
    apiFetchCollection
  } = apiDispatch;
  const [accountState] = useAccountContext();

  const [defaultDirectory, setDefaultDirectory] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(1)
  const account = !forcedAccount ? accountState.account : forcedAccount;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState(null)

  useEffect(() => {
    setLoading(true);
    fetchTags();
  }, [needUpdate, reload])

  const fetchTags = () => {
    let params = { filters: [{ name: 'account', value: account.id }] };
    apiFetchCollection('get_folders', params, (response) => {
      setDefaultDirectory(response);
      setLoading(false);
    });
  }

  const showRenameConfirm = (tag) => {
    confirm({
      title: 'Voulez vous renommer les images avec le nouveau nom du dossier ?',
      content: 'Cette action va modifier le nom de vos images déjà triées avec le renommage automatique, en remplaçant l\'ancien nom du dossier par le nouveau.',
      okText: 'Oui remplacer',
      cancelText: 'Non conserver l\'ancien',
      onOk() {
        const params = {
          id: account.id,
          filters: [
            {
              name: 'change_folder_name',
              value: true
            },
            {
              name: 'folder_id',
              value: tag.id
            }]
        }
        console.log(params)
        apiFetchSubResourceWithFilter('accounts', params, 'rename_assets', response => {
        })
      },
      onCancel() {
      },
    });
  }


  const updateTag =  async (tag, data) => {

    await apiUpdateEntity('tags', tag.id, data, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.warning(t('Une erreur s\'est produite lors de la mise à jour d\'un dossier, actualiser la page pour être sûr des modifications effectuées'))
        return setNeedUpdate(needUpdate + 1);
      }

      message.success('Dossier modifié avec succès')
      if (data.name && (tag.assets_count > 0 || tag.childTags.length > 0)) {
        showRenameConfirm(response);
      }
    })
  }

  const createTag = async (tag, parent) => {
    setLoading(true)
    parent ? tag.parentTag = parent : '';
    tag.account = account['@id'];
    tag.childTags = [];

    await apiPostEntity('tags', tag, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.warning(t('Une erreur s\'est produite lors de la création d\'un dossier, actualiser la page pour être sûr des modifications effectuées'))
        return setNeedUpdate(needUpdate + 1);
      }
      return response;
    })
  }

  const deleteTag = async (item) => {
    await apiPostEntity('deep-remove-directory', { directoryId: item.id }, (response) => {
      if (response['@type'] === 'hydra:Error') {
        message.warning(t('Une erreur s\'est produite lors de la suppression d\'un dossier, actualiser la page pour être sûr des modifications effectuées'))
        setNeedUpdate(needUpdate + 1);
      } else {
        message.success(t('Dossier supprimer avec succès'));
      }
    })
  }

  const handleAdd =  async (value, parent) => {
    value = value.trim()
    if (accountState.editor && accountState.tags.length > 0 && !parent)
      return message.warning(t('Vous devez sélectionner un dossier'))

    if (value !== undefined && value.length <= 1)
      return message.warning(t('Le nom d\'un dossiers doit contenir au moins deux caractères'));

    if (value.toLowerCase() === 'corbeille' || value.toLowerCase() === 'trash')
      return message.warning(t('Ce nom est réservé'))

    let newTag = {
      'account': account['@id'],
      "name": value,
      "type": 1,
      "key": "0-" + defaultDirectory.length,
      "childTags": []
    }
    await createTag(newTag, parent);
    setNeedUpdate(needUpdate + 1)
    setValue('');
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  function exportInDb() {
    if (defaultDirectory.length) {
      apiPostEntity("save-arbo-from-account", { account: account.id }, response => {
        message.success(t("Export réussi avec succès"))
      })
    } else {
      message.error(t("Pas de dossier"))
    }
  }

  const buildSelectFolder = (folders) => {
    return folders.map(folder => <TreeSelect.TreeNode value={folder['id']} key={folder.id} title={folder.name}>
      {folder.childTags.length ? buildSelectFolder(folder.childTags) : null}
    </TreeSelect.TreeNode>)
  }

  const selectBefore = (
    <TreeSelect placeholder={accountState.editor && accountState.tags.length > 0 ? "Dans le dossier" : "A la racine"} value={selectedFolder} style={{ width: 150 }}
      onChange={setSelectedFolder} allowClear
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    >
      {
        buildSelectFolder(defaultDirectory)
      }
    </TreeSelect>
  );


  return (
    <Spin spinning={loading} tip={t('Chargement des dossiers')}>
      {showTitle ?
        <Divider>{t('Éditer votre arborescence')}</Divider>
        : ''}
      {!loading && !createAccount && auth.roles.includes("ROLE_ADMIN") && defaultDirectory.length
        ? <Button
          className="ml"
          style={{ float: "right" }} icon="upload"
          type="primary" size="small"
          onClick={exportInDb}
        >
          {t("Export")}
        </Button> : null}
      {!loading && !createAccount && accountState.admin && !defaultDirectory.length
        ? <Button
          style={{ float: "right" }} icon="download"
          type="primary" size="small"
          onClick={() => setVisible(true)}
        >
          {t("Importer arbo")}
        </Button> : null}
      <h4>{t('Ajouter un dossier')}</h4>
      <Input.Search addonBefore={accountState.editor && accountState.tags.length > 0 ? selectBefore : null} style={{ maxWidth: '100%' }} value={value} onChange={handleChange}
        onSearch={(value) => { handleAdd(value, selectedFolder) }} enterButton={t('Ajouter')} placeholder={t('Nom du dossier')} loading={loading}/>
      <TagsTreeDraggable defaultDirectory={defaultDirectory} setDefaultDirectory={setDefaultDirectory}
        addTag={handleAdd} updateTag={updateTag} deleteTag={deleteTag}
      />
      <ModalTreeChoice
        visible={visible}
        setVisible={setVisible}
        accountsIds={[account.id]}
        reloadTags={() => setNeedUpdate(needUpdate + 1)}
      />
    </Spin>
  )
}

export default EditDirectories;
