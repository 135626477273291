import React from "react";

export default function PowerPoint(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512" width='100%'
    >
      <path
        fill={props.fill}
        d="M365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448ZM128 240C119 240 112 247 112 256V432C112 441 119 448 128 448S144 441 144 432V384H200C240 384 272 352 272 312S240 240 200 240H128ZM240 312C240 334 222 352 200 352H144V272H200C222 272 240 290 240 312Z"
      />
    </svg>

  )
}
