import React, {useState} from 'react'
import {Button, Modal, TreeSelect, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";

const {TreeNode} = TreeSelect;

export default function ModalSortByAccount({visible, setVisible, itemsChecked, setItemsChecked}) {

  const [apiDispatch] = useApiContext();
  const {patch} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const {rerender} = accountDispatch;

  const {t} = useTranslation();
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)

  function onChange(data) {
    setValue(data)
  }

  function handleOk() {
    setLoading(true)
    const data = {
      account: value,
      ids: itemsChecked.map(i => i.id)
    }
    patch('/api/change-account', data, response => {
      close()
    })
  }

  function close() {
    setValue(null)
    setLoading(false)
    setVisible(false)
    setItemsChecked(new Set())
    rerender()
  }

  return (
    <Modal
      visible={visible}
      title={t('Choisissez un compte')}
      closable={true}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
      okText={t('Valider')}
      onOk={handleOk}
    >
      <Spin spinning={loading}>
        <TreeSelect
          showSearch
          style={{width: '100%'}}
          value={value}
          dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
          placeholder="Please select"
          allowClear
          treeDefaultExpandAll
          onChange={onChange}
          treeNodeFilterProp="title"
        >
          <TreeNode value={accountState.account.id} title={accountState.account.name}>
            {accountState.account.childAccount ?
              accountState.account.childAccount.map(child => (
                <TreeNode key={child.id} value={child.id} title={child.name}/>
              ))
              : null}
          </TreeNode>
        </TreeSelect>
      </Spin>
    </Modal>
  )
}
