import React from 'react'
import DocumentIcon from './DocumentIcon';
import VideoView from './VideoView';
import Placeholder from '../../assets/img_placeholder.svg'
import { useAccountContext } from '../../providers/AccountProvider';

const shareLink = process.env.API_ENTRYPOINT + '/share/';

export default function MediaView({ asset, title, share = false, handleClick = () => { } }) {

    const [accountState] = useAccountContext();
    const { appearance } = accountState
    const media = asset.currentMedia ? asset.medias.find(media => media.id === asset.currentMedia) : asset.medias[0];
    const transparentBg = media && (media.mimeType === 'image/png' || media.mimeType === 'image/svg' || media.mimeType === 'image/svg+xml');

    function getUrl() {

        if (!share && !media.extraUrl) return Placeholder;
        if (media.mimeType === 'image/gif') return share ? `${shareLink}${asset?.uuid}/${encodeURIComponent(asset?.usageName)}` : media.contentUrl
        return share ? `${shareLink}${asset?.uuid}/${encodeURIComponent(asset?.usageName)}?size=sm` : media.extraUrl.sm.url
    }

    if (!media) return <></>
    if (asset.type === 3)
        return <VideoView asset={asset} onClick={handleClick} title={appearance.size === 'small' ? null : title} />

    if (asset.type === 1)
        return <img
            className={`pointer ${appearance?.card === 'list' && 'thumb-classical'} ${transparentBg ? 'contain bg-pattern-'+appearance?.background : ''}`}
            onClick={handleClick} loading="lazy"
            src={getUrl()} />

    if (asset.type === 2) {
        return <DocumentIcon onClick={handleClick} asset={asset} title={title} />
    }

    return (<></>)
}
