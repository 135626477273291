import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAccountContext } from "../../../providers/AccountProvider";
import { getResponse, getUrlParam } from '../helpers';
import AssetsList from './AssetsList';
import ModalSelection from "./ModalSelection";
import widget from "../WidgetComponent.js"; // client/widget component

const Assets = () => {

  const [accountState, accountDispatch] = useAccountContext();
  const { setActiveTab } = accountDispatch
  const search = new URLSearchParams(window.location.search)
  const multiple = search.get('multiple') && search.get('multiple') === 'true';
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(new Set());

  useEffect(() => {
    if (accountState.activeTab === '1') {
      setActiveTab('2')
    }
  }, [accountState.activeTab])

  function handleValidate(e) {
    e.stopPropagation()
    e.preventDefault()
    let funcNum = getUrlParam('CKEditorFuncNum');
    const response = accountState.selection.map(item => {
      let value = getResponse(item);
      if (funcNum)
        value.funcNum = funcNum

      return value;
    })
    setVisible(false)

    widget.fire('select', response, 'broadcast');
    if (funcNum) window.close()
  }

  return (
    <div style={{ height: 'calc(100vh - 55px)', position: 'relative' }}>

      {accountState.activeTab === "2" &&
        <AssetsList setSelected={setSelected} selected={selected} />
      }

      {accountState.activeTab === "5" &&
        <AssetsList
          setSelected={setSelected} selected={selected}
          baseFilters={[{ "name": "type", "value": "2" }]}
        />
      }

      {accountState.activeTab === "6" &&
        <AssetsList
          setSelected={setSelected} selected={selected}
          baseFilters={[{ "name": "type", "value": "3" }]}
        />
      }

      {!!(multiple && selected.size) &&
        <div style={{ position: "absolute", bottom: 20, left: '50%', transform: "translateX(-50%)", zIndex: 1 }}>
          <Button className="mr" onClick={() => setVisible(true)}>Voir la sélection</Button>
          <Button type="primary" onClick={handleValidate} >Valider cette selection</Button>
        </div>}

      {!!visible &&
        <ModalSelection
          visible={visible} setVisible={setVisible} selection={selected} setSelection={setSelected}
          handleValidate={handleValidate}
        />}

    </div>
  );
}

export default Assets;
