import React, { useState } from 'react'
import { Radio, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const defaultCrop = { width: 100, height: 100, unit: 'px', x: 0, y: 0 }
const radioStyle = {
    display: 'block',
    height: '25px',
    lineHeight: '25px',
};

export default function Crop({ save, crop, setCrop }) {

    const { t } = useTranslation();
    const [aspect, setAspect] = useState(undefined)

    useEffect(() => {
        if ((!crop.width || !crop.height) && !crop.aspect)
            return setCrop({ ...defaultCrop })

    }, [crop])

    const handleAspectSelect = (e) => {
        let value = e.target.value;
        let data = { ...crop }
        if (value) {
            data.aspect = value
            delete data.height
        } else {
            data.aspect = null
        }
        console.log(data)
        setAspect(value)
        setCrop(data);
    }

    const handleSave = () => {
        setAspect(undefined)
        save('crop')
    }


    return (
        <div style={{ padding: 10 }}>
            <p>{t('Ratio de la zone')} : </p>
            <Radio.Group onChange={handleAspectSelect} value={aspect}>
                <Radio style={radioStyle} value={undefined}>Libre</Radio>
                <Radio style={radioStyle} value={16 / 10}>16/10       </Radio>
                <Radio style={radioStyle} value={16 / 9} >16/9        </Radio>
                <Radio style={radioStyle} value={4 / 3}  >4/3         </Radio>
                <Radio style={radioStyle} value={1}      >Carré       </Radio>
                <Radio style={radioStyle} value={2 / 3}  >2/3 (iphone)</Radio>
                <Radio style={radioStyle} value={3 / 5}  >3/5         </Radio>
            </Radio.Group>
            <div style={{ margin: '1em 0' }}>
                <Button type="primary" icon="scissor" onClick={handleSave} >{t('Valider')}</Button>
            </div>
        </div>
    )
}
