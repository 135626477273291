import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {Button, Input, message, Popconfirm, Popover, Select, Spin, Tag} from 'antd';

const {Option} = Select;
const {Search} = Input;

export default function AdminTagProposition({apiFetchCollection, apiUpdateEntity, apiPostEntity, apiDeleteEntity}) {

  const {t, i18n} = useTranslation();
  const [family, setFamily] = useState([])
  const [needUpdate, setNeedUpdate] = useState(0)
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState(null)
  const [tagList, setTagList] = useState(null)
  const [activeCat, setActiveCat] = useState("default")
  const [activeFam, setActiveFam] = useState("default")


  useEffect(() => {
    fetchTags()
  }, [needUpdate])

  const fetchTags = async () => {
    let params = {
      filters: [{
        name: "exists[parent]",
        value: false
      }]
    }
    apiFetchCollection('tag_admins', params, response => {
      //console.log(response)
      setFamily(response['hydra:member'])
      build(response['hydra:member'])
      setLoading(false)
    })
  }

  function build(object) {
    if (category) {
      if (object.filter(fam => fam.id === Number(activeFam) && fam.children).length === 1) {
        setCategory(object.filter(fam => fam.id === Number(activeFam))[0].children)
      } else {
        setCategory(object.filter(fam => fam.id === Number(activeFam))[0]['@id'])
      }
      if (tagList) {
        let cat = object.filter(fam => fam.id === Number(activeFam))[0].children
        if (cat.filter(cat => cat.id === Number(activeCat) && cat.children).length === 1) {
          setTagList(cat.filter(cat => cat.id === Number(activeCat))[0].children)
        } else {
          setTagList(cat.filter(cat => cat.id === Number(activeCat))[0]['@id'])
        }
      }
    }
  }

  function onFamilyChange(value) {
    setActiveFam(value)
    setTagList(null)
    setActiveCat('default')
    if (value === "default") {
      setCategory(null)
      return;
    }
    if (family.filter(fam => fam.id === Number(value) && fam.children).length === 1) {
      setCategory(family.filter(fam => fam.id === Number(value))[0].children)
      return;
    }
    setCategory(family.filter(fam => fam.id === Number(value))[0]['@id'])
  }

  function onCategoryChange(value) {
    setActiveCat(value)
    if (value === "default") {
      setTagList(null)
      return;
    }
    if (category.filter(cat => cat.id === Number(value) && cat.children).length === 1) {
      setTagList(category.filter(cat => cat.id === Number(value))[0].children)
      return;
    }
    setTagList(category.filter(cat => cat.id === Number(value))[0]['@id'])
  }

  function defineParent(type, data) {
    switch (type) {
      case 'category':
        typeof category === "object" ? data.parent = category[0].parent : data.parent = category;
        break;
      case 'tag':
        typeof tagList === "object" ? data.parent = tagList[0].parent : data.parent = tagList;
        break;
      default:
        break;
    }
  }

  function ifExist(type, value) {
    if (value.length < 2) {
      return true;
    }
    switch (type) {
      case 'family':
        if (typeof family === "object" && family.filter(fam => fam.value.toLowerCase() === value.toLowerCase()).length === 1) {
          return true;
        }
        break;
      case 'category':
        if (typeof category === "object" && category.filter(cat => cat.value.toLowerCase() === value.toLowerCase()).length === 1) {
          return true;
        }
        break;
      case 'tag':
        if (typeof tagList === "object" && tagList.filter(tag => tag.value.toLowerCase() === value.toLowerCase()).length === 1) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  }

  function beforePost(value, type) {
    if (ifExist(type, value.trim())) {
      return;
    }
    let data = {
      value: value.trim(),
      label: value.trim(),
    }
    defineParent(type, data)
    post(data, type)
  }

  function post(data, type) {
    apiPostEntity('tag_admins', data, (response) => {
      setLoading(true)
      //console.log(response)
      message.success(response.value + ' ' + t('Enregistré avec succès'));
      setNeedUpdate(needUpdate + 1);
    })
  }

  function beforeDel(type, id = null) {
    if ((type === 'family' && activeFam === 'default') || (type === 'category' && activeCat === "default")) {
      return;
    }
    switch (type) {
      case 'family':
        del(activeFam, type);
        setActiveFam('default');
        setCategory(null);
        setTagList(null);
        break;
      case 'category':
        del(activeCat, type);
        setActiveCat('default');
        setTagList(null);
        break;
      case 'tag':
        del(id, type);
        break;
    }
  }

  function del(id) {
    apiDeleteEntity('tag_admins', id, response => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t('Impossible de suprimmer cette elément, veuillez supprimer tous ses enfants'))
      } else {
        message.success(t('Supression terminée'))
        setLoading(true)
        setNeedUpdate(needUpdate + 1)
      }
    })
  }

  function beforeEdit(value, type) {
    if (ifExist(type, value.trim())) {
      return;
    }
    setLoading(true)
    let id = type === 'family' ? activeFam : activeCat;
    edit(value, id)
  }

  function edit(value, id) {
    let data = {
      value: value,
      label: value
    }
    apiUpdateEntity('tag_admins', id, data, response => {
      // console.log(response)
      setNeedUpdate(needUpdate + 1)
    })
  }

  return (
    <div className="contenu-tab">
      <Spin spinning={loading}>
        <div className='admin-tag-propositions'>
          <div style={{padding: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <h3>{t('Choisissez une famille')}</h3>
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <Popover
                    content={(
                      <Search
                        enterButton={t('Enregistrer')}
                        size="small"
                        onSearch={(value) => beforePost(value, 'family')}
                      />
                    )}
                    trigger="click"
                  >
                    <Button type="dashed" size="small">{t('Nouveau')}</Button>
                  </Popover>
                </div>
                <div>
                  <Popover
                    content={(
                      <Search
                        enterButton={t('Editer')}
                        size="small"
                        onSearch={(value) => beforeEdit(value, 'family')}
                      />
                    )}
                    trigger="click"
                  >
                    <Button type="dashed" size="small">{t('Editer')}</Button>
                  </Popover>
                </div>
                <div>
                  <Popconfirm title={t('Êtes-vous sûr ?')} okText={t('Oui')} cancelText={t('Non')}
                              onConfirm={() => beforeDel('family')}>
                    <Button type="dashed" size="small">{t('Supprimer')}</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
            <Select
              className='admin-tag-select'
              value={activeFam}
              onChange={onFamilyChange}
            >
              <Option key="default">-- {t('Sélectionner votre famille')} --</Option>
              {
                family.sort((a, b) => {
                  let textA = a.value.toUpperCase();
                  let textB = b.value.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map(tag => (
                  <Option key={tag.id}>{tag.value}</Option>
                ))
              }
            </Select>
          </div>
          {
            category
              ?
              <div style={{padding: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <h3>{t('Sélectionner votre catégorie')}</h3>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <Popover
                        content={(
                          <Search
                            enterButton={t('Enregistrer')}
                            size="small"
                            onSearch={(value) => beforePost(value, 'category')}
                          />
                        )}
                        trigger="click"
                      >
                        <Button type="dashed" size="small">{t('Nouveau')}</Button>
                      </Popover>
                    </div>
                    <div>
                      <Popover
                        content={(
                          <Search
                            enterButton={t('Editer')}
                            size="small"
                            onSearch={(value) => beforeEdit(value, 'category')}
                          />
                        )}
                        trigger="click"
                      >
                        <Button type="dashed" size="small">{t('Editer')}</Button>
                      </Popover>
                    </div>
                    <div>
                      <Popconfirm title={t('Êtes-vous sûr ?')} okText={t('Oui')} cancelText={t('Non')}
                                  onConfirm={() => beforeDel('category')}>
                        <Button type="dashed" size="small">{t('Sup')}</Button>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
                <Select
                  value={activeCat}
                  className='admin-tag-select'
                  onChange={onCategoryChange}
                >
                  <Option key="default">-- {t('Sélectionner votre catégorie')} --</Option>
                  {
                    typeof category === 'object'
                      ?
                      category.sort((a, b) => {
                        let textA = a.value.toUpperCase();
                        let textB = b.value.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      }).map(tag => (
                        <Option key={tag.id}>{tag.value}</Option>
                      ))
                      :
                      null
                  }
                </Select>
              </div>
              :
              null
          }
        </div>
        <div style={{padding: '20px'}}>
          {
            tagList
              ?
              <div>
                <div style={{display: 'flex'}}>
                  <h3 style={{marginRight: 30}}>{t('Liste des tags')}</h3>
                  <Popover
                    content={(
                      <Search
                        enterButton={t('Enregistrer')}
                        size="small"
                        onSearch={(value) => beforePost(value, 'tag')}
                      />
                    )}
                    trigger="click"
                  >
                    <Button type="dashed" size="small">{t('Nouveau')}</Button>
                  </Popover>
                </div>
                {
                  typeof tagList === "object"
                    ?
                    tagList.sort((a, b) => {
                      let textA = a.value.toUpperCase();
                      let textB = b.value.toUpperCase();
                      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }).map(tag => (
                      <Tag
                        key={tag.id}
                        closable
                        onClose={e => {
                          e.preventDefault();
                          beforeDel('tag', tag.id);
                        }}
                      >
                        {tag.value}
                      </Tag>

                    ))
                    :
                    null
                }
              </div>
              :
              null
          }
        </div>
      </Spin>
    </div>
  )
}
