import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, } from 'react-router-dom'
import { Icon } from 'antd'
import { useApiContext } from '../providers/ApiProvider.js';
import AssetsListGroup from '../components/asset/AssetsListGroup.js';
import { useAccountContext } from "../providers/AccountProvider";
import Documents from "../components/asset/Documents";
import Videos from "../components/asset/video/Videos";

export default function AccountGroup() {
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiFetchSubResourceWithFilter } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { accountSet, setGroup, addNotification } = accountDispatch;
  const [account, setAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirection, setRedirection] = useState(false);
  const isMounted = useRef(null);
  const params = useParams();

  useEffect(() => {
    isMounted.current = true;

    let accountID = params.account_id;
    // fetch at startup and whenever needUpdate is changed
    fetchData(accountID);

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [])

  function fetchData(accountID) {
    apiFetchSubResource('accounts', {
      id: accountID,
      withoutExt: true
    }, "get-account-with-perm", (hydra_response) => {
      if (isMounted.current) {
        if (hydra_response['@type'] === "hydra:Error") {
          setRedirection(true);
          setLoading(false);
        } else {
          if (!hydra_response.childAccount.length || !hydra_response.groupAdmin) {
            setRedirection(true)
            setLoading(false);
          }

          accountSet(hydra_response, true, true);
          setAccount(hydra_response);
          // setGroup(hydra_response)
          if (hydra_response.admin)
            fetchGroupShare(accountID)
          setLoading(false);
        }
      }
    });
  }

  function fetchGroupShare(accountID) {
    console.log(accountID)
    let params = {
      id: accountID,
      filters: [
        {
          name: 'share',
          value: true
        },
        {
          name: 'tags.name[]',
          value: '_temp_share_tag'
        }
      ]
    }

    apiFetchSubResourceWithFilter('accounts', params, 'permissions', response => {
      if (response['@type'] === "hydra:Collection") {
        if (response['hydra:member'].length > 0) {
          let text = buildList(response['hydra:member'])
          makeNotification('Derniers partages effectués', text)
        }
      }
      console.log(response)
    })
  }

  function buildList(items) {
    let list = [];
    items.map(item => {
      list.push(<p key={'mail_' + item.id}>{item.email} <a href={'/visitor/' + item.token} target="_blank"> <Icon type="link" /></a></p>)
    })
    return list;
  }

  function makeNotification(title, text) {
    const notif = {};
    notif.message = title;
    notif.description = text
    notif.id = 'share_historic'
    addNotification(notif);

  }


  if (loading)
    return <Icon type="loading" />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: { status: '404', subtitle: t('Ce groupe n\'existe pas'), page: './dashboard' }
    }} />

  if (accountState.filters.some(filter => filter.name === "type" && filter.value === "2"))
    return <Documents />

  if (accountState.filters.some(filter => filter.name === "type" && filter.value === "3"))
    return <Videos />


  return (
    <AssetsListGroup account={account} />
  );
}
