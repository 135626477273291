import React from "react";

export default function Excel(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width='100%'
    >
      <path
        fill={props.fill}
        d="M365 125L259 19C247 7 230 0 214 0H64C29 0 0 29 0 64V448C0 483 29 512 64 512H320C355 512 384 483 384 448V170C384 154 377 137 365 125ZM224 34C228 36 233 38 236 41L343 148C346 151 348 156 350 160H240C231 160 224 153 224 144V34ZM352 448C352 466 338 480 320 480H64C46 480 32 466 32 448V64C32 46 46 32 64 32H192V144C192 170 214 192 240 192H352V448ZM192 308L149 247C144 240 134 238 127 243C120 248 118 258 123 265L172 336L123 407C118 414 120 424 127 429C130 431 133 432 136 432C141 432 146 430 149 425L192 364L235 425C238 430 243 432 248 432C251 432 254 431 257 429C264 424 266 414 261 407L212 336L261 265C266 258 264 248 257 243S240 240 235 247L192 308Z"
      />
    </svg>
  )
}
