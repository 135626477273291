import React from "react"
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../providers/AccountProvider";
import ActiveFilterLight from "../../ActiveFilterLight";
import ModalAddVideo from "./ModalAddVideo";
import SocialVideos from "./SocialVideos";

const { TabPane } = Tabs;

const socialMediasFilters = {
  youtube: { type: 2, platform: 1 },
  vimeo: { type: 2, platform: 2 },
  channels: { type: 1, platform: 1 }
}

export default function Videos() {

  const { t } = useTranslation();
  const [accountState] = useAccountContext();

  const btnAdd = (accountState.editor && <ModalAddVideo />);

  return (
    <div style={{ height: "100%" }}>
      <ActiveFilterLight activeFilter="Vidéos" extra={btnAdd} />

      <div className="assets-list">

        <Tabs defaultActiveKey="channel">
          <TabPane tab="Chaine" key="channel">
            <SocialVideos type={socialMediasFilters.channels.type}
              platform={socialMediasFilters.channels.platform} />
          </TabPane>
          <TabPane tab="Youtube" key="youtube">
            <SocialVideos type={socialMediasFilters.youtube.type}
              platform={socialMediasFilters.youtube.platform} />
          </TabPane>
          <TabPane tab="Vimeo" key="vimeo">
            <SocialVideos type={socialMediasFilters.vimeo.type}
              platform={socialMediasFilters.vimeo.platform} />
          </TabPane>
        </Tabs>
      </div>

    </div>
  )
}
