import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, message, Progress, Radio, Tree, Upload } from 'antd';
import { useAuthContext } from '../../providers/AuthProvider.js';
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";
import { getAcceptedTypes } from './helpers.js';

const { DirectoryTree } = Tree;

let totalSize = 0

const UploadFolder = ({ visible, closeModal, setIsUploading }) => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { addNotification } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiFetchSubResourceWithFilter, apiFetchEntity } = apiDispatch;
  const isMounted = useRef(false);
  const [directories, setDirectories] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadAll, setUploadAll] = useState(false)
  const maxUpload = 64;
  const [displayChoice, setDisplayChoice] = useState(false)
  const [choiceA, setChoiceA] = useState([])
  const [choiceB, setChoiceB] = useState([])
  const [arbo, setArbo] = useState([])
  const [nbFilesSave, setNbFilesSave] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [freeSpace, setFreeSpace] = useState(0)
  const acceptedType = getAcceptedTypes(false)

  useEffect(() => {
    isMounted.current = true;
    setLoading(true)
    if (visible) {
      fetchTags()
      fetchStorage()
      totalSize = 0
    }

    return () => {
      isMounted.current = false;
      setLoading(false)

    }

  }, [visible]
  )

  const fetchStorage = () => {
    apiFetchEntity('storages', accountState.account.storage.id, response => {
      if (isMounted.current) {
        setFreeSpace(response.maxSize - response.currentSize)
      }

    })
  }

  function fetchTags() {
    const params = {
      id: accountState.account.id,
      filters: [{
        id: 'directory',
        name: 'type',
        value: 1
      },
      {
        id: 'pagination',
        name: 'pagination',
        value: 'false'
      }
      ]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'tags', (response) => {
      if (isMounted.current) {
        setDirectories(response['hydra:member'])
        setLoading(false)
      }
    })
  }

  let passBeforeUpload = false;
  let fileDone = 0;
  let total = 0
  let failedFiles = [];
  let nbFiles = 0;

  const beforeUpload = (file, fileList) => {
    if (!passBeforeUpload) {
      passBeforeUpload = true
      let folders = handleFileList([...fileList])
      if (total > freeSpace) {
        message.error(t('Vous n\'avez plus assez d\'espace pour uploader ces images, veuillez en libérer depuis les réglages du compte ou passer a un compte prenium plus'));
        return false;
      }

      totalSize = total
      if (folders.length > 0) {
        if (folders[0].children.length > 0) {
          let arrA = [], arrB = [];
          makeTree(folders, arrA, arrB, false)
          setDisplayChoice(true)
          alphabeticallySort(arrB)
          alphabeticallySort(arrA)
          setChoiceA(arrA)
          setChoiceB(arrB)
          setArbo(folders)
        } else {
          console.time('count')
          setIsUploading(true)
          setUploading(true)
          setDisplayChoice(false)
          handleTags(folders)
        }
      }

    }
    return false
  }

  function alphabeticallySort(arr) {
    arr.sort((a, b) => a.title.localeCompare(b.title))

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children && arr[i].children.length > 0) {
        alphabeticallySort(arr[i].children)
      }
    }
  }

  const makeTree = (folders, arrA, arrB, pushAll = true) => {
    for (let i = 0; i < folders.length; i++) {
      arrA.push({ title: folders[i].name, key: folders[i].name })
      if (pushAll) {
        arrB.push({ title: folders[i].name, key: folders[i].name });
      }
      if (folders[i].children && folders[i].children.length > 0 && !pushAll) {
        arrA[i].children = [];
        makeTree(folders[i].children, arrA[i].children, arrB);
      } else {
        arrA[i].children = [];
        arrB[i].children = [];
        makeTree(folders[i].children, arrA[i].children, arrB[i].children);
      }
    }
  }

  // get the fileList and format it to a better structure
  const handleFileList = (fileList) => {
    let folders = [];
    let wrongFiles = 0;
    for (let i = 0; i < fileList.length; i++) {
      const isUnderLimit = fileList[i].size / 1024 / 1024 < maxUpload;
      const isAcceptedType = acceptedType.includes(fileList[i].type);
      if (isAcceptedType && isUnderLimit) {
        total += fileList[i].size
        let path = fileList[i].webkitRelativePath.split('/');
        // taking of the name of the file at the end of the path
        path.pop();
        // console.log(path)
        let folder = folders.filter(item => {
          if (item.name === path[path.length - 1] && (item.parent === null && path.length < 2 || item.parent === path[path.length - 2])) {
            return item
          }
        })[0]
        if (folder) {
          folder.files = [...folder.files, fileList[i]]
        } else {
          folders = createFolders(folders, path, fileList[i]);
        }
      } else {
        wrongFiles++
      }
    }
    nbFiles = fileList.length - wrongFiles;
    setNbFilesSave(nbFiles)
    return formatFolders(folders);
  }

  // fonction a appelé a la validation du choix
  const handleChoice = () => {
    setIsUploading(true)
    setUploading(true)
    setDisplayChoice(false)
    console.time('count')
    if (uploadAll) {
      nbFiles = nbFilesSave
      handleTags(arbo)
    } else {
      nbFiles = nbFilesSave - arbo[0].files.length;
      handleTags(arbo[0].children)
    }
  }

  const handleTags = (tags, parentIri = null) => {
    tags.map(tag => {
      tag = checkExistingTags(tag, parentIri)
      if (!tag.tag) {
        createTag(tag, parentIri)
      } else {
        handleFiles(tag)
      }
    })
  }

  // take the path of a file and create all the folders in it
  // if the last folder exist then it return directly
  const createFolders = (folders, path, currentFile) => {
    for (let i = 0; i < path.length; i++) {
      let folder = folders.filter(folder => folder.name === path[i] && (folder.parent === null && i === 0 || folder.parent === path[i - 1]))[0];
      if (!folder) {
        let item = {
          name: path[i],
          files: [],
          parent: i > 0 ? path[i - 1] : null,
          children: []
        }
        if (i === path.length - 1) {
          item.files = [currentFile]
        }
        folders.push(item)
      }
    }
    return folders
  }

  //Take the folder list and format it to a better structure
  // Intake an array of object and put the children of a folder in his children key
  const formatFolders = (folders) => {
    let newFolders = folders.filter(folder => folder.parent === null);
    let folderWithParent = folders.filter(folder => folder.parent);
    folderWithParent.map(item => {
      let parent = folders.filter(folder => folder.name === item.parent)[0]
      parent.children = [...parent.children, item]
    })
    return newFolders;
  }

  // Check if a folder already exist on this account
  const checkExistingTags = (item, parentIri) => {
    let existingTag = directories.filter(tag => tag.name === item.name && ((parentIri === null && tag.parentTag === undefined) || (tag.parentTag && parentIri === tag.parentTag['@id'])))[0]
    item.tag = existingTag;
    return item;
  }


  const handleFiles = async (tag) => {
    if (tag.children.length > 0) {
      handleTags(tag.children, tag.tag['@id'])
    }
    let divider = accountState.account.renameOn ? 2 : 1;
    await Promise.all(
      tag.files.map(async file => {
        await uploadMedia(file, tag.tag).then(result => {
          let fileWithProblems = []
          if (result.status) {
            tag.count++
          } else {
            file.message = result.message
            fileWithProblems.push(file)
          }
          updateResults(1 / divider, fileWithProblems)
          return 'done'
        })
      })
    )

    if (accountState.account.renameOn) {
      await renammeAssetsFolder(tag.tag)
      await updateResults(tag.files.length / divider, [])
    }
    // updateResults(tag.files.length - fileWithProblems.length, fileWithProblems)
    checkIfDone()
  }

  const renammeAssetsFolder = async (tag) => {
    const params = {
      id: accountState.account.id,
      filters: [
        {
          name: 'force_rename_folder',
          value: true
        },
        {
          name: 'folder_id',
          value: tag.id
        }]
    }
    await apiFetchSubResourceWithFilter('accounts', params, 'rename_assets', response => {
      // console.log(response)
    })

  }

  const createTag = async (tag, parent,) => {
    let params = {
      name: tag.name,
      account: accountState.account['@id'],
      parentTag: parent,
      type: 1
    }

    await apiPostEntity('tags', params, (response) => {
      tag.tag = response;
      handleFiles(tag)
    })
  }

  const uploadMedia = async (file, tag) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountState.account.id);
    formData.append('action', 'create');
    formData.append('tagId', tag.id);
    formData.append('name', file.name)
    let result = {
      status: false,
    };
    await apiPostEntity("media_objects", formData, (response) => {
      if (response['@type'] === "hydra:Error") {
        const error = response['hydra:description']
        result.message = t(error.type) + " " + error.file;

      } else {
        result.status = true
      }
    });
    return result;
  }

  const radioStyle = {
    display: 'block'
  };

  const updateResults = (count, fileWithProblems, error = null) => {
    fileDone = fileDone + count;
    setPercent(((100 / nbFiles) * fileDone));
    if (error) {
      fileWithProblems = fileWithProblems.map(file => {
        file.message = error;
        return file;
      })
    }
    failedFiles = [...failedFiles, ...fileWithProblems]
  }

  const checkIfDone = () => {
    if (fileDone >= nbFiles) {
      console.timeEnd('count');

      const dataStat = {
        type: 'upload',
        size: String(totalSize),
        accountEntity: "/api/accounts/" + accountState.account.id,
        userEntity: "/api/users/" + auth.id,
        quantity: fileDone,
      }
      apiPostEntity('statistics', dataStat, response => { })
      apiFetchSubResourceWithFilter('accounts', { id: accountState.account.id }, 'update', response => {
        setLoading(false)
        sendNotif()
        resetUpload();
        closeModal()
      })
    }
  }

  const sendNotif = () => {
    let descr = [(<p key="result">{fileDone - failedFiles.length} {t('Fichier(s) Téléchargé(s) avec succès')}</p>)];
    if (failedFiles.length > 0) {
      failedFiles.map((file, index) => {
        descr = [...descr, (
          <p key={index} style={{ color: 'red' }}>{t('Erreur')} : {file.name} - {file.message}</p>)]
      })
    }
    let notif = {
      id: 'upload',
      message: `${fileDone - failedFiles.length} ${t('Fichier(s) uploadé(s)')}`,
      description: descr
    };
    addNotification(notif);
  }

  const resetUpload = () => {
    setNbFilesSave(0)
    setUploadAll(false)
    setDisplayChoice(false)
    setChoiceA([])
    setChoiceB([])
    setArbo([])
    setUploading(false)
    setPercent(0)
    setDirectories([])
    setIsUploading(false)
  }

  return (
    <div>
      {
        !uploading && !displayChoice ?
          <div>
            <p>{t('Vous pouvez choisir directement un dossier, ce dossier ainsi que ces sous dossiers seront automatiquement crées avec les photos correspondantes')}</p>
            <p>{t('Si le renommage automatique est activé, celle-ci seront également renommer')}</p>
            <Upload
              directory
              showUploadList={false}
              name="file"
              beforeUpload={beforeUpload}
              disabled={loading}
            >
              <Button type="primary" loading={loading}>
                <Icon type="upload" /> {t('Importer un dossier')}
              </Button>
            </Upload>
          </div>
          : null
      }
      {
        displayChoice ?
          <div>
            <h4>{t('Prendre en compte le dossier racine de l\'import')} :</h4>
            <p>{nbFilesSave} {t('Image(s) peuvent être importée(s)').toLowerCase()}</p>
            <Radio.Group style={{ width: '100%' }} value={uploadAll} onChange={(e) => setUploadAll(e.target.value)}>
              <Radio style={radioStyle} value={true}>
                {t('Oui')}<br />
                &nbsp;&nbsp;&nbsp;&nbsp;{t('Résultat')} :
                <DirectoryTree
                  style={{ width: '100%' }}
                  className='example-tree'
                  multiple
                  defaultExpandAll
                  treeData={choiceA}
                />
              </Radio>
              <Radio style={radioStyle} value={false}>
                {t('Non')} ({t('Les fichiers dans le dossier racine ne seront pas importés').toLowerCase()})<br />
                &nbsp;&nbsp;&nbsp;&nbsp;{t('Résultat')} :
                <DirectoryTree
                  style={{ width: '100%' }}
                  className='example-tree'
                  multiple
                  defaultExpandAll
                  treeData={choiceB}
                />
              </Radio>
            </Radio.Group>
            <Button type="primary" onClick={handleChoice}>{t('Lancer l\'import')}</Button>
          </div>
          : null
      }
      {
        uploading
          ?
          <div style={{ textAlign: 'center' }}>
            <Progress
              style={{ margin: '20px auto' }}
              type="circle"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              percent={Math.round(percent)}
            />
            <div style={{ textAlign: 'left' }}>
              <p>
                {percent > 15 ?
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> :
                  <Icon type="sync" spin />
                } {t('Création des dossiers')}</p>
              {percent > 15 ?
                <p>{percent > 45 ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> :
                  <Icon type="sync" spin />} {t('Upload des images')}</p> : null
              }
              {percent > 45 ?
                <p>{percent > 75 ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> :
                  <Icon type="sync" spin />} {t('Optimisation des images')}</p> : null
              }
              {percent > 75 ?
                <p>{percent > 99 ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> :
                  <Icon type="sync" spin />} {t('Classement automatiques dans les dossiers correspondants')}</p> : null
              }
            </div>
          </div>
          :
          null
      }

    </div>
  );
}
  ;

export default UploadFolder;
