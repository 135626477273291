import React  from 'react';
import {Redirect} from 'react-router-dom';

import { useAuthContext } from '../providers/AuthProvider.js';

import UserList from '../components/user/UserList.js';
import {useTranslation} from "react-i18next";

const Users = () => {

  const {t, i18n} = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const isSuper  = auth.roles.includes('ROLE_ADMIN');

  if (!isSuper) {
    return <Redirect to={{
      pathname: "/erreur",
      state: {status: '403', subtitle: t('Vous n\'êtes pas autorisé à accéder à cette page')}
    }}/>
  }


  return (
    <div className="contenu-tab">
      <h2>{t('Gestion des utilisateurs')}</h2>
      <UserList />
    </div>
  );
};

export default Users;
