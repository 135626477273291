import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Dropdown, Icon, message, Tooltip } from 'antd';
import ModalRenameAsset from '../components/modal/ModalRenameAsset';
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from '../providers/ApiProvider.js';
import { useTranslation } from "react-i18next";
import ModalFreemium from "../components/modal/ModalFreemium";
import MediaSidebar from '../components/media/MediaSidebar';
import ImageEdit from '../components/media/ImageEdit/ImageEdit';
import MediaView from '../components/media/MediaView';
import Download from '../components/asset/Download';
import { useMediaQuery } from 'react-responsive';
import ContextMenu from '../components/Widget/v2/ContextMenu'

const ButtonGroup = Button.Group;

export default function Media({ assets, assetId, setAssetId, updateAsset, isWidget = false }) {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiFetchEntity } = apiDispatch;

  const [assetNeedUpdate, setAssetNeedUpdate] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const [patternBg, setPatternBg] = useState(false)

  // modal Rename
  const [visible, setVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [optionRename, setOptionRename] = useState("1");

  const isMounted = useRef(true);

  const [media, setMedia] = useState(null)
  const [asset, setAsset] = useState(null)
  const [isSidebarActive, setIsSidebarActive] = useState(isTabletOrMobile ? false : true)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [galeryMode, setGaleryMode] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setCanEdit(accountState.editor)
  }, [])

  useEffect(() => {
    isMounted.current = true;
    let selectedAsset = asset;

    if (assetId !== asset?.id) {
      let index = assets.findIndex(item => item.id === assetId)

      selectedAsset = assets[index]
      setCurrentIndex(index)
      setAsset(selectedAsset);
    }

    let currentMedia = getMedia(selectedAsset)
    if (currentMedia) {
      let mimeType = currentMedia.mimeType;
      setPatternBg((mimeType === 'image/png' || mimeType === 'image/svg' || mimeType === 'image/svg+xml') && selectedAsset?.type === 1);
      if (selectedAsset?.type === 3 && currentMedia?.status === 'transcoding') {
        updateVideoCheck(currentMedia)
      }
    }
    setMedia(currentMedia)
    return () => {
      isMounted.current = false;
    }
  }, [assetNeedUpdate, assetId]);

  function updateVideoCheck(currentMedia) {
    apiFetchEntity('video-update', currentMedia.id, response => console.log(response))
  }

  function getMedia(asset) {
    return !!asset ? asset.medias.find(media => media.id === asset.currentMedia) : null
  }

  function updateCurrentAsset(direction) {
    let index = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
    let asset = assets[index]

    setAssetId(asset.id)
    setGaleryMode(false)
  }

  const extraHeader =
    <div key={'extra'} className="flex">
      {
        asset && asset.type === 1 && media && media.mimeType === 'image/png' &&
        <Button
          size="small"
          key='bg'
          className="mr-5"
          type={patternBg ? "primary" : "default"}
          icon="eye"
          title={t('Modifier le fond')}
          onClick={() => setPatternBg(!patternBg)}
        />
      }
      {asset && <Download asset={asset} isButton />}
      {canEdit && !isTabletOrMobile && isEditable() ?
        <Button
          size="small"
          className="mr-5"
          key={2}
          icon="highlight"
          title={t('Editer l\'image')}
          onClick={() => setEditMode(true)}
        /> : null}
      {
        asset && isEditable() &&
        <Button
          size="small"
          key={3}
          className="mr-5"
          type={galeryMode ? "primary" : "default"}
          icon="picture"
          title={t('Galerie photos')}
          onClick={toggleGalery}
        />
      }
      <Button icon='menu' type={isSidebarActive ? "primary" : "default"} className="mr-5" size="large"
        onClick={toggleSidebar} />
    </div>
    ;

  function isEditable() {
    let media = getMedia(asset)
    let mimeType = media.mimeType;
    let exceptType = ['image/svg', 'image/svg+xml', 'image/gif']
    return (asset.type === 1 && !exceptType.includes(mimeType))
  }

  function toggleGalery() {
    setGaleryMode(!galeryMode)
    setIsSidebarActive(false)
  }

  function toggleSidebar() {
    setIsSidebarActive(prev => !prev)
    setGaleryMode(false)
  }

  const iconeRename =
    canEdit ?
      <Tooltip title={t('Renommer')} style={{ marginLeft: '.5em' }}>
        <Icon type="edit" theme="twoTone" onClick={() => setVisible(true)} />
      </Tooltip>
      :
      null;

  // rename call api
  const updateEntity = async (entity, id, Data, type = null) => {
    await apiUpdateEntity(entity, id, Data, (response) => {
      if (response.error) {
        message.error(t('Une erreur est survenue'));
        return;
      }

      if (type)
        message.success(type);

      updateAsset(response)
      setAsset(response)
    });
  };


  //rename asset modal
  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  //rename asset modal
  const handleCreate = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        return;
      }

      let newName = ""
      switch (optionRename) {
        case '1':
          let prefix = asset.usageName.replace(/-\d*$/, '-');
          newName = prefix + values.index;
          break;
        case '2':
          newName = values.nomComplet;
          break;
      }

      if (newName !== asset.usageName) {
        let Data = {
          usageName: newName
        }
        updateEntity('assets', assetId, Data, 'Renommage effectué')
      }

      formRef.resetFields();
      setVisible(false);
    });
  };

  function handleClose() {
    document.querySelector(`div[data-key="${assetId}"]`)?.scrollIntoView({ behavior: 'smooth' })
    setAssetId(null)
  }

  function handleEditClose(needUpdate = true) {
    if (needUpdate)
      setAssetNeedUpdate((new Date()).getTime())

    setGaleryMode(false)
    setEditMode(false)
  }

  return (
    <>
      <div className={`media-page modal ${patternBg && 'png_bg'} ${(isSidebarActive || editMode) && !isTabletOrMobile && 'isActive'}`}
        style={{ height: '100%' }}>
        <div style={{ position: 'absolute', top: '.5em', left: '1em', zIndex: '2' }} className='flex align_center'>
          <Button icon='close' className='mr' onClick={handleClose} />
          {
            editMode &&
            <Button icon='arrow-left' className='mr' onClick={() => handleEditClose(false)} />
          }
        </div>
        {asset && editMode &&
          <ImageEdit media={getMedia(asset)} assetId={assetId} handleClose={handleEditClose} usageName={asset.usageName} />
        }
        {
          !editMode &&
          <>
            <Dropdown overlay={<ContextMenu asset={asset} original={!isWidget}/>} trigger={asset?.type === 3 ? [] : ['contextMenu']}>
              <div className='media-container'>
                <div className='bold asset-name'>{asset?.usageName}</div>
                {
                  !!assets.length &&
                  <div style={{ position: 'absolute', top: '.5em', left: '50%', transform: 'translateX(-50%)' }}>

                    <ButtonGroup className="mr-5">
                      <Button
                        size="small"
                        onClick={() => updateCurrentAsset('prev')}
                        disabled={!currentIndex}
                      >
                        <Icon type="left" />
                        {isTabletOrMobile ? '' : t('Précédent')}
                      </Button>
                      <Button
                        size="small"
                        onClick={() => updateCurrentAsset('next')}
                        disabled={currentIndex >= assets.length - 1}
                      >
                        {isTabletOrMobile ? '' : t('Suivant')}
                        <Icon type="right" />
                      </Button>
                    </ButtonGroup>
                  </div>
                }
                <div style={{ position: 'absolute', top: '.5em', right: '1em' }}>
                  {!isWidget && extraHeader}
                </div>

                <MediaView asset={asset} canEdit={canEdit} galeryMode={galeryMode} />
              </div>
            </Dropdown>

            {
              isTabletOrMobile ?
                <Drawer visible={isSidebarActive} onClose={() => setIsSidebarActive(false)} width='100%' bodyStyle={{ padding: 0 }}>
                  <MediaSidebar
                    canEdit={canEdit} asset={asset} updateAsset={updateAsset}
                    updateEntity={updateEntity} iconeRename={iconeRename} toggleGalery={toggleGalery}
                    setAsset={setAsset} isWidget={isWidget}
                  />
                </Drawer>
                :
                <MediaSidebar
                  canEdit={canEdit} asset={asset} updateAsset={updateAsset}
                  updateEntity={updateEntity} iconeRename={iconeRename} toggleGalery={toggleGalery}
                  setAsset={setAsset} isWidget={isWidget}
                />
            }
          </>
        }
      </div>

      {
        asset &&
        <ModalRenameAsset
          ref={saveFormRef}
          visible={visible}
          onCancel={() => setVisible(false)}
          onCreate={() => handleCreate()}
          setOptionRename={setOptionRename}
          assetName={asset.usageName}
        />
      }
      <ModalFreemium />
    </>
  )
}
