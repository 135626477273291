import React, { useState } from 'react'
import { Button } from 'antd';
import { useApiContext } from '../providers/ApiProvider.js';
import { moveToTrash } from "../helpers.js"
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'

export default function DeleteAction({ selection, setSelection, rerender, accountState }) {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;
  const [isLoading, setIsLoading] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const moveSelectionToTrash = async () => {
    setIsLoading(true)
    //console.log(selection)

    let tagFilter = accountState.filters.find(item => item.type === "directory")
    await moveToTrash(selection, apiUpdateEntity, (response) => {
      setIsLoading(false)
      setSelection([])
      rerender()
    }, tagFilter?.value)

  }

  return (
    <Button style={{ marginRight: '1em' }} size="small" icon="delete" shape="round" loading={isLoading} onClick={moveSelectionToTrash} title={t('Supprimer')}>
      {!isTabletOrMobile && t('Supprimer')}
    </Button>
  )
}
