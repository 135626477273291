import React, {useEffect, useState} from 'react'
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Input, message, Spin} from "antd";
import {useTranslation} from "react-i18next";
import CreateAccountForm from "../account/CreateAccountForm";
import MyPagination from "../MyPagination";
import {useAuthContext} from "../../providers/AuthProvider";

import widget from "./WidgetComponent.js"; // client/widget component

export default function WidgetAccountSelection() {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const [accounts, setAccounts] = useState([])
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostEntity} = apiDispatch;
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [createAccount, setCreateAccount] = useState(false)
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1)

  useEffect(() => {
    fetchAccounts()
  }, [searchFilter, page])

  function handleSearch(e) {
    let value = e.target.value
    clearTimeout(searchTimeout)
    setSearchTimeout(setTimeout(() => {
      // console.log(value)
      setSearchFilter(value)
      setPage(1)
    }),500)
  }


  function fetchAccounts() {
    setLoading(true)
    let params = {
      page: page,
      itemsPerPage: 5,
      filters: [
        {
          name: 'status', value: '1'
        },
        {
          name: 'name', value: searchFilter
        }
      ]
    }
    apiFetchCollection('accounts/admin', params, response => {
      // console.log(response)
      let accs = response['hydra:member']
      setTotalPage(response['hydra:totalItems']);
      setAccounts(accs)
      if (accs.length === 1 && searchFilter.trim() === '') {
        setSelectedAccount(accs[0])
      }
      // console.log(accounts)
      setLoading(false)

    })

  }

  function connect() {
    if (!selectedAccount) return
    setLoading(true)
    let domain = document.referrer.trim()

    apiPostEntity('permissions', {domain, account: selectedAccount['@id'], tags: []}, response => {
      // console.log(response)
      if (response['@type'] === 'hydra:Error') {
        return message.error(t('Il y a eu une erreur'));
      }
      sendResponse(response)
      message.success(t('Acces crée avec succes'))
      setLoading(false)

    })
  }

  function sendResponse(response) {
    widget.fire('select',response,'broadcast');
    window.close()
  }

  function formatResponse(response) {
    return {
      token: response.token,
      // publicToken: response.publicToken,
      // domain: response.domain,
      account: response.account.name
    }
  }

  function createCallback(account) {
    setSelectedAccount(account)
    setCreateAccount(false)
  }

  if (createAccount) {
    return (
      <div className="contenu-tab" style={{minHeight: '350px',textAlign: 'center', maxWidth: '450px', margin: '24px auto'}}>
        <div style={{margin: '10px'}}>
          <Button onClick={()=>setCreateAccount(false)}>{t('Retour')}</Button>
        </div>
        <CreateAccountForm  widgetCallBack={createCallback}/>
      </div>
      )
  }

  return (
    <div className="contenu-tab" style={{minHeight: '350px',textAlign: 'center', maxWidth: '450px', margin: '24px auto'}}>
      {
        !selectedAccount ?
          <>
            <h2>{t('Séléctionez un compte')}</h2>
            <p style={{textAlign: 'center',}}>{t('Pour l\'utiliser avec')} : <strong>{document.referrer}</strong></p>
            <div style={{ margin: '0 auto', display:'flex', justifyContent: 'space-between'}}>
              <Input placeholder={t("Rechercher")} onChange={handleSearch}  style={{width: '250px'}}/>
              {
                auth.roles.includes('ROLE_ADMIN') &&
                <Button onClick={()=> setCreateAccount(true)}>{t('Créer un compte')}</Button>
              }
            </div>

            <Spin spinning={loading}>
              <div style={{margin: '1em 0'}}>
                {
                  accounts.map(account =>
                    <div className="account-selectable" key={account.id}
                         onClick={() => setSelectedAccount(account)}>{account.name}</div>
                  )
                }
              </div>
              <MyPagination
                current={page}
                totalItem={totalPage}
                itemPerPage={5}
                changePage={setPage}
                margin={'1rem'}
                bgColor={'none'}
              />
            </Spin>
          </>
          :
          <>
            <h2>{t('Confirmez la connexion avec')} :</h2>
            <h2><strong>{document.referrer}</strong></h2>
            <p style={{textAlign: 'center',}}><strong>{selectedAccount.name}</strong></p>
            <p style={{maxWidth: '50%',margin: '2em auto'}}>{t('Cette action donnera un acces et permettra l\'utilisation du widget Hapidam sur ce site.')}</p>
            <div className="flex between" style={{margin: '2em 0'}}>
              <Button onClick={() => setSelectedAccount(null)}>{t('Retour')}</Button>
              <Button type="primary" onClick={connect}>{t('Confirmer')}</Button>
            </div>
          </>
      }

    </div>
  )

}
