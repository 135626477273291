import React, { useEffect, useState, useRef } from 'react';
import { Tag, Button, Icon } from 'antd';
import { useAccountContext } from "../../providers/AccountProvider";
import { useAuthContext } from '../../providers/AuthProvider.js';
import { useApiContext } from "../../providers/ApiProvider";
import BtnSelectAll from "../asset/button/BtnSelectAll.js"
import { useTranslation } from "react-i18next";
import ModalTransfer from "../modal/ModalTransfer";
import ModalGroupSelection from "../modal/ModalGroupSelection";
import ModalDouble from '../modal/ModalDouble';
import CardViewChoice from "./CardViewChoice";
import { useMediaQuery } from 'react-responsive';

const titles = { 2: 'Tous les médias', 5: "Documents", 6: "Videos" }
const ActiveFilters = ({
  selection, setSelection,
  selectAll, unselectAll,
  activeActionBar = true,
  messageSelectAll
}) => {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchEntity, apiFetchCollection, apiPostEntity } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { filter, resetFilter, setActiveAccount, addNotification } = accountDispatch;
  const [breadCrumb, setBreadCrumb] = useState([]);
  const isMounted = useRef(null);
  const { t } = useTranslation();
  const [visibleByAccount, setVisibleByAccount] = useState(false)
  const [isGroupSelectionVisible, setIsGroupSelectionVisible] = useState(false)
  const [isHapiFolder, setIsHapiFolder] = useState(null)
  const [similarList, setSimilarList] = useState(null)
  const [modalDoubleVisible, setModalDoubleVisible] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    isMounted.current = true;
    buildBreadCrumb();
    //Clear le useEffect
    return () => {
      isMounted.current = false;
    }
  }, [accountState.filters]);

  const buildBreadCrumb = async () => {
    let filAriane = [];
    let title = titles[accountState.activeTab]
    let album = (<span style={{ cursor: "pointer" }} key={'album'} onClick={resetFilter}>{t(title)}</span>);

    if (accountState.filters.filter(item => item.id === 'trash').length > 0) {
      album = (<span style={{ cursor: "pointer" }} key={'trash'} onClick={resetFilter}>{t('Corbeille')}</span>);
    }

    // show the name of an account of a group when in group mode
    if (accountState.accountActive !== null && accountState.globalSearch) {
      album = (<span style={{ cursor: "pointer" }} key={'album'}
        onClick={resetGroupFilters}>{accountState.accountActive.name}</span>);
      if (accountState.filters.filter(item => item.id === 'trash').length > 0) {
        filAriane.push((
          <span style={{ cursor: "pointer" }} key={'trash'} onClick={resetFilter}> > {t('Corbeille')}</span>));
      }
    }

    let dirFilter = accountState.filters.filter(item => item.type === 'directory');
    if (dirFilter.length > 0) {
      if (dirFilter[0].tagName === 'hapiv2') {
        setIsHapiFolder(dirFilter[0].value)
      } else {
        setIsHapiFolder(null)
      }
      let tag = {};
      await apiFetchEntity('tags', dirFilter[0].element.id, (response) => {
        if (isMounted.current) {
          tag = response;
        }
      });
      filAriane = buildDirectories(tag, filAriane);
    }

    if (isMounted.current) {

      filAriane = buildTags(filAriane);
      filAriane = buildSearch(filAriane);
      filAriane = buildType(filAriane);
      filAriane = buildOrientation(filAriane);
      filAriane = buildDate(filAriane);
      filAriane = buildDateBetween(filAriane);
      filAriane = buildColor(filAriane);
      filAriane = buildNote(filAriane);

      setBreadCrumb([album, ...filAriane]);
    }

  };

  const buildDirectories = (directory, array) => {
    let newArray = [...array];
    let span = (<span key={directory.id}> > <span style={{ cursor: "pointer" }}
      onClick={() => clickDirectory(directory)}> {directory.name}</span> </span>);
    newArray.unshift(span);
    if (directory.parentTag) {
      return buildDirectories(directory.parentTag, newArray);
    } else {
      return newArray;
    }
  };

  const buildTags = (array) => {
    let tags = accountState.filters.filter(item => item.type === 'tag');

    tags.map(tag => {
      let span = (<span key={tag.id}> > <span style={{ cursor: "pointer", color: 'red' }}
        onClick={() => delFilter(tag)}> {tag.tagName}</span> </span>);
      array.push(span);
    });
    return array;
  };

  const buildSearch = (array) => {
    let searchTerms = accountState.filters.filter(item => item.type === 'search');

    searchTerms.map((tag, index) => {
      if (index === 0) {
        let text = (<span key={'search'}> & avec les mots clés suivants :</span>);
        array.push(text);
      } else {
        let text = (<span key={'search_' + index}> & </span>);
        array.push(text);
      }
      let span = (<span key={tag.id}> "<span style={{ cursor: "pointer", color: 'red' }}
        onClick={() => delFilter(tag)}>{tag.value}</span>" </span>);
      array.push(span);
    });
    return array;
  }

  const handleClose = (e, filter) => {
    e.preventDefault();
    delFilter(filter);
  }

  const buildType = (array) => {
    let filtres = accountState.filters.filter(item => item.type === 'orType');
    filtres.map((filtre, index) => {
      let text;
      let types = { 1: 'Image', 2: 'Document', 3: 'Video' }
      let type = t(types[filtre.value])

      const tagElem = <Tag
        key={'type_' + type} closable
        onClose={e => handleClose(e, filtre)}
      >
        {type}
      </Tag>
      if (index === 0) {
        text = (<span key="span_type"> | {t('Type')} : {tagElem}</span>);
      } else {
        text = tagElem
      }
      array.push(text);
    })
    return array;
  }

  const buildOrientation = (array) => {
    let filtres = accountState.filters.filter(item => item.type === 'orientation');
    filtres.map((filtre, index) => {
      let text;
      let orientations = { 1: 'Paysage', 2: 'Portrait', 3: 'Carré' }
      let orientation = t(orientations[filtre.value])

      const tagElem = <Tag
        key={'orientation_' + orientation} closable
        onClose={e => handleClose(e, filtre)}
      >
        {orientation}
      </Tag>
      if (index === 0) {
        text = (<span key="span_orientations"> | {t('Orientation')} : {tagElem}</span>);
      } else {
        text = tagElem
      }
      array.push(text);
    })
    return array;
  }

  const buildDate = (array) => {
    let dates = accountState.filters.filter(item => item.type === 'date');
    let text;
    dates.map(date => {
      const tagElem = <Tag
        key={date.id} closable
        onClose={e => handleClose(e, date)}
      >
        {date.text}
      </Tag>
      text = <span key="key_date"> | {t('Il y a')} {tagElem}</span>
      array.push(text)
    })
    return array
  }

  const buildDateBetween = (array) => {
    let dateBetween = accountState.filters.filter(item => item.type === 'dateBetween');
    let text;
    dateBetween.map(date => {
      const tagElem = <Tag
        key={date.id} closable
        onClose={e => handleClose(e, date)}
      >
        {date.text}
      </Tag>
      text = <span key="date_between"> {tagElem}</span>
      array.push(text)
    })
    return array
  }

  const buildColor = (array) => {
    let text;
    let colors = accountState.filters.filter(item => item.type === 'color');

    colors.map(color => {
      const tagElem =
        <Tag
          style={color === 'white' ? { border: '1px black solid' } : null}
          color={color.value === 'white' ? null : color.color}
          key={color.id} closable
          onClose={e => handleClose(e, color)}
        >
          {color.name === 'blackAndWhite' ? t('Noir et blanc') : null}
        </Tag>
      text = <span key="key_color"> | {t('Couleur')} : {tagElem}</span>
      array.push(text)
    })
    return array
  }

  const buildNote = (array) => {
    let note = accountState.filters.filter(item => item.type === 'stars');
    if (note.length > 0) {
      note = note[0]
      if (note.value > 0) {
        const desc = ['à partir de 1', 'à partir de 2', 'à partir de 3', 'à partir de 4', 'égale à 5'];
        let text = <span key='key_note'> | {t('Note')} : {desc[note.value - 1]} <Icon type='star' theme='filled'
          style={{ color: '#fadb14' }} /> </span>
        array.push(text)
      }
      if (note.value === 0) {
        let text = <span key='key_note'> | {t('Note')} : <Icon type="star" theme="filled" /> </span>
        array.push(text)
      }

    }
    return array
  }

  const clickDirectory = (item) => {
    let filtre = {
      id: item.id,
      name: 'tags.name[]',
      value: item.id,
      type: 'directory',
      element: item
    };
    filter(filtre);
  };

  const delFilter = (item) => {
    let filtre = {
      ids: item.ids ? item.ids : null,
      id: item.id,
      name: item.name,
      value: item.value,
      type: item.type,
      element: item.element ? item.element : null
    };
    filter(filtre);
  };


  const resetGroupFilters = () => {
    setActiveAccount(null)
    resetFilter()
  }

  const cleanHapiFolder = () => {
    if (similarList) {
      return setModalDoubleVisible(true);
    }
    setCheckLoading(true)
    apiPostEntity('check-folder', { folderId: isHapiFolder }, response => {
      let done = handleCheckResponse(response, false)
      if (!done) retryCleanUp()
    })
  }

  const retryCleanUp = () => {
    setTimeout(() => {
      apiPostEntity('check-folder', { folderId: isHapiFolder, checkOnly: true }, response => {
        let done = handleCheckResponse(response, true)

        if (!done) retryCleanUp()
      })
    }, 30000)
  }

  const handleCheckResponse = (response, isRetry) => {

    let notif = {
      id: (new Date()).getTime()
    }
    if (response.success === 0 && !isRetry) {
      addNotification({ ...notif, message: 'Erreur', description: response.error, type: 'error' })
    }
    if (response.success === 1 && response.step === 1) {
      let message = response.hasOwnProperty('executionTime') ?
        `${response.deleted} assets supprimés sur ${response.hapiAssets} en ${response.executionTime} min` : ''
      addNotification({ ...notif, message: response.status, description: message, type: 'success' })
      if (response.close && !!response.close.length) {

        setSimilarList(response.close)
        setModalDoubleVisible(true)
      }
      setCheckLoading(false)
      return true;
    } else if (response.success === 1) {
      addNotification({
        id: 'compareStart',
        message: response.status,
        description: 'Demande de comparaison effectué',
        type: 'success'
      })

    }
    setCheckLoading(false)
    return false
  }

  return (
    <div className="tab-topbar">
      <div>
        {breadCrumb}
      </div>

      <div className="flex">
        {
          accountState.editor ?
            <>
              {accountState.globalSearch && selection.size ?
                <>
                  <Button onClick={() => setIsGroupSelectionVisible(true)}
                    size="small">{t('Ma sélection') + ' (' + selection.size + ')'} </Button>
                  <Button className="ml-10" onClick={() => unselectAll()} size={"small"}>{t("Désélectionner")}</Button>
                </>
                : null}

              {isGroupSelectionVisible ?
                <ModalGroupSelection setIsVisible={setIsGroupSelectionVisible} apiFetchCollection={apiFetchCollection} selection={selection} setSelection={setSelection} />
                : null}


              {auth && auth.roles.includes('ROLE_ADMIN') && !!isHapiFolder &&
                <>
                  <Button onClick={cleanHapiFolder} loading={checkLoading}
                    size="small" type='danger'>Nettoyer</Button>
                  <ModalDouble setVisible={setModalDoubleVisible} visible={modalDoubleVisible} assetList={similarList}
                    folderId={isHapiFolder} />
                </>}

              {accountState.group && accountState.accountActive && !selection.size && !accountState.globalSearch && !isTabletOrMobile ?
                <>
                  <Button onClick={() => setVisibleByAccount(true)}
                    size="small">{t('Transferer')}</Button>
                  {
                    visibleByAccount ?
                      <ModalTransfer
                        visible={visibleByAccount}
                        setVisible={setVisibleByAccount}
                      />
                      : null
                  }
                </>
                : null}


              {!accountState.globalSearch && messageSelectAll}

              {!accountState.globalSearch &&
                <BtnSelectAll
                  selectAll={selectAll}
                  unselectAll={unselectAll}
                  itemsChecked={selection}
                />}</>
            :
            null}

        <CardViewChoice />

      </div>


    </div>
  )
};

export default ActiveFilters;
