import React, { useEffect, useState } from 'react'
import { useApiContext } from "../../providers/ApiProvider";
import { Button, Input, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import ModalUserPermission from "../modal/ModalUserPermission";
import moment from "moment";
import { useAuthContext } from "../../providers/AuthProvider";

const roleResolver = (role) => {
  let roles = [
    { role: 'ROLE_ADMIN', text: 'Admin' },
    { role: 'ROLE_VIEW', text: 'Viewer' },
    { role: 'ROLE_EDIT', text: 'Editeur' },
  ]
  return roles.filter(item => item.role === role)[0]
}

const AccountPermissions = ({ account, update }) => {

  const { t } = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter, apiDeleteEntity } = apiDispatch;
  const [loadingPermanent, setLoadingPermanent] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPerm, setSelectedPerm] = useState(null)
  const [needUpdate, setNeedUpdate] = useState(0);
  const [search, setSearch] = useState('')
  const [searchTimeout, setSearchTimeout] = useState()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: 'bottom',
    size: "small"
  })


  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchPermissions()

    // fetchData();
  }, [needUpdate, update]);

  const columns = [
    {
      title: t('Utilisateur'),
      dataIndex: 'user',
      render: (user, record) => <> <span>{record.share ? record.email : user.username}</span> <br />
        <small>{record.share ? <a href={'/visitor/' + record.token} target="_blank">{t('Voir')}</a> : user.email}</small></>,
    },
    {
      title: t('Durée d\'accès'),
      dataIndex: 'share',
      render: (share, record) => <><span>{share ? t('Temporaire') : t('Permanent')}</span><br />{share ?
        <small>{moment(record.deletedAt).format('DD-MM-YYYY')}</small> : null} </>,
    },
    {
      title: t('Rôle'),
      dataIndex: 'role',
      render: (role) => <span>{roleResolver(role).text}</span>,
    },
    {
      title: t('Type accès'),
      dataIndex: 'tags',
      render: (tags) => <span>{tags.length <= 0 ? t("Tous les dossiers") :
        isGroupShare(tags)
      }
      </span>
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      render: (actions, record) => record.user && record.user.id === auth.id ? null :
        <span>
          {
            record.tags.length === 1 && record.tags[0].type === 3 ? null :
              <Button size="small" onClick={() => openPermModal(record)}>{t('Editer')}</Button>
          }
          <Button size="small" onClick={() => handleDelete(record.id)}>{t('Supprimer')}</Button>
        </span>,
    }
  ];

  const isGroupShare = (tags) => {
    if (tags.length === 1 && tags[0].name === '_temp_share_tag') {
      return t('Sélection groupe')
    }
    return t('Sélection')
  }

  const handleDelete = (permissionID) => {
    apiDeleteEntity('permissions', permissionID, response => {
      setNeedUpdate(needUpdate + 1)
    })

  }

  const fetchPermissions = () => {
    setLoadingPermanent(true)
    const params = {
      id: account.id,
      itemsPerPage: pagination.pageSize || 10,
      page: pagination.current || 1,
      filters: [
        {
          name: 'exists[domain]',
          value: false
        },
        {
          name: 'user.email',
          value: search
        },
      ]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'permissions', (response) => {
      if (response['@type'] === "hydra:Collection") {
        setPermissions(response['hydra:member'])
        setPagination(prev => { return { ...prev, total: response['hydra:totalItems'] } })
      }
      setLoadingPermanent(false)
    })
  }

  function openPermModal(perm) {
    setSelectedPerm(perm)
    setIsModalVisible(true)
  }

  function handleTableChange(table_pagination) {
    // update current pagination
    const pager = { ...table_pagination };
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  }


  const handleSearch = (e) => {
    setSearch(e.target.value.trim())
    clearTimeout(searchTimeout)
    setSearchTimeout(setTimeout(() => {
      console.log(e.target)
      setPagination(prev => { return { ...prev, current: 1 } })
      setNeedUpdate((new Date()).getTime())
    }, 200))

  }

  return (
    <div>
      <div className='mb'>
      <span>{t('Recherche')} : </span>
        <Input.Search
          allowClear
          placeholder={t("Rechercher par email")}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </div>
      <ModalUserPermission visible={isModalVisible} accountForce={account} perm={selectedPerm}
        setVisible={setIsModalVisible}
        needUpdate={needUpdate} setNeedUpdate={setNeedUpdate} />
      <Table columns={columns}
        title={(currentPageData) => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <b>{t('Personnes pouvant accèder au compte')}</b>
          <Button type={"primary"} size="small" onClick={() => {
            openPermModal(null)
          }}>+</Button>
        </div>}
        bordered={true}
        dataSource={permissions}
        rowKey={record => record['@id']}
        pagination={pagination}
        loading={loadingPermanent}
        onChange={handleTableChange}
      />
    </div>
  )

}

export default AccountPermissions;
