import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Form, Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../providers/ApiProvider";

function FirstConnection({form}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {postRequestBasic} = apiDispatch;
  const {getFieldDecorator, getFieldValue, validateFieldsAndScroll} = form;
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      setLoading(true)
      if (!err) {
        push(values);
      } else {
        returnMessageErr(t('Certains champs présentent des erreurs'))
      }
    });
  }

  function push(data) {
    if (!params.id)
      return returnMessageErr(t("Une erreur est survenue, si le problème persist contactez l'agence MMCréation"));

    delete data.confirm;
    data.userId = params.id;

    postRequestBasic("/first-connection", data, response => {
      if (!response.success)
        return returnMessageErr(t("Une erreur est survenue, si le problème persist contactez l'agence MMCréation"));

      message.success(t('Succès, vous allez être redirigé vers la page login dans quelques secondes'));
      setTimeout(() => {
        setLoading(false);
        window.location.href = "/login";
      }, 3000)
    })
  }

  function returnMessageErr(content) {
    message.error(t(content));
    setLoading(false);
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(t("Les deux mots de passe sont différents"));
    } else {
      callback();
    }
  }

  return (
    <div className="contenu-tab">
      <div className="classic-text">
        {t("Bienvenue sur Hapidam, pour accéder à votre espace veuillez rentrer vos coordonnées ainsi que votre mot de passe. Nous vous remercions de votre compréhension")}
      </div>
      <div className="classic-form">
        <Form onSubmit={handleSubmit} style={{margin: 20}}>

          <Form.Item label={t("Prénom")}>
            {getFieldDecorator('firstname', {
              rules: [
                {
                  required: true,
                  message: t('Champs requis'),
                },
              ]
            })(<Input/>)}
          </Form.Item>

          <Form.Item label={t("Nom")}>
            {getFieldDecorator('lastname', {
              rules: [
                {
                  required: true,
                  message: t('Champs requis'),
                },
              ]
            })(<Input/>)}
          </Form.Item>

          <Form.Item label={t("Nom d'utilisateur")}>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: t('Champs requis'),
                },
              ]
            })(<Input/>)}
          </Form.Item>

          <Form.Item label={t("Mot de passe")} hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: t('Champs requis'),
                },
              ]
            })(<Input.Password/>)}
          </Form.Item>

          <Form.Item label={t("Confirmer mot de passe")} hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: t('Champs requis'),
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(<Input.Password/>)}
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {t('Enregistrer')}
            </Button>
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}

export default Form.create()(FirstConnection);

