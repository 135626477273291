import React, { useEffect, useState } from 'react'
import { Button, Icon, Spin, message } from 'antd'
import { useAuthContext } from "../../providers/AuthProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import { downloadResource } from '../../helpers.js'
import ModalCropImage from '../../components/modal/ModalCropImage.js'
import { useTranslation } from "react-i18next";
import { useApiContext } from '../../providers/ApiProvider';
import CropImage from '../media/CropImage';

const displayOrder = ['Facebook', 'Instagram', 'Linkedin', 'Hapidam', 'Profil']

export default function Galery({ canEdit, assetId }) {

    const { t } = useTranslation();
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResource, apiFetchSubResourceWithFilter, apiPostEntity } = apiDispatch;
    const [authState] = useAuthContext();
    const { auth } = authState;
    const [accountState] = useAccountContext();

    const [asset, setAsset] = useState(null)
    const [media, setMedia] = useState(null)
    const [loading, setLoading] = useState(true)
    const [needUpdate, setNeedUpdate] = useState(0)

    const [pictures, setPictures] = useState({})
    const [visibleCrop, setVisibleCrop] = useState(false)

    const [fileExt, setFileExt] = useState(null)
    const [className, setClassName] = useState(null)
    const [socials, setSocials] = useState(false)
    const [checkingSocials, setCheckingSocials] = useState(false)
    const [cropImage, setCropImage] = useState(null)

    useEffect(() => {
        checkSocials()
    }, [assetId])

    function checkSocials() {
        setCheckingSocials(true)
        apiFetchSubResource("assets", { id: assetId, withoutExt: true }, "check-networks-pics", response => {
            setSocials(response.socials)
            setCheckingSocials(false)
        })
    }

    useEffect(() => {
        fetch()
    }, [needUpdate])

    useEffect(() => {
        if (media) {
            switch (media.mimeType) {
                case 'image/jpeg':
                    setFileExt('.jpg');
                    break;
                case 'image/png':
                    setFileExt('.png');
                    setClassName('png_transparent');
                    break;
            }
        }
    }, [media])

    function fetch() {
        setLoading(true);

        apiFetchSubResource('assets', { id: assetId, withoutExt: true }, 'all_medias', response => {

            if (response['@type'] === "hydra:Error") {
                return;
            }
// console.log('response', response)
            sortMedias(response.currentMedia);
            setMedia(response.currentMedia)
            setAsset(response);
            setLoading(false);
        })
    }

    function sortMedias(media) {
        let allPictures = {
            hapidam: filterMediaByprefix(media.extraUrl, 'hapidam'),
            profil: filterMediaByprefix(media.extraUrl, 'profil'),
            facebook: filterMediaByprefix(media.extraUrl, 'facebook'),
            instagram: filterMediaByprefix(media.extraUrl, 'instagram'),
            linkedin: filterMediaByprefix(media.extraUrl, 'linkedin'),
        };
        setPictures(allPictures)
    }

    function filterMediaByprefix(media, prefix) {
        let result = []
        for (const [key, value] of Object.entries(media)) {
console.log(value);
            if (value.name.startsWith(prefix)) {
                result.push(value)
            }
        }
        return orderByHeight(result);
    }

    function orderByHeight(array) {
        return array.sort((a, b) => {
            return parseInt(a.height) - parseInt(b.height) > 0 ? -1 : 0
        })
    }

    function download(url, name) {
        const data = {
            type: "download",
            userEntity: "/api/users/" + auth.id,
            assetEntity: "/api/assets/" + asset.id,
            mediaEntity: "/api/media_objects/" + media.id,
            accountEntity: accountState.account['@id'],
            cropType: cropType(name),
            quantity: 1
        }
        downloadResource(url, name, { post: apiPostEntity, data: data })
    }

    function cropType(name) {
        const nameWithoutExt = name.replace(/\.[^/.]+$/, "")
        const suffix = nameWithoutExt.match(/[a-zA-Z]+-[a-zA-Z]+$/)[0];
        switch (suffix) {
            case 'hapidam-large':
                return 'lg';
            case 'hapidam-medium':
                return 'md';
            case 'hapidam-small':
                return 'sm';
            default:
                return suffix;
        }
    }

    const [loadCropAll, setLoadCropAll] = useState(false)

    function callCrop() {
        if (loadCropAll)
            return message.warning(t('Une demande est déja en attente'));

        setLoadCropAll(true);
        const params = {
            id: asset.id,
            filters: [
                { name: 'mode', value: 'auto' },
                { name: 'no_scaling', value: 0 },
                { name: 'force', value: 'true' }
            ]
        }

        cropRequest(params, setLoadCropAll)
    }

    function cropRequest(params, setLoader, goTo = null) {
        apiFetchSubResourceWithFilter('assets', params, 'crop', hydra_response => {
            if (hydra_response.error)
                return message.error(hydra_response.error);

            if (hydra_response['@type'] === 'hydra:Error')
                return message.error(hydra_response['hydra:description']);

            setSocials(false);
            setNeedUpdate((new Date()).getTime())
            setLoader(false)
            if (goTo)
                document.getElementById(goTo).scrollIntoView({ behavior: "smooth" });
        })
    }

    const buildGrid = () => {
        return displayOrder.map(name => {
            let useName = name.toLowerCase()
            if (pictures[useName]?.length) {
                return (
                    <div key={name}>
                        <h2 style={{ textAlign: 'center', color: "#fff", width: '100%', marginBottom: '1em' }}>
                            {name !== 'hapidam' && <Icon type={useName} theme="filled" style={{ marginRight: '10px' }} />} {name}
                        </h2>
                        <div className={`galery-grid `}>
                            {
                                pictures[useName].map(picture =>
                                    <div key={picture.name}>
                                        <div className='flex between picture-name'>
                                            <span>{picture.name.replace(useName + '-', '')}</span>
                                            <span>{`${picture.width}x${picture.height}`}</span>
                                        </div>
                                        <div className={`item-card`}
                                            data-key={picture.name}>
                                            <img alt="example" className={className} style={{ height: '100%' }}
                                                src={picture.isCrop ? picture.url : media.extraUrl?.md?.url} />

                                            <div className="see-on-hover asset-actions">
                                                <div
                                                    className="is_btn"
                                                    title={t('Télécharger')}
                                                    onClick={() => download(picture.url, `${asset.usageName}-${picture.name}${fileExt}`)}
                                                >
                                                    <Icon type="download" />
                                                </div>
                                                {
                                                    picture.isCrop &&
                                                    <div
                                                        title={t('Recadrer')}
                                                        className="is_btn"
                                                        onClick={() => {setCropImage(picture)}}
                                                    >
                                                        <Icon type="scissor" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        })
    }

    if (loading)
        return <div className="loading_container_virgin"><Icon type="loading" /></div>

    return (
        <Spin spinning={loading}>
            <div style={{ height: '100%', overflow: 'auto' }}>
                {
                    cropImage ?
                        <CropImage image={cropImage} setImage={setCropImage} assetId={assetId} media={media} apiFetchSubResourceWithFilter={apiFetchSubResourceWithFilter}/>
                        :
                        <>
                            <div className="assets-flex list bg-transparent">
                                {
                                    buildGrid()
                                }
                            </div>
                            {
                                !socials && !checkingSocials &&
                                <Button
                                    loading={loadCropAll}
                                    onClick={callCrop}
                                    type="primary"
                                    disabled={media.mimeType === 'image/svg' || media.mimeType === 'image/svg+xml'}
                                >
                                    {t('Générer les images pour les réseaux sociaux')}
                                </Button>
                            }
                        </>
                }
            </div>

            <ModalCropImage
                title={t('Recadrer l\'image')}
                visible={visibleCrop}
                setVisible={setVisibleCrop}
                image={asset.currentMedia.extraUrl.lg.url}
                width={1}
                height={1}
                originalWidth={asset.currentMedia.extraUrl.lg.width}
                cropRequest={apiFetchSubResourceWithFilter}
                croppedElem={''}
                id={asset.id}
                reload={() => {
                    setLoading(true)
                    setNeedUpdate(needUpdate + 1)
                }}
            />
        </Spin>
    )
}
