import React, { useEffect, useState } from 'react'
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAccountContext } from '../../../providers/AccountProvider';
import { useApiContext } from '../../../providers/ApiProvider';
import YoutubeChannels from './YoutubeChannels';
import PlatformVideos from './PlatformVideos';
import MyPagination from '../../MyPagination';

export default function SocialVideos({ type, platform }) {

    const { t } = useTranslation();
    const [accountState] = useAccountContext();
    const params = useParams();
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResourceWithFilter } = apiDispatch;
    const [accountID, setAccountID] = useState(accountState.globalSearch && accountState.accountActive ? accountState.accountActive.id : params.account_id)
    const [videos, setVideos] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        position: 'bottom',
        size: "small"
    })
    const [loading, setLoading] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        setAccountID(accountState.globalSearch && accountState.accountActive ? accountState.accountActive.id : params.account_id)
    }, [accountState.accountActive, params.account_id])


    useEffect(() => {
        fetchVideos()
    }, [pagination.current, needUpdate, accountState.rerender])


    function fetchVideos() {
        setLoading(true)
        const data = {
            itemsPerPage: pagination.pageSize || 10,
            page: pagination.current || 1,
            id: accountID,
            filters: [{ name: 'type', value: type }, { name: 'platform', value: platform }]
        }
        apiFetchSubResourceWithFilter('accounts', data, 'social_networks', response => {
            if (response['@type'] === "hydra:Collection") {
                setPagination(prev => { return { ...prev, total: response['hydra:totalItems'] } })
                setVideos(response['hydra:member'])
            }
            setLoading(false)
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="assets-masonry-grid">
                {
                    type === 1 &&
                    <YoutubeChannels channels={videos} setNeedUpdate={setNeedUpdate} />
                }
                {
                    type === 2 && platform === 1 &&
                    <PlatformVideos
                        videos={videos}
                    />
                }
                {
                    type === 2 && platform === 2 &&
                    <PlatformVideos
                        videos={videos}
                    />
                }
                <MyPagination
                    current={pagination.page}
                    totalItem={pagination.total}
                    itemPerPage={pagination.itemsPerPage}
                    changePage={page => setPagination({ ...pagination, page })}
                    bgColor="transparent"
                />
            </div>
        </Spin>
    )
}
