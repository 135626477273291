import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import FreemiumFallback from "../FreemiumFallback";
import {useAccountContext} from "../../providers/AccountProvider";

export default function ModalFreemium() {

  const {t} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const {freemiumOptions} = accountState;
  const {setFreemiumOptions} = accountDispatch;

  function cancel() {
    setFreemiumOptions({visible: false, type: ''})
  }

  function handleOk() {
    cancel();
  }

  return (
    <Modal
      title={t("Passer en mode Premium")}
      visible={freemiumOptions.visible}
      onCancel={cancel}
      onOk={handleOk}
      footer={null}
    >
      <FreemiumFallback/>
    </Modal>
  )
}
