import React, { useState, useEffect } from 'react'
import { Button } from 'antd';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';

/**
 * -Front
 * --Bouton pour selectionner tous les assets
 */
function ButtonSelectPage({ setSelected, selected, items = [] }) {

    const { t } = useTranslation();
    const [hasSelection, setHasSelection] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

    useEffect(() => {
        if (selected && selected.size > 0) {
            setHasSelection(true)
        } else {
            setHasSelection(false)
        }
    }, [selected])

    function onClick() {
        if (items.length > 0) {
            if (hasSelection) {
                setSelected(new Set())
            } else {
                selectAll();
            }

            setHasSelection(!hasSelection)
        }
    }

    function selectAll() {
        setSelected(prev => {
            const next = new Set(prev);
            items.map((item) => next.add(item.id));
            return next;
        })
    }

    return (
        <Button
            type={hasSelection ? 'default' : 'primary'}
            style={{ marginLeft: window.innerWidth > 768 ? '1em' : '0' }}
            onClick={onClick}
            size={'small'}
            shape={isTabletOrMobile && hasSelection ? "circle" : "round"}
            icon={hasSelection ? 'close' : ''}
        >
            {
                hasSelection
                    ? <i className={'no-mobile'} style={{ fontStyle: 'normal' }}>{!isTabletOrMobile ? t('Désélectionner') : ''}</i>
                    : <span>{t('Tout sélectionner')}</span>
            }
        </Button>
    )
}

export default ButtonSelectPage
