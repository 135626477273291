import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../providers/AccountProvider";
import DisplayCards from "./DisplayCards"

export default function ModalSelection({ visible, setVisible, selection, setSelection, handleValidate }) {

  const { t } = useTranslation();
  const [accountState] = useAccountContext();
  const [initialSelection, setInitialSelection] = useState([...accountState.selection])

  function unselect() {
    setSelection(new Set())
    setVisible(false)
  }

  return (
    <Modal
      width="80vw"
      destroyOnClose
      bodyStyle={{ maxHeight: '80vh', overflow: 'auto' }}
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      title={"Ma Sélection " + ' : ' + selection.size + ' ' + (selection.size > 1 ? 'éléments' : 'élément')} onOk={handleValidate}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)}>{t('Retour')}</Button>,
        <Button key="unselect" onClick={unselect}>{t('Désélectionner tout')}</Button>,
        <Button key="ok" onClick={handleValidate} type="primary">{t('Valider la selection')}</Button>
      ]}
    >
      <div className="assets-list selectable-container no-active-bar" style={{background: '#fff'}} >

        <DisplayCards
          selectionPreview
          multiple={true}
          selected={selection}
          setSelected={setSelection}
          selectable={true}
          assets={initialSelection}
          infiniteScroll={false}
        />
      </div>
    </Modal>
  )
}
