import React, { useCallback, useState } from "react";
import { Button, Drawer, Dropdown, Icon, Layout, Menu } from "antd";
import { useAccountContext } from "../../providers/AccountProvider";
import SearchInput from "../SearchInput";
import ModalUploadFiles from "../modal/ModalUploadFiles";
import { useHistory, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AccountSidebar from "../layout/AccountSidebar";
import { useTranslation } from "react-i18next";
import CardViewChoice from '../asset/CardViewChoice'

export default function WidgetHeader({ switchedOn = false }) {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { setWidgetCardActive } = accountDispatch;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [isSearchDrawerVisible, setIsSearchVisible] = useState(false);

  const history = useHistory();
  const params = useParams()

  function switchWidget(widget) {
    history.push(`/${widget}/${params.token}${window.location.search}`)
  }

  const closeSidebar = useCallback(() => {
    setIsSidebarVisible(false)
  }, [])

  const closeSearch = useCallback(() => {
    setIsSearchVisible(false)
  }, [])

  return (
    <Layout.Header className="header flex align_center between">
      <>
        <div className="flex align_center">
          {isTabletOrMobile ?
            <>
              <Button id="toggleSidebar" className="mr" icon={'menu-fold'} onClick={() => setIsSidebarVisible(true)} />
              <Button icon="search" onClick={() => setIsSearchVisible(true)} />

              <Drawer closable={false} visible={isSidebarVisible} placement='left' bodyStyle={{ padding: 0, height: '100%' }} width={'auto'} onClose={closeSidebar}>
                <AccountSidebar closeMenu={closeSidebar} />
              </Drawer>
              <Drawer
                placement='top'
                closable={false}
                onClose={closeSearch}
                visible={isSearchDrawerVisible} height="auto"
                title={t('Rechercher un média')}
              >
                <SearchInput callback={closeSearch} visible={isSearchDrawerVisible} mobileMode />
              </Drawer>
            </>
            : <div className="bold">{accountState.account?.name}</div>
          }
        </div>

        <div className="flex align_center">
          {
            !isTabletOrMobile && <SearchInput />
          }
          {!!(switchedOn && location.pathname.includes(('/widget/'))) &&
            <Button className="ml" type="primary" shape="round" onClick={() => switchWidget('widget-builder')}>
              Mode requête
            </Button>
          }
          {
            accountState.editor &&
            <div className="ml">
              <ModalUploadFiles />
            </div>
          }
          <CardViewChoice />
        </div>
      </>

    </Layout.Header>
  )

}
