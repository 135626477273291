import React, {useState} from 'react';
import {Button, Dropdown, Icon, message, Radio} from 'antd';
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../providers/AccountProvider";

const DownloadSelectionDropdown = ({apiFetchCollection}) => {

    const {t} = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const {setFreemiumOptions} = accountDispatch;

    const [quality, setQuality] = useState('lg')
    const [visible, setVisible] = useState(false)

    function downloadSelection() {
      if (accountState.account.contractType === 'freemium')
        return setFreemiumOptions({visible: true, type: 'download'});

      let ids = [];
      accountState.selection.map(item => {
        ids.push(item.id);
      })
      let params = {
        filters: [
          { name: 'account', value: accountState.account.id},
          { name: 'quality', value: quality },
          { name: 'assets', value: ids.length > 1 ? ids.join(',') : ids[0]}
        ]
      }

      apiFetchCollection('assets/download_selection', params, response => {
        if (response.success) {
          window.location = response.link;
        } else {
          message.error(t('Il y a eu un problème, si il persiste contacter un administrateur'))
        }
        setVisible(false)
      })
    }

    const downloadMenu =
      <div className="my_dropdown dl_dr"
           style={{backgroundColor: 'white'}}
           onClick={e => e.stopPropagation()}
           onMouseLeave={() => setVisible(false)}
      >
        <Radio.Group style={{width: '100%'}} value={quality} onChange={() => setQuality(!quality)}>
          <Radio className="radio_drop" key="hd_download" value="original">Original</Radio>
          <Radio className="radio_drop" key="sd_download" value='lg'>lg</Radio>
        </Radio.Group>
        <Button
          style={{display: 'block', margin: '5px auto'}}
          type="primary"
          size="small"
          onClick={downloadSelection}
        >
          {t('Télécharger')}
        </Button>
      </div>

    return (
      <Dropdown overlay={downloadMenu}
                visible={visible}
                onClick={(e) => {
                  setVisible(!visible);
                  e.stopPropagation();
                }}
      >
        <Button>
          {t('Télécharger')} <Icon type="up"/>
        </Button>
      </Dropdown>
    );
  }
;

export default DownloadSelectionDropdown;
