import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Select, Spin, Icon, message, Divider} from 'antd';
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import {useApiContext} from '../providers/ApiProvider.js';
import DropdownTagAdmin from "./tag/DropdownTagAdmin";

const DirectoryOverview = ({form, selectedDirectory, setSelectedDirectory, setNeedUpdate, needUpdate}) => {

  const {getFieldDecorator} = form;
  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity, apiFetchSubResourceWithFilter, apiFetchEntity} = apiDispatch;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const params = useParams();

  useEffect(() => {
    fetchCropProfiles()
    fetchFolder()
  }, [])

  const fetchFolder = () => {
    apiFetchEntity('tags', selectedDirectory, response => {

      if (response['@type'] === "hydra:Error") {
        message.error(t('Il y a eu une erreur'))
        setSelectedDirectory(null)
        setNeedUpdate((new Date()).getTime())
      } else {
        setData(response);
        setLoading(false)
      }
    })

  }

  const fetchCropProfiles = () => {
    let data = {
      id: params.account_id,
    }
    apiFetchSubResourceWithFilter('accounts', data, 'cropping_profiles', (response) => {
      if (response['"hydra:Error"']) {
        message.error(t('Il y a eu une erreur'))
      } else {
        setProfiles(response['hydra:member']);
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        updateDirectory(values);
      }
    });
  };

  const updateDirectory = (values) => {
    let params = {
      name: values.name,
    };
    values.profile !== null ? params.croppingProfile = values.profile : params.croppingProfile = null
    apiUpdateEntity('tags', selectedDirectory, params, (response) => {
      setData(response);
      setLoading(false)
    })
  };

  const goBack = () => {
    setSelectedDirectory(null);
    setNeedUpdate(needUpdate + 1)
  }

  return (
    <Spin spinning={loading}>
      <h2><Icon type="arrow-left" onClick={goBack} style={{marginRight: '1rem'}}/>{t('Editer le dossier')} :
        "{data.name}"</h2>
      <Form labelCol={{span: 24}}
            onSubmit={handleSubmit}>
        <Form.Item label={t("Nom")}>
          {getFieldDecorator('name',
            {
              rules: [{required: true, message: t('Le nom du profil est requis'), whitespace: true}],
              initialValue: data.name
            },
          )
          (<Input style={{width: '300px'}}/>)}
        </Form.Item>
        <Form.Item label={t("Profil de cropping")}>
          {getFieldDecorator('profile', {initialValue: data.croppingProfile ? data.croppingProfile : null})
          (profiles.length > 0 ?
            <Select style={{width: '300px'}}>
              <Select.Option key={'no_value'} value={null}>{t('Aucun profil')}</Select.Option>
              {
                profiles.map(item =>
                  <Select.Option key={item.id} value={item['@id']}>{item.name}</Select.Option>
                )}
            </Select>
            : <p>{t("Aucun Profil n'a été crée")}</p>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{span: 12}} style={{margin: '1rem 0'}}>
          <Button type="primary" htmlType="submit">
            {t('Enregistrer')}
          </Button>
        </Form.Item>
      </Form>
      <Divider/>
      {data.tagAdmins ?
        <DropdownTagAdmin directory={data}/>
        : null}
    </Spin>
  )
}
export default Form.create()(DirectoryOverview)

