import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon, Input, Tag, Button, Checkbox } from 'antd';
import { useTranslation } from "react-i18next";
import ApiCollectionTable from '../ApiCollectionTable.js';
import { useAuthContext } from "../../providers/AuthProvider";
import { useApiContext } from "../../providers/ApiProvider";
import ModalUser from "../modal/ModalUser";

const { Search } = Input;

const UserList = () => {

  const { t } = useTranslation();
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { customRequest } = apiDispatch;

  const initialFilters = [{ name: 'email', value: '' }, { name: 'roles', value: 'ROLE_BOT' }]
  const [filters, setFilters] = useState(initialFilters)
  const [needUpdate, setNeedUpdate] = useState(0)

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email, record) => <Link to={`./admin/user-overview/${record.id}`}><Icon type="edit" /> {email}</Link>,
    },
    {
      title: 'Prénom',
      dataIndex: 'firstname'
    }, 
    {
      title: 'Username',
      dataIndex: 'username'
    }, 
    {
      title: 'Super admin',
      dataIndex: 'roles',
      render: (roles, record) => roles.includes('ROLE_ADMIN') ? 'Oui' : 'Non'
    }

  ];

  const handleClose = () => {
    setFilters(initialFilters);
  }

  const handleSearch = (e) => {
    setFilters(prev => [...prev.filter(item => item.name !== 'email'), { name: 'email', value: e.trim()}]);
  }

  function addZero(number) {
    if (number < 10)
      return "0" + number;

    return number;
  }

  function dlCsv() {
    customRequest('export-users-csv', response => {
      const now = new Date();
      const arr = [addZero(now.getDate()), addZero(now.getMonth() + 1), now.getFullYear()]
      const date = arr.join('-')
      const filename = 'hapidam-' + date + '.csv'
      const link = document.createElement('a');
      link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  function toggleAdmin() {
    let hasFilter = filters.find(item => item.name === 'roles' && item.value === 'like:ROLE_ADMIN')

    setFilters(prev => 
      hasFilter ? [...prev.filter(item => item.value !== 'like:ROLE_ADMIN')] :
      [...prev, {name: 'roles', value: 'like:ROLE_ADMIN'}]
      )
  }

  return (
    <div>
      <div className='table-header flex between align_center'>
        <div>
          <div>
            <span>{t('Recherche')} : </span>
            <Search
              placeholder={t("Email d'utilisateur")}
              onSearch={handleSearch}
              style={{ width: 200 }}
            />
          </div>
          <div>
            <Checkbox onChange={toggleAdmin}>Super admin</Checkbox>
          </div>
          <div>
            {filters.find(item => item.name === 'email')?.value ?
              <Tag closable onClose={handleClose}>
                {filters.find(item => item.name === 'email').value}
              </Tag>
              : null}
          </div>
        </div>

        {auth.roles.includes("ROLE_ADMIN") ?
          <div>
            <ModalUser reload={() => setNeedUpdate(needUpdate + 1)} />
            <Button className="ml-10" type="primary" onClick={dlCsv}>
              Télécharger les données des utilisateurs
            </Button>
          </div> : null}
      </div>
      <ApiCollectionTable
        endpoint="users"
        columns={columns}
        filtres={filters}
        reload={needUpdate}
      />
    </div>
  )
}

export default UserList;
