import React from 'react';
import { Checkbox, Dropdown, Icon, Rate, Tag } from "antd";
import TagPopup from "../../../asset/TagPopup";
import { useAccountContext } from "../../../../providers/AccountProvider";
import { handleDblClick } from "../../helpers";
import { useTranslation } from "react-i18next";

import ContextMenu from '../ContextMenu';
import MediaView from '../MediaView';
import AssetDraggable from '../../../asset/AssetDraggable';


const CardList = ({ openFullscreen, item, isSelected, selected, setSelected, setLoading, selectable, selectionPreview = false, draggable = false }) => {

  const { t } = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const { filter } = accountDispatch

  const media = item.medias.find(media => media.id === item.currentMedia);
  const ext = media.fileName.split('.').pop()

  const directory = item.tags.find(tag => tag.type === 1);
  const isTranscoding = item.type === 3 && media?.status === 'transcoding';

  function directoryFilter() {
    if (typeof filter !== "function") {
      return;
    }
    let filtre = {
      id: directory.id,
      name: 'tags.name[]',
      value: directory.id,
      tagName: directory.name,
      type: 'directory',
      element: directory
    }
    filter(filtre);
  }

  function handleChange() {
    if (selectable) {
      setSelected(prev => {
        const next = new Set(prev);
        if (isSelected) {
          next.delete(item.id)
        } else {
          next.add(item.id)
        }
        return next;
      })
    }
  }

  return (
    <Dropdown overlay={<ContextMenu asset={item} />} trigger={['contextMenu']}>
      <AssetDraggable
        duplicate={false}
        asset={item}
        itemsChecked={selected}
        setItemsChecked={setSelected}
        setLoading={setLoading}
        draggable={draggable}
      >
        <div
          className={`flex align_center w-100per`}
          onDoubleClick={() => { !selectionPreview ? handleDblClick(item) : console.log('nop') }}
        >
          <div className="flex align_center" style={{ width: '15%' }}>
            {selectable &&
              <Checkbox className="mr" checked={isSelected} onChange={handleChange} />}
            {
              !selectionPreview &&
              <div
                className="mr pointer" style={{ fontSize: 20 }}
                title={t('Plein écran')}
                onClick={e => {
                  e.stopPropagation()
                  openFullscreen()
                }}
              >
                <Icon type="fullscreen" />
              </div>
            }
            <div>
              <MediaView asset={item} />
            </div>
          </div>


          <div className="pointer" style={{ width: '45%' }}>
            <div title={`${item.usageName} (${ext})`}>{item.usageName} {ext !== 'jpeg' ? `(${ext})` : null}</div>
            <div title={media?.originalName} className="light">{media?.originalName}</div>
          </div>

          <div style={{ width: '20%' }}>
            {directory &&
              <span
                onClick={directoryFilter}
                style={{ color: 'rgba(0, 0, 0, 0.65', cursor: 'pointer' }}
                key="dir"
              >
                <Icon type="folder" theme="filled" />&nbsp;
                {accountState.globalSearch ?

                  <span style={{ color: "#1890ff" }}>
                    {item.account.name}
                  </span> : directory.parentTag !== undefined ? directory.parentTag.name + ' > ' : ''}
                {directory.name}
              </span>}
          </div>

          <div style={{ width: '20%' }}>

            <div className="flex align_center end" >

              <div className="mr">
                <Rate value={item.note} disabled />
              </div>

              <div className="mr">
                <TagPopup tags={item.tags.filter(tag => tag.type === 0)} />
              </div>

            </div>
            <div className="flex align_center end asset-status">
              {isTranscoding ? <Tag color="red" title={t('En cours d\'optimisation')}>{t('Optimisation')}</Tag> : null}
            </div>
          </div>
        </div>
      </AssetDraggable>
    </Dropdown>
  );
};

export default CardList;
