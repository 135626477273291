import React, {useState} from 'react'
import {Button} from 'antd'
import {useTranslation} from "react-i18next";
import {useAssetContext} from "../../../providers/AssetProvider";
import {useApiContext} from "../../../providers/ApiProvider";
import {useAccountContext} from "../../../providers/AccountProvider";

export default function BtnSelectVeryAll({itemsChecked, setItemsChecked}) {

  const {t} = useTranslation();
  const [assetState] = useAssetContext();
  const {assets, total} = assetState;
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter} = apiDispatch;
  const [accountState] = useAccountContext();

  const isActive = itemsChecked.size === assets.length && itemsChecked.size < total && total > 30;
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true)
    let data = {
      id: accountState.account.id,
      filters: accountState.filters.concat({name: 'pagination', value: 'false'})
    }

    apiFetchSubResourceWithFilter('accounts', data, 'assets', (hydra_response) => {
      setLoading(false);
      if (hydra_response['@type'] === 'hydra:Error') return;
      setItemsChecked(new Set(hydra_response['hydra:member'].map(item => item.id)))
    });
  }

  return (
    !!isActive &&
    <Button size='small' onClick={onClick} style={{marginLeft: '1em'}} loading={loading}>
      {`${t('Sélectionner les')} ${total} ${t('Images')}`}
    </Button>
  )
}
