import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Icon} from 'antd';
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";

const {SubMenu} = Menu;

export default function DropdownTagAdmin({directory, hiddenTxt = false, tree, setTree, treeKey, forceTagAdmins = null}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, apiUpdateEntity} = apiDispatch;

  const [tagAdmins, setTagAdmins] = useState(forceTagAdmins ? forceTagAdmins : [])
  const [selectedKeys, setSelectedKeys] = useState(directory ? makeTagAdmin() : [])

  function makeTagAdmin() {
    if (tree && setTree && typeof setTree === "function" && treeKey)
      return directory.tagAdmins ? directory.tagAdmins.map(ta => "/api/tag_admins/" + ta) : [];
    else
      return directory.tagAdmins;
  }

  useEffect(() => {
    if (forceTagAdmins)
      setTagAdmins(forceTagAdmins);
  }, [forceTagAdmins])

  useEffect(() => {
    if (!forceTagAdmins)
      fetch();
  }, [])

  function fetch() {
    const data = {
      filters: [
        {name: "pagination", value: false},
        {name: "exists[parent]", value: false}
      ]
    }
    apiFetchCollection("tag_admins", data, response => {
      setTagAdmins(response["hydra:member"])
    })
  }

  function handleClick(e) {
    e.domEvent.stopPropagation()
    let result;
    let action;

    if (selectedKeys.includes(e.key)) {
      result = selectedKeys.filter(key => key !== e.key)
      action = "delete"
    } else {
      result = [...selectedKeys, e.key]
      action = "create"
    }

    action += "/" + e.key.split("/").pop() + "/" + directory.id
    update(result, action)
    setSelectedKeys(result)
  }

  function update(iris, action) {
    if (tree && setTree && typeof setTree === "function" && treeKey)
      updateTreeAdmin(iris)
    else
      updateDirectories(iris, action)
  }

  function updateTreeAdmin(iris) {
    tree.tree = addTagAdminRecursive(iris, "0-", tree.tree)
    setTree({...tree})
  }

  function updateDirectories(iris, action) {
    if (directory) {
      apiUpdateEntity("tags", directory.id, {tagAdmins: iris, changeid: action}, response => {
      })
    }
  }

  function addTagAdminRecursive(newvalue, prefix, array) {
    let newArray = [];

    array.map((t, key) => {
      let row = t;

      if (prefix + key === treeKey)
        row.tagAdmins = newvalue.map(nv => nv.split("/").pop())
      else if (row.tagAdmins)
        row.tagAdmins = row.tagAdmins.map(ta => ta)

      if (t.children)
        row.children = addTagAdminRecursive(newvalue, prefix + key + "-", t.children)

      newArray.push(row)
    })

    return newArray;
  }

  function mapMenu(items) {
    return (
      items.map(item => (
        item.children ?
          <SubMenu key={item["@id"]} title={item.value}>
            {mapMenu(item.children)}
          </SubMenu>
          :
          <Menu.Item key={item["@id"]}>
            {item.value}
          </Menu.Item>
      ))
    )
  }

  const menu = (
    tagAdmins.length ?
    <Menu selectedKeys={selectedKeys} onClick={handleClick}>
      {mapMenu(tagAdmins)}
    </Menu> :
      <div className="my_dropdown"><em>{t("Pas de tag a lier a ce dossier")}</em></div>
  )

  const content = hiddenTxt ?
    <Icon type="tags" theme="twoTone" twoToneColor={selectedKeys.length ? "#52c41a" : ""}/>
    : (<span>{t("Lier des tags au dossier")} <Icon type="down"/></span>);

  return (
    <Dropdown overlay={menu}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        {content}
      </a>
    </Dropdown>
  )
}
