import React from 'react';
import { Checkbox, Tag } from 'antd';
import Actions from "./Actions";
import MediaView from './MediaView';
import { useTranslation } from 'react-i18next';

function CardNoRange({
  item, handleCheck,
  isSelected = false, selectable = false,
  fullscreen = null, seeActions = false, handleClick
}) {

  const { t } = useTranslation()

  const isTranscoding = item.type === 3 && item?.['medias'][0]?.status === 'transcoding';

  return (
    <div className={`selectable-asset item-card ${selectable && isSelected ? 'card-selected' : ''}`}
      data-key={item.id}>
      <MediaView asset={item} title handleClick={(e) => handleClick(e, !isSelected, item.id)} />
      {selectable &&
        <Checkbox checked={isSelected} data={item.id}
          className={`item-card-checkbox ${isSelected ? '' : 'see-on-hover'}`}
          onChange={handleCheck} />}
      {fullscreen}
      <Actions item={item} seeActions={seeActions}/>
      <div className='hide-on-hover asset-status' style={{ position: 'absolute', bottom: '.5em', right: '.5em' }}>
        {isTranscoding ? <Tag color="red" title={t('En cours d\'optimisation')}>{t('Optimisation')}</Tag> : null}
        {item.obsolete ? <Tag color="geekblue">{t('Obsolète')}</Tag> : null}
        {item.isPrivate ? <Tag color="orange">{t('Privé')}</Tag> : null}
      </div>
    </div>
  )
}

export default CardNoRange

