import React, { useState } from "react";
import { Input, Radio, Button, message } from 'antd';
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../../providers/ApiProvider";
import { useParams } from "react-router-dom";
import YoutubeChannel from "./YoutubeChannel";

export default function AddVideo() {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;
  const params = useParams();

  const initialType = 1;
  const initialInputValue = "";
  const initialErrorMessage = ""
  const [type, setType] = useState(initialType);
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)

  const initialChannel = null;
  const initialYt = null;
  const initialVimeo = null;
  const [channel, setChannel] = useState(initialChannel);
  const [yt, setYt] = useState(initialYt)
  const [vimeo, setVimeo] = useState(initialVimeo)

  function resetState() {
    resetResult()
    setInputValue(initialInputValue)
  }

  function resetResult() {
    setChannel(initialChannel)
    setYt(initialYt)
    setVimeo(initialVimeo)
  }

  function changeType(e) {
    resetState()
    setType(e.target.value)
  }


  function search() {
    setErrorMessage(initialErrorMessage)
    if (channel || yt || vimeo)
      resetResult()

    switch (type) {
      case 1:
        getIdYt()
        break;
      case 2:
        getChannel()
        break;
      case 3:
        getVimeoId()
        break;
    }
  }

  function getIdYt() {
    let res = inputValue;

    if (res.includes("?v="))
      res = res.split("?v=").pop();

    if (res.includes("&t="))
      res = res.split("&t=").shift();

    setYt(res)
  }

  function getChannel() {
    let res = inputValue;

    if (res.includes("youtube.com/channel/"))
      res = res.split("youtube.com/channel/").pop();

    setChannel(res);
  }

  function getVimeoId() {
    let res = inputValue;

    if (res.includes("vimeo.com/"))
      res = res.split("vimeo.com/").pop()

    setVimeo(res);
  }

  function push() {
    switch (type) {
      case 1:
        pushYt()
        break;
      case 2:
        pushChannel()
        break;
      case 3:
        pushVimeo()
        break;
    }
  }

  function pushVimeo() {
    const data = {
      account: "/api/accounts/" + params.account_id,
      type: 2,
      platform: 2,
      networkId: vimeo
    }

    flush(data)
  }

  function pushYt() {
    const data = {
      account: "/api/accounts/" + params.account_id,
      type: 2,
      platform: 1,
      networkId: yt
    }

    flush(data)
  }

  function pushChannel() {
    const data = {
      account: "/api/accounts/" + params.account_id,
      type: 1,
      platform: 1,
      networkId: channel
    }

    flush(data)
  }

  function flush(data) {
    apiPostEntity("social_networks", data, response => {
      if (response["@type"] === "hydra:Error")
        return setErrorMessage(t(response["hydra:description"]));

      message.success(t('Enregistrement terminé'));
      setInputValue('')
      setChannel(null)
      setYt(null)
      setVimeo(null)
    })
  }

  return (
    <div>

      <div style={{ maxWidth: 500 }}>
        <div className="padding-10">
          <p>
            {t('Choisissez votre type de vidéo')} :
          </p>
          <Radio.Group className="mt-5" value={type} onChange={changeType}>
            <Radio style={{ display: "block" }} value={1}>{t('Vidéo')} Youtube</Radio>
            <Radio style={{ display: "block" }} value={2}>{t('Chaîne')} Youtube</Radio>
            <Radio style={{ display: "block" }} value={3}>{t('Vidéo')} Viméo</Radio>
          </Radio.Group>
        </div>

        <div className="padding-10">
          {t('Copiez-collez l\'url ou l\'identifiant')} :
          <Input.Search className="mt-5" placeholder={t("ID de la vidéo/chaîne")} value={inputValue}
            onChange={(e) => setInputValue(e.target.value)} onSearch={search} enterButton={t('Rechercher')} />
        </div>

      </div>

      <div>

        {channel ?
          <YoutubeChannel channelId={channel} />
          : null}

        {yt ?
          <div className={"video-container"}>
            <iframe
              src={"https://www.youtube.com/embed/" + yt}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          : null}

        {vimeo ?
          <div className={"video-container"}>
            <iframe
              src={`https://player.vimeo.com/video/${vimeo}`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
          : null}

        <div className="pt-default">
          {
            (channel || yt || vimeo) &&
            <Button onClick={push}>{t('Valider')}</Button>
          }
          {
            errorMessage &&
            <p style={{ color: "red" }}>{errorMessage}</p>
          }
        </div>
      </div>
    </div>
  )
}
