import React from 'react';
import {Modal, Button} from "antd";
import EditDirectories from "../EditDirectories";
import {useTranslation} from "react-i18next";

const ModalEditDirectories = ({visible, handleClose}) => {

  const {t} = useTranslation();

  return (
    <Modal
      visible={visible}
      centered
      title={t('Modification des dossiers')}
      closable={true}
      onCancel={handleClose}
      bodyStyle={{maxHeight: "70vh", overflow: "auto"}}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={handleClose}>{t('Fermer')}</Button>,
      ]}
    >
      <EditDirectories showTitle={false}/>
    </Modal>
  )
}

export default ModalEditDirectories
