import React from 'react'
import {Button} from 'antd';
import {useTranslation} from "react-i18next";
import { useMediaQuery } from 'react-responsive';

/**
 * -Front
 * --Bouton pour selectionner tous les assets
 */
function BtnSelectAll({selectAll, itemsChecked, unselectAll}) {

  const {t} = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  function onClick() {
    if (itemsChecked.size)
      unselectAll()
    else
      selectAll()
  }

  return (
    <Button
      type={itemsChecked.size ? 'default' : 'primary'}
      className={window.innerWidth > 768 ? 'ml-10' :''}
      onClick={onClick}
      size={'small'}
      shape={isTabletOrMobile && itemsChecked.size ? "circle" : "round"}
      icon={itemsChecked.size ? 'close' : ''}
    >
      {
        itemsChecked.size
        ? <i className={'no-mobile'} style={{fontStyle: 'normal'}}>{!isTabletOrMobile && t('Désélectionner')}</i>
        : <span>{t('Tout sélectionner')}</span>
      }
    </Button>
  )
}

export default BtnSelectAll
