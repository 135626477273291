import React from 'react'
import {Modal, Rate} from 'antd'
import {Link} from "react-router-dom";

export default function ModalImage({title, visible, setVisible, image, footer, mimeType, link, stars}) {
    const className = mimeType === "image/png" ? 'png_transparent' : null;

    function click(e) {
        if (!link)
            e.preventDefault();
    }

    return (
        <Modal
            className="modal_img"
            centered={true}
            title={title}
            style={{textAlign: "center"}}
            width="auto"
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={footer}
            destroyOnClose={true}
        >
            <Link to={link ? link : "/"} onClick={click}>
                <img
                    className={className}
                    id="image_social_modal"
                    style={{marginTop: 20, maxHeight: '80vh', maxWidth: '90vw'}}
                    src={image ? image : null}
                />
            </Link>
            <div>
                <Rate disabled value={stars}/>
            </div>
        </Modal>
    )
}
