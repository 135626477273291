import React, {useEffect, useState} from "react";
import {Button, Form, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import Trees from "../TreeChoice/Trees";
import MyPagination from "../MyPagination";

export default function ModalTreeChoice({visible, setVisible, accountsIds, reloadTags}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchCollection, patch} = apiDispatch;

  const [trees, setTrees] = useState([]);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    fetch();
  }, [page])

  function fetch() {
    apiFetchCollection("trees", {page: page, itemsPerPage: itemsPerPage}, response => {
      setTrees(response["hydra:member"]);
      setTotal(response["hydra:totalItems"]);
    })
  }

  function onOk() {
    if (!selected)
      return message.error(t("Aucune arborescence selectioné"));

    if (!accountsIds)
      return message.error(t("Une erreur est survenu, veuillez réessayer plus tard"))

    setLoading(true)

    const data = {
      accounts: accountsIds,
      tree: selected
    }

    patch("/api/push-tree-in-account", data, response => {
      if (typeof reloadTags === "function")
        reloadTags()

      message.success(t("Arborescence importé"))
      close()
    })
  }

  function close() {
    setLoading(false)
    setSelected(0)
    setVisible(false)
  }

  return (
    <Modal
      width={1200}
      visible={visible}
      title={t("Choisissez une arborescence")}
      closable={true}
      destroyOnClose={true}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('Annuler')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onOk}
          loading={loading}
        >
          {t('Confimer')}
        </Button>
      ]}
    >
      <Form>
        <Trees
          trees={trees}
          selected={selected}
          setSelected={setSelected}
        />
        <MyPagination
          current={page}
          changePage={setPage}
          totalItem={total}
          itemPerPage={itemsPerPage}
        />
      </Form>
    </Modal>
  )
}
