import React from 'react'
import {Tabs} from 'antd'
import Help from '../components/support/Help'
import {useAuthContext} from '../providers/AuthProvider.js';
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

export default function Support() {

  const {t, i18n} = useTranslation();
  const [authState, authDispatch] = useAuthContext();
  const {auth, authLogout} = authState;

  return (
    <Tabs
      animated={false}
      size='small'
    >
      <TabPane className="scrollPan" tab={t('Aide')} key="1">
        <Help/>
      </TabPane>
      <TabPane className="scrollPan" tab={t('Support')} key="2">
        <div className="contenu-tab">
          {t('Pour des demandes plus précises, contactez nous par mail :')} <a
          href={`mailto:support@mmcreation.com?subject=HapiDam_support%20from%20${auth.username}`}>support@mmcreation.com</a>
        </div>
      </TabPane>
    </Tabs>
  )
}
