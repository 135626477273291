import React, {useEffect, useState} from "react"
import {Spin} from "antd";

export default function TemplateColumn({images, loading, displayImage}) {

  const [column, setColumn] = useState(nbColumnStart());
  const [columnA, setColumnA] = useState([]);
  const [columnB, setColumnB] = useState([]);
  const [columnC, setColumnC] = useState([]);
  const [columnD, setColumnD] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', function (event) {
      onResize()
    });
  }, [])

  useEffect(() => {
      makeColumn(images)
  }, [column, images])


  function nbColumnStart() {
    if (window.innerWidth > 1400) {
      return 4;
    }
    if (window.innerWidth > 968) {
      return 3;
    }
    if (window.innerWidth > 768) {
      return 2;
    }
    return 1;
  }

  function onResize() {
    if (window.innerWidth > 1289) {
      setColumn(4);
      return;
    }
    if (window.innerWidth > 968) {
      setColumn(3);
      return;
    }
    if (window.innerWidth > 768) {
      setColumn(2);
      return;
    }

    setColumn(1);
  }

  function makeColumn(items) {
    let a = [], b = [], c = [], d = [];
    items.map((item, id) => {
      switch ((id + 1) % column) {
        case 1 :
          a.push(item);
          break;
        case 2 :
          b.push(item);
          break;
        case 3 :
          c.push(item);
          break;
        case 0 :
          pushInGood(item, a, b, c, d);
          break;
      }
      setColumnA(a);
      setColumnB(b);
      setColumnC(c);
      setColumnD(d);
    })
  }

  function pushInGood(item, a, b, c, d) {
    switch (column) {
      case 1 :
        a.push(item);
        break;
      case 2 :
        b.push(item);
        break;
      case 3 :
        c.push(item);
        break;
      case 4 :
        d.push(item);
        break;
    }
  }

  function displayColumn() {
    let grap = [];
    for (var i = 1; i <= column; i++) {
      switch (i) {
        case 1 :
          grap.push(makeGrap(columnA, 1));
          break;
        case 2 :
          grap.push(makeGrap(columnB, 2));
          break;
        case 3 :
          grap.push(makeGrap(columnC, 3));
          break;
        case 4 :
          grap.push(makeGrap(columnD, 4));
          break;
      }
    }

    return grap;
  }

  function makeGrap(tab, key) {
    return (
      <div key={key} className="column_galery" style={key === column ? {marginRight: 0} : null}>
        {
          tab.map((item, id) => (
            displayImage(item, id, key, tab.length)
          ))
        }
      </div>
    )
  }

  return (
    <Spin spinning={loading}>
      <div className="galery">
        {displayColumn()}
      </div>
    </Spin>
  )
}
