import React, {useEffect, useState} from 'react';
import {Table, Form, Input, Button, InputNumber, Spin, Icon, TreeSelect } from 'antd';
import {useTranslation} from 'react-i18next';
import {useAccountContext} from "../providers/AccountProvider";
import {useApiContext} from '../providers/ApiProvider.js';
import {formatFolderForSelect} from "../helpers";


const CroppingProfile = ({form, profile, mode = 'create', setMode, setSelectedItem, setNeedUpdate, needUpdate}) => {

  const {getFieldDecorator, getFieldValue} = form;
  const {t, i18n} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const {apiUpdateEntity, apiPostEntity, apiFetchCollection} = apiDispatch;
  const [data, setData] = useState(profile);
  const [loading, setLoading] = useState(false);
  const [directories, setDirectories] = useState([]);
  const [folderValue, setFolderValue] = useState(profile.tags)

  useEffect(() => {
    fetchFolders()
  }, [])

  const fetchFolders = () => {
    let params = {
      filters: [{
        name: 'account',
        value: accountState.account.id
      }]
    };
    apiFetchCollection('get_folders', params, (response) => {
      setDirectories(response);
      setLoading(false);
    });
  }


  const columns = [
    {
      title: t('Nom'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record, index) => <Form.Item style={{margin: 'none'}}>
        {getFieldDecorator('name_' + index, {
          initialValue: name
        })(<Input/>,
        )}
      </Form.Item>,
    },
    {
      title: t('Largeur'),
      dataIndex: 'width',
      key: 'width',
      render: (width, record, index) => <Form.Item>
        {getFieldDecorator('width_' + index, {
          initialValue: width
        })(<InputNumber/>,
        )}
      </Form.Item>
    },
    {
      title: t('Hauteur'),
      dataIndex: 'height',
      key: 'height',
      render: (height, record, index) => <Form.Item>
        {getFieldDecorator('height_' + index, {
          initialValue: height
        })(<InputNumber/>,
        )}
      </Form.Item>
    },
    {
      title: t('Action'),
      key: 'action',
      render: (text, record, index) => (
        <Button size="small" type="danger" onClick={() => handleDelete(index)}>
          {t('Supprimer')}
        </Button>
      ),
    },
  ];

  const handleDelete = (id) => {
    let dataCopy = {...data};
    let newCropSizeArray = dataCopy.cropSizes.filter((item, index) => index !== id);
    newCropSizeArray = reOrder(newCropSizeArray);
    dataCopy.cropSizes = [...newCropSizeArray];
    setData(dataCopy);
  }

  const reOrder = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].order = i;
    }
    return arr
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        console.log('Received values of form: ', values);
        const cropSizeTable = await getUpdatedTableData();
        const name = values.profile_name;

        if (mode === 'create') {
          createCropProfile(name, cropSizeTable);
        } else if (mode === 'update') {
          updateCropProfile(name, cropSizeTable);
        }
      }
    });
  };

  const getUpdatedTableData = () => {
    let tableDataCopy = [...data.cropSizes];
    let cropArr = [];
    for (let i = 0; i < tableDataCopy.length; i++) {
      let item = {
        order: i,
        name: getFieldValue('name_' + i),
        width: getFieldValue('width_' + i),
        height: getFieldValue('height_' + i),
      }
      cropArr.push(item)
    }
    return cropArr;
  }

  const createCropProfile = (name, cropSizeTable) => {
    let params = {
      account: accountState.account['@id'],
      name,
      cropSizes: cropSizeTable,
      tags: folderValue,
      updated: new Date()
    };
    console.log('cropSizeTable', cropSizeTable)
    apiPostEntity('cropping_profiles', params, (response) => {
      setData(response);
      setLoading(false)
      setMode('update')
    })
  };

  const updateCropProfile = (name, cropSizeTable) => {
    let params = {
      name,
      cropSizes: cropSizeTable,
      tags: folderValue,
      updated: new Date()
    };
    apiUpdateEntity('cropping_profiles', data.id, params, (response) => {
      setData(response);
      setLoading(false)
    })
  };

  const addRow = () => {
    let dataCopy = {...data};
    dataCopy.cropSizes.push({
      order: dataCopy.cropSizes.length,
      name: 'Size name',
      width: 450,
      height: 450
    })
    setData(dataCopy);
  }

  const goBack = () => {
    setSelectedItem({name: '', cropSizes: []});
    setMode('list');
    setNeedUpdate(needUpdate + 1)

  }
  return (
      <Spin spinning={loading}>
        <h2><Icon type="arrow-left" onClick={goBack}/> {t(`${mode === 'update' ? 'editer' : 'créer'} un profil`)}</h2>
        <Form labelCol={ {span: 24}}
              wrapperCol={{xs: {span:24},md: {span: 12}}}
              onSubmit={handleSubmit}>
          <Form.Item label={t("Nom du profil")}>
            {getFieldDecorator('profile_name',
              {
                rules: [{required: true, message: t('Le nom du profil est requis'), whitespace: true}],
                initialValue: mode === 'update' ? data.name : ''
              },
            )
            (<Input/>)}
          </Form.Item>
          <div style={{display:'flex', justifyContent: 'space-between'}}>
            <h3>{t('Taille des images')}</h3>
            <Button size="small" type="primary" onClick={addRow}>
              {t('Ajouter une taille')}
            </Button>
          </div>
          <Table className="cropSizeTable" columns={columns}
                 dataSource={data.cropSizes} pagination={false}
                 rowKey={record => record.order} />
          <h3>{t('Dossier liés')}</h3>
          <TreeSelect
            style={{ width: '50%' }}
            value={folderValue}
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            placeholder="Choisissez un ou plusieurs dossiers"
            allowClear
            multiple
            showSearch={false}
            treeDefaultExpandAll
            onChange={(value) => setFolderValue(value)}
          >
            {formatFolderForSelect(directories, 0)}
          </TreeSelect>
          <Form.Item wrapperCol={{span: 12}}>
            <Button type="primary" htmlType="submit" style={{marginTop: '1em'}}>
              {t('Enregistrer')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
  )
}
export default Form.create()(CroppingProfile)

