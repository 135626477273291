import React from 'react'
import { Icon, List, message, Popconfirm, Skeleton, Switch } from 'antd'
import moment from 'moment'
import { useApiContext } from '../providers/ApiProvider';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../providers/AuthProvider';
const entrypoint = process.env.ENTRYPOINT;


export default function PermissionListItem({ permission, setNeedUpdate, setSelectedPerm, setEditingPerm }) {

    const { t } = useTranslation()
    const [apiDispatch] = useApiContext();
    const { apiDeleteEntity, apiUpdateEntity } = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const { auth } = authState;

    function handleUpdate(perm, value) {
        let params = { isActive: value }
        apiUpdateEntity('permissions', perm.id, params, response => {
            if (response['@type'] === 'hydra:Error') {
                message.error(t('Il y a eu une erreur'))
                setNeedUpdate((new Date()).getTime())
                return
            }
            message.success(t('Permission mis à jour'))
        })

    }

    const handleDelete = async (permission) => {
        await apiDeleteEntity('permissions', permission.id, response => {
        })
        setNeedUpdate((new Date()).getTime())

    }

    function handleEdit(perm) {
        setSelectedPerm(perm)
        setEditingPerm(true)
    }

    const roleResolver = (permission) => {
        let suffix = permission.createdAt ?
            t('créer le') + ' ' + moment(permission.createdAt).format('DD-MM-YYYY')
            : '';
        let text = '';

        if (permission.share) {
            if (permission.deletedAt) suffix += ' - ' + t('expire le') + ' ' + moment(permission.deletedAt).format('DD-MM-YYYY')
            text = t('Invité')
        } else {
            switch (permission.role) {
                case 'ROLE_VIEW':
                    text = t('Viewer')
                    break;
                case 'ROLE_ADMIN':
                    text = t('Admin')
                    break;
                case 'ROLE_EDIT':
                    text = t('Éditeur')
                    break;
            }
        }

        return <span>{text} <small>{suffix}</small> </span>
    }

    function displayUserData(user) {
        let username = user.email !== user.username ? user.username : ''
        return <span>{user.email} - <small>{username}</small></span>
    }

    function displayShareData(permission) {
        let duration = permission?.deletedAt ? "temporaire" : "permanent"
        return <span>
            {t('Partage')} {duration} <small>{permission.email}</small>
        </span >
    }

    function copy(e) {
        e.stopPropagation()
        let link = entrypoint + '/visitor/' + permission.token
        navigator.clipboard.writeText(link);
        message.success('Lien copié dans le presse papier')
    }

    return (
        <Skeleton avatar title={false} loading={permission.loading} active>
            <List.Item.Meta
                description={<span>{roleResolver(permission)}</span>}
                title={permission.user ? displayUserData(permission.user) : displayShareData(permission)}
            />

            < div style={{ textAlign: 'right' }}>
                {
                    permission.user && permission.user.id === auth.id ? null :
                        <>
                            <>
                                {
                                    permission.share ?
                                        <div>
                                            <a href={'/visitor/' + permission.token} target="_blank" style={{ marginRight: '5px' }}>{t('Voir')}</a>
                                            <Icon type="copy" onClick={copy} />
                                        </div> :
                                        <Switch size="small" defaultChecked={permission.isActive !== null ? permission.isActive : true}
                                            onChange={(e) => handleUpdate(permission, e)} style={{ display: 'block' }} />
                                }
                            </>
                            <Icon style={{ marginRight: '5px' }} type="edit" onClick={() => handleEdit(permission)} />
                            <Popconfirm placement="top"
                                title={t('Etes-vous sûr de vouloir supprimer cette permission ?')}
                                onConfirm={() => handleDelete(permission)} okText={t('Oui')}
                                cancelText={t('Non')}>
                                <Icon type="delete" />
                            </Popconfirm>
                        </>
                }
            </div >
        </Skeleton >
    )
}
