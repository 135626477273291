import React, { useEffect, useState } from 'react';
import Selecto from "react-selecto";

const Selectable = ({ setSelected, scrollerRef, continueSelect = false }) => {

  const [scrollOptions, setScrollOptions] = useState({});
  const extractIds = (els => els.map((v) => parseFloat(v.getAttribute("data-key"))))

  useEffect(() => {
    setScrollOptions({
      container: scrollerRef.current,
      throttleTime: 20,
      threshold: 0,
    });
  }, []);

  const handleSelect = (e) => {
    const { added, removed } = e
    added.forEach(el => {
      el.classList.add("card-selected");
    });
    removed.forEach(el => {
      el.classList.remove("card-selected");
    });

    setSelected((prev) => {
      const next = new Set(prev);
      extractIds(added).forEach((id) => next.add(id));
      extractIds(removed).forEach((id) => next.delete(id));
      return next;
    });
  }

  const handleSelectStart = (e) => {
    const { selected } = e
    if (!selected.length && !e.inputEvent.shiftKey)
      setSelected(new Set());
  }

  return (
    <Selecto
      dragContainer={".selectable-container"}
      selectableTargets={[".selectable-asset"]}
      hitRate={0}
      scrollOptions={scrollOptions}
      selectByClick={false}
      selectFromInside={false}
      continueSelect={continueSelect}
      toggleContinueSelect={['ctrl']}
      ratio={0}
      onSelect={handleSelect}
      onSelectStart={handleSelectStart}
      onScroll={e => {
        scrollerRef.current.scrollBy(e.direction[0] * 20, e.direction[1] * 20);
      }}
    />
  )
}

export default Selectable;
