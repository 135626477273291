import React, { useEffect, useState, useRef } from 'react';
import { useParams, Redirect, Link } from 'react-router-dom'
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from '../providers/ApiProvider.js';
import { useAuthContext } from '../providers/AuthProvider.js';
import { Button, Card, message, PageHeader, Spin } from 'antd'
import { useTranslation } from "react-i18next";
import SEOTabs from '../components/media/imageData/SEOTabs';
import MediaView from '../components/asset/MediaView';
import MyPagination from '../components/MyPagination';

const AccountSeo = () => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiFetchSubResourceWithFilter, deeplTranslation } = apiDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const { resetState } = accountDispatch;
  const [loading, setLoading] = useState(true);

  const isMounted = useRef(null);
  const [assets, setAssets] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1)
  const [texts, setTexts] = useState({})
  const [trads, setTrads] = useState(null)

  const params = useParams();

  useEffect(() => {
    isMounted.current = true;

    fetchAssets();

    return function cleanup() {
      resetState();
      isMounted.current = false;
    }

  }, [page]);

  useEffect(() => {
    if (assets.length > 0) {
      let content = assets.reduce((acc, current) => {
        let result = []
        if (current?.seoLegend && JSON.parse(current?.seoLegend)?.fr) result.push(JSON.parse(current?.seoLegend)?.fr)
        if (current?.seoAlt && JSON.parse(current?.seoAlt)?.fr) result.push(JSON.parse(current?.seoAlt)?.fr)
        return [...acc, ...result]
      }, [])
      console.log('content', content)
      setTexts(content)
    }
  }, [assets])

  function fetchAssets() {
    setLoading(true)
    const data = {
      id: params.account_id,
      filters: [
        { name: 'type', value: 1 },
        { name: 'page', value: page }
      ]
    }
    apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
      if (response['@type'] === "hydra:Collection") {

        setTotalItem(Math.ceil(response['hydra:totalItems']));
        setAssets(response['hydra:member'])
        setLoading(false)
      }
    })
  }


  const updateEntity = async (entity, id, Data, type = null) => {
    await apiUpdateEntity(entity, id, Data, (response) => {
      console.log('response', response)
      if (response.error) {
        message.error(t('Une erreur est survenue'));
        return;
      }

      if (type)
        message.success(type);
    });
  };

  const translate = async () => {
    if (texts.length < assets.length) return message.error('veuillez traduire tous les textes afin d\'optimiser la traduction')
    deeplTranslation('en', texts, 'fr', response => {
      console.log('response', response)
      if (response.translations) {
        message.success('Traduction réussi')
        let tradObj = {}
        response.translations.map((trad, index) => tradObj[texts[index]] = trad)
        setTrads(tradObj)
      }
    })
  }

  if (!auth.roles.includes('ROLE_ADMIN'))
    return <Redirect to={`/account/${accountState.account.id}/`} />

  return (
    <div className="contenu-tab">
      <PageHeader title="Edition Seo" ghost={false}
        extra={[
          <Button key='trad' onClick={translate}>Trad</Button>,
          <Link key="setting" to={`/account/${accountState.account.id}`}>
            <Button
              className='ml'
              icon="home" >
              {window.innerWidth > 768 ? t('Accueil') : ''}
            </Button>
          </Link>]} />
      <MyPagination
        current={page}
        totalItem={totalItem}
        itemPerPage={30}
        changePage={setPage}
        margin={'1rem'}
        bgColor={'none'}
      />
      <Spin spinning={loading} >
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(19%, 1fr))', gridGap: '1em' }}>
          {
            assets.map(asset =>
              <Card
                key={asset.id}
                title={asset.usageName}
                cover={<div className='item-card' style={{ height: '200px' }}><MediaView asset={asset} title={false} /></div>}
              >
                <SEOTabs asset={asset} updateEntity={updateEntity} translations={trads} />
              </Card>
            )
          }
        </div>
      </Spin>
    </div>
  );
};

export default AccountSeo;
