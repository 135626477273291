import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {useAuthContext} from "../providers/AuthProvider";
import {Link, Redirect} from "react-router-dom";
import {Button, Card, Divider, message, Tooltip, Icon} from "antd";
import CreateAccountForm from "../components/account/CreateAccountForm";
import ModalTreeChoice from "../components/modal/ModalTreeChoice";
import ModalFolderPermission from "../components/modal/ModalFolderPermission";
import {useApiContext} from "../providers/ApiProvider";

export default function CreateAccounts() {

  const {t} = useTranslation();
  const [authState] = useAuthContext();
  const {auth} = authState;
  const [apiDispatch] = useApiContext();
  const {apiDeleteEntity} = apiDispatch;

  const [accounts, setAccounts] = useState([]);
  const [tree, setTree] = useState(false);
  const [accountsIds, setAccountsIds] = useState([])
  const [permission, setPermission] = useState(false);
  const [account, setAccount] = useState(0);
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    if (accounts.length) {
      setIsCreating(false)
    }

  }, [accounts])

  if (!auth.roles.includes('ROLE_ADMIN')) {
    return <Redirect to={{
      pathname: "/erreur",
      state: {status: '403', subtitle: t('Vous n\'êtes pas autorisé à accéder à cette page')}
    }}/>
  }

  function removeAccount(account) {
    if (account.childAccount && account.childAccount.length)
      return message.error(t("Vous ne pouvez pas supprimer un compte avec des enfants, supprimez d'abord les enfants"));

    apiDeleteEntity("accounts", account.id, response => {
      let ids = accounts.map(a => {
        if ((a.parentAccount && a.parentAccount.id === account.id) || a.id === account.id)
          return a.id;
      })

      setAccounts(accounts.filter(a => !ids.includes(a.id)));
    })
  }

  function modal(account, action) {
    switch (action) {
      case "tree":
        setAccountsIds([account.id])
        setTree(true);
        break;
      case "trees":
        setAccountsIds(accounts.map(a => a.id));
        setTree(true);
        break;
      case "perm":
        setAccount(account);
        setPermission(true);
        break;
    }
  }

  return (
    <div className="contenu-tab">
      {
        isCreating || !accounts.length ?
            <CreateAccountForm setAccounts={setAccounts}/>
          :
          <div className={"more-button"} onClick={() => setIsCreating(true)}>
            <Icon type='plus' />
            <div className="ant-upload-text">Créer un compte</div>
          </div>
      }
      <div>
        {accounts.length ?
          <div>
            <div className="flex between m-20">
              <h3>{t("Compte(s) créé(s)")} :</h3>
              {accounts.length > 1 ?
                <Button icon="download" type="primary" onClick={() => modal(null, "trees")}>
                  {t("Importer une arbo sur tous les comptes")}
                </Button> : null}
            </div>
            <div className="flex wrap">
              {accounts.map(account => (
                <Card
                  key={account.id}
                  style={{width: 300, margin: 5}}
                  title={<Link to={`/account/${account.id}`}>{account.name}</Link>}
                  extra={
                    <div>
                      <Tooltip title={t("Permissions")}>
                        <Button className="mr-5" icon="lock" size="small" onClick={() => modal(account, "perm")}/>
                      </Tooltip>
                      <Tooltip title={t("Importer arbo")}>
                        <Button className="mr-5" icon="download" size="small" type="primary"
                                onClick={() => modal(account, "tree")}/>
                      </Tooltip>
                      <Button size="small" type="danger" icon="close" onClick={() => removeAccount(account)}/>
                    </div>
                  }
                >
                  <p>{t("Contrat")} : {account.contractType}</p>
                  <p>{t("Renommage")} : {account.renameOn ? "oui" : "non"}</p>
                  <p>{t("Parent")} : {account.parentAccount ? account.parentAccount.name : "aucun"}</p>
                </Card>
              ))}
            </div>
            <Divider/>
          </div> : null}


        {tree ? <ModalTreeChoice visible={tree} setVisible={setTree} accountsIds={accountsIds}/> : null}
        {permission ? <ModalFolderPermission account={account} setIsEditingPerms={setPermission}/> : null}
      </div>

    </div>
  );
}
