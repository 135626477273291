import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Divider, Icon, Input, Row, Spin, Tag } from 'antd';
import { useApiContext } from "../providers/ApiProvider";
import { useAuthContext } from "../providers/AuthProvider.js";
import EmptyPlaceholder from "./EmptyPlaceholder";
import MyPagination from "./MyPagination";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const ApiCardList = ({ endpoint }) => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const [data, setData] = useState([])
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1)
  const initialFilters = [{ name: 'name', value: '' }, { name: 'status', value: '1' }];
  const [filters, setFilters] = useState(initialFilters);

  const [loading, setLoading] = useState(false)

  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;

    // fetch at startup and whenever [value] is changed
    fetch({
      page: page,
      itemsPerPage: 30,
      filters
    });
    return () => {
      isMounted.current = false;
    }
  }, [filters[0].value, page])

  function fetch(params) {
    setLoading(true)
    apiFetchCollection(endpoint, params, (hydra_response) => {
      if (isMounted.current) {
        setTotalPage(hydra_response['hydra:totalItems']);
        setData(hydra_response['hydra:member'])
        setLoading(false);
      }
    })
  }

  function handleSearch(value) {
    setFilters([{ name: 'name', value: value }, { name: 'status', value: '1' }])
    setPage(1)
  }

  function linkToGroup(account) {
    return account.childAccount.length &&
      (auth.roles.includes("ROLE_ADMIN") || auth.permissions.some(p => p.account.id === account.id && !p.tags.length));
  }

  return (
    <Spin spinning={loading}>
      <div style={{ margin: '1em 0' }}>
        <Input.Search
          placeholder={t("Rechercher un compte")} onSearch={handleSearch}
          enterButton={t("Rechercher")} className="mr" style={{ width: 300 }} />
        {filters[0].value ?
          <div>
            Filtre actif : <Tag closable onClose={() => setFilters(initialFilters)}>{filters[0].value}</Tag>
          </div> : null
        }
        <Divider />
        <MyPagination
          current={page}
          totalItem={totalPage}
          itemPerPage={30}
          changePage={setPage}
          margin={'1rem'}
          bgColor={'none'}
        />
        <Row gutter={[16, 16]}>
          {data.length > 0 ?
            data.map(account => (
              <Col key={account.id} className="gutter-row" sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Card
                  actions={
                    linkToGroup(account) ?
                      [
                        <Link key="group-link" to={`./account-group/${account.id}/`}>
                          {t("Accès groupe")}
                        </Link>,
                        <Link key="acc-link" to={`./account/${account.id}/`}>
                          {t("Accès compte")}
                        </Link>
                      ]
                      :
                      [<Link key="acc-link" to={`./account/${account.id}/`}>
                        {t("Accès compte")}
                      </Link>]
                  }
                >
                  <Link to={`./account/${account.id}/`}>
                    <Meta
                      avatar={
                        linkToGroup(account) ? <Icon type="bank" theme="twoTone" twoToneColor="#0cc800" /> :
                          <Icon type="home" theme="twoTone" />
                      }
                      title={account.name}
                    />
                  </Link>
                  {
                    auth.roles.includes("ROLE_ADMIN") &&
                      <Tag style={{ lineHeight: '15px', margin: '0', padding: '0 4px', position: 'absolute', top:'.5em', right: '.5em' }}
                        color="#108ee9">{account.assets}</Tag>
                  }
                </Card>
              </Col>
            ))
            :
            <EmptyPlaceholder message={endpoint} />
          }
        </Row>
      </div>
    </Spin>

  );
};

export default ApiCardList;
