import React, { useEffect, useRef, useState } from 'react';
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import Folder from './Folder.js';
import { useTranslation } from "react-i18next";
import { Icon, Spin, Tag } from "antd";
import { useAuthContext } from "../../providers/AuthProvider";

const TreeTag = ({ closeMenu = () => {}, setEditDirectoriesModal, setHasChildrenOpen = null, forceOpen = false }) => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiPostEntity, apiFetchSubResourceWithFilter } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { setActiveTab, resetFilter } = accountDispatch;
  const [authState] = useAuthContext();
  const { auth } = authState;

  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [folders, setFolders] = useState([]);
  const [total, setTotal] = useState(0);
  const [firstLoadOpened, setFirstLoadOpened] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    //search folders id that should be opened on fisrt load
    let folderFilter = accountState.filters.find(filter => filter.type === 'directory')
    if (folderFilter && folders.length > 0)
      setFirstLoadOpened(getFolderListFromId(folderFilter.id, folders))
  }, [folders])

  const getFolderListFromId = (searchId, list) => {
    return list.reduce((accumulator, current) => {
      if (current.id == searchId) {
        accumulator.push(current.id)
      } else if (current.childTags.length) {
        let matchList = getFolderListFromId(searchId, current.childTags)
        if (matchList.length) {
          accumulator = [...accumulator, ...matchList, current.id]
        }
      }
      return accumulator;
    }, [])
  }

  useEffect(() => {

    if (accountState.activeTab === '2' && accountState.filters.filter(filter => filter.name === 'status').length === 1
      && accountState.filters.filter(filter => filter.type === 'directory').length === 0) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }

  }, [accountState.activeTab, accountState.filters])

  useEffect(() => {
    if (accountState.account) {
      isMounted.current = true;
      fetchTags();
      fetchTotal()
    }
    return () => { isMounted.current = false }
  }, [accountState.account, accountState.rerender]);

  function fetchTags() {
    setLoading(true)
    let params = { filters: [{ name: 'account', value: accountState.account.id }] };

    if (accountState.globalSearch && accountState.accountActive)
      params.filters = [{ name: 'account', value: accountState.accountActive.id }];

    //console.time('fetch')
    apiFetchCollection('get_folders', params, (hydra_response) => {
      if (isMounted.current) {
        //console.timeEnd('fetch')
        setFolders(hydra_response)
        if (auth.roles.includes('ROLE_ADMIN')) checkHapiFolder(hydra_response)
        setLoading(false)
      }
    });
  }

  function fetchTotal() {
    let data = {
      id: accountState.account.id,
      itemsPerPage: 1,
      page: 1,
      filters: [
        { name: 'status', value: '1' },
      ]
    }
    if (!accountState.admin)
      data.filters = data.filters.concat({ name: 'isPrivate', value: 'false' });

    if (!accountState.admin && !accountState.editor) {
      data.filters = data.filters.concat([{ name: 'obsolete', value: 'false' },]);
    }
    apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
      setTotal(response['hydra:totalItems']);
    });
  }

  function checkHapiFolder(folders) {
    const hapiFolder = folders.find(item => item.name === 'hapiv2')

    if (hapiFolder) {
      apiPostEntity('check-folder', { folderId: hapiFolder.id, checkOnly: true }, response => {

        if (response?.success === 1 && response?.step === 1) {
          let message = `${response.deleted} assets supprimés sur ${response.hapiAssets} en ${response.executionTime} min`
          addNotification({ id: 'checkFolder', message: response.status, description: message, type: 'success' })
        }
      })
    }
  }

  function handleClick() {
    if (isSelected) return
    resetFilter()
    setActiveTab("2")
    setIsSelected(true);
    closeMenu()
  }

  function displayNoFolder() {
    if ((accountState.admin || (accountState.isWidget && accountState.editor)) && !accountState.globalSearch) {
      return (
        <div className={`sidebar-button`} onClick={() => setEditDirectoriesModal(true)}>
          <Icon type="plus-circle" /> {t('Ajouter des dossiers')}
        </div>
      )
    }
    return <div className={`sidebar-button`}>{t('Aucun dossier')}</div>
  }

  return (
    <>
      <div className={`sidebar-title between ${isSelected && 'selected'}`} onClick={handleClick}>
        <div>
          <Icon type="folder" style={{ marginRight: '5px' }} theme={isSelected ? 'filled' : 'outlined'} /> <span>{t('Dossiers')}</span>
        </div>
        {!!total && <Tag className='assets-number' style={{ lineHeight: '15px', padding: '0 4px' }}>{total}</Tag>}
      </div>
      <Spin spinning={loading} style={{ height: 'auto' }}>
        {
          folders.length > 0 ?
            folders.map((folder) => {
              return <Folder key={folder.id} tag={folder} closeMenu={closeMenu} setHasChildrenOpen={setHasChildrenOpen} forceOpen={forceOpen || firstLoadOpened.includes(folder.id)} />
            })
            : displayNoFolder()
        }
      </Spin>
    </>
  );
};

export default React.memo(TreeTag);
