import React from 'react'
import Csv from '../Svg/Csv';
import Eps from '../Svg/Eps';
import Excel from '../Svg/Excel';
import Illustrator from '../Svg/Illustrator';
import Pdf from '../Svg/Pdf';
import Photoshop from '../Svg/Photoshop';
import PowerPoint from '../Svg/PowerPoint';
import Publisher from '../Svg/Publisher';
import Word from '../Svg/Word';
import Zip from '../Svg/Zip';

const extList = ['pdf', 'doc', 'word', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'zip', 'pub', 'excel', 'ai', 'psd', "eps"]
const componentList = {
    pdf: <Pdf fill="#bb0706"  />,
    word: <Word fill="#25559a"  />,
    excel: <Excel fill="#007334"  />,
    ppt: <PowerPoint fill="#cb4525"  />,
    csv: <Csv fill="#007334"  />,
    zip: <Zip fill="rgba(0,0,0,0.75)"  />,
    pub: <Publisher fill="#077467"  />,
    ai: <Illustrator fill="#077467"  />,
    psd: <Photoshop fill="#077467"  />,
    eps: <Eps fill="#077467"  />
}


export default function DocumentIcon({ asset, title, iconWidth = 30, style = {}, onClick = () => { } }) {

    let media = asset.currentMedia ? asset.medias.filter(media => media.id === asset.currentMedia)[0] : asset.medias[0];

    function getDocIcon(ext) {

        if (!ext) return '';
        if (ext.match('doc|word')) ext = 'word'
        if (ext.match('xls|excel')) ext = 'excel'
        if (ext.match('ppt')) ext = 'ppt'
        if (ext.match('eps')) ext = 'eps'
        ext = extList.includes(ext) ? ext : 'pdf';

        return componentList[ext]
    }

    return (
        <div className='document-icon-container pointer h-100per' style={{ ...style }} onClick={onClick}>
            <div className={title ? 'asset-title' : 'centered-icon'}>
                <div style={{width: iconWidth, display: 'inline-block'}}>{getDocIcon(media?.fileName?.split('.')?.pop())}</div>
                {
                    title &&
                    <p style={{overflowWrap: 'break-word'}}>{asset?.usageName}</p>
                }
            </div>
        </div>
    )
}
