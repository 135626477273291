import React, { useEffect, useRef, useState } from "react";
import { Icon } from "antd";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from "../../providers/ApiProvider";

export default function SortButton({ closeMenu = () => {}}) {

    const [accountState, accountDispatch] = useAccountContext();
    const { setActiveTab, resetFilter } = accountDispatch;
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResourceWithFilter } = apiDispatch;
    const { t } = useTranslation();
    const isMounted = useRef(null);

    const [nbAssets, setNbAssets] = useState(0)
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setIsSelected(accountState.activeTab === '1')
    }, [accountState.activeTab])

    useEffect(() => {
        isMounted.current = true;

        getTotal()
        return () => {
            // executed when unmount
            isMounted.current = false;
        }
    }, [accountState.rerender])

    function getTotal() {
        const data = {
            id: accountState.accountActive ? accountState.accountActive.id : accountState.account.id,
            itemsPerPage: 1, page: 1,
            filters: [{ name: 'status', value: '0' }]
        }

        apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
            if (isMounted.current) {
                if (response['@type'] === "hydra:Collection") {
                    setNbAssets(response['hydra:totalItems'])
                }
            }
        })
    }

    function handleClick() {
        resetFilter()
        setActiveTab("1")
        setIsSelected(true);
        closeMenu()
    }
    if (!nbAssets) return <div></div>

    return (
        <div className={`sidebar-button warning ${isSelected && 'selected'}`} onClick={handleClick}>
            <Icon type="exclamation-circle" /> {t('Vous avez')} {nbAssets} {t('Média(s) à trier')}
        </div>
    )
}
