import React, { useEffect, useState } from 'react';
import { Layout, message } from 'antd';
import HeaderSharing from "./HeaderSharing";
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import VisitorAccess from '../../pages/VisitorAccess';
import { useAccountContext } from '../../providers/AccountProvider';

const { Content } = Layout;

const ShareLayout = ({ children, type }) => {

  const [accountState, accountDispatch] = useAccountContext()
  const { setter } = accountDispatch;
  const params = useParams();
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [downloadMaxQuality, setDownloadMaxQuality] = useState(null);
  const [passwordData, setPasswordData] = useState('');
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    getPerm()
  }, [passwordData])

  async function getPerm() {
    setLoading(true)
    await fetch()
    setLoading(false)
  }

  async function fetch() {
    let endpoint = type === 'share' ? 'check' : 'check-cart';
    const url = `${process.env.API_ENTRYPOINT}/${endpoint}/${params.token}?password=${passwordData}`
    let response = await Axios.get(url)
    handleData(response.data)
  }

  function handleData(data) {

    console.log('data', data)
    setter('visitorView', data?.shareView ?? 'assets')

    setPasswordProtected(data?.password)
    setError(data.deletedAt ? !moment(data.deletedAt).isAfter(moment()) : false)
    setEndDate(data?.deletedAt ? moment(data.deletedAt) : null)
    setDownloadMaxQuality(data?.maxDownloadSize ? data.maxDownloadSize : 'original')

    if (!data.id) {
      setError(!data?.password)
      return message.error(data.message)
    }
  }

  if (loading || error || passwordProtected)
    return <VisitorAccess loading={loading} error={error} passwordProtected={passwordProtected} handlePassword={setPasswordData} />

  return (
    <Layout className="root-layout" style={{ minHeight: '100vh' }} >
      <Layout>
        <HeaderSharing endDate={endDate} downloadMaxQuality={downloadMaxQuality} type={type} />
        <Content >
          {React.cloneElement(children, { downloadMaxQuality, type })}
        </Content>
      </Layout>
    </Layout>
  );
};

export default ShareLayout;
