import React from 'react'
import PasswordPage from './PasswordPage'
import Hapidam_logo from "../assets/logo_hapidam.png";
import { Empty, Layout, Spin } from 'antd';

const { Content } = Layout;

export default function VisitorAccess({ loading, passwordProtected, handlePassword, error }) {

    return (
        <Layout className="root-layout" style={{ minHeight: '100vh' }} >
            <Content className='flex'>
                <div className='login-container'>
                    <div className="logo">
                        <img alt="Hapidam" src={Hapidam_logo} />
                    </div>
                    <Spin spinning={loading}>
                        {
                            passwordProtected && !error ?
                                <PasswordPage handleForm={handlePassword} /> :
                                <Empty description="Aucun contenu n'existe pour cette adresse" />
                        }
                    </Spin>
                </div>
            </Content>
        </Layout>

    )
}
