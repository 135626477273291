import React, { useEffect, useRef, useState } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../providers/AuthProvider';
import { useAccountContext } from '../../providers/AccountProvider';

export default function AppMenu({setIsDownloadModalVisible}) {

    const { t, i18n } = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const { auth } = authState;

    const [accountState, accountDispatch] = useAccountContext();
    const { setFreemiumOptions } = accountDispatch;
    const [lngOptions, setLngOptions] = useState([{
        id: localStorage.getItem('i18nextLng').substring(0, 2),
        lng: localStorage.getItem('i18nextLng').substring(0, 2)
    }])
    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        fetchLng()
        return () => {
            isMounted.current = false;
        }
    }, [])

    const fetchLng = () => {
        setLngOptions(i18n.availableLanguages)
    }

    const openDownloadModal = () => {
        if (accountState.account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN'))
            return setFreemiumOptions({ visible: true, type: 'download' });

        setIsDownloadModalVisible(true)
    }

    return (
        <Menu>
            <Menu.Item key="/dashboard">
                <Link to="/dashboard">
                    {t('Dashboard')}
                </Link>
            </Menu.Item>
            {
                accountState.account && !accountState.globalSearch ?
                    <Menu.Item key="/download" onClick={openDownloadModal}>
                        {t('Téléchargement')}
                    </Menu.Item>
                    : null
            }
            {
                accountState.account && accountState.admin ?
                    <Menu.Item key="/settings">
                        <Link to={'/admin/account-overview/' + accountState.account.id}>
                            {t('Réglages')}
                        </Link>
                    </Menu.Item>
                    : null
            }
            {auth && auth.roles.includes('ROLE_ADMIN') ?
                <Menu.Item key="/admin/create-account">
                    <Link to="/admin/create-account">
                        {t('Créer un compte')}
                    </Link>
                </Menu.Item>
                : ''
            }
            {auth && auth.roles.includes('ROLE_ADMIN') && accountState?.account?.id ?
                <Menu.Item key={`account/${accountState.account.id}/seo`}>
                    <Link to={`/account/${accountState.account.id}/seo`}>
                        {t('Seo')}
                    </Link>
                </Menu.Item>
                : ''
            }
            <Menu.Item key="/support">
                <Link to="/support">
                    {t('Aide & support')}
                </Link>
            </Menu.Item>
            <Menu.SubMenu
                key={'lng'}
                title={t('Langue')}
            >
                {
                    lngOptions.map(lng => <Menu.Item
                        style={{ color: lng.lng === localStorage.getItem('i18nextLng').substring(0, 2) ? '#1890ff' : '' }}
                        key={'lng/' + lng.lng} onClick={() => i18n.changeLanguage(lng.lng)}>{lng.label}</Menu.Item>)
                }
            </Menu.SubMenu>
            <Menu.Item key="/logout">
                <Link to="/logout">
                    {t('Déconnexion')}
                </Link>
            </Menu.Item>
        </Menu>
    )
}
