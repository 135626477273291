import React, { useState, useEffect } from 'react';
import {Table, PageHeader} from 'antd';
import {useTranslation} from 'react-i18next';
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from '../../providers/ApiProvider.js';

const CartTableList = () => {

  const {t, i18n} = useTranslation();
  const [accountState, accountDispatch] = useAccountContext();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '25', '50', '100'],
    position: 'both',
  })

  const columns = [{
      title: t('Nom'),
      dataIndex: 'name',
      render: (name) => <span > {name}</span>,
    },
    {
      title: t('Assets'),
      dataIndex: 'assets',
      render: (assets) => <span>{assets.length}</span>,
    },
    {
      title: t('Créateur'),
      dataIndex: 'createdBy',
      render: (createdBy) => <span>{createdBy.username}</span>,
    }
  ];

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    setLoading(true);
    const params = {
      id: accountState.account.id,
      filters: [{
        name: 'type',
        value: 2
      }]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'tags', (hydra_response) => {
      // console.log(hydra_response);
      const pager = { ...pagination };
      pager.total = hydra_response['hydra:totalItems'];

      setData(hydra_response['hydra:member'])
      setPagination(pager)
      setLoading(false);
    });
  }


  return (
    <div className="contenu-tab">
      <PageHeader title={t('Liste des paniers')} ghost={false}/>

      <Table columns={columns}
      dataSource={data}
      rowKey={record => record['@id']}
      pagination={pagination}
      loading={loading}
      />
    </div>

  )
}

export default CartTableList;
