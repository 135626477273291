import React, {useEffect, useState} from 'react';
import {Select, DatePicker} from 'antd';
import {useTranslation} from "react-i18next";

const {Option} = Select;
const {RangePicker} = DatePicker;

export default function DateFilter({filter, accountState, setCollapsed}) {

  const {t} = useTranslation();
  const [activeFilter, setActiveFilter] = useState()
  const [activeFilterBetween, setActiveFilterBetween] = useState()

  useEffect(() => {
    setActiveFilter(accountState.filters.filter(item => item.id === 'date')[0])
    setActiveFilterBetween(accountState.filters.filter(item => item.id === 'dateBetween')[0])
  }, [filter])

  function cleanFilter() {
    if (activeFilter) {
      filter(activeFilter)
    }
  }

  function cleanFilterBetween() {
    if (activeFilterBetween) {
      filter(activeFilterBetween)
    }
  }

  function handleChange(value) {
    cleanFilter()
    var date = null;
    switch (value) {
      case '1jour':
        date = new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleString('en-US');
        break;
      case '1semaine':
        date = new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleString('en-US');
        break;
      case '1mois':
        date = new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleString('en-US');
        break;
      case '1an':
        date = new Date(new Date().setDate(new Date().getDate() - 365)).toLocaleString('en-US');
        break;
      case '+1an':
        date = new Date(new Date().setDate(new Date().getDate() - 365)).toLocaleString('en-US');
        break;
      default:
        setActiveFilter();
        return;
    }

    addFilter(date, value);
  }

  function addFilter(date, value) {
    let Tab = {
      id: 'date',
      name: value === '+1an' ? "created[before]" : "created[after]",
      value: encodeURI(date),
      type: 'date',
      text: value
    };
    filter(Tab);
    setActiveFilter(Tab);
  }

  function onChange(date, dateString) {
    cleanFilterBetween()
    if (!dateString[0] || !dateString[1])
      return;

    const between = {
      id: 'dateBetween',
      name: `medias.metaData.datetimeOriginal[after]=${encodeURI(dateString[0])}&medias.metaData.datetimeOriginal[before]`,
      value: encodeURI(dateString[1]),
      type: 'dateBetween',
      text: `${t('Après')} ${dateString[0]} ${t('Et avant')} ${dateString[1]}`
    }

    filter(between)
    setActiveFilterBetween(between)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{margin: 10}}>
        {t("Date d'upload")}
      </div>
      <Select value={activeFilter ? activeFilter.text : 'default'} style={{width: 200}} onChange={handleChange}>
        <Option value="default">{t('Trier par date d\'ajout')}</Option>
        <Option value="1jour">{t('- de 1 jour')}</Option>
        <Option value="1semaine">{t('- de 1 semaine')}</Option>
        <Option value="1mois">{t('- de 1 mois')}</Option>
        <Option value="1an">{t('- de 1 an')}</Option>
        <Option value="+1an">{t('+ de 1 an')}</Option>
      </Select>
      <div style={{margin: 10}}>
        {t("Date de prise de vue")}
      </div>
      <RangePicker
        onChange={onChange}
        allowClear/>
    </div>
  )
}
