import React from "react";
import {Button, Result} from "antd";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../providers/AccountProvider";

export default function FreemiumFallback() {

  const {t} = useTranslation();
  const [accountState] = useAccountContext();
  const {freemiumOptions} = accountState;

  function genContent() {
    switch (freemiumOptions.type) {
      case 'download':
        return t("Pour acceder à la fonction télécharger, vous devez passer en mode Premium");
      case 'share':
        return t("Pour acceder à la fonction partager, vous devez passer en mode Premium");
      case 'permission':
        return t("Pour acceder à la fonction éditer les permissions, vous devez passer en mode Premium");
      case 'edit':
        return t("Pour acceder à la fonction éditer l'image, vous devez passer en mode Premium");
      case 'galery':
        return t("Pour acceder à la page galerie, vous devez passer en mode Premium");
      case 'social':
        return t("Pour acceder à la fonction réseaux sociaux, vous devez passer en mode Premium");
      case 'cart':
        return t("Pour acceder à la fonction panier, vous devez passer en mode Premium");
      default:
        return t("Pour acceder à cette fonctionnalité, vous devez passer en mode Premium");
    }
  }

  return (
    <Result
      status="404"
      title={t('Désolé')}
      subTitle={genContent()}
      extra={<Button type="primary"><a href="mailto: infos@mmcreation.com">{t('Contacter MMCreation')}</a></Button>}
    />
  )

}
