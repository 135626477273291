import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Input, PageHeader, Select } from 'antd'
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";

const { confirm } = Modal

export default function Advanced({ account }) {

  const { t } = useTranslation();
  const [accountName, setAccountName] = useState(account.name)
  const [existingAccounts, setExistingAccounts] = useState([]);
  const [parent, setParent] = useState()
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiFetchSubResourceWithFilter, apiUpdateEntity } = apiDispatch;

  useEffect(() => {
    if (account.parentAccount) {
      setParent(account.parentAccount['@id'])
      fetchAccounts(account.parentAccount.name)
    }
  }, [])

  function displayPrompt() {
    const response = prompt('Ou peut-on avoir a faire a du CO2 compressé ?')
    if (response.toLowerCase().trim() === 'plage' || response.toLowerCase().trim() === 'la plage') {
      showDeleteConfirm()
      return message.success('Salutation camarade')
    }
    message.error('Instruisez vous plus a l\'avenir')
  }

  const fetchAccounts = (value) => {
    setExistingAccounts([]);
    const params = {
      filters: [
        { name: 'name', value: value },
        { name: 'exists[parentAccount]', value: false }
      ]
    }
    apiFetchCollection('accounts', params, (hydra_response) => {
      const results = (hydra_response['hydra:member'])
      const data = results.map(account => ({
        text: account.name,
        value: account.id,
        iri: account['@id']
      }));

      setExistingAccounts(data);
    });
  };

  function showDeleteConfirm() {
    confirm({
      title: 'Êtes-vous sûr de vouloir supprimer ce compte ?',
      content: 'Les données liées a ce compte seront perdu',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Annuler',
      onOk() {
        del()
      },
      onCancel() {
        message.info('Sage décision');
      },
    });
  }

  const showRenameConfirm = () => {
    const data = { name: accountName }
    confirm({
      title: 'Voulez vous renommer les images avec le nouveau nom ?',
      content: 'Cette action va modifier le nom de vos images déja triées avec le renommage automatique, en remplacant l\'ancien nom par le nouveau.',
      okText: 'Oui remplacer',
      cancelText: 'Non conserver l\'ancien',
      onOk() {
        const params = {
          id: account.id,
          filters: [
            {
              name: 'change_prefix',
              value: true
            },
            {
              name: 'new_prefix',
              value: accountName
            }]
        }
        apiFetchSubResourceWithFilter('accounts', params, 'rename_assets', response => {
          //console.log(response)
          save(data)
        })
      },
      onCancel() {
        save(data)
      },
    });
  }

  const updateParent = () => {
    let parentAccount = parent ?? null
    const data = { parentAccount: parentAccount }
    save(data)
  }

  function save(data) {
    console.log('data', data)
    apiUpdateEntity('accounts', account.id, data, response => {
      //console.log(response)
      message.success('mise à jour effectué')
    })
  }

  function del() {
    let date = new Date()
    let newDate = new Date(date.setMonth(date.getMonth() + 1))

    const Data = {
      status: 0,
      toDelete: newDate
    }
    apiUpdateEntity('accounts', account.id, Data, response => {
      // console.log(response)
      if (response['@type'] === "hydra:Error") {
        return message.error('Petit problème, réessaie plus tard.')
      }

      window.location.href = "/"
    })
  }

  function onChange(e) {
    setAccountName(e.target.value)
  }

  const handleParentChange = value => {
    // console.log(value)
    setParent(value);
  };

  return (
    <div className="contenu-tab">
      <PageHeader title={t('Reglages avancés')} ghost={false} />
      <div style={{ marginBottom: 20 }}>
        Renommer le compte :&nbsp;&nbsp;&nbsp;&nbsp;
        <Input
          style={{ width: 250, marginRight: 15 }}
          value={accountName}
          onChange={onChange}
        />
        {
          account.name !== accountName &&
          <Button type="primary" onClick={showRenameConfirm}>Enregistrer</Button>
        }
      </div>
      {
        !account?.childAccount.length &&
        <div style={{ marginBottom: 20 }}>
          Modifier le compte parent :&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            showSearch
            value={parent}
            placeholder={t('Rechercher un compte parent')}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={fetchAccounts}
            onChange={handleParentChange}
            notFoundContent={null}
            allowClear
            style={{ width: 250 }}
          >
            {existingAccounts.map(d => {
              return <Select.Option key={d.iri}>{d.text}</Select.Option>
            })}
          </Select>
          {
            account?.parentAccount?.['@id'] !== parent &&
            <Button type="primary" onClick={updateParent}>Enregistrer</Button>
          }
        </div>
      }
      <div>
        <Button type="link" style={{ color: 'red' }} onClick={displayPrompt}>Supprimer le compte</Button>
      </div>
    </div>
  )
}
