import React from 'react';
import { Button, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function Copyright({ save, copyright, setCopyright }) {

    const { t } = useTranslation();
    function handleChange(property, value) {
        setCopyright(prev => { return { ...prev, [property]: value } })
    }

    return (
        <div style={{ padding: 5 }}>
            <Input placeholder="Nom /société" allowClear onChange={(e) => handleChange('text', e.target.value)} />
            <div style={{ margin: '5px 0' }}>
                <Select value={copyright.color} style={{ width: 120 }} onChange={(value) => handleChange('color', value)}>
                    <Option value="black">{t('Noir')}</Option>
                    <Option value="white">{t('Blanc')}</Option>
                </Select>
                <Select defaultValue={' ,5px,0, '} style={{ width: 120 }} onChange={(value) => handleChange('position', value.split(','))}>
                    <Option value={' ,5px,0, '}>{t('En bas à droite')}</Option>
                    <Option value={' , ,0,5px'}>{t('En bas à gauche')}</Option>
                    <Option value={'0,5px, , '}>{t('En haut à droite')}</Option>
                    <Option value={'0, , ,5px'}>{t('En haut à gauche')}</Option>
                </Select>
            </div>
            <div style={{ margin: '5px 0' }}>
                <Button.Group>
                    <Button type="primary" icon="check" onClick={() => save('copyright')} />
                </Button.Group>
            </div>
        </div>
    )
}
