import React, {useState} from 'react'
import ApiCollectionTable from '../ApiCollectionTable.js';
import {Select, Input, Tag, Switch, Button} from 'antd';
import {useTranslation} from "react-i18next";
import {stringDate, fileConvertSize} from '../../helpers.js'

const {Search} = Input;
const {Option} = Select;

export default function Log({apiFetchCollection}) {

  const {t, i18n} = useTranslation();
  const [filters, setFilters] = useState([
    {name: 'withAdmin', value: false},
    {name: 'username', value: ''},
    {name: 'type', value: ''}
  ])

  const [duplicate, setDuplicate] = useState({})
  const [loadDuplicate, setLoadDuplicate] = useState(false)

  const columns = [
    {
      title: t('Nom'),
      dataIndex: 'username',
      render: (username, record) => <span>{username ? username : record.emailShare}</span>
    },
    {
      title: t('Action'),
      dataIndex: 'type',
      render: type => <span>{type}</span>
    },
    {
      title: t('Compte'),
      dataIndex: 'name',
      render: accountName => <span>{accountName ? accountName : "-"}</span>
    },
    {
      title: t('Taille'),
      dataIndex: 'size',
      render: (size, record) =>
        <span>{size ? fileConvertSize(size) : "0"}</span>
    },
    {
      title: t('Quantité'),
      dataIndex: 'quantity',
      render: quantity => <span>{quantity ? quantity : "-"}</span>
    },
    {
      title: t('Date'),
      dataIndex: 'created',
      render: created => <span>{stringDate(created)}</span>
    }
  ];

  function updateFilter(name, value) {
    let arr = [...filters];
    const pos = arr.map(a => a.name).indexOf(name);

    arr[pos].value = value;
    setFilters(arr);
  }

  const children = [
    (<Option key="download">download</Option>),
    (<Option key="upload">upload</Option>),
    (<Option key="connection">connection</Option>)
  ];

  function fetchDuplicate() {
    setLoadDuplicate(true)
    apiFetchCollection("duplicate", {}, response => {
      setDuplicate(response)
      setLoadDuplicate(false)
    })
  }

  return (
    <div className="contenu-tab">
      <div className='table-header'>
        <span>{t('Recherche')} : </span>
        <Search
          placeholder={t("Nom d'utilisateur")}
          onSearch={(e) => updateFilter('username', e.trim())}
          style={{width: 200}}
        />
        {
          filters[1].value ?
            <Tag closable onClose={() => updateFilter('username', '')}>
              {filters[1].value}
            </Tag>
            : ''
        }
        <span style={{marginLeft: 20}}>{t('Inclure super admin')}</span>
        <Switch style={{marginLeft: 5}} onChange={(e) => updateFilter('withAdmin', e)}/>
        <span style={{marginLeft: 20}}>{t('Type')} : </span>
        <Select
          mode="multiple"
          placeholder={t('Filtrez par type')}
          onChange={(values) => updateFilter('type', values.join(","))}
          style={{marginLeft: 5, width: 200}}
        >
          {children}
        </Select>
      </div>
      <ApiCollectionTable
        endpoint="statistics/log_page"
        columns={columns}
        filtres={filters}
      />
      <Button
        loading={loadDuplicate}
        type="primary"
        onClick={fetchDuplicate}>
        Checker les doublons
      </Button>
      {duplicate.success ?
        <div>
          <div>
            Nb d'asset dupliqué : {duplicate.totalDuplicate}&nbsp;&nbsp;|&nbsp;&nbsp;
            Nb total d'asset dur le dam : {duplicate.totalAssets}&nbsp;&nbsp;|&nbsp;&nbsp;
            Pourcentage d'utilisation : {(100*duplicate.totalDuplicate/duplicate.totalAssets).toFixed(1)}%
          </div>
          <div>
            <h2>Détails</h2>
            {
              duplicate.assets.sort((a, b) => a.account.localeCompare(b.account)).map(asset => (
                <div>{asset.account}, {asset.usageName} ({asset.directories.map((dir, index) => (
                  <span>{index ? ", " : null}<strong>{dir}</strong></span>
                ))})</div>
              ))
            }
          </div>
        </div>
        : null}
    </div>
  )
}
