import React, {useState} from "react";
import {AutoComplete, Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";

const {Option, OptGroup} = AutoComplete;
const {Search} = Input;


export default function TagAutoComplete({assets, refresh = null, assetTags = null, setAssetTags = null}) {

  const {t} = useTranslation();
  const [apiDispatch] = useApiContext();
  const {apiFetchSubResourceWithFilter, apiFetchCollection, apiPostEntity} = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const [saving, setSaving] = useState(false)

  const [value, setValue] = useState('');
  const [normalizedTags, setNormalizedTags] = useState([])
  const [personalizedTags, setPersonalizedTags] = useState([])
  const [requestTimeout, setRequestTimeout] = useState(null)

  // Récupère les tags en fonction de la valeur de l'input
  const getTagsNormalized = (value) => {
    const params = {
      id: accountState.account.id,
      itemsPerPage: 5,
      filters: [
        {
          id: 'parent',
          name: 'exists[parent]',
          value: true
        },
        {
          id: 'value',
          name: 'value',
          value: value
        }
      ]
    }

    apiFetchCollection('tag_admins', params, (hydra_response) => {
      if (hydra_response['@type'] !== 'hydra:Error') {
        setNormalizedTags(hydra_response['hydra:member'])
      }
    })

  }

  // Récupère les tags en fonction de la valeur de l'input
  const getTags = (value) => {
    const params = {
      id: accountState.account.id,
      itemsPerPage: 5,
      filters: [
        {
          id: 'tag',
          name: 'type',
          value: 0
        },
        {
          id: 'name',
          name: 'name',
          value: value
        },
        {
          name: 'pagination',
          value: true
        },
        {
          name: 'normalized',
          value: false
        }
      ]
    }
    apiFetchSubResourceWithFilter('accounts', params, 'tags', (hydra_response) => {
      if (hydra_response['@type'] !== 'hydra:Error') {
        setPersonalizedTags(hydra_response['hydra:member'])
      }
    })

  }

  const handleSearch = (value) => {
    setValue(value);
    value = value.trim();
    setNormalizedTags([])
    setPersonalizedTags([])
    clearTimeout(requestTimeout);
    if (value) {
      setRequestTimeout(setTimeout(function () {
          getTags(value)
          getTagsNormalized(value)
        }, 200
      ))
    }

  }

  const handleSelection = (selection) => {
    let selectionArr = selection.split('-')
    setValue(selectionArr[1])
    setNormalizedTags([])
    setPersonalizedTags([])
  }

  const handleAdd = async (value, event) => {
    if (event && event.key === 'Enter' && (normalizedTags.length > 0 || personalizedTags.length > 0)) {
      return
    }
    event ? event.stopPropagation() : '';
    value = value.trim();

    if (value.length > 0) {
      if (assetTags) {
        const hasTag = assetTags.filter(item => item.name === value && item.type === 0).length > 0;
        if (hasTag) {
          message.info(t('Cet asset a déjà ce tag'));
          return
        }
      }
      newTag(value);
    }
    setValue('')
  }

  //Prépare les options à afficher
  const buildDataSource = (results, type) => {
    let label = type === 'tagAdmin' ? t('Tag normalisé') : t('Tag')
    if (results.length === 0) {
      return <Option key={type} className={'hidden'}/>
    }
    return <OptGroup key={type} label={label + ' :'}>
      {
        results.map(item => renderOption(item, type))
      }
    </OptGroup>;
  }

  const renderOption = (item, type) => {
    return (
      <Option key={item['id'] + '-' + item[type === 'tagAdmin' ? 'label' : 'name']}>
        {item[type === 'tagAdmin' ? 'label' : 'name']}
      </Option>
    );
  }

  // Ajoute un nouveau tag type 0 (description)
  const newTag = (name) => {
    setSaving(true)
    let data = {
      name: name,
      type: 0,
      account: '/api/accounts/' + accountState.account.id,
      assets: assets.map(asset => `/api/assets/${asset.id}`)
    }
    apiPostEntity("tags", data, (response) => {
      if (response['@type'] === 'hydra:Error' && response['hydra:description'] !== 'success') {
        message.error(t('Il y a eu un problème'));
      } else {
        // console.log(response)
        if (setAssetTags) {
          if (response['hydra:description'] === 'success') {
            refresh((new Date()).getTime())
          } else {
            setAssetTags([...assetTags, response])
          }
        } else {
          refresh()
        }
        message.success('tag ajouté avec succes')
        setSaving(false)
      }
      setPersonalizedTags([])
      setNormalizedTags([])
      setValue('')
    })
  }


  return (
    <div>
      <AutoComplete
        className="global-search"
        onSearch={handleSearch}
        optionLabelProp="text"
        dataSource={normalizedTags.length || personalizedTags.length ? [buildDataSource(normalizedTags, 'tagAdmin'), buildDataSource(personalizedTags, 'tag')] : ''}
        value={value}
        onSelect={handleSelection}
      >
        <Search
          allowClear={true}
          style={{width: '100%'}}
          placeholder={t('Ajouter un tag')}
          enterButton={t('Ajouter')}
          onSearch={handleAdd}
          loading={saving}
        />
      </AutoComplete>
    </div>
  )

}
