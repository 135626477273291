import React, { useEffect, useState } from 'react'
import { Button } from 'antd';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import DocumentIcon from '../asset/DocumentIcon';

export default function DocumentView({ asset }) {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setNumPages(null)
    setPageNumber(1)
  }, [asset])

  let media = asset.currentMedia ? asset.medias.filter(media => media.id === asset.currentMedia)[0] : asset.medias[0];
  let ext = media.fileName.split('.').pop()

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return <div>
    {
      ext === 'pdf' ?
        <>
          <Document file={media.contentUrl} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          {
            numPages > 1 &&
            <div style={{ paddingTop: '1em' }}>
              <Button.Group>
                <Button size="small" icon="left" onClick={() => setPageNumber(prev => prev - 1)} disabled={pageNumber === 1} />
                <Button size="small" icon="right" onClick={() => setPageNumber(prev => prev + 1)} disabled={pageNumber === numPages} />
              </Button.Group>
              <div style={{ float: 'right', color: "#fff" }}>
                {pageNumber} / {numPages}
              </div>
            </div>
          }
        </>
        : <DocumentIcon asset={asset} iconWidth={100}/>
  }
  </div>
}
