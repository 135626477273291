import React from 'react';
import {Pagination} from 'antd';

const MyPagination = ({current, totalItem, itemPerPage, changePage, loading, padding, margin, bgColor = 'white', simple}) => {

  const onChange = page => {
    changePage(page)
  };

  return (
    totalItem > itemPerPage && !loading
      ?
      <div style={{padding: padding, textAlign: 'center', backgroundColor : bgColor, margin: margin}}>
        <Pagination size='small' current={current} total={totalItem} pageSize={itemPerPage} onChange={onChange} simple={simple}/>
      </div>
      :
      null
  );
};

export default MyPagination;
