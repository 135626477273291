import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../providers/AccountProvider";

const { TreeNode } = TreeSelect;

function TagsTreeSelect({
  onSelect, currentValue, isShare,
  tags, treeCheckable = false,
  placeholder = "Choisissez un dossier",
}) {
  const { t } = useTranslation()

  const [accountState] = useAccountContext();
  const { filters } = accountState;
  const [ctrlKey, setCtrlKey] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', toggleCtrl);
    document.addEventListener('keyup', toggleCtrl)

    return () => {
      document.removeEventListener('keydown', toggleCtrl)
      document.removeEventListener('keyup', toggleCtrl)
    }
  });

  const toggleCtrl = (e) => {
    setCtrlKey(e.ctrlKey || e.metaKey);
  };

  useEffect(() => {
    if (filters.some((f) => f.type === "directory") && tags.length && !currentValue)
      onSelect(filters.filter((f) => f.type === "directory")[0].id);
  }, [filters, tags]);

  const loop = (defaultTree, isParentChecked = false) => {
    return defaultTree.map((item) => {

      let isSelected = false;
      if (treeCheckable) {
        isSelected = isShare ? false : !!currentValue.find(val => val.value === item.id);
      }
      if (item.childTags && item.childTags.length) {
        return (
          <TreeNode value={item["id"]} title={item.name} key={item.id} disabled={isParentChecked}>
            {loop(item.childTags, isSelected)}
          </TreeNode>
        );
      } else {
        return <TreeNode value={item["id"]} title={item.name} key={item.id} disabled={isParentChecked} />;
      }
    });
  };

  return (
    <TreeSelect
      treeCheckable={treeCheckable}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeCheckStrictly
      maxTagCount={3}
      name="folder"
      showSearch
      style={{ width: "100%" }}
      value={currentValue}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={t(placeholder)}
      allowClear
      onChange={(value, label, extra) => onSelect(value, label, extra, ctrlKey)}
      treeNodeFilterProp="title"
    >
      {loop(tags)}
    </TreeSelect>
  );
}

export default TagsTreeSelect;
