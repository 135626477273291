import React, { useState } from "react";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import { useApiContext } from '../../providers/ApiProvider.js';
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../providers/AuthProvider.js";
import { useAccountContext } from "../../providers/AccountProvider.js";

const BtnModalCreateCart = ({ visible, setVisible, btnVisible, form }) => {

  const [authState] = useAuthContext();
  const { auth } = authState;
  const [accountState, accountDispatch] = useAccountContext();
  const {setter} = accountDispatch;
  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;
  const [loading, setLoading] = useState(false)
  const { getFieldDecorator } = form;

  const handleCreate = () => {
    if(loading) return message.error(t('L\'enregistrement est en cours'))
    form.validateFields((err, values) => {
      if (err) {
        return console.log(err);
      }
      createCart(values.name)
    });
  };

  function createCart(name) {
    let data = {
      name: name, type: 2, private: 1,
      account: "/api/accounts/" + accountState.account.id,
      createdBy: "/api/users/" + auth.id,
    }
    setLoading(true)
    apiPostEntity("tags", data, (response) => {
      // console.log("response :", response);
      message.info(response.name + ' ' + t('A bien été créé'))
      form.resetFields()
      setVisible(false);
      setter('cartsNeedUpdate', (new Date()).getTime());
      setLoading(false)
    })
  }

  return (
    <>
      {
        btnVisible &&
        <Button size="small" style={{ margin: "0 20" }} type="primary" icon='plus' onClick={() => setVisible(true)}>{t('Nouveau panier')}</Button>
      }
      <Modal
        visible={visible} centered
        title={t('Créer un panier')}
        okText={t('Ajouter')}
        onCancel={() => setVisible(false)}
        onOk={handleCreate}
      >
        <Spin spinning={loading} >
          <Form layout="vertical">
            <Form.Item label={t('Nom')}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: t('Ce champs est obligatoire!')
                  }
                ]
              })(<Input onPressEnter={handleCreate} />)}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default Form.create()(BtnModalCreateCart)

