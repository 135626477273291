import widget from "./WidgetComponent"; // client/widget component
const shareLink = process.env.API_ENTRYPOINT + '/share/';

export function handleDblClick(asset, selectedFormat) {
  let response = getResponse(asset);

  let funcNum = getUrlParam('CKEditorFuncNum');
  if (funcNum) { response.funcNum = funcNum }
  response.selectedFormat = selectedFormat ? response?.display?.native[selectedFormat] : selectedFormat
  widget.fire('select', response, 'broadcast');
  if (funcNum) window.close()
}

export function getResponse(asset) {
  if (asset.type === 1) return formatImageResponse(asset)
  if (asset.type === 2) return formatDocumentResponse(asset)
  if (asset.type === 3) return formatVideoResponse(asset)

  return message.error("Ce type de média ne peut être utilisé")
}

export function isObserverAvailable() {
  if ('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {

    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype,
        'isIntersecting', {
        get: function () {
          return true;
        }
      });
    }
    return true;
  }
  return false
}

export function formatImageResponse(asset) {
  // console.log('asset', asset)
  let media = asset.medias.find(media => media.id === asset.currentMedia);
  let tags = asset.tags.filter(tag => tag.type != 3).map(tag => {
    return tag.name;
  })

  let myRegexp = /.*\.(\w{3,})$/g;
  let match = myRegexp.exec(media.fileName);

  let colors = asset.smartTags && asset.smartTags.colors.colors ? asset.smartTags.colors.colors : []
  let nativeDisplay = getNativeDisplay(media, asset, match[1])
  let squareDisplay = getSquareDisplay(media, asset, match[1])


  return {
    "uid": asset.uuid,
    "type": "image",
    "ctime": Math.round(new Date(asset.created).getTime() / 1000), // hapidam media creation time (unix timestamp)
    "mtime": Math.round(new Date(asset.updated).getTime() / 1000), // hapidam media update time (unix timestamp)
    "name": asset.usageName,
    "description": asset.description || '',
    "alt": asset?.seoAlt ? JSON.parse(asset.seoAlt) : { fr: '' },
    "legend": asset?.seoLegend ? JSON.parse(asset.seoLegend) : { fr: '' },
    "copyright": asset?.seoCopyright || '',
    "tags": tags,
    "file": {
      "name": media.fileName, // may be a completly forged value if non relevant in hapidam
      "ext": match[1],
      "mimetype": media.mimeType,
      "hash": media.hash, // real file source hash
      "size": media.fileSize, // original file size in bytes
    },
    "image": {
      "width": media.dimensions[0],
      "height": media.dimensions[1],
      "colors": colors, // main colors extracted from image
      "coi": [50, 50], // Center Of Interest (default 50%,50%)
    },
    "display": {
      "native": { ...nativeDisplay },
      "square": { ...squareDisplay },
      // {width} {height} {mode} will be replace by consumer website with real value
      // {mode} = [ratio,crop] // other value expected ???
      // {coi} = x,y // eg 50,50
      "custom": `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}.${match[1]}?mode={mode}&w={width}&h={height}&coi={x},{y}`
    }
  }

}

export function formatDocumentResponse(asset) {

  let media = asset.medias.find(media => media.id === asset.currentMedia);
  let myRegexp = /.*\.(\w{3,})$/g;
  let match = myRegexp.exec(media.fileName);

  return {
    "uid": asset.uuid,
    "type": "document",
    "ctime": Math.round(new Date(asset.created).getTime() / 1000), // hapidam media creation time (unix timestamp)
    "mtime": Math.round(new Date(asset.updated).getTime() / 1000), // hapidam media update time (unix timestamp)
    "name": asset.usageName,
    "file": {
      "name": media.fileName, // may be a completly forged value if non relevant in hapidam
      "ext": match[1],
      "mimetype": media.mimeType,
      "hash": media.hash, // real file source hash
      "size": media.fileSize, // original file size in bytes
    },
    "display": {
      "original": `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}`
    }
  }
}

export function formatVideoResponse(asset) {

  let media = asset.medias.find(media => media.id === asset.currentMedia);
  let myRegexp = /.*\.(\w{3,})$/g;
  let match = myRegexp.exec(media.fileName);

  return {
    "uid": asset.uuid,
    "type": "video",
    "ctime": Math.round(new Date(asset.created).getTime() / 1000), // hapidam media creation time (unix timestamp)
    "mtime": Math.round(new Date(asset.updated).getTime() / 1000), // hapidam media update time (unix timestamp)
    "name": asset.usageName,
    "file": {
      "name": media.fileName, // may be a completly forged value if non relevant in hapidam
      "ext": match[1],
      "mimetype": media.mimeType,
      "hash": media.hash, // real file source hash
      "size": media.fileSize, // original file size in bytes
    },
    "provider": media?.vimeoId ? 'vimeo' : 'hapidam',
    "embedId": media?.vimeoId,
    "poster": media?.vimeoPictureLink,
    "display": media?.vimeoId ? formatVimeoLinks(media.vimeoFiles) :
      {
        "original": `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}`
      }
  }
}

const formatVimeoLinks = (data) => {
  let response = {}
  for (const property in data) {
    response[property] = data[property]['url']
  }
  return response;
}


const getNativeDisplay = (media, asset, ext) => {
  let baseLink = `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}.${ext}`
  let nativeDisplay = {
    original: baseLink
  }
  Object.keys(media.extraUrl).map(key => {
    nativeDisplay[key] = `${baseLink}?size=${key}`
  })
  return nativeDisplay
}
const getSquareDisplay = (media, asset, ext) => {

  let baseLink = `${shareLink + asset.uuid}/${encodeURIComponent(asset.usageName)}.${ext}`
  let size = asset.orientation > 1 ? 'width' : 'height';
  let nativeDisplay = {}
  Object.keys(media.extraUrl).map(key => {
    nativeDisplay[key] = `${baseLink}?&w=${media.extraUrl[key][size]}&h=${media.extraUrl[key][size]}&mode=crop`
  })
  return nativeDisplay
}


export const getUrlParam = (paramName) => {
  let reParam = new RegExp('(?:[\?&]|&)' + paramName + '=([^&]+)', 'i');
  let match = window.location.search.match(reParam);

  return (match && match.length > 1) ? match[1] : null;
}
