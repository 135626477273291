import React, { useState } from 'react';
import Stars from "../Stars";
import TagPopup from "./TagPopup";
import Download from './Download';
import CartAction from '../CartAction';

function Actions({ item, seeActions }) {

  const [allTags, setAllTags] = useState(item.tags.map(tag => tag['@id']));

  return (
    <div className="see-on-hover asset-actions">
      <p title={item.usageName}>{item.usageName}</p>
      {seeActions &&
        <div className="flex between align_center">
          <Download asset={item} />
          <Stars itemId={item.id} stars={item.note} inTooltip />
          <TagPopup tags={item.tags.filter(tag => tag.type === 0)} />
          <CartAction asset={item} assetTags={allTags} setAssetTags={setAllTags} color="white"/>
        </div>
      }
    </div>
  )
}

export default Actions

