import React, { useEffect, useState } from "react";
import { Input, List, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";
import ModalUserPermission from "./ModalUserPermission";
import { useAuthContext } from "../../providers/AuthProvider";
import FreemiumFallback from "../FreemiumFallback";
import PermissionListItem from "../PermissionListItem";


const ModalFolderPermission = ({ account, setIsEditingPerms }) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [permissions, setPermissions] = useState([])
  const [needUpdate, setNeedUpdate] = useState(0)
  const [selectedPerm, setSelectedPerm] = useState(null)
  const [editingPerm, setEditingPerm] = useState(false)
  const [search, setSearch] = useState('')
  const [searchTimeout, setSearchTimeout] = useState()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    position: 'bottom',
    size: "small",
    onChange: handlePageChange
  })

  useEffect(() => {
    if (!!account.id && (account.contractType !== 'freemium' || auth.roles.includes('ROLE_ADMIN'))) {
      setLoading(true)
      fetchPermissions()
    }
  }, [account.id, needUpdate, pagination.current, search])


  const handleSearch = (e) => {
    setSearch(e.target.value.trim())
    clearTimeout(searchTimeout)
    setSearchTimeout(setTimeout(() => {
      console.log(e.target)
      setPagination(prev => { return { ...prev, current: 1 } })
      setNeedUpdate((new Date()).getTime())
    }, 200))

  }

  const fetchPermissions = () => {
    const params = {
      id: account.id,
      itemsPerPage: pagination.pageSize || 10,
      page: pagination.current || 1,
      filters: [
        {
          name: 'exists[domain]',
          value: false
        },
        {
          name: 'order[createdAt]',
          value: 'DESC'
        }
      ]
    }
    if (search)
      params.filters = params.filters.concat({ name: 'user.email', value: search })

    apiFetchSubResourceWithFilter('accounts', params, 'permissions', (response) => {
      if (response['@type'] === "hydra:Collection") {
        setPermissions(response['hydra:member'])
        setPagination(prev => { return { ...prev, total: response['hydra:totalItems'] } })
      }
      setLoading(false)
    })
  }

  function handleCloseEdit() {
    setSelectedPerm(null)
    setEditingPerm(false)
  }



  function handlePageChange(page) {
    // update current pagination
    setPagination(prev => { return { ...prev, current: page } })
  }

  return (
    !editingPerm ?
      <Modal
        visible={!!account.id}
        centered
        title={t("Permissions") + ' : ' + account.name}
        onCancel={() => setIsEditingPerms(false)}
        onOk={() => {
          setEditingPerm(true)
        }}
        cancelText={t('Annuler')}
        okText={t('Ajouter une permission')}
      >
        {
          account.contractType === 'freemium' && !auth.roles.includes('ROLE_ADMIN') ?
            <FreemiumFallback /> :
            <>
              <Input.Search
                allowClear
                placeholder={t("Rechercher par email")}
                onChange={handleSearch}
                style={{ width: 200 }}
              />
              <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={permissions}
                pagination={pagination}
                renderItem={item => (
                  <List.Item>
                    <PermissionListItem permission={item} setNeedUpdate={setNeedUpdate} setEditingPerm={setEditingPerm} setSelectedPerm={setSelectedPerm} />
                  </List.Item>
                )}
              />
            </>
        }
      </Modal>
      :
      <ModalUserPermission visible={true} perm={selectedPerm} setVisible={handleCloseEdit} accountForce={account} modal
        setNeedUpdate={setNeedUpdate} needUpdate={needUpdate} />
  );
};

export default ModalFolderPermission;
