import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import ChildTagAssets from "./ChilTagAssets";
import {Icon} from "antd";

const SubDirectory = ({id, ...props}) => {

  const [apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;

  const [childTags, setChildTags] = useState([])

  const [visibleTags, setVisibleTags] = useState(1);
  const [loading, setLoading] = useState(true);
  const hasMoreTag = visibleTags < childTags.length
  const isMounted = useRef(null);

  useEffect(() => {
        isMounted.current = true;

    setChildTags([])
    setLoading(true)
    setVisibleTags(1)
    fetch()
        return () => {
      isMounted.current = false;
    }
  }, [id])

  function fetch() {
    apiFetchEntity('tags', id, response => {
      if (response.childTags && isMounted.current) {
        setChildTags(recursive(response.childTags, [{['@id']: response['@id'], id: response.id, name: response.name}]))
      }

    })
  }

  function recursive(tags, parent) {
    let result = []

    tags.sort((a, b) => a.name.localeCompare(b.name)).map(tag => {
      if (tag.assets.length)
        result.push({...tag, parents: [...parent, {['@id']: tag['@id'], id: tag.id, name: tag.name}]})

      if (tag.childTags.length)
        result = [...result, ...recursive(tag.childTags, [...parent, {['@id']: tag['@id'], id: tag.id, name: tag.name}])]
    })

    return result;
  }

  const observer = useRef();
  const loaderTag = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreTag) {
          setVisibleTags(next => next + 1)
          setLoading(true)
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMoreTag]
  );

  return (
    !!childTags.length &&
    <div>
      {childTags.map((tag, index) => (
        index < visibleTags &&
        <ChildTagAssets tag={tag} key={tag.id} loadingParent={() => setLoading(false)} {...props}/>
      ))}


      <div>
        <div style={{height: 50, width: '100%', display: "flex", justifyContent: 'center', alignItems: "center", position: "relative"}}>
        <div ref={loaderTag} style={{width: 1, height: 1, position: "relative", top: '-200px'}}/>
          <div>{!!loading && <Icon type="loading" style={{fontSize: 40}}/>}</div>
        </div>
      </div>
    </div>
  );
};

export default SubDirectory;
