import React  from 'react'
import { useDrag } from 'react-dnd'

function getStyles(left, top, textStyle, isDragging) {
  return {
    position: 'absolute',
    top,
    left,
    fontSize: textStyle.fontSize + 'px',
    fontFamily: textStyle.font,
    fontStyle: textStyle.fontStyle,
    fontWeight: textStyle.fontWeight,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    zIndex: 18,
    lineHeight:1,
    color: textStyle.color,
    overflowWrap: 'normal',
    boder: '2px solid red'
  }
}
const DraggableTextBox = ({box}) => {
  console.log(box)
  const { id, title, left, top, textStyle } = box;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'BOX', id, left, top, title, textStyle },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })


  return (
    <div ref={drag} style={getStyles(left, top, textStyle, isDragging)} id={id}>
      {title}
    </div>
  )
}

export default DraggableTextBox
