import React, { useEffect, useState } from "react";
import { Rate, Tooltip, Icon, message } from 'antd';
import { useApiContext } from "../providers/ApiProvider";
import { useAccountContext } from "../providers/AccountProvider";
import { useTranslation } from "react-i18next";

export default function Stars({ itemId, stars, setStars = () => {}, inTooltip, disabled = false }) {

  const { t } = useTranslation();

  const [apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;
  const [accountState] = useAccountContext();
  const touch = matchMedia('(hover: none)').matches;
  const [note, setNote] = useState(0)

  useEffect(() => {
    if (stars != note) setNote(stars)
  }, [stars])

  const handleChange = (value) => {
    let initialValue = stars
    setNote(value)
    setStars(value)

    if (!itemId) return

    let params = {
      note: value
    }
    apiUpdateEntity('assets', itemId, params, response => {
      if (response['@type'] === 'hydra:Error') {
        message.error(t('Il y a eu une erreur'))
        setNote(initialValue)
      }
    })
  }

  const content = (
    <div onClick={(e) => { e.stopPropagation() }}>
      <Rate value={note} onChange={handleChange} disabled={(!accountState.isWidget && !accountState.editor) || disabled} />
    </div>
  )

  if (inTooltip) {
    return (
      <Tooltip title={accountState.editor ? content : ''} trigger={touch ? "click" : 'hover'}>
        <div>{!!note ? note : ''} <Icon type="star" theme="filled" style={{ color: !!note ? "#fadb14" : '' }} /></div>
      </Tooltip>
    )
  }

  return content;
}
