import React from 'react';
import ListedCards from "./listedCard/ListedCards";
import MosaicCards from "./mosaic/MosaicCards";
import InfiniteScroll from "./InfiniteScroll";
import { useAccountContext } from '../../providers/AccountProvider';

const DisplayCards = ({ infiniteScroll = true, ...props }) => {

  const [accountState] = useAccountContext();
  const { appearance } = accountState

  return (
    <>
      {appearance.card === 'list' ?
        <ListedCards {...props} />
        :
        <MosaicCards {...props} />}

      {infiniteScroll &&
        <InfiniteScroll
          page={props.page}
          setPage={props.setPage}
          loading={props.loading}
          hasMore={props.hasMore}
        />}
    </>
  );
}

export default DisplayCards;
