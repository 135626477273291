import React, { useState } from 'react';
import { Button, Card, Icon, message } from 'antd';
import { useApiContext } from '../../providers/ApiProvider.js';
import PercentCard from '../PercentCard.js';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

const TagsPropositions = ({ handleAdd, assetId }) => {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [tagsPropositions, setTagsPropositions] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [autoTaggingLoading, setAutoTaggingLoading] = useState(false)

  useEffect(() => {
    setTagsPropositions([])
    setIsActive(false)
  }, [assetId])

  const requestTags = () => {
    setIsActive(!isActive);
    if (autoTaggingLoading || tagsPropositions.length > 0) {
      return
    }
    setAutoTaggingLoading(true);
    let parameters = { id: assetId }
    apiFetchSubResourceWithFilter('assets', parameters, 'tagging', (response) => {
      if (response?.tags) {
        setTagsPropositions(response.tags);
      }
      message.warning(t('Aucun tag n\'a été trouvé'))
      setAutoTaggingLoading(false);
    })
  }

  return (
    <>
      <Card className={isActive ? 'stats-card active' : 'stats-card'} onClick={requestTags}
        style={{ cursor: "pointer" }}>
        <Icon type="eye" /> {t('Reconnaissance automatique')} {autoTaggingLoading ? <Icon type="loading" /> : null}
      </Card>

      {isActive ?
        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
          {
            isActive && !autoTaggingLoading ?
              <div style={{ maxWidth: '90%' }}>
                <h4>Tags : </h4>
                {tagsPropositions.map((tag, index) =>
                  <PercentCard key={index} title={tag.tag.fr} percent={tag.confidence} actionText={"Ajouter"}
                    action={() => {
                      handleAdd(tag.tag.fr)
                    }} />
                )}
              </div>
              : null
          }
        </div>
        : <Button style={{ display: "none" }} type="primary"
          onClick={requestTags}>{t('Reconnaissance automatique')}</Button>
      }
    </>
  );
}

export default TagsPropositions;
