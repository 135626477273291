import React from 'react';
import {Route} from 'react-router-dom';
import {useAuthContext} from '../providers/AuthProvider.js';
import PrivateLayout from './layout/Layout.js';

const PrivateRoute = ({component: Component, sidebar, ...rest}) => {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const {authIsLogged, authLogout} = authDispatch

  const renderer = (sidebar,props) => {
    if (authIsLogged() && !auth.visitor) {
      return <PrivateLayout sidebar={sidebar} auth={auth} private={true} {...props}><Component {...props} /></PrivateLayout>
    }
    authLogout()
    window.location.href = '/login'
    return;
  }

  return (
    <Route {...rest} render={renderer.bind(null,sidebar)} />
  );
};

export default PrivateRoute;
