import React, { useState } from 'react'
import WidgetSlider from './WidgetSlider'

export default function WidgetGalery({ images }) {

    const [visible, setVisible] = useState(false)

    const handleClose = () => { setVisible(false) }

    return (
        <>
            <div style={{ height: '200px', color: 'rgba(0, 0, 0, 0.55)' }} className='flex flex-column'>
                <p>Prévisualisation de vos photos: </p>
                {
                    images.length === 0 &&
                    <div className='galery-empty'>Remplissez les critères précédents afin d’obtenir une prévisualisation de photo.</div>
                }
                {
                    images.length > 0 &&
                    <div className='galery-grid' style={{ gridTemplateColumns: `repeat(${images.length < 3 ? images.length : 3}, 1fr)` }}>
                        {
                            images.map((image, index) => index < 5 &&
                                <div onClick={() => setVisible(true)} key={image.uid} style={{ background: `url('${image.display.native.sm}') center / cover` }} />
                            )
                        }
                        {
                            images.length > 5 &&
                            <div onClick={() => setVisible(true)} className='gallery-more'>+ {images.length - 5}</div>
                        }
                    </div>
                }
            </div>
            <WidgetSlider images={images} visible={visible} handleClose={handleClose} />
        </>
    )
}
