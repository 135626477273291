import { Button, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function CropImage({ assetId, image, setImage, media, apiFetchSubResourceWithFilter }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [crop, setCrop] = useState({
        unit: '%',
        x: 10,
        y: 10,
        width: 80,
        aspect: image.width / image.height
    });

    function onChange(crop) {
        setCrop(crop)
    }

    function onOk() {
        if (crop.height < 20 || crop.width < 20) return message.error(t('Votre sélection est trop petite.'))

        setLoading(true)
        let ratio = media.extraUrl.lg.width / document.getElementsByClassName('ReactCrop__image')[0].width;
        let params = {
            id: assetId,
            filters: [
                { name: 'width', value: image.width },
                { name: 'height', value: image.height },
                { name: 'mode', value: 'manuel' },
                { name: 'x', value: crop.x * ratio },
                { name: 'y', value: crop.y * ratio },
                { name: 'dimensionsW', value: crop.width * ratio > 0 ? crop.width * ratio : 1 },
                { name: 'dimensionsH', value: crop.height * ratio > 0 ? crop.height * ratio : 1 },
                { name: 'prefix', value: image.name }
            ]
        }

        apiFetchSubResourceWithFilter('assets', params, 'crop', hydra_response => {
            if (hydra_response.url) {
                setImage(null)
            }
            if (hydra_response['@type'] === 'hydra:Error') {
                message.error(hydra_response['hydra:description'])
            }
            setLoading(false)
        })
    }

    return (
        <Spin spinning={loading}>
            <h2 style={{ textAlign: 'center', color: "#fff", width: '100%', marginBottom: '1em' }}>
                {t('Recadrer votre image')} : {image.name}
            </h2>
            <div className='flex center'>
                <ReactCrop
                    src={media.extraUrl.lg.url}
                    crop={crop}
                    ruleOfThirds
                    onChange={onChange}
                    imageStyle={{ maxHeight: '75vh' }}
                />
            </div>
            <div className='flex center mt-20'>
                <Button key="back" onClick={() => setImage(null)}>
                    {t('Retour')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={onOk}
                >
                    {t('Enregistrer')}
                </Button>
            </div>
        </Spin>
    )
}
