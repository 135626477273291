import React, {useState} from 'react'
import ApiCollectionTable from '../ApiCollectionTable.js';
import { Link } from 'react-router-dom'
import { Icon, Button } from 'antd';
import {useTranslation} from 'react-i18next';
import {stringDate} from '../../helpers.js'

export default function RestoreAccount({apiFetchCollection, apiUpdateEntity}) {

	const {t, i18n} = useTranslation();
	const [reload, setReload] = useState(0)

	const columns = [
	    {
	      title: t('Nom'),
	      dataIndex: 'name',
	      render: (name, reccord) => <Link to={`./admin/account-overview/${reccord.id}`}><Icon type="edit" /> {name}</Link>,
	    },
	    {
	      title: t('Date de destruction'),
	      dataIndex: 'toDelete',
	      render: (toDelete, reccord) => <span>{stringDate(toDelete)}</span>,
	    },
	   	{
	      title: t('Action'),
	      dataIndex: 'action',
	      render: (name, reccord) => <Button type="primary" icon="rollback" onClick={() => restore(reccord.id)}>{t('Restaurer')}</Button>,
	    }		
	]

	const filters = [
		{	
			name: 'status',
			value: 0
		}
	]

	function restore(id) {
		const data = {
			status: 1,
			toDelete: null
		}
		apiUpdateEntity('accounts', id, data, response => {
			//console.log(response)
			setReload(reload + 1)
		})
	}

	return (
		<div className="contenu-tab">
			<ApiCollectionTable
				endpoint="accounts"
				columns={columns}
				filtres={filters}
				reload={reload}
			/>
		</div>
	)
}