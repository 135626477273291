import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import SideBarTitle from "./SideBarTitle";

export default function DeleteFolder({ closeMenu = () => {}}) {

  const { t } = useTranslation();
  const [apiDispatch] = useApiContext();
  const { apiFetchSubResourceWithFilter } = apiDispatch;
  const [accountState, accountDispatch] = useAccountContext();
  const { filter, resetFilter } = accountDispatch;
  const [isSelected, setIsSelected] = useState(false);
  const [total, setTotal] = useState(0);

  const tag = { id: 'deleted', name: t('Éléments supprimés') };

  useEffect(() => {
    fetch();
  }, [accountState.account, accountState.rerender])

  useEffect(() => {
    const selected = accountState.filters.some(item => item.id === tag.id && item.type === tag.id);
    setIsSelected(selected);
  }, [accountState.filters, accountState.accountActive])

  function fetch() {
    const data = {
      id: accountState.accountActive ? accountState.accountActive.id : accountState.account.id,
      itemsPerPage: 1,
      page: 1,
      filters: [{ name: 'status', value: 3 }]

    }

    apiFetchSubResourceWithFilter('accounts', data, 'assets', (response) => {
      if (response['@type'] === "hydra:Collection")
        setTotal(response['hydra:totalItems']);
    })
  }

  function handleClick() {
    resetFilter()
    let filtre = {
      id: tag.id,
      name: 'status',
      value: 3,
      type: tag.id,
      element: tag
    }
    filter(filtre);
    setIsSelected(!isSelected);
    closeMenu()
  }

  return (
    <SideBarTitle
      isSelected={isSelected}
      opacity={accountState.activeTab === '1' ? 'opacity-medium' : ''}
      handleClick={handleClick} name={tag.name}
      total={total}
      icon='warning' />
  )
}
