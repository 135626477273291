import React from "react";

export default function Photoshop(props) {

  return (
    <svg viewBox="0 0 176 176" xmlns="http://www.w3.org/2000/svg" width="100%">
      <g id="Layer_2" data-name="Layer 2">
        <g id="_3.Adobe_Photoshop" data-name="3.Adobe Photoshop">
          <g id="background">
            <path id="background-2" d="m0 0h144.46a31.54 31.54 0 0 1 31.54 31.54v144.46a0 0 0 0 1 0 0h-144.46a31.54 31.54 0 0 1 -31.54-31.54v-144.46a0 0 0 0 1 0 0z" fill="#002732" data-name="background" />
            <path id="shade" d="m144.46 8a23.57 23.57 0 0 1 23.54 23.54v136.46h-136.46a23.57 23.57 0 0 1 -23.54-23.54v-136.46zm0-8h-144.46v144.46a31.54 31.54 0 0 0 31.54 31.54h144.46v-144.46a31.54 31.54 0 0 0 -31.54-31.54z" fill="#00d4ff" />
          </g>
          <g id="icon" fill="#00d4ff">
            <path d="m48.22 117v-58.7a.56.56 0 0 1 .63-.63q6.84-.18 17.37-.18 12.42 0 18.23 5.58a18.34 18.34 0 0 1 5.8 13.77q0 10.16-7.2 15.16t-18.05 4.91h-5.35v20a.64.64 0 0 1 -.72.72h-10q-.71-.02-.71-.63zm11.43-50.87v22.14a55.82 55.82 0 0 0 5.85.18q6 0 9.77-2.66t3.73-8.68q0-5.31-3.28-8.19t-9.5-2.92c-1 0-2 0-3 0s-1.8 0-2.43 0z" />
            <path d="m124.36 84.13a19.58 19.58 0 0 0 -10.53-3.13 7.48 7.48 0 0 0 -4.64 1.17 3.46 3.46 0 0 0 -1.48 2.79 3.85 3.85 0 0 0 1.29 2.82 18.42 18.42 0 0 0 5.31 2.83q7.47 2.88 10.48 6.08a11.5 11.5 0 0 1 3 8.23 12 12 0 0 1 -4.64 9.86q-4.64 3.73-12.55 3.73t-13-2.88a.83.83 0 0 1 -.54-.9v-8.37a.52.52 0 0 1 .22-.49.31.31 0 0 1 .41 0 20.68 20.68 0 0 0 12.51 4.32 8.57 8.57 0 0 0 4.81-1.12 3.41 3.41 0 0 0 1.67-2.93 4.26 4.26 0 0 0 -1.35-3.19 17 17 0 0 0 -5.31-2.84q-7.2-2.79-10-6a11.7 11.7 0 0 1 -2.83-8.06 12.05 12.05 0 0 1 4.23-9.36q4.22-3.69 12.05-3.69 7.29 0 11.25 2 .45.27.45 1.17v7.47q0 1.03-.81.49z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
