import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';

import { useApiContext } from '../providers/ApiProvider.js';

const ApiCollectionTable = ({ endpoint, columns, filtres, reload }) => {

  const [apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '25', '50', '100'],
    position: 'bottom',
  })
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)

  // internal trigger to force natural data fetch based on state change
  const [needUpdate, setNeedUpdate] = useState(0)
  const isMounted = useRef(null);


  useEffect(() => {
    isMounted.current = true;

    // fetch at startup and whenever needUpdate is changed
    fetch({
      itemsPerPage: pagination.pageSize,
      page: pagination.current,
    });
    return () => {
      isMounted.current = false;
    }
  }, [needUpdate, reload, order])

  useEffect(() => {
    setPagination(prev => {return {...prev, current: 1}})
    setNeedUpdate(needUpdate + 1);
  }, [filtres])

  function handleTableChange(table_pagination, filters, sorter) {
    console.log('sorter', sorter)
    if (sorter) {
      setOrder(sorter.order ? {name: `order[${sorter.field}]`, value: sorter.order == "descend" ? 'DESC' : 'ASC'} : null)
    }
    // update current pagination
    const pager = { ...table_pagination };
    pager.current = table_pagination.current;
    setPagination(pager)
    // trigger a data refresh
    setNeedUpdate(needUpdate + 1);
  }

  function fetch(params) {
    setLoading(true)
    params.filters = filtres;
    if (order) {
      params.filters = [...filtres, order]
    }
    apiFetchCollection(endpoint, params, (hydra_response) => {
      if (isMounted.current) {
        const pager = { ...pagination };
        pager.total = hydra_response['hydra:totalItems'];
        setData(hydra_response['hydra:member'])
        setPagination(pager)
        setLoading(false);
      }
    });
  }

  return (
    <Table
      className="ApiCollectionTable"
      columns={columns}
      rowKey={record => record['@id'] ? record['@id'] : record.created}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

export default ApiCollectionTable;
