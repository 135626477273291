import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAccountContext } from "../providers/AccountProvider";

import PublicRoute from './PublicRoute.js'
import PrivateRoute from './PrivateRoute.js'
import PrivateAccountRoute from "./PrivateAccountRoute";

import Login from '../pages/Login.js'
import Logout from '../pages/Logout.js'
import Accounts from '../pages/Accounts.js'
import CreateAccounts from "../pages/CreateAccounts";
import AccountOverview from "../pages/AccountOverview";
import Dashboard from '../pages/Dashboard.js'
import Users from '../pages/Users.js'
import UserOverview from '../pages/User.js'
import GestionAdmin from '../pages/GestionAdmin.js';
import Error from '../pages/Error';
import Support from '../pages/Support';
import Visitor from '../pages/Visitor'
import NewPassword from '../pages/NewPassword'

import WidgetDocumentation from '../pages/WidgetDocumentation';
import WidgetConnect from "./Widget/WidgetConnect"
import WidgetRoute from "./Widget/WidgetRoute";
import FirstConnection from "../pages/FirstConnection";
import ApiDocumentation from '../pages/ApiDocumentation'
import RequestBuilder from '../pages/RequestBuilder';

import Assets from "./asset/Assets";
import AccountGroup from '../pages/AccountGroup.js'
import AccountSeo from '../pages/AccountSeo';

function Router() {

  let location = useLocation();
  const [accountState, accountDispatch] = useAccountContext();
  const { resetState } = accountDispatch;

  useEffect(() => {
    if (!location.pathname.match(/^\/account|account-group\/[0-9]*\/[media|edit|galerie]|^\/support|^\/widget/)) {
      console.log('reset state')
      resetState();
    }
  }, [location.pathname])

  return (
    <>
      <PublicRoute path="/docs" component={ApiDocumentation} />
      <PublicRoute path="/widget-documentation" component={WidgetDocumentation} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/new-password/:token" component={NewPassword} />
      <PublicRoute path="/erreur" component={Error} />
      <PublicRoute exact path="/visitor/:token" component={Visitor} visitor={true} type="share"/>
      <PublicRoute exact path="/cart/:token" component={Visitor} visitor={true} type="cart"/>
      <PublicRoute exact path="/authentication/:id" component={FirstConnection} />

      <PrivateRoute exact path="/" component={Dashboard} sidebar={true} />
      <PrivateRoute path="/accounts" component={Accounts} sidebar={true} />
      <PrivateRoute path="/admin/create-account" component={CreateAccounts} sidebar={true} />
      <PrivateRoute path="/admin/account-overview/:account_id" component={AccountOverview} sidebar={true} />
      <PrivateRoute path="/dashboard" component={Dashboard} sidebar={true} />
      <PrivateRoute path="/users" component={Users} sidebar={true} />
      <PrivateRoute path="/admin/user-overview/:user_id" component={UserOverview} sidebar={true} />
      <PrivateRoute path="/gestion-admin" component={GestionAdmin} sidebar={true} />
      <PrivateRoute path="/logout" component={Logout} />
      <PrivateRoute path="/support" component={Support} sidebar={true} />

      <PrivateAccountRoute exact path="/account/:account_id" component={Assets} sidebar />
      <PrivateAccountRoute exact path="/account/:account_id/seo" component={AccountSeo} />
      <PrivateAccountRoute path="/account-group/:account_id" component={AccountGroup} />

      <WidgetRoute exact path="/widget/:token" sidebar={true} component={Assets} />
      <WidgetRoute exact path="/widget-builder/:token" component={RequestBuilder} sidebar={false} hasLayout={false} />

      <PublicRoute exact path="/widget-connect" component={WidgetConnect} />
    </>
  );
}

export default Router
