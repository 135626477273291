import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAccountContext } from '../../providers/AccountProvider';
import { useApiContext } from '../../providers/ApiProvider';
import Selectable from "../asset/Selectable";

import { Spin } from 'antd';
import AssetDraggable from '../asset/AssetDraggable';
import CardNoRange from '../asset/CardNoRange';
import NoSortBar from '../asset/NoSortBar';
import { useMediaQuery } from 'react-responsive';

export default function AssetNoSort() {

    const [apiDispatch] = useApiContext();
    const { apiFetchSubResourceWithFilter } = apiDispatch;
    const [accountState, accountDispatch] = useAccountContext();
    const { setActiveTab } = accountDispatch;

    const scrollerRef = useRef(null);
    const [renameOn, setRenameOn] = useState(accountState.account.renameOn)

    const [loading, setLoading] = useState(false)
    const [assets, setAssets] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1)
    const params = useParams();

    const [selected, setSelected] = useState(() => new Set());
    const [mode, setMode] = useState('masonry')
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

    const accountEditor = accountState.editor && !accountState.tags.length

    useEffect(() => {
        if (accountState.admin || accountEditor) {

            fetch({
                id: params.account_id,
                itemsPerPage: 60,
                page: page,
                filters: [{
                    name: 'status',
                    value: '0'
                }]
            });
        }
    }, [page, accountState.rerender]);

    function fetch(params) {
        setLoading(true);
        apiFetchSubResourceWithFilter('accounts', params, 'assets', (hydra_response) => {

            if (hydra_response['@type'] === 'hydra:Error')
                return console.log('error: ', hydra_response['description']);


            let totalAssets = hydra_response['hydra:totalItems'];
            if ((totalAssets / 30) <= (page - 1))
                setPage(1);

            setTotal(totalAssets)
            setAssets(hydra_response['hydra:member']);
            if (totalAssets === 0) {
                setActiveTab("2")
                // rerender()
            }
            setLoading(false);
        });
    }


    function handleClick(e, checked, id) {
        return toggleSelect(checked, id)
    }

    const toggleSelect = (checked, id) => {
        setSelected((prev) => {
            // console.log(prev)
            const next = new Set(prev);
            if (checked) {
                next.add(id)
            } else {
                next.delete(id)
            }
            return next;
        });
    }

    const handleCheck = (e) => {
        let checked = e.target.checked
        let id = e.target.data

        toggleSelect(checked, id)
    }

    return (
        <Spin style={{ minWidth: '100%', height: '100%' }} spinning={loading}>
            <NoSortBar totalItems={total} selected={selected} setSelected={setSelected} renameOn={renameOn} setRenameOn={setRenameOn} setLoading={setLoading} page={page} setPage={setPage} items={assets} mode={mode} setMode={setMode} />
            {
                !isTabletOrMobile &&
                <Selectable setSelected={setSelected} scrollerRef={scrollerRef} />
            }
            <div className='selectable-container assets-list' ref={scrollerRef}>
                <div className={`assets-${mode}-grid`}>

                    {assets.map((item) => (
                        <div key={item.id} className="grid-item">
                            <AssetDraggable
                                asset={item}
                                itemsChecked={selected}
                                setItemsChecked={setSelected}
                                renameOn={renameOn}
                                setLoading={setLoading}
                                draggable
                            >
                                <CardNoRange item={item} isSelected={selected.has(item.id)} handleCheck={handleCheck} selectable handleClick={handleClick} />
                            </AssetDraggable>
                        </div>
                    ))}
                </div>
            </div>
        </Spin>
    )
}
